import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import NavBar from '../components/NavBar'
import FrTextInput from '../components/FrTextInput'
import FrSelect from '../components/FrSelect'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'

import api from '../services/api'
import { paramsToObject } from '../utils/functions'

import './styles/DefaultReg.css';
import FooterDelphus from '../components/FooterDelphus';

const swal = withReactContent(Swal)

function RegFuncao() {
    const [data, setData] = useState({})
    const [updateType, setUpdateType] = useState(false)
    const [enableEdit, setEnableEdit] = useState('')

    useEffect(() => {
        const fetchData = async () => {
            swal.fire({
                html: <FrLoading text="Carregando..." />,
                showConfirmButton: false,
                allowOutsideClick: false,
                allowEscapeKey: false
            })
            const urlParams = new URLSearchParams(window.location.search);
            let query = paramsToObject(urlParams)

            if (query.codigo > 0) {
                setEnableEdit(await api.getAcessos('Funcao', 'Alteracao'))
            } else {
                setEnableEdit(await api.getAcessos('Funcao', 'Inclusao'))
            }

            if (query.codigo > 0) {
                setUpdateType(true)
                api.get('/funcao?pk_fnc=' + query.codigo).then(r => {
                    if (r.data[0]) {
                        console.log(r.data[0])
                        setData(r.data[0])
                        swal.close()
                    } else {
                        swal.fire('Código inválido', 'Código informado não encontrado!', 'warning')
                            .then((result) => {
                                if (result.value) {
                                    window.history.back()
                                }
                            })
                    }
                })
            } else swal.close()
        }
        fetchData()
    }, [])

    function consistData(data) {
        let required = [
            'descricao'
        ]

        let errors = []

        required.forEach((curr, index) => {
            let fieldName = curr
            if (curr == 'descricao') fieldName = 'Descrição'

            if (!data[curr]) return errors.push(fieldName)
        })

        return errors
    }

    function handleSubmit(e) {
        e.preventDefault();
        swal.fire({
            title: 'ATENÇÃO',
            text: "Deseja salvar " + (updateType ? 'as alterações' : 'a inclusão') + ' do registro?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                const auxValues = { ...data };
                let errors = consistData(auxValues)
                console.log(errors.length)
                if (errors.length === 0) {
                    if (updateType) {
                        api.post('/funcao/edit', auxValues).then(r => {
                            if (r.status === 200) {
                                swal.fire({
                                    title: 'Registro Alterado',
                                    text: "O registro foi alterado com sucesso!",
                                    icon: 'success',
                                }).then((result) => {
                                    if (result.value) {
                                        window.history.back()
                                    }
                                })
                            } else {
                                swal.fire({
                                    title: 'Erro!',
                                    text: "O registro não foi alterado!",
                                    icon: 'warning',
                                })
                            }
                        })
                    } else {
                        api.post('/funcao/add', auxValues).then(r => {
                            if (r.status === 200) {
                                swal.fire({
                                    title: 'Registro Adicionado',
                                    text: "O registro foi adicionado com sucesso!",
                                    icon: 'success',
                                }).then((result) => {
                                    if (result.value) {
                                        window.history.back()
                                    }
                                })
                            } else {
                                swal.fire({
                                    title: 'Erro!',
                                    text: "O registro não foi adicionado!",
                                    icon: 'warning',
                                })
                            }
                        })
                    }
                    // } else {
                    //     swal.fire({
                    //         title: 'Erro!',
                    //         text: "Verifique o(s) campo(s) " + errors.join(','),
                    //         icon: 'warning',
                    //     })
                }
            }
        })
    }

    function handleCancel(e) {
        e.preventDefault();
        swal.fire({
            title: 'ATENÇÃO',
            text: "Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                window.history.back()
            }
        })
    }

    const handleChange = (e) => {
        const auxValues = { ...data };
        switch (e.target.name) {
            case '':
            default: auxValues[e.target.name] = e.target.value;
        }
        setData(auxValues);
    };

    return (
        <div className="defaultReg">
            <NavBar />
            <div className="defaultRegContent">
                <div className="defaultHeader">
                    <h3>{updateType ? 'Edição' : 'Inclusão'} de Função</h3>
                    <UserOptions />
                </div>
                <br />
                <form id="regForm" onSubmit={handleSubmit}>

                    <FrTextInput
                        maxLength='75'
                        value={data.descricao || ''}
                        onChange={handleChange}
                        name="descricao"
                        id="descricao"
                        color="#9c9595"
                        label="Descrição"
                        variant="outlined"
                        size="small"
                        required
                        style={{ width: 400 }}
                    />

                    <FrSelect
                        value={data.ativo || ''}
                        onChange={handleChange}
                        name="ativo"
                        id="ativo"
                        label='Ativo'
                        style={{ width: 150 }}
                        data={[{ display: 'Sim', value: 'S' }, { display: 'Não', value: 'N' }]}
                    />

                </form>
                <div className="regButtonsDiv">
                    {enableEdit === 'S' ?
                        (<button onClick={handleSubmit}>Salvar</button>) : (<></>)}
                    <button onClick={handleCancel}>{enableEdit === 'S' ? 'Cancelar' : 'Fechar'}</button>
                </div>
                <FooterDelphus />
            </div>
        </div >
    );
}

export default RegFuncao