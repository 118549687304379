import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import { Icon } from 'react-icons-kit'
import NavBar from '../components/NavBar'
import FrTextInput from '../components/FrTextInput'
import FrSelect from '../components/FrSelect'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import FrTableReg from '../components/FrTableReg'
import FrDatePicker from '../components/FrDatePicker'
import Tabs from '../components/Tabs';

import api from '../services/api'
import { paramsToObject } from '../utils/functions'
import { ic_add_box } from 'react-icons-kit/md/ic_add_box'

import './styles/DefaultReg.css';
import FooterDelphus from '../components/FooterDelphus';
import { ClearAll } from '@material-ui/icons';

const swal = withReactContent(Swal)

function RegPalestra() {


    const [modal, setModal] = useState(false)
    const [updatemodal, setUpdateModal] = useState(false)
    const [pkModal, setPkModal] = useState(0)

    const [dataPar, setDataPar] = useState([{ display: '', funcao: '' }])
    const [par_nome, setPar_Nome] = useState('')
    const [fk_par, setfk_par] = useState('')
    const [dataTem, setDataTem] = useState([{ display: '' }])
    const [tem_Titulo, setTem_Titulo] = useState('')
    const [fk_tem, setfk_tem] = useState('')
    const [data, setData] = useState({})
    const [updateType, setUpdateType] = useState(false)
    const [enableEdit, setEnableEdit] = useState('')
    const [comboFuncoes, setComboFuncoes] = useState([{ value: '', display: '' }])
    const [comboMinistrante, setComboMinistrante] = useState([{ value: '', display: '' }])
    const [comboParticipantes, setComboParticipantes] = useState([{ value: '', display: '' }])
    const [comboTemaPalestra, setComboTemaPalestra] = useState([{ value: '', display: '' }])
    const [comboStatus, setComboStatus] = useState([{ value: '', display: '' }])


    const [comboCid, setComboCid] = useState([{ value: '', display: '' }])
    const [comboEst, setComboEst] = useState([{ value: '', display: '' }])
    const [comboClientes, setComboClientes] = useState([{ value: '', display: '' }])

    const columnsPar = [{ id: 'display', numeric: false, label: 'Nome', align: 'left', minWidth: 100 },
    { id: 'funcao', numeric: false, label: 'Função', align: 'left', minWidth: 100 },
    ]

    const columnsTem = [{ id: 'display', numeric: false, label: "Tema", align: 'left', minWidth: 100 },
    ]

    useEffect(() => {
        const fetchData = async () => {
            swal.fire({
                html: <FrLoading text="Carregando..." />,
                showConfirmButton: false,
                allowOutsideClick: false,
                allowEscapeKey: false
            })
            const urlParams = new URLSearchParams(window.location.search);
            let query = paramsToObject(urlParams)

            setComboEst(await api.getComboData('estados'))
            setComboFuncoes(await api.getComboData('Funcao'))
            setComboParticipantes(await api.getComboData('Participantes'))
            setComboTemaPalestra(await api.getComboData('Tema_Palestra'))
            setComboMinistrante(await api.getComboData('Palestra_Ministrante'))
            setComboStatus(await api.getComboData('Situacao_Palestra'))
            setComboClientes(await api.getComboData('Cliente'))

            if (query.codigo > 0) {
                setEnableEdit(await api.getAcessos('Palestra', 'Alteracao'))
            } else {
                setEnableEdit(await api.getAcessos('Palestra', 'Inclusao'))
            }

            if (query.codigo > 0) {
                setUpdateType(true)
                api.get('/palestra?pk_pal=' + query.codigo).then(r => {
                    if (r.data[0]) {
                        console.log(r.data[0])
                        setData(r.data[0])
                        swal.close()

                        api.get('/palestra/participantes?pk_pal=' + query.codigo).then(r => {
                            console.log(r.data)
                            if (r.data.length > 0)
                                setDataPar(r.data)
                        })

                        api.get('/palestra/temas?pk_pal=' + query.codigo).then(r => {
                            console.log(r.data)
                            if (r.data.length > 0)
                                setDataTem(r.data)
                        })

                    } else {
                        swal.fire('Código inválido', 'Código informado não encontrado!', 'warning')
                            .then((result) => {
                                if (result.value) {
                                    window.history.back()
                                }
                            })
                    }
                })
            } else swal.close()
        }
        fetchData()
    }, [])

    useEffect(() => {
        const fetchCidades = async () => {
            if (data.fk_est > 0) {
                swal.fire({
                    html: <FrLoading text="Buscando Cidades..." />,
                    showConfirmButton: false,
                    allowOutsideClick: false,
                    allowEscapeKey: false
                })
                setComboCid(await api.getComboData('cidades', data.fk_est))
                swal.close()
            }
        }

        fetchCidades()
    }, [data.fk_est])



    function consistData(data) {
        let required = [
            'fk_pes_cli', 'fk_min', 'fk_stp', 'fk_cid', 'fk_est'
        ]

        let errors = []

        required.forEach((curr, index) => {
            let fieldName = curr

            if (fieldName === 'fk_pes_cli') fieldName = "Empresa";
            if (fieldName === 'fk_min') fieldName = "Ministrante";
            if (fieldName === 'fk_stp') fieldName = "Status";
            if (fieldName === 'fk_cid') fieldName = "Cidade";
            if (fieldName === 'fk_est') fieldName = "Estado";

            if (!data[curr]) return errors.push(fieldName)
        });

        return errors
    }


    function handleSubmit(e) {
        e.preventDefault();
        swal.fire({
            title: 'ATENÇÃO',
            text: "Deseja salvar " + (updateType ? 'as alterações' : 'a inclusão') + ' do registro?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                const auxValues = { ...data };
                auxValues.dataPtm = dataTem
                auxValues.dataPpa = dataPar
                let errors = consistData(auxValues)
                console.log(errors.length)
                if (errors.length === 0) {
                    if (updateType) {
                        {
                            if (verificaCampos()) {

                                api.post('/palestra/edit', auxValues).then(r => {
                                    if (r.status === 200) {
                                        swal.fire({
                                            title: 'Registro Alterado',
                                            text: "O registro foi alterado com sucesso!",
                                            icon: 'success',
                                        }).then((result) => {
                                            if (result.value) {
                                                window.history.back()
                                            }
                                        })
                                    } else {
                                        swal.fire({
                                            title: 'Erro!',
                                            text: "O registro não foi alterado!",
                                            icon: 'warning',
                                        })
                                    }

                                })
                            }
                        }
                    } else {
                        if (verificaCampos()) {

                            api.post('/palestra/add', auxValues).then(r => {
                                if (r.status === 200) {
                                    swal.fire({
                                        title: 'Registro Adicionado',
                                        text: "O registro foi adicionado com sucesso!",
                                        icon: 'success',
                                    }).then((result) => {
                                        if (result.value) {
                                            window.history.back()
                                        }
                                    })
                                } else {
                                    swal.fire({
                                        title: 'Erro!',
                                        text: "O registro não foi adicionado!",
                                        icon: 'warning',
                                    })
                                }
                            })
                        }
                    }
                } else {
                    swal.fire({
                        title: 'Erro!',
                        text: "Verifique o(s) campo(s) " + errors.join(', '),
                        icon: 'warning',
                    })
                }
            }
        })
    }

    function verificaCampos() {
        let atualiza = true;
        if (data.fk_stp == 3) {
            if ((data.data_realizada || '').split('T')[0] == '') {
                swal.fire({
                    title: 'Erro!',
                    text: "Informe a data de realização da palestra.",
                    icon: 'warning'
                })

                atualiza = false;
            }

        } else {
            if ((data.data_realizada || '').split('T')[0] != '') {
                swal.fire({
                    title: 'Erro!',
                    text: "Altere o status da palestra para 'Realizada'.",
                    icon: 'warning'
                })
                atualiza = false;
            } else
                atualiza = true;
        }
        return atualiza;
    }

    function handleCancel(e) {
        e.preventDefault();
        swal.fire({
            title: 'ATENÇÃO',
            text: "Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                window.history.back()
            }
        })
    }

    function mostraModal(e) {
        e.preventDefault()
        setfk_par('')
        setfk_tem('')
        setUpdateModal(false)
        setModal(true)

    }

    function closeModal(e) {
        e.preventDefault()
        setModal(false)
    }

    function handleSubmitModalParticipantes(e) {
        e.preventDefault()
        if (fk_par) {
            if (!updatemodal)
                incluiPar(e)
            else
                updatePar(e)
            closeModal(e)
        } else
            swal.fire({
                title: 'Erro!',
                text: "Por favor, informe o participante.",
                icon: 'warning',
            })
    }

    async function incluiPar(e) {

        e.preventDefault();
        let nomepar = ''
        let nomefun = ''
        let pk = 1
        let erroDup = false

        console.log(dataPar)

        await api.get('/participantes?pk_par=' + fk_par).then(r => {
            if (r.data.length > 0) {
                console.log(r.data, dataPar)
                if(dataPar.filter(item => { return item.fk_par == r.data[0].pk_par}).length > 0){
                    console.log('oi')
                    erroDup = true
                } 
                
                nomepar = r.data[0].nome
                nomefun = r.data[0].descricao

                
            }
        })

        if(erroDup){
            return swal.fire({
                title: 'Erro!',
                text: 'Participante já adicionado',
                icon: 'error'
            })
        }

        if (dataPar[0].display === '') {
            setDataPar([{
                fk_par: fk_par,
                funcao: nomefun,
                display: nomepar,
                pk: pk,

            }])

        } else {

            let item = [{
                fk_par: dataPar[0].fk_par,
                pk: pk,
                funcao: dataPar[0].funcao,
                display: dataPar[0].display

            }]
            pk++
            var i;
            for (i = 1; i < dataPar.length; i++) {
                item.push({
                    fk_par: dataPar[i].fk_par,
                    pk: pk,
                    funcao: dataPar[i].funcao,
                    display: dataPar[i].display

                })
                pk++
            }
            item.push({
                fk_par: fk_par,
                pk: pk,
                funcao: nomefun,
                display: nomepar
            })
            pk++
            setDataPar(item)
        }
    }

    async function updatePar(e) {
        e.preventDefault();

        let nomepar = ''
        let nomefun = ''
        let item = []
        let i

        await api.get('/participantes?pk_par=' + fk_par).then(r => {
            if (r.data.length > 0) {
                nomepar = r.data[0].nome
                nomefun = r.data[0].descricao
            }
        })

        console.log(dataPar)
        for (i = 0; i < dataPar.length; i++) {
            if (dataPar[i].pk == pkModal) {

                item.push({
                    fk_par: fk_par,
                    funcao: nomefun,
                    pk: pkModal,
                    display: nomepar
                })
            } else {
                item.push({
                    fk_par: dataPar[i].fk_par,
                    pk: dataPar[i].pk,
                    funcao: dataPar[i].funcao,
                    display: dataPar[i].display
                })
            }

            if (i + 1 == dataPar.length) {
                console.log(dataPar)
                setDataPar(item)
            }

        }
    }

    function handleSubmitModalTemas(e) {
        e.preventDefault()
        if (fk_tem) {
            if (!updatemodal)
                incluiTem(e)
            else
                updateTem(e)
            closeModal(e)
        } else
            swal.fire({
                title: 'Erro!',
                text: "Por favor, informe o tema.",
                icon: 'warning',
            })

    }

    async function incluiTem(e) {

        e.preventDefault();
        let tituloTem = ''
        let pk = 1

        await api.get('/tema_palestra?pk_tem=' + fk_tem).then(r => {
            if (r.data.length > 0)
                tituloTem = r.data[0].titulo
        })

        if (dataTem[0].display === '') {
            setDataTem([{
                fk_tem: fk_tem,
                display: tituloTem,
                pk: pk,

            }])

        } else {

            let item = [{
                fk_tem: dataTem[0].fk_tem,
                pk: pk,
                display: dataTem[0].display

            }]
            pk++
            var i;
            for (i = 1; i < dataTem.length; i++) {
                item.push({
                    fk_tem: dataTem[i].fk_tem,
                    pk: pk,
                    display: dataTem[i].display

                })
                pk++
            }
            item.push({
                fk_tem: fk_tem,
                pk: pk,
                display: tituloTem
            })
            pk++
            setDataTem(item)
        }
    }

    async function updateTem(e) {
        e.preventDefault();

        let tituloTem = ''

        let item = []
        let i

        await api.get('/tema_palestra?pk_tem=' + fk_tem).then(r => {
            if (r.data.length > 0)
                tituloTem = r.data[0].titulo
        })

        for (i = 0; i < dataTem.length; i++) {
            if (dataTem[i].pk == pkModal) {

                item.push({
                    fk_tem: fk_tem,
                    pk: pkModal,
                    display: tituloTem
                })
            } else {
                item.push({
                    fk_tem: dataTem[i].fk_tem,
                    pk: dataTem[i].pk,
                    display: dataTem[i].display
                })
            }

            if (i + 1 == dataTem.length)
                setDataTem(item)
        }

    }

    const handleChange = (e) => {
        const auxValues = { ...data };
        switch (e.target.name) {
            default: auxValues[e.target.name] = e.target.value;
        }

        setData(auxValues);
    }

    const handleChangeModal = (e) => {
        if (e.target.name == 'par_nome')
            setPar_Nome(e.target.value)
        if (e.target.name == 'fk_par')
            setfk_par(e.target.value)

    }

    const handleChangeModalTema = (e) => {

        if (e.target.name == 'fk_tem')
            setfk_tem(e.target.value)
        if (e.target.name == 'tituloTem')
            setTem_Titulo(e.target.value)
    }


    return (
        <div className="defaultReg">
            <NavBar />
            <div className="defaultRegContent">
                <div className="defaultHeader">
                    <h3>{updateType ? 'Edição' : 'Inclusão'} de Palestra</h3>
                    <UserOptions />
                </div>
                <br />

                <form id="regForm" onSubmit={handleSubmit}>

                    <FrSelect
                        maxLength='75'
                        value={data.fk_pes_cli || ''}
                        onChange={handleChange}
                        name="fk_pes_cli"
                        id="fk_pes_cli"
                        color="#9c9595"
                        label="Empresa *"
                        variant="outlined"
                        size="small"
                        required
                        style={{ width: 300 }}
                        data={comboClientes}
                    />

                    <FrSelect
                        maxLength='75'
                        value={data.fk_min || ''}
                        onChange={handleChange}
                        name="fk_min"
                        id="fk_min"
                        color="#9c9595"
                        label="Ministrante *"
                        variant="outlined"
                        size="small"
                        required
                        style={{ width: 300 }}
                        data={comboMinistrante}
                    />

                    <br />
                    <FrTextInput
                        maxLength='50'
                        value={data.local || ''}
                        onChange={handleChange}
                        name="local"
                        id="local"
                        color="#49573B"
                        label="Local"
                        variant="outlined"
                        size="small"
                        style={{ width: 300 }}
                    />

                    <FrSelect
                        maxLength='75'
                        value={data.fk_stp || ''}
                        onChange={handleChange}
                        name="fk_stp"
                        id="fk_stp"
                        color="#9c9595"
                        label="Status *"
                        variant="outlined"
                        size="small"
                        required
                        style={{ width: 300 }}
                        data={comboStatus}
                    />
                    <br />
                    <FrSelect
                        maxLength='75'
                        value={data.fk_est || ''}
                        onChange={handleChange}
                        name="fk_est"
                        id="fk_est"
                        color="#9c9595"
                        label="Estado *"
                        variant="outlined"
                        size="small"
                        required
                        style={{ width: 300 }}
                        data={comboEst}
                    />

                    <FrSelect
                        maxLength='75'
                        value={data.fk_cid || ''}
                        onChange={handleChange}
                        name="fk_cid"
                        id="fk_cid"
                        color="#9c9595"
                        label="Cidade *"
                        variant="outlined"
                        size="small"
                        required
                        style={{ width: 300 }}
                        data={comboCid}
                    />

                    <br />
                    <FrDatePicker
                        name="data_prevista"
                        id="data_prevista"
                        color="#9c9595"
                        label="Data Prevista"
                        variant="outlined"
                        size="small"
                        style={{ width: 220 }}
                        value={(data.data_prevista || '').split('T')[0]}
                        onChange={handleChange}

                    />

                    <FrDatePicker
                        name="data_realizada"
                        id="data_realizada"
                        color="#9c9595"
                        label="Data Realizada"
                        variant="outlined"
                        size="small"
                        style={{ width: 220 }}
                        value={(data.data_realizada || '').split('T')[0]}
                        onChange={handleChange}

                    />
                    <br />
                    <FrTextInput
                        maxLength='1000'
                        name='descricao_tema'
                        id='descricao_tema'
                        color='#9c9595'
                        label='Descrição do Tema'
                        variant="filled"
                        size='small'
                        style={{ width: 820 }}
                        multiline
                        rows="11"
                        rowsMax="11"
                        value={data.descricao_tema || ''}
                        onChange={handleChange}
                        fullWidth
                    />

                    <br />
                    <FrTextInput
                        maxLength='1000'
                        name='observacoes'
                        id='observacoes'
                        color='#9c9595'
                        label='Observações'
                        variant="filled"
                        size='small'
                        style={{ width: 820 }}
                        multiline
                        rows="11"
                        rowsMax="11"
                        value={data.observacoes || ''}
                        onChange={handleChange}
                        fullWidth
                    />

                </form>

                <Tabs setModal={""} aba1_caption="Participantes" aba1_hidden={false} Aba1={<div>

                    <div class="row" style={{ paddingTop: '10px', paddingLeft: '30px' }}>
                    </div>

                    <div className="grupoEdits">
                        <button onClick={(e) => mostraModal(e)}>
                            <Icon icon={ic_add_box} size={18} />
                            Incluir
                        </button>

                        <div className="manutReg" >
                            {<FrTableReg setPkModal={setPkModal} setUpdateModal={setUpdateModal} setModal={setModal} setfk_par={setfk_par}
                                editar={true} setDataGrid={setDataPar} detail regName="" columns={columnsPar} searched={false} codeName="display" page={''} data={dataPar} />}

                            <div className="register" hidden={!modal}>
                                <div className="fields">

                                    <FrSelect
                                        value={fk_par || ''}
                                        onChange={handleChangeModal}
                                        name="fk_par"
                                        id="fk_par"
                                        label='Participante'
                                        data={comboParticipantes}
                                        style={{ width: 250 }}
                                    />

                                    <div className="regButtonsDiv">
                                        <button onClick={handleSubmitModalParticipantes}>Salvar</button>
                                        <button onClick={closeModal}>Cancelar</button>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>}
                    aba2_caption="Temas" aba2_hidden={false} Aba2={<div>
                        <div class="row" style={{ paddingTop: '10px', paddingLeft: '30px' }}>
                        </div>

                        <div className="grupoEdits">
                            <button onClick={(e) => mostraModal(e)}>
                                <Icon icon={ic_add_box} size={18} />
                                Incluir
                            </button>

                            <div className="manutReg" >
                                {<FrTableReg setPkModal={setPkModal} setUpdateModal={setUpdateModal} setModal={setModal} setfk_tem={setfk_tem}
                                    editar={true} setDataGrid={setDataTem} detail regName="" columns={columnsTem} searched={false} codeName="display" page={''} data={dataTem} />}

                                <div className="register" hidden={!modal}>
                                    <div className="fields">

                                        <FrSelect
                                            value={fk_tem || ''}
                                            onChange={handleChangeModalTema}
                                            name="fk_tem"
                                            id="fk_tem"
                                            label='Temas'
                                            data={comboTemaPalestra}
                                            style={{ width: 250 }}
                                        />

                                        <div className="regButtonsDiv">
                                            <button onClick={handleSubmitModalTemas}>Salvar</button>
                                            <button onClick={closeModal}>Cancelar</button>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>}






                ></Tabs>

                <div className="regButtonsDiv">
                    {enableEdit === 'S' ?
                        (<button onClick={handleSubmit}>Salvar</button>) : (<></>)}
                    <button onClick={handleCancel}>{enableEdit === 'S' ? 'Cancelar' : 'Fechar'}</button>
                </div>
                <FooterDelphus />
            </div>
        </div >
    );
}

export default RegPalestra