import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import NavBar from '../components/NavBar'
import FrTextInput from '../components/FrTextInput'
import FrSelect from '../components/FrSelect'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import FrDatePicker from '../components/FrDatePicker'
import Tooltip from '@material-ui/core/Tooltip'
import FrSwitch from '../components/FrSwitch'

import api from '../services/api'
import { paramsToObject } from '../utils/functions'

import './styles/DefaultReg.css';
import FooterDelphus from '../components/FooterDelphus';

const swal = withReactContent(Swal)

function RegAnotacoes() {
  const [data, setData] = useState({ativo: 'S'})
  const [updateType, setUpdateType] = useState(false)
  const [enableEdit, setEnableEdit] = useState('N')
  const [comboCliente, setComboCliente] = useState([{ value: '', display: '' }])
  const [comboPiloto, setComboPiloto] = useState([{ value: '', display: '' }])
  const [comboAer, setComboAer] = useState([{ value: '', display: '' }])
  const [comboApu, setComboApu] = useState([{ value: '', display: '' }])
  const [comboAro, setComboAro] = useState([{ value: '', display: '' }])
  const [comboMecanico, setComboMecanico] = useState([{ value: '', display: '' }])
  const [comboOrg, setComboOrg] = useState([{ value: '', display: '' }])
  const [comboAca, setComboAca] = useState([{ value: '', display: '' }])
  const [comboAsi, setComboAsi] = useState([{ value: '', display: '' }])
  const [blockRelacionado, setBlockRelacionado] = useState(false)
  const [selectedRelacionado, setSelectedRelacionado] = useState({})
  const [comboTipo, setComboTipo] = useState(
    [ 
      {display: 'Aeronave', value: 'aer'}, 
      {display: 'Aeroporto', value: 'aro'}, 
      {display: 'Apua', value: 'apu'},
      {display: 'Empresa', value: 'cli'}, 
      {display: 'Mecânico', value: 'mec'}, 
      {display: 'Órgão', value: 'org'},
      {display: 'Piloto', value: 'pil'},  
    ]);

  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)

      if (query.codigo > 0) {
        setEnableEdit(await api.getAcessos('Anotacoes', 'Alteracao'))
      } else {
        setEnableEdit(await api.getAcessos('Anotacoes', 'Inclusao'))
      }

      setComboAca(await api.getComboData('categoria_anotacao'))
      setComboAsi(await api.getComboData('situacao_anotacao'))

      
      setComboCliente(await api.getComboData('cliente'));
      
      setComboMecanico(await api.getComboData('mecanicos'));
      
      setComboAer(await api.getComboData('aeronave'));

      setComboAro(await api.getComboData('aeroporto'));
      
      setComboApu(await api.getComboData('apua'));

      setComboOrg(await api.getComboData('orgao'));
      
      setComboAer(await api.getComboData('aeronave'));

      setComboPiloto(await api.getComboData('piloto'));      

      if (query.codigo > 0) {
        setUpdateType(true)
        api.get('/anotacoes?pk_ano=' + query.codigo).then(r => {
          if (r.data[0]) {
            setData(r.data[0])
            swal.close()
            console.log(r.data[0])            
          } else {
            swal.fire('Código inválido', 'Código informado não encontrado!', 'warning')
              .then((result) => {
                if (result.value) {
                  window.history.back()
                }
              })
          }
        })
      } else {
        setBlockRelacionado(true)
        swal.close()
      }
    }

    fetchData()
  }, [])

  const relacionados = [
    {
      type: 'cli',
      value: data.fk_pes_cli,
      name: 'fk_pes_cli',
      id: 'fk_pes_cli',
      label: 'Empresa',
      data: comboCliente
    },
    {
      type: 'mec',
      value: data.fk_pes_mec,
      name: 'fk_pes_mec',
      id: 'fk_pes_mec',
      label: 'Mecânico',
      data: comboMecanico
    },
    {
      type: 'pil',
      value: data.fk_pes_pil,
      name: 'fk_pes_pil',
      id: 'fk_pes_pil',
      label: 'Piloto',
      data: comboPiloto
    },
    {
      type: 'aer',
      value: data.fk_aer,
      name: 'fk_aer',
      id: 'fk_aer',
      label: 'Aeronave',
      data: comboAer
    },
    {
      type: 'aro',
      value: data.fk_aro,
      name: 'fk_aro',
      id: 'fk_aro',
      label: 'Aeroporto',
      data: comboAro
    },
    {
      type: 'apu',
      value: data.fk_apu,
      name: 'fk_apu',
      id: 'fk_apu',
      label: 'Apua',
      data: comboApu
    },
    {
      type: 'org',
      value: data.fk_org,
      name: 'fk_org',
      id: 'fk_org',
      label: 'Órgão',
      data: comboOrg
    },
  ]

  // const fetchRelacionado = async () => {
  //   if (data.tipo) {
  //     swal.fire({
  //       html: <FrLoading text={"Buscando Relacionado..."} />,
  //       showConfirmButton: false,
  //       allowOutsideClick: false,
  //       allowEscapeKey: false
  //     })
  //     if (!updateType) {
  //       data.fk_pes_pil = null
  //       data.fk_pes_cli = null
  //       data.fk_aer = null
  //       data.fk_pes_mec = null
  //       data.fk_apu = null
  //       data.fk_org = null
  //       data.fk_aro = null
  //     }
  //     let relacionado = relacionados.filter((item) => item.type === data.tipo)
  //     switch (data.tipo) {
  //       case 'cli':
  //         relacionado[0].data = await api.getComboData('cliente')
  //         setComboCliente(relacionado[0].data)
  //       break

  //       case 'aer':
  //         relacionado[0].data = await api.getComboData('aeronave')
  //         setComboAer(relacionado[0].data)
  //       break

  //       case 'aro':
  //         relacionado[0].data = await api.getComboData('aeroporto')
  //         setComboAro(relacionado[0].data)
  //       break

  //       case 'apu':
  //         relacionado[0].data = await api.getComboData('apua')
  //         setComboApu(relacionado[0].data)
  //       break

  //       case 'org':
  //         relacionado[0].data = await api.getComboData('orgao')
  //         setComboOrg(relacionado[0].data)
  //       break

  //       case 'mec':
  //         relacionado[0].data = await api.getComboData('mecanicos')
  //         setComboMecanico(relacionado[0].data)
  //       break

  //       case 'pil':
  //         relacionado[0].data = await api.getComboData('piloto')
  //         setComboPiloto(relacionado[0].data)
  //       break
  //     }

  //     setSelectedRelacionado(relacionado[0])
  //     swal.close()
  //     setBlockRelacionado(false)
  //   } else setBlockRelacionado(true)
  // }


  // useEffect(() => {    

  //   fetchRelacionado()
  // }, [data.tipo])

  function consistData(data) {
    let required = [

    ]

    let errors = []

    required.forEach((curr, index) => {
      let fieldName = curr
      if (!data[curr]) return errors.push(fieldName)
    })

    if ((!data.fk_pes_pil) && (!data.fk_pes_cli) && (!data.fk_aer) && (!data.fk_pes_mec) && (!data.fk_apu) && (!data.fk_aro) && (!data.fk_org)) errors.push('Relacionado')

    return errors
  }

  function handleSubmit(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Deseja salvar " + (updateType ? 'as alterações' : 'a inclusão') + ' do registro?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        const auxValues = { ...data };
        let errors = consistData(auxValues)
        console.log(errors.length)
        auxValues.data = auxValues.data ? auxValues.data.split('T')[0] : null
        auxValues.prazo = auxValues.prazo ? auxValues.prazo.split('T')[0] : null
        if (errors.length === 0) {
          if (updateType) {
            api.post('/anotacoes/edit', auxValues).then(r => {
              if (r.status === 200) {
                swal.fire({
                  title: 'Registro Alteraddo',
                  text: "O registro foi alterado com sucesso!",
                  icon: 'success',
                }).then((result) => {
                  if (result.value) {
                    window.history.back()
                  }
                })
              } else {
                swal.fire({
                  title: 'Erro!',
                  text: "O registro não foi alterado!",
                  icon: 'warning',
                })
              }
            })
          } else {
            api.post('/anotacoes/add', auxValues).then(r => {
              if (r.status === 200) {
                swal.fire({
                  title: 'Registro Adicionado',
                  text: "O registro foi adicionado com sucesso!",
                  icon: 'success',
                }).then((result) => {
                  if (result.value) {
                    window.history.back()
                  }
                })
              } else {
                swal.fire({
                  title: 'Erro!',
                  text: "O registro não foi adicionado!",
                  icon: 'warning',
                })
              }
            })
          }
        } else {
          swal.fire({
            title: 'Erro!',
            text: "Verifique o(s) campo(s) " + errors.join(','),
            icon: 'warning',
          })
        }
      }
    })
  }

  function handleCancel(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        window.history.back()
      }
    })
  }

  const handleChange = (e) => {
    const auxValues = { ...data };
    console.log(auxValues)
    switch (e.target.name) {
      case 'recurso':
        auxValues[e.target.name] = e.target.checked ? 'S' : 'N';
      break;
      case 'acompanha':
        auxValues[e.target.name] = e.target.checked ? 'S' : 'N';
      break;

      default: auxValues[e.target.name] = e.target.value;
    }
    setData(auxValues);
  };

  

  return (
    <div className="defaultReg">
      <NavBar />
      <div className="defaultRegContent">
        <div className="defaultHeader">
          <h3>{updateType ? 'Edição' : 'Inclusão'} de Anotações</h3>
          <UserOptions />
        </div>
        <br />
        <form id="regForm" onSubmit={handleSubmit}>
          <FrDatePicker
            maxLength='50'
            value={(data.data || '').split('T')[0]}
            onChange={handleChange}
            name="data"
            id="data"
            color="#9c9595"
            label="Data"
            variant="outlined"
            size="small"
            style={{ width: 200 }}
          />
          <br/>
          <FrSelect
            value={data.fk_aca || ''}
            onChange={handleChange}
            name="fk_aca"
            id="fk_aca"
            label='Categoria'
            data={comboAca}
            style={{ width: 220 }}
          />
          <br/>
          <FrSelect
            value={data.fk_asi || ''}
            onChange={handleChange}
            name="fk_asi"
            id="fk_asi"
            label='Situação'
            data={comboAsi}
            style={{ width: 220 }}
          />
          <br/>
          <FrSelect
            value={data.tipo || ''}
            onChange={handleChange}
            name="tipo"
            id="tipo"
            label='Relacionado'
            data={comboTipo}
            style={{ width: 120 }}
          />

          <Tooltip title='Selecione o tipo de relacionado'>
            <FrSelect
              data={[{value: '', display: ''}]}
              style={{ width: data.tipo != null && data.tipo != '' ? 0 : 300 }}
              disabled
              hidden={data.tipo != null && data.tipo != ''}
            />
          </Tooltip>
          
          <FrSelect
            value={data.fk_aer}
            onChange={handleChange}
            name="fk_aer"
            id="fk_aer"
            label="Aeronave"
            data={comboAer}
            style={{ width: data.tipo != 'aer'? 0 : 300 }}
            hidden={data.tipo != 'aer'}
          />

          <FrSelect
            value={data.fk_aro}
            onChange={handleChange}
            name="fk_aro"
            id="fk_aro"
            label="Aeroporto"
            data={comboAro}
            style={{ width: data.tipo != 'aro'? 0 : 300 }}
            hidden={data.tipo != 'aro'}
          />
          
          <FrSelect
            value={data.fk_apu}
            onChange={handleChange}
            name="fk_apu"
            id="fk_apu"
            label="Apua"
            data={comboApu}
            style={{ width: data.tipo != 'apu'? 0 : 300 }}
            hidden={data.tipo != 'apu'}
          />

          <FrSelect
            value={data.fk_pes_cli}
            onChange={handleChange}
            name="fk_pes_cli"
            id="fk_pes_cli"
            label="Empresa"
            data={comboCliente}
            style={{ width: data.tipo != 'cli'? 0 : 300 }}
            hidden={data.tipo != 'cli'}
          />

          <FrSelect
            value={data.fk_pes_mec}
            onChange={handleChange}
            name="fk_pes_mec"
            id="fk_pes_mec"
            label="Mecânico"
            data={comboMecanico}
            style={{ width: data.tipo != 'mec'? 0 : 300 }}
            hidden={data.tipo != 'mec'}
          />

          <FrSelect
            value={data.fk_org}
            onChange={handleChange}
            name="fk_org"
            id="fk_org"
            label="Órgão"
            data={comboOrg}
            style={{ width: data.tipo != 'org'? 0 : 300 }}
            hidden={data.tipo != 'org'}
          />

          <FrSelect
            value={data.fk_pes_pil}
            onChange={handleChange}
            name="fk_pes_pil"
            id="fk_pes_pil"
            label="Piloto"
            data={comboPiloto}
            style={{ width: data.tipo != 'pil'? 0 : 300 }}
            hidden={data.tipo != 'pil'}
          />

          <FrTextInput
            maxLength='300'
            value={data.anotacao || ''}
            onChange={handleChange}
            name="anotacao"
            id="anotacao"
            color="#9c9595"
            label="Anotação"
            variant="outlined"
            size="small"
            style={{ width: '100%' }}
            rows={5}
            maxRows={5}
            multiline
          />  
          <FrSwitch
            checked={(data.acompanha || null) === 'S'}
            value={data.acompanha}
            name="acompanha" 
            id="acompanha" 
            label='Acompanhamento' 
            onChange={handleChange}
          />
          <FrDatePicker
            maxLength='50'
            value={(data.prazo || '').split('T')[0]}
            onChange={handleChange}
            name="prazo"
            id="prazo"
            color="#9c9595"
            label="Data"
            variant="outlined"
            size="small"
            style={{ width: 200 }}
          />  
          <FrTextInput
            maxLength='300'
            value={data.observacoes || ''}
            onChange={handleChange}
            name="observacoes"
            id="observacoes"
            color="#9c9595"
            label="Observações"
            variant="outlined"
            size="small"
            style={{ width: '100%' }}
            rows={5}
            maxRows={5}
            multiline
          /> 
        </form>
        <div className="regButtonsDiv">
          {enableEdit === 'S' ?
            (<button onClick={handleSubmit}>Salvar</button>) : (<></>)}
          <button onClick={handleCancel}>{enableEdit === 'S' ? 'Cancelar' : 'Fechar'}</button>
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default RegAnotacoes;
