import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import NavBar from '../components/NavBar'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import TabsAeroporto from '../components/TabsAeroporto'

import api from '../services/api'
import { paramsToObject, validarCNPJ, onlyNumbers, removeSpecialChar, validarCPF } from '../utils/functions'
import { mCNPJ, mTel, mCEP, mCPF } from '../utils/masks'

import './styles/DefaultReg.css';
import FooterDelphus from '../components/FooterDelphus';

const swal = withReactContent(Swal)

function RegAeroporto() {
  const [data, setData] = useState({ ativo: 'S' })
  const [updateType, setUpdateType] = useState(false)
  const [enableEdit, setEnableEdit] = useState('N')
  const [comboCidades, setComboCidades] = useState([{ value: '', display: '' }])
  const [comboTipo, setComboTipo] = useState([{ value: '', display: '' }])
  const [comboSituacao, setComboSituacao] = useState([{ value: '', display: '' }])
  const [codigo, setCodigo] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })

      setComboTipo(await api.getComboData('tipo_aeroporto'))
      setComboCidades(await api.getComboData('cidades'))
      setComboSituacao(await api.getComboData('situacao_aeroporto'))

      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)

      if (query.codigo > 0) {
        setEnableEdit(await api.getAcessos('Aeroporto', 'Alteracao'))
      } else {
        setEnableEdit(await api.getAcessos('Aeroporto', 'Inclusao'))
      }

      if (query.codigo > 0) {
        setUpdateType(true)
        setCodigo(query.codigo)
        api.get('/aeroporto?pk_aro=' + query.codigo).then(r => {
          if (r.data[0]) {
            r.data[0].fone1 = mTel(r.data[0].fone1 || '')
            r.data[0].fone2 = mTel(r.data[0].fone2 || '')
            r.data[0].celular = mTel(r.data[0].celular || '')
            r.data[0].cep = mCEP(r.data[0].cep || '')
            setData(r.data[0])
            swal.close()

          } else {
            swal.fire('Código inválido', 'Código informado não encontrado!', 'warning')
              .then((result) => {
                if (result.value) {
                  window.history.back()
                }
              })
          }
        })
      } else swal.close()
    }

    fetchData()
  }, [])

  function handleSubmit(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Deseja salvar " + (updateType ? 'as alterações' : 'a inclusão') + ' do registro?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        const auxValues = { ...data };
        auxValues.cep = onlyNumbers(auxValues.cep)
        auxValues.fone1 = onlyNumbers(auxValues.fone1)
        auxValues.fone2 = onlyNumbers(auxValues.fone2)
        auxValues.celular = onlyNumbers(auxValues.celular)
        let errors = []
        if (errors.length === 0) {
          if (updateType) {
            api.post('/aeroporto/edit', auxValues).then(r => {
              if (r.status === 200) {
                swal.fire({
                  title: 'Registro Alterado',
                  text: "O registro foi alterado com sucesso!",
                  icon: 'success',
                }).then((result) => {
                  if (result.value) {
                    window.history.back()
                  }
                })
              } else {
                swal.fire({
                  title: 'Erro!',
                  text: "O registro não foi alterado!",
                  icon: 'warning',
                })
              }
            })
          } else {
            api.post('/aeroporto/add', auxValues).then(r => {
              if (r.status === 200) {
                swal.fire({
                  title: 'Registro Adicionado',
                  text: "O registro foi adicionado com sucesso!",
                  icon: 'success',
                }).then((result) => {
                  if (result.value) {
                    window.history.back()
                  }
                })
              } else {
                swal.fire({
                  title: 'Erro!',
                  text: "O registro não foi adicionado!",
                  icon: 'warning',
                })
              }
            })
          }
        } else {
          swal.fire({
            title: 'Erro!',
            text: "Verifique o(s) campo(s) " + errors.join(','),
            icon: 'warning',
          })
        }
      }
    })
  }

  function handleCancel(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        window.history.back()
      }
    })
  }

  const handleChange = (e) => {
    const auxValues = { ...data };
    switch (e.target.name) {
      case 'fone1':
      case 'fone2':
      case 'celular':
        auxValues[e.target.name] = mTel(e.target.value)
      break

      case 'cpf':
        auxValues[e.target.name] = mCPF(e.target.value)
      break

      case 'cep':
        auxValues[e.target.name] = mCEP(e.target.value)
      break
  
      default: auxValues[e.target.name] = e.target.value;
    }
    setData(auxValues);
  };

  
  return (
    <div className="defaultReg">
      <NavBar />
      <div className="defaultRegContent">
        <div className="defaultHeader">
          <h3>{updateType ? 'Edição' : 'Inclusão'} de Aeroporto</h3>
          <UserOptions />
        </div>
        <br />
        <form id="regForm" onSubmit={handleSubmit}>
          <TabsAeroporto
            data={data}
            handleChange={handleChange}
            setData={setData}
            comboSituacao={comboSituacao}
            comboTipo={comboTipo}
            comboCidades={comboCidades}
            pk_aro={codigo} />
        </form>
        <div className="regButtonsDiv">
          {enableEdit === 'S' ?
            (<button onClick={handleSubmit}>Salvar</button>) : (<></>)}
          <button onClick={handleCancel}>{enableEdit === 'S' ? 'Cancelar' : 'Fechar'}</button>
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default RegAeroporto;
