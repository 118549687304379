import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { Icon } from 'react-icons-kit'
import { ic_add_box } from 'react-icons-kit/md/ic_add_box'
import {ic_search} from 'react-icons-kit/md/ic_search'
import {ic_delete} from 'react-icons-kit/md/ic_delete'
import {ic_add} from 'react-icons-kit/md/ic_add'
import {ic_close} from 'react-icons-kit/md/ic_close'
import Modal from '@material-ui/core/Modal';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import NavBar from '../components/NavBar'
import FrTableProcessos from '../components/FrTableProcessos'
import FrTable from '../components/FrTable'
import FrTextInput from '../components/FrTextInput'
import FrDatePicker from '../components/FrDatePicker'
import FrSelect from '../components/FrSelect'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import FooterDelphus from '../components/FooterDelphus'
import FrSwitch from '../components/FrSwitch'


import api from '../services/api'
import { paramsToObject, formToObject, populateForm, objectToQueryString, onlyNumbers } from '../utils/functions'

import './styles/DefaultMainScreen.css';
import './styles/Manut.css';

const swal = withReactContent(Swal)

function Processos(props) {
  const [data, setData] = useState([])
  const [dataAcompanhamento, setDataAcompanhamento] = useState([])
  const [filter, setFilter] = useState({})
  const [filtered, setFiltered] = useState(false)
  const [comboCliente, setComboCliente] = useState([{ display: '', value: '' }])
  const [comboUnidade, setComboUnidade] = useState([{ display: '', value: '' }])
  const [comboOrgao, setComboOrgao] = useState([{ display: '', value: '' }])
  const [enableAdd, setenableAdd] = useState('N')
  const [enableEdit, setenableEdit] = useState('N')
  const [enableDel, setenableDel] = useState('N')
  const [showModal, setShowModal] = useState(false)
  const [registro, setRegistro] = useState({})
  const [idAcompanhamento, setIdAcompanhamento] = useState(null)

  const auth = JSON.parse(localStorage['authejr'] || JSON.stringify({ authenticated: false, user: 0 }))
 
  const columns = [
    {id: 'numero', numeric: false, label: 'Número', align: 'left', minWidth: 120},
    {id: 'data_formatada', numeric: false, label: 'Data', align: 'left', minWidth: 120, order: 'data_registro'},
    {id: 'nomecli', numeric: false, label: 'Empresa', align: 'left', minWidth: 400},
    {id: 'nomeorg', numeric: false, label: 'Órgão', align: 'left', minWidth: 300},
    {id: 'nomeoun', numeric: false, label: 'Unidade', align: 'left', minWidth: 300},
    {id: 'data_entrega_formatada', numeric: false, label: 'Data Entrega', align: 'left', minWidth: 120},
    {id: 'hora_entrega', numeric: false, label: 'Hora Entrega', align: 'left', minWidth: 80}
  ]

  const columnsAcompanhamento = [
    {id: 'data_formatada', numeric: false, label: 'Data', align: 'left', minWidth: 120},
    {id: 'prazo', numeric: false, label: 'Prazo', align: 'left', minWidth: 80},
    {id: 'descricao', numeric: false, label: 'Descrição', align: 'left', minWidth: 600},
  ]

  useEffect(() => {
    const fetchData = async () => {
      let tela = 'Processos'
      let consulta = await api.getAcessos(tela, 'Consulta')
      if (consulta !== 'S') {
        return swal.fire('Usuário sem acesso', 'Usuário sem acesso a essa tela!', 'warning')
          .then((result) => {
            if (result.value) {
              window.history.back()
            }
          })
      }
      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)
      // if (query.iframe) require('./styles/iframeReg.css')

      swal.fire({
        html: <FrLoading text="Carregando..."/>,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })

      if (!props.frame) {
        setenableAdd(await api.getAcessos('Processos', 'Inclusao'))
        setenableEdit(await api.getAcessos('Processos', 'Alteracao'))
        setenableDel(await api.getAcessos('Processos', 'Exclusao'))

        setComboCliente(await api.getComboData('cliente'))
        setComboUnidade(await api.getComboData('orgao_unidade'))
        setComboOrgao(await api.getComboData('orgao'))

        setFilter(query)
        populateForm(document.getElementById('searchForm'), query)
        if (query.buscar === 'true') {
          let event = new Event('start');
          console.log(event)
          getData(event)
        }
      }
      swal.close()
    }
    
    fetchData()
  }, [])

  function getData(e) {
    e.preventDefault()
    var formData = new FormData(document.getElementById('searchForm'))
    var queryObject = formToObject(formData)
    // if (!isEmpty(queryObject)) {
      if (true){
      queryObject.buscar = true
      if (e.type == `start`) queryObject.concluidos = 'false'
      setFiltered(true)
      if (!props.frame) {
        window.history.replaceState({ filtered: true }, 'filter', "/ejr/processos?" + objectToQueryString(queryObject));
      }
      // queryObject.numero = filter.numero ? onlyNumbers(queryObject.numero) : null
      swal.fire({
        html: <FrLoading text="Buscando dados..."/>,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      api.get('/processos', { params: queryObject }).then(r=> {
        setData(r.data)
        swal.close()
      })
    } else {
      swal.fire('Nenhum filtro informado!', 'Informe ao menos um filtro para realizar a busca.', 'warning')
    }
  }

  function clearForm(e) {
    e.preventDefault();
		console.log('limpa');
    window.history.replaceState({ filtered: false }, 'filter', '/ejr/processos');
    document.getElementById('searchForm').reset()
    setFilter({})
  }

  const handleChange = (e) => {
    const auxValues = { ...filter };
    auxValues[e.target.name] = e.target.value;
    setFilter(auxValues);
  };

  const handleChangeRegistro = (e) => {
    const auxValues = { ...registro };
    auxValues[e.target.name] = e.target.value;
    setRegistro(auxValues);
  };

  const handleAddRegistro = (e) => {
    e.preventDefault()
    swal.fire({
      title: 'ATENÇÃO',
      text: "Deseja incluir o registro de acompanhamento?",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        const auxValues = { ...registro };
        auxValues.fk_pro = idAcompanhamento
        auxValues.fk_usu = auth.user
        api.post('/processos/acompanhamento/add', auxValues).then(r => {
          if (r.status === 200) {
            swal.fire({
              title: 'Registro Adicionado',
              text: "O registro foi adicionado com sucesso!",
              icon: 'success',
            }).then((result) => {
              if (result.value) {
                swal.fire({
                  html: <FrLoading text="Atualizando registros..."/>,
                  showConfirmButton: false,
                  allowOutsideClick: false,
                  allowEscapeKey: false
                })
                api.get('/processos/acompanhamento', { pk_pro: idAcompanhamento }).then(r=> {
                  setDataAcompanhamento(r.data)
                  setRegistro({})
                  swal.close()
                })
              }
            })
          } else {
            swal.fire({
              title: 'Erro!',
              text: "O registro não foi adicionado!",
              icon: 'warning',
            })
          }
        })
      }
    })
  }

  const handleClose = (e) => {
    e.preventDefault();
    setShowModal(false)
  }

  const handleAcompanhamento = (e, id, regName) => {
    swal.fire({
      html: <FrLoading text="Buscando registros..."/>,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false
    })
    console.log(id)
    api.get('/processos/acompanhamento?pk_pro='+id).then(r=> {
      setIdAcompanhamento(id)
      setDataAcompanhamento(r.data)
      setRegistro({})
      swal.close()
    })
    setShowModal(true)
  }

  return (
    <div className="Manut">
      {props.frame ? <div/> : <NavBar/>}
      <div className={"ManutContent"+(props.frame ? '-frame' : '')}>
        <div className="defaultHeader">
          <h3>Processos</h3>
          {props.frame ? <div/> : <UserOptions/>}
        </div>
        <div className="filtersDiv">
          <Modal
            open={showModal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div className="modalAcompanhamento">
              <form>
                <div className="grupoTitulo">
                  Novo Registro
                </div>
                <div className="grupoEdits">
                  <FrDatePicker
                    maxLength='50'
                    value={(registro.data || '').split('T')[0]}
                    onChange={handleChangeRegistro}
                    name="data"
                    id="data"
                    color="#9c9595"
                    label="Data"
                    variant="outlined"
                    size="small"
                    style={{ width: 200 }}
                  /> 
                  <FrTextInput
                    maxLength='50'
                    value={registro.prazo || ''}
                    onChange={handleChangeRegistro}
                    name="prazo"
                    id="prazo"
                    color="#9c9595"
                    label="Hora"
                    variant="outlined"
                    size="small"
                    required
                    style={{ width: 100 }}
                  />
                  <FrTextInput
                    maxLength='300'
                    value={registro.descricao || ''}
                    onChange={handleChangeRegistro}
                    name="descricao"
                    id="descricao"
                    color="#9c9595"
                    label="Descrição"
                    variant="outlined"
                    size="small"
                    style={{ width: '100%' }}
                    rows={5}
                    maxRows={5}
                    multiline
                  />
                  <button onClick={handleAddRegistro}> <Icon icon={ic_add_box} size={17}/> Inserir registro</button>
                </div>
              </form>
              <div className="tableDiv">
                <FrTable readOnly regName="processos" frame={true} columns={columnsAcompanhamento} searched={filtered} codeName="pk_pan" page={Processos} data={dataAcompanhamento} enableEdit={enableEdit} enableDel={enableDel}/>
              </div>
              <button onClick={handleClose}> <Icon icon={ic_close} size={18}/>Fechar</button>
            </div>
          </Modal>
          <form id="searchForm" onSubmit={getData}>
          <div>
            <FrTextInput 
              value={filter.numero || ''} 
              style={{width: 200}} 
              name="numero" 
              id="numero" 
              color="#9c9595" 
              label="Cód. Processo" 
              variant="outlined" 
              size="small"
              onChange={handleChange} 
            />
            <FrTextInput 
              value={filter.descricao || ''} 
              style={{width: 330}} 
              name="descricao" 
              id="descricao" 
              color="#9c9595" 
              label="Descrição" 
              variant="outlined" 
              size="small"
              onChange={handleChange} 
            />
            <FrDatePicker
              value={(filter.data_ini || '').split('T')[0] } 
              onChange={handleChange} 
              name="data_ini" 
              id="data_ini" 
              color="#9c9595" 
              label="Data Inicial" 
              variant="outlined" 
              size="small"
              style={{width: 190}}
            />
            <FrDatePicker
              value={(filter.data_fim || '').split('T')[0] } 
              onChange={handleChange} 
              name="data_fim" 
              id="data_fim" 
              color="#9c9595" 
              label="Data Final" 
              variant="outlined" 
              size="small"
              style={{width: 190}}
            />
            <FrSelect
              value={filter.fk_pes_cli || ''}
              onChange={handleChange}
              name='fk_pes_cli'
              id='fk_pes_cli'
              label='Empresa'
              data={comboCliente}
              style={{ width: 300 }}
            />
            <FrSelect
              value={filter.fk_org || ''}
              onChange={handleChange}
              name='fk_org'
              id='fk_org'
              label='Órgão'
              data={comboOrgao}
              style={{ width: 300 }}
            />
            <FrSelect
              value={filter.fk_oun || ''}
              onChange={handleChange}
              name='fk_oun'
              id='fk_oun'
              label='Unidade'
              data={comboUnidade}
              style={{ width: 300 }}
            />
            </div>
            <div>
              <button onClick={getData}>
                <Icon icon={ic_search} size={18}/>
                Pesquisar
              </button>
              <button onClick={clearForm}>
                <Icon icon={ic_delete} size={18}/>
                Limpar
              </button>
              {(enableAdd !== 'S') ? '' :
                <Link to="/ejr/processos/registro">
                  <button >
                    <Icon icon={ic_add_box} size={18} />
                  Novo Registro
                </button>
                </Link>}
            </div>
          </form>
        </div>
        <br/>
        <div className={"tableDiv"+(props.frame && data.length>0 ? '-frame' : '')}>
          <FrTableProcessos regName="processos" handleAcompanhamento={handleAcompanhamento} frame={props.frame} columns={columns} searched={filtered} codeName="pk_pro" page={Processos} data={data} enableEdit={enableEdit} enableDel={enableDel}/>
        </div>
        {props.frame ? (<div>

        </div>) : <FooterDelphus/>}
        
      </div>
    </div>
  );
}

export default Processos;
