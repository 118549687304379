import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import FrSwitch from '../FrSwitch'
import { FixedSizeList } from 'react-window';
import { Icon } from 'react-icons-kit'
import { iosSearchStrong } from 'react-icons-kit/ionicons/iosSearchStrong'
import { ic_playlist_add } from 'react-icons-kit/md/ic_playlist_add'


import FrTextInput from '../FrTextInput'

import './index.css';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: '#F7EFEF',
    color: '#9c9595',
  },
}));


const useStylesRow = makeStyles((theme) => ({
  root: {
    '&.Mui-selected': {
      backgroundColor: 'rgba(255, 106, 0, 0.8)',
      color: '#F7EFEF',
      '&:hover': {
        backgroundColor: 'rgba(255, 106, 0, 0.8)',
        color: '#F7EFEF',
      }
    },
    '&:hover': {
      backgroundColor: 'rgba(255, 106, 0, 0.8)',
      color: '#F7EFEF',
    }
  },
}))

function renderRow(props) {
  const { index, style, data } = props;
  console.log(data.items)
  if (data.items.length > 0 && data.data(index)) {
    return (
      <ListItem className={data.classesRow.root} button style={style} selected={data.selected === (data.data(index))['codigo']} key={index} onClick={(event) =>  data.handleListItemClick(event, (data.data(index))['codigo'])}>
        <ListItemText primary={(data.items.length > 0) ? (data.data(index)[data.textField]) : ''} />
      </ListItem>
    );
  } else return null
}

renderRow.propTypes = {
  index: PropTypes.number.isRequired,
  style: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  classesRow: PropTypes.object.isRequired,
};

export default function ListRegisters(props) {
  const classes = useStyles();
  const classesRow = useStylesRow();
  const [showData, setShowData] = React.useState(props.items)
  const [filter, setFilter] = React.useState('')
  const [filterValidade, setFilterValidade] = React.useState(true)


  const handleListItemClick = props.handleClickItem
  const textField = props.textField
  const thisItem = (index) => showData[index]
  const getSelected = (codigo) => props.items.find((item) => item.codigo === codigo)

  useEffect(() => {
    let filtered = props.items.filter(item => String(item[textField]).toLowerCase().indexOf(filter.toLowerCase()) > -1)
    if (filterValidade) {
      filtered = filtered.filter(item => (new Date(item.dt_validade || (new Date()).setHours(0,0,0,0))).setHours(0,0,0,0) >= (new Date()).setHours(0,0,0,0) )
    }
    console.log(filtered)
    setShowData(filtered)
  }, [filter, filterValidade])

  useEffect(() => {
    let filtered = props.items
    if (filterValidade) {
      filtered = filtered.filter(item => (new Date(item.dt_validade || (new Date()).setHours(0,0,0,0))).setHours(0,0,0,0) >= (new Date()).setHours(0,0,0,0) )
    }
    setShowData(filtered)
  }, [props.items])


  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: Number(props.width)+'px' }}>
      <div style={{ display: 'flex', flex: 1}}>
        <FrTextInput
          name="pesquisar"
          id="pesquisar"
          color="#9c9595"
          label="Pesquisar"
          variant="outlined"
          size="small"
          onChange={(e) => {e.preventDefault(); return setFilter(e.target.value)}}
          value={filter}
          style={{ flex: '1' }}
          startAdornment={(<Icon icon={iosSearchStrong} size={15} style={{ marginLeft: '-5px', color: '#9c9595' }}/>)}
        />
        {props.hideValidos ? (<div style={{ display: 'none', margin: '0', padding: '0' }}></div>) : (
          <FrSwitch
            checked={filterValidade} 
            name="validos" 
            id="validos" 
            label='Válidos' 
            onChange={(e) => {e.preventDefault(); setFilterValidade(e.target.checked)}}
          />)
        }  
      </div>
      <button className="listAddButton" onClick={(e) => {e.preventDefault(); props.handleAdd(props.tab)}}><Icon icon={ic_playlist_add} size={20} style={{ color: '#9c9595' }}/> Inserir</button>
      <div className={classes.root}>
        <FixedSizeList className="listRegister" width={props.width} height={props.height ? props.height : 400} itemSize={30} itemCount={showData.length} itemData={{ getSelected, textField, classesRow, selected: props.selected, handleListItemClick, data: thisItem, items: props.items }}>
          {renderRow}
        </FixedSizeList>
      </div>
    </div>
  );
}