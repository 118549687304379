import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { Icon } from 'react-icons-kit'
import { ic_add_box } from 'react-icons-kit/md/ic_add_box'
import { ic_search } from 'react-icons-kit/md/ic_search'
import { ic_delete } from 'react-icons-kit/md/ic_delete'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import NavBar from '../components/NavBar'
import FrTable from '../components/FrTable'
import FrDatePicker from '../components/FrDatePicker'
import FrSelect from '../components/FrSelect'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import FooterDelphus from '../components/FooterDelphus'


import api from '../services/api'
import { paramsToObject, formToObject, populateForm, objectToQueryString } from '../utils/functions'

import './styles/DefaultMainScreen.css';
import './styles/Manut.css';

const swal = withReactContent(Swal)

function Visitas(props) {
  const [data, setData] = useState([])
  const [filter, setFilter] = useState({})
  const [filtered, setFiltered] = useState(false)
  const [enableAdd, setenableAdd] = useState('N')
  const [enableEdit, setenableEdit] = useState('N')
  const [enableDel, setenableDel] = useState('N')
  const [comboSvi, setComboSvi] = useState([])
  const [comboCli, setComboCli] = useState([])


  const columns = [
    { id: 'format_data', numeric: false, label: 'Data Realização', align: 'left', minWidth: 100 },
    { id: 'nomecli', numeric: false, label: 'Cliente', align: 'left', minWidth: 300 },
    { id: 'nomesvi', numeric: false, label: 'Situação', align: 'left', minWidth: 100 },
  ]

  useEffect(() => {
    const fetchData = async () => {
      let tela = 'Visitas'
      let consulta = await api.getAcessos(tela, 'Consulta')
      if (consulta !== 'S') {
        return swal.fire('Usuário sem acesso', 'Usuário sem acesso a essa tela!', 'warning')
          .then((result) => {
            if (result.value) {
              window.history.back()
            }
          })
      }
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      if (!props.frame) {
        setenableAdd(await api.getAcessos('Visitas', 'Inclusao'))
        setenableEdit(await api.getAcessos('Visitas', 'Alteracao'))
        setenableDel(await api.getAcessos('Visitas', 'Exclusao'))


        setComboSvi(await api.getComboData('situacao_visita'))
        setComboCli(await api.getComboData('cliente'))

        const urlParams = new URLSearchParams(window.location.search);
        let query = paramsToObject(urlParams)
        setFilter(query)
        populateForm(document.getElementById('searchForm'), query)
        if (query.buscar === 'true') {
          let event = new Event('build');
          getData(event)
        }
      }
      swal.close()
    }

    fetchData()
  }, [])

  function getData(e) {
    e.preventDefault()
    var formData = new FormData(document.getElementById('searchForm'))
    var queryObject = formToObject(formData)
    // if (!isEmpty(queryObject)) {
    if (true) {
      queryObject.buscar = true
      setFiltered(true)
      if (!props.frame) {
        window.history.replaceState({ filtered: true }, 'filter', "/ejr/visitas?" + objectToQueryString(queryObject));
      }
      swal.fire({
        html: <FrLoading text="Buscando dados..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      api.get('/visitas', { params: queryObject }).then(r => {
        setData(r.data)
        swal.close()
      })
    } else {
      swal.fire('Nenhum filtro informado!', 'Informe ao menos um filtro para realizar a busca.', 'warning')
    }
  }

  function clearForm(e) {
    e.preventDefault();
    console.log('limpa');
    window.history.replaceState({ filtered: false }, 'filter', '/ejr/visitas');
    document.getElementById('searchForm').reset()
    setFilter({})
  }

  const handleChange = (e) => {
    const auxValues = { ...filter };
    console.log(auxValues)
    auxValues[e.target.name] = e.target.value;
    setFilter(auxValues);
  };

  return (
    <div className="Manut">
      {props.frame ? <div /> : <NavBar />}
      <div className={"ManutContent" + (props.frame ? '-frame' : '')}>
        <div className="defaultHeader">
          <h3>Vistorias</h3>
          {props.frame ? <div /> : <UserOptions />}
        </div>
        <div className="filtersDiv">
          <form id="searchForm" onSubmit={getData}>
            <div>
              <FrSelect
                value={filter.fk_cli || []}
                style={{ width: 300 }}
                name="fk_cli"
                id="fk_cli"
                label='Cliente'
                data={comboCli}
                onChange={handleChange}
              />

              <FrSelect
                value={filter.fk_svi || []}
                style={{ width: 200 }}
                name="fk_svi"
                id="fk_svi"
                label='Situação'
                data={comboSvi}
                onChange={handleChange}
              />

              <FrDatePicker
                value={(filter.dataini || '').split('T')[0]}
                onChange={handleChange}
                name="dataini"
                id="dataini"
                color="#9c9595"
                label="Período Realização"
                variant="outlined"
                size="small"
                style={{ width: 190 }}
              />
              <FrDatePicker
                value={(filter.datafim || '').split('T')[0]}
                onChange={handleChange}
                name="datafim"
                id="datafim"
                color="#9c9595"
                // label="Prazo Final - Final" 
                variant="outlined"
                size="small"
                style={{ width: 190 }}
              />

            </div>
            <div>
              <button onClick={getData}>
                <Icon icon={ic_search} size={18} />
                Pesquisar
              </button>
              <button onClick={clearForm}>
                <Icon icon={ic_delete} size={18} />
                Limpar
              </button>
              {(enableAdd !== 'S') ? '' :
                <Link to="/ejr/visitas/registro">
                  <button >
                    <Icon icon={ic_add_box} size={18} />
                    Novo Registro
                  </button>
                </Link>}
            </div>
          </form>
        </div>
        <br />
        <div className={"tableDiv" + (props.frame && data.length > 0 ? '-frame' : '')}>
          <FrTable regName="visitas" frame={props.frame} columns={columns} searched={filtered} codeName="pk_vis" page={Visitas} data={data} enableEdit={enableEdit} enableDel={enableDel} pdf={"S"} />
        </div>
        {props.frame ? (<div>

        </div>) : <FooterDelphus />}

      </div>
    </div>
  );
}

export default Visitas;
