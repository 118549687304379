import React, { useState } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import FrTextInput from '../FrTextInput'
import FrImageEdit from '../FrImageEdit'
import FrDatePicker from '../FrDatePicker'
import FrSelect from '../FrSelect'
import ListRegisters from '../ListRegisters'
import { Icon } from 'react-icons-kit'
import ListRegistersDetailedFilter from '../ListRegistersDetailedFilter'
import { ic_save } from 'react-icons-kit/md/ic_save'
import { ic_mode_edit } from 'react-icons-kit/md/ic_mode_edit'
import { ic_delete } from 'react-icons-kit/md/ic_delete'
import { ic_undo } from 'react-icons-kit/md/ic_undo'
import Tooltip from '@material-ui/core/Tooltip';

import consultaCep from '../../services/consultaCep'
import { paramsToObject, validarCNPJ, onlyNumbers, removeSpecialChar } from '../../utils/functions'
import { mcnpj, mTel, mCEP } from '../../utils/masks'

import api from '../../services/api'

import './index.css';
import FrTable from '../FrTableReg';

const swal = withReactContent(Swal)

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTabs-root': {
            backgroundColor: '#9c9595',
        },
        flexGrow: 1,
        width: '100%',
        border: '2px solid #9c9595',
        borderRadius: 4,    
        '& .MuiTabs-indicator': {
            backgroundColor: 'white',
            height: '5px'
        },
        '& .MuiTabs-scrollButtonsDesktop': {
            backgroundColor: '#9c9595',
        }
        //   backgroundColor: theme.palette.background.paper,
    },
}));

function TabsOrgao(props) {
    const classes = useStyles();

    //Tabs
    const [value, setValue] = React.useState(0);
    const handleChangeTabs = (event, newValue) => {
        setValue(newValue);
    };

    //Dados
    let data = props.data
    const [currentDataUnidades, setCurrentDataUnidades] = React.useState(null)
    const [editUnidades, setEditUnidades] = React.useState(null) 
    const [newUnidades, setNewUnidades] = React.useState(false)
    const setData = props.setData
    const handleChange = props.handleChange
    const [erroCep, setErroCep] = useState(false)
    const [erroCepOperacional, setErroCepOperacional] = useState(false)
    const [erroCnpj, setErroCnpj] = useState(false)
    const [propsTabUnidades, setPropsTabUnidades] = React.useState({
        selected: null,
    })
    const filtersUnidades = [
        { type: 'text', field: 'descricao', name: 'Unidade' },
        
    ]
    function handleCnpj(e) {
        e.preventDefault();
        let cnpj = e.target.value
        if(cnpj) {
          if(!validarCNPJ(cnpj)) {
            setErroCnpj(true)
            swal.fire('CNPJ inválido', 'Informe um CNPJ válido!', 'warning')
          } else {
            setErroCnpj(false)
          }
        }
      }

      function handleCep(e) {
        e.preventDefault();
        if (e.target.value) {
          if (e.target.value) {
            consultaCep.get('/' + onlyNumbers(e.target.value) + '/json').then(r => {
              console.log(r)
              if (r.data.erro) {
                setErroCep(true)
                swal.fire('CEP inválido', 'Informe um CEP válido!', 'warning')
              } else {
                setErroCep(false)
                const auxValues = { ...data };
                auxValues['logradouro'] = r.data.logradouro;
                auxValues['bairro'] = r.data.bairro;
                let cidade = props.comboCidades.find(item => String(item.display).normalize('NFD').replace(/[\u0300-\u036f]/g, "") === String(r.data.localidade).toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ""))
                if (cidade) auxValues['fk_cid'] = cidade.value
                else alert('Cidade não cadastrada no sistema!')
                setData(auxValues)
              }
            }).catch(err => {
              setErroCep(true)
              swal.fire('CEP inválido', 'Informe um CEP válido!', 'warning')
            })
          }
        }
      }

    function handleCepOperacional(e) {
        e.preventDefault();
        if (e.target.value) {
            if (e.target.value) {
                consultaCep.get('/' + onlyNumbers(e.target.value) + '/json').then(r => {
                    console.log(r)
                    if (r.data.erro) {
                        setErroCepOperacional(true)
                        swal.fire('CEP inválido', 'Informe um CEP válido!', 'warning')
                    } else {
                        setErroCepOperacional(false)
                        const auxValues = { ...data };
                        auxValues['logradouro_operacional'] = r.data.logradouro;
                        auxValues['bairro_operacional'] = r.data.bairro;
                        setData(auxValues)
                    }
                }).catch(err => {
                    setErroCepOperacional(true)
                    swal.fire('CEP inválido', 'Informe um CEP válido!', 'warning')
                })
            }
        }
    }

    const selectUnidades = (event, codigo) => {
        setEditUnidades(false)
        setNewUnidades(false)
        console.log(codigo, event)
        console.log(propsTabUnidades)
        setPropsTabUnidades({... propsTabUnidades, selected: codigo })
    
    }
    React.useEffect(() => {
        console.log(propsTabUnidades)
        console.log(props.dataUnidades)
        setCurrentDataUnidades(props.dataUnidades.find((item) => item.codigo === propsTabUnidades.selected))
    }, [propsTabUnidades.selected])
    

    const saveUnidades = () => {
        const auxValues = { ...currentDataUnidades };
        auxValues.fk_org = props.pk_org
        console.log(auxValues)
        if (newUnidades) {
            api.post('/orgao_unidade/add', auxValues).then(r => {
                if (r.status === 200) {
                    swal.fire({
                        title: 'Registro Adicionado',
                        text: "O registro foi incluído com sucesso!",
                        icon: 'success',
                    }).then((result) => {
                        if (result.value) {
                            setPropsTabUnidades({... propsTabUnidades, selected: null })
                            setCurrentDataUnidades({})
                            setEditUnidades(false)
                            setNewUnidades(false)
                            props.reloadUnidades()
                        }
                    })
                } else {
                    swal.fire({
                        title: 'Erro!',
                        text: "O registro não foi alterado!",
                        icon: 'warning',
                    })
                }
            })
        } else {
            api.post('/orgao_unidade/edit', auxValues).then(r => {
                if (r.status === 200) {
                    swal.fire({
                        title: 'Registro Alteraddo',
                        text: "O registro foi alterado com sucesso!",
                        icon: 'success',
                    }).then((result) => {
                        if (result.value) {
                            setPropsTabUnidades({... propsTabUnidades, selected: null })
                            setCurrentDataUnidades({})
                            setEditUnidades(false)
                            setNewUnidades(false)
                            props.reloadUnidades()
                        }
                    })
                } else {
                    swal.fire({
                        title: 'Erro!',
                        text: "O registro não foi alterado!",
                        icon: 'warning',
                    })
                }
            })
        }
    }

    const deleteUnidades = () => {
        const auxValues = { ...currentDataUnidades };
        swal.fire({
            title: 'ATENÇÃO',
            text: 'Deseja excluir o registro?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                api.post('/orgao_unidade/delete/'+auxValues.pk_oun).then(r => {
                    if (r.status === 200) {
                        swal.fire({
                            title: 'Registro Excluído',
                            text: "O registro foi excluído com sucesso!",
                            icon: 'success',
                        }).then((result) => {
                            if (result.value) {
                                setPropsTabUnidades({... propsTabUnidades, selected: null })
                                setCurrentDataUnidades({})
                                setEditUnidades(false)
                                setNewUnidades(false)
                                props.reloadUnidades()
                            }
                        })
                    } else {
                        swal.fire({
                            title: 'Erro!',
                            text: "O registro não foi alterado!",
                            icon: 'warning',
                        })
                    }
                })
            }
        })
    }

    const handleChangeUnidades = (e) => {
        const auxValues = { ...currentDataUnidades };
        switch (e.target.name) {
            case 'CNPJ':
            default: auxValues[e.target.name] = e.target.value;
        }
        setCurrentDataUnidades(auxValues);
    }

    const handleAdd = (tab) => {
        switch (tab) {
            case 'Unidades':
                setEditUnidades(true)
                setCurrentDataUnidades({})
                setPropsTabUnidades({... propsTabUnidades, selected: null })
                setNewUnidades(true)
            break            

    
        }
    }

    //Save Tabs
    const handleSave = (e, tab) => {
        e.preventDefault()
        switch (tab) {

            case 'Unidades':
                saveUnidades()
            break
        }
    }

    //Delete Tabs
    const handleDelete = (e, tab) => {
        e.preventDefault()
        switch (tab) {
            

            case 'Unidades': 
                deleteUnidades()
            break
        }
    }
    const handleEdit = (e, tab) => {
        e.preventDefault()
        switch (tab) {
            case 'Unidades':
                setEditUnidades(!editUnidades)
            break
        }
    }


    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Tabs
                    value={value}
                    onChange={handleChangeTabs}
                    indicatorColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                >
                    <Tab label="Dados" {...a11yProps(0)} />
                    <Tab label="Unidades" disabled={!props.updateType} {...a11yProps(1)} />

                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <div className="flexImageFields">
                    <div>
                        <FrTextInput
                            maxLength='150'
                            value={data.descricao || ''}
                            onChange={handleChange}
                            name="descricao"
                            id="descricao"
                            color="#49573B"
                            label="Órgão"
                            variant="outlined"
                            size="small"
                            required
                            style={{ width: 500 }}
                        />
                        <FrTextInput 
                            error={erroCnpj} 
                            maxLength='18'
                            value={data.cnpj || ''} 
                            onChange={handleChange} 
                            onBlur={handleCnpj}  
                            name="cnpj" 
                            id="cnpj" 
                            color="#49573B" 
                            label="CNPJ" 
                            variant="outlined" 
                            size="small"
                            required
                            style={{width: 180}}
                        />
                        <FrSelect
                            value={data.fk_sit || []}
                            style={{ width: 200 }}
                            name="fk_sit"
                            id="fk_sit"
                            label='Situação'
                            data={props.comboSituacao}
                            onChange={handleChange}
                        />

                        <br />
                        <FrTextInput
                            maxLength='10'
                            value={data.cep || ''}
                            error={erroCep}
                            onChange={handleChange}
                            onBlur={handleCep}
                            name="cep"
                            id="cep"
                            color="#9c9595"
                            label="CEP"
                            variant="outlined"
                            size="small"
                            style={{ width: 130 }}
                            required
                        />
                        <FrTextInput
                            maxLength='50'
                            value={data.logradouro || ''}
                            onChange={handleChange}
                            name="logradouro"
                            id="logradouro"
                            color="#9c9595"
                            label="Endereço"
                            variant="outlined"
                            size="small"
                            required
                            style={{ width: 400 }}
                        />
                        <FrTextInput
                            maxLength='50'
                            value={data.numero || ''}
                            onChange={handleChange}
                            name="numero"
                            id="numero"
                            color="#9c9595"
                            label="Número"
                            variant="outlined"
                            size="small"
                            required
                            style={{ width: 100 }}
                        />
                        <FrTextInput
                            maxLength='50'
                            value={data.bairro || ''}
                            onChange={handleChange}
                            name="bairro"
                            id="bairro"
                            color="#9c9595"
                            label="Bairro"
                            variant="outlined"
                            size="small"
                            required
                            style={{ width: 250 }}
                        />
                        <FrTextInput
                            maxLength='50'
                            value={data.complemento || ''}
                            onChange={handleChange}
                            name="complemento"
                            id="complemento"
                            color="#9c9595"
                            label="Complemento"
                            variant="outlined"
                            size="small"
                            style={{ width: 330 }}
                        />
                        <FrSelect
                            value={data.fk_cid || []}
                            style={{ width: 300 }}
                            name="fk_cid"
                            id="fk_cid"
                            label='Cidade'
                            data={props.comboCidades}
                            onChange={handleChange}
                            required
                        />
                        <br />
                        <FrTextInput
                            maxLength='50'
                            value={data.fone1 || ''}
                            onChange={handleChange}
                            name="fone1"
                            id="fone1"
                            color="#9c9595"
                            label="Telefone"
                            variant="outlined"
                            size="small"
                            style={{ width: 140 }}
                        />
                        <FrTextInput
                            maxLength='50'
                            value={data.fone2 || ''}
                            onChange={handleChange}
                            name="fone2"
                            id="fone2"
                            color="#9c9595"
                            label="Telefone"
                            variant="outlined"
                            size="small"
                            style={{ width: 140 }}
                        />
                        <FrTextInput
                            maxLength='50'
                            value={data.celular || ''}
                            onChange={handleChange}
                            name="celular"
                            id="celular"
                            color="#9c9595"
                            label="Celular"
                            variant="outlined"
                            size="small"
                            style={{ width: 140 }}
                        />
                        <FrTextInput
                            maxLength='50'
                            value={data.email || ''}
                            onChange={handleChange}
                            name="email"
                            id="email"
                            color="#9c9595"
                            label="Email"
                            variant="outlined"
                            size="small"
                            style={{ width: 350 }}
                        />

                        <br />
                        <FrTextInput
                            maxLength='500'
                            value={data.observacoes || ''}
                            onChange={handleChange}
                            name="observacoes"
                            id="observacoes"
                            color="#9c9595"
                            label="Observações"
                            variant="outlined"
                            size="small"
                            style={{ width: '100%', flex: 1 }}
                            rows={5}
                            maxRows={5}
                            multiline
                        />
                    </div>
                </div>

            </TabPanel>
            <TabPanel value={value} index={1}>
            <div className="regRelacionados">
                

                <div className="manutReg Relacionados">
                    {console.log(props.dataUnidades)}
                    <ListRegistersDetailedFilter reload={props.reloadUnidades} filters={filtersUnidades} width={500} height={300} tab="Unidades" handleClickItem={selectUnidades} selected={propsTabUnidades.selected} items={props.dataUnidades} textField={'descricao'} handleAdd={handleAdd} />
                    <div className="register">
                        <div className="buttons">
                            <Tooltip title="Salvar">
                                <button onClick={(e) => {handleSave(e, 'Unidades')}} disabled={!editUnidades}>
                                    <Icon icon={ic_save} size={20}/>
                                </button>
                            </Tooltip>
                            <Tooltip title={editUnidades ? 'Desfazer' : 'Editar'}>
                                <button onClick={(e) => {handleEdit(e, 'Unidades')}} disabled={newUnidades}>
                                    <Icon icon={editUnidades ? ic_undo : ic_mode_edit} size={20}/>
                                </button>
                            </Tooltip>
                            <Tooltip title="Excluir">
                                <button onClick={(e) => {handleDelete(e, 'Unidades')}} disabled={(!(Number(propsTabUnidades.selected) > 0)) || (newUnidades)}>
                                    <Icon icon={ic_delete} size={20}/>
                                </button>
                            </Tooltip>
                        </div>
                        <div className="fields">


                        <FrTextInput
                            maxLength='150'
                            value={currentDataUnidades?.descricao || ''}
                            onChange={handleChangeUnidades}
                            name="descricao"
                            id="descricao"
                            color="#49573B"
                            label="Unidade"
                            variant="outlined"
                            size="small"
                            required
                            style={{ width: 500 }}
                        />
                       
                        <br />
                        <FrTextInput
                            maxLength='10'
                            value={currentDataUnidades?.cep || ''}
                            error={erroCep}
                            onChange={handleChangeUnidades}
                            onBlur={handleCep}
                            name="cep"
                            id="cep"
                            color="#9c9595"
                            label="CEP"
                            variant="outlined"
                            size="small"
                            style={{ width: 130 }}
                            required
                        />
                        <FrTextInput
                            maxLength='50'
                            value={currentDataUnidades?.logradouro || ''}
                            onChange={handleChangeUnidades}
                            name="logradouro"
                            id="logradouro"
                            color="#9c9595"
                            label="Endereço"
                            variant="outlined"
                            size="small"
                            required
                            style={{ width: 400 }}
                        />
                        <FrTextInput
                            maxLength='50'
                            value={currentDataUnidades?.numero || ''}
                            onChange={handleChangeUnidades}
                            name="numero"
                            id="numero"
                            color="#9c9595"
                            label="Número"
                            variant="outlined"
                            size="small"
                            required
                            style={{ width: 100 }}
                        />
                        <FrTextInput
                            maxLength='50'
                            value={currentDataUnidades?.bairro || ''}
                            onChange={handleChangeUnidades}
                            name="bairro"
                            id="bairro"
                            color="#9c9595"
                            label="Bairro"
                            variant="outlined"
                            size="small"
                            required
                            style={{ width: 250 }}
                        />
                        <FrTextInput
                            maxLength='50'
                            value={currentDataUnidades?.complemento || ''}
                            onChange={handleChangeUnidades}
                            name="complemento"
                            id="complemento"
                            color="#9c9595"
                            label="Complemento"
                            variant="outlined"
                            size="small"
                            style={{ width: 330 }}
                        />
                        <FrSelect
                            value={currentDataUnidades?.fk_cid || []}
                            style={{ width: 300 }}
                            name="fk_cid"
                            id="fk_cid"
                            label='Cidade'
                            data={props.comboCidades}
                            onChange={handleChangeUnidades}
                            required
                        />
                        <br />
                        <FrTextInput
                            maxLength='50'
                            value={currentDataUnidades?.fone1 || ''}
                            onChange={handleChangeUnidades}
                            name="fone1"
                            id="fone1"
                            color="#9c9595"
                            label="Telefone"
                            variant="outlined"
                            size="small"
                            style={{ width: 140 }}
                        />
                        <FrTextInput
                            maxLength='50'
                            value={currentDataUnidades?.celular || ''}
                            onChange={handleChangeUnidades}
                            name="celular"
                            id="celular"
                            color="#9c9595"
                            label="Celular"
                            variant="outlined"
                            size="small"
                            style={{ width: 140 }}
                        />
                        <FrTextInput
                            maxLength='50'
                            value={currentDataUnidades?.email || ''}
                            onChange={handleChangeUnidades}
                            name="email"
                            id="email"
                            color="#9c9595"
                            label="Email"
                            variant="outlined"
                            size="small"
                            style={{ width: 350 }}
                        />

                        <br />
                        <FrTextInput
                            maxLength='500'
                            value={currentDataUnidades?.observacoes || ''}
                            onChange={handleChangeUnidades}
                            name="observacoes"
                            id="observacoes"
                            color="#9c9595"
                            label="Observações"
                            variant="outlined"
                            size="small"
                            style={{ width: '100%', flex: 1 }}
                            rows={5}
                            maxRows={5}
                            multiline
                        />
                           
                        </div>
                    </div>
                </div>
            </div>

            </TabPanel>

        </div>
    );
}

export default TabsOrgao;