import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import NavBar from '../components/NavBar'
import FrTextInput from '../components/FrTextInput'
import FrSelect from '../components/FrSelect'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import TabsAeronave from '../components/TabsAeronave'

import api from '../services/api'
import consultaCNPJ from '../services/consultaCNPJ'
import { paramsToObject, validarCNPJ, onlyNumbers, removeSpecialChar } from '../utils/functions'
import { mCNPJ, mTel, mCEP, mPRE } from '../utils/masks'

import './styles/DefaultReg.css';
import FooterDelphus from '../components/FooterDelphus';

const swal = withReactContent(Swal)

function RegAeronave() {
  const [data, setData] = useState({ativo: ''})
  const [dataDocumentos, setDataDocumentos] = useState([])
  // const [dataInspecoes, setDataInspecoes] = useState([])
  const [updateType, setUpdateType] = useState(false)
  const [enableEdit, setEnableEdit] = useState('N')
  const [erroCnpj, setErroCnpj] = useState(false)
  const [comboCidades, setComboCidades] = useState([{ value: '', display: '' }])
  const [comboCategoria, setComboCategoria] = useState([{ value: '', display: '' }])
  const [comboTipoDocumento, setComboTipoDocumento] = useState([{ value: '', display: '' }])
  const [comboTipoCombustivel, setComboTipoCombustivel] = useState([{ value: '', display: '' }])
  const [dataRelacionadosAeronave, setDataRelacionadosAeronave] = useState([])
  const [comboFabricante, setComboFabricante] = useState([{ value: '', display: '' }])
  const [comboCor, setComboCor] = useState([{ value: '', display: '' }])
  const [comboSituacao, setComboSituacao] = useState([{ value: '', display: '' }])
  const [comboClientes, setComboClientes] = useState([{ value: '', display: '' }])
  const [comboSituacaoGeral, setComboSituacaoGeral] = useState([{ value: '', display: '' }])
  const [comboModeloDocumento, setComboModeloDocumento] = useState([{ value: '', display: '' }])
  const [codigo, setCodigo] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })

      setComboCategoria(await api.getComboData('aeronave_categoria'))
      setComboTipoDocumento(await api.getComboData('tipo_documento'))
      setComboFabricante(await api.getComboData('aeronave_fabricante'))
      setComboCor(await api.getComboData('aeronave_cor'))
      setComboSituacao(await api.getComboData('situacao_aeronave'))
      setComboClientes(await api.getComboData('cliente'))
      setComboSituacaoGeral(await api.getComboData('situacao_padrao'))
      setComboTipoCombustivel(await api.getComboData('tipo_combustivel'))
      setComboModeloDocumento(await api.getComboData('modelo_documento'))

      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)

      if (query.codigo > 0) {
        setEnableEdit(await api.getAcessos('Aeronave', 'Alteracao'))
      } else {
        setEnableEdit(await api.getAcessos('Aeronave', 'Inclusao'))
      }

      if (query.codigo > 0) {
        setUpdateType(true)
        setCodigo(query.codigo)
        api.get('/aeronave?pk_aer=' + query.codigo).then(r => {
          if (r.data[0]) {
            console.log(r.data[0])
            
            setData(r.data[0])
            api.get('/aeronave/documentos?pk_aer=' + query.codigo).then(r => {
                if (r.data) {
                  setDataDocumentos(r.data)
                }
                // api.get('/aeronave/inspecoes?pk_aer=' + query.codigo).then(r => {
                //   if (r.data) {
                //     setDataInspecoes(r.data)
                //   }
                  api.get('/aeronave/cliente?pk_aer=' + query.codigo).then(r => {
                    if (r.data) {
                      console.log(r.data)
                      setDataRelacionadosAeronave(r.data)
                    }
                    swal.close()
                  })
                // })
            })
          } else {
            swal.fire('Código inválido', 'Código informado não encontrado!', 'warning')
              .then((result) => {
                if (result.value) {
                  window.location.href = '/ejr/aeronave'
                }
              })
          }
        })
      } else swal.close()
    }

    fetchData()
  }, [])

  const reloadRelacionadosAeronave = () => {
    swal.fire({
      html: <FrLoading text="Carregando..." />,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false
    })
    api.get('/aeronave/cliente?pk_aer=' + data.pk_aer).then(r => {
      swal.close()
      if (r.data) {
        console.log(r.data)
        setDataRelacionadosAeronave(r.data)
      }
    })
  }

  const reloadDocumentos = () => {
    swal.fire({
      html: <FrLoading text="Carregando..." />,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false
    })
    api.get('/aeronave/documentos?pk_aer=' + data.pk_aer).then(r => {
      if (r.data) {
        console.log(r.data)
        setDataDocumentos(r.data)
      }
      swal.close()
    })
  }

  const reloadInspecoes = () => {
    swal.fire({
      html: <FrLoading text="Carregando..." />,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false
    })
    // api.get('/aeronave/inspecoes?pk_aer=' + data.pk_aer).then(r => {
    //   if (r.data) {
    //     console.log(r.data)
    //     setDataInspecoes(r.data)
    //   }
    //   swal.close()
    // })
  }

  function consistData(data) {
    let required = [
      'CNPJ'
    ]

    let errors = []

    required.forEach((curr, index) => {
      let fieldName = curr
      if (!data[curr]) return errors.push(fieldName)
    })

    return errors
  }

  const handleImageChange = (image, fieldName) => {
    const auxValues = { ...data };
    auxValues[fieldName] = image
    setData(auxValues)
  }

  function handleSubmit(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Deseja salvar " + (updateType ? 'as alterações' : 'a inclusão') + ' do registro?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        const auxValues = { ...data };
        auxValues.dt_inic_segreta = auxValues.dt_inic_segreta ? auxValues.dt_inic_segreta.split('T')[0] : null
        auxValues.dt_fim_segreta = auxValues.dt_fim_segreta ? auxValues.dt_fim_segreta.split('T')[0] : null
        auxValues.dt_inic_segcasco = auxValues.dt_inic_segcasco ? auxValues.dt_inic_segcasco.split('T')[0] : null
        auxValues.dt_fim_segcasco = auxValues.dt_fim_segcasco ? auxValues.dt_fim_segcasco.split('T')[0] : null
        auxValues.data_revisao = auxValues.data_revisao ? auxValues.data_revisao.split('T')[0] : null
        auxValues.dt_vcto_contrato = auxValues.dt_vcto_contrato ? auxValues.dt_vcto_contrato.split('T')[0] : null
        // let errors = consistData(auxValues)
        let errors = []
        console.log(auxValues.dt_vcto_contrato)
        console.log( ' - aqui ta ó le aqui')
        if (errors.length === 0) {
          if (updateType) {
            api.post('/aeronave/edit', auxValues).then(r => {
              if (r.status === 200) {
                swal.fire({
                  title: 'Registro Alteraddo',
                  text: "O registro foi alterado com sucesso!",
                  icon: 'success',
                }).then((result) => {
                  if (result.value) {
                    window.location.href = '/ejr/aeronave'

                  }
                })
              } else {
                swal.fire({
                  title: 'Erro!',
                  text: "O registro não foi alterado!",
                  icon: 'warning',
                })
              }
            })
          } else {
            api.post('/aeronave/add', auxValues).then(r => {
              if (r.status === 200) {
                let pk_aer = r.data
                swal.fire({
                  title: 'Registro adicionado com sucesso!',
                  text: "Deseja editar agora as outras abas deste cadastro?",
                  icon: 'success',
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: 'Sim',
                  cancelButtonText: 'Não'
                }).then((result) => {
                  console.log(result)
                  if (result) {
                    window.location.href = '/ejr/aeronave/registro?codigo='+pk_aer
                  } else {
                    window.location.href = '/ejr/aeronave'
                  }
                })
              } else {
                swal.fire({
                  title: 'Erro!',
                  text: "O registro não foi adicionado!",
                  icon: 'warning',
                })
              }
            }).catch(e => {
              swal.fire({
                title: 'Erro!',
                text: "O registro não foi adicionado!",
                icon: 'warning',
              })
            })
          }
        } else {
          swal.fire({
            title: 'Erro!',
            text: "Verifique o(s) campo(s) " + errors.join(','),
            icon: 'warning',
          })
        }
      }
    })
  }

  function handleCancel(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        window.location.href = '/ejr/aeronave'
      }
    })
  }

  const handleChange = (e) => {
    const auxValues = { ...data };
    console.log(e.target.value)
    switch (e.target.name) {
      case 'CNPJ':
      default: auxValues[e.target.name] = e.target.value;
    }
    
    setData(auxValues);

  };

  function handleCnpj(e) {
    e.preventDefault();
    let cnpj = e.target.value
    if(cnpj) {
      if(!validarCNPJ(cnpj)) {
        setErroCnpj(true)
        swal.fire('CNPJ inválido', 'Informe um CNPJ válido!', 'warning')
      } else {
        swal.fire({
          text: "Deseja buscar os dados pelo CNPJ?",
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não'
        }).then((result) => {
          if (result.value) {
            consultaCNPJ.get(String(onlyNumbers(cnpj)), {"Access-Control-Allow-Origin": "*"}).then(r=>{
              console.log(r)
              if (r.data.erro) {
                setErroCnpj(true)
                swal.fire('CNPJ inválido', 'Informe um CNPJ válido!', 'warning')
              } else {
                setErroCnpj(false)
                const auxValues = { ...data };
                auxValues['razao_social'] = r.data.nome;
                auxValues['fantasia'] = r.data.fantasia;
                setData(auxValues)
              }
            }).catch(err=>{
              setErroCnpj(true)
              swal.fire('CNPJ inválido', 'Informe um CNPJ válido!', 'warning')
            })
          }
        })
      }
    }
  }

  return (
    <div className="defaultReg">
      <NavBar />
      <div className="defaultRegContent">
        <div className="defaultHeader">
          <h3>{updateType ? 'Edição' : 'Inclusão'} de Aeronave</h3>
          <UserOptions />
        </div>
        <br />
        <form id="regForm" onSubmit={handleSubmit}>
          <TabsAeronave 
            data={data}
            handleChange={handleChange}
            setData={setData}
            comboTipoDocumento={comboTipoDocumento}
            comboCategoria={comboCategoria}
            comboCidades={comboCidades}
            comboFabricante={comboFabricante}
            comboCor={comboCor}
            comboSituacao={comboSituacao}
            reloadDocumentos={reloadDocumentos}
            dataDocumentos={dataDocumentos}
            // reloadInspecoes={reloadInspecoes}
            // dataInspecoes={dataInspecoes}
            handleImageChange={handleImageChange}
            comboClientes={comboClientes}
            comboTipoCombustivel={comboTipoCombustivel}
            updateType={updateType}
            comboSituacaoGeral={comboSituacaoGeral}
            dataRelacionadosAeronave={dataRelacionadosAeronave}
            reloadRelacionadosAeronave={reloadRelacionadosAeronave}
            comboModeloDocumento={comboModeloDocumento}
            setComboTipoDocumento={setComboTipoDocumento}
            pk_aer={data.pk_aer}/> 
        </form>
        <div className="regButtonsDiv">
          {enableEdit === 'S' ?
            (<button onClick={handleSubmit}>Salvar</button>) : (<></>)}
          <button onClick={handleCancel}>{enableEdit === 'S' ? 'Cancelar' : 'Fechar'}</button>
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default RegAeronave;
