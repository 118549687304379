import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { makeStyles, withStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { ptBR } from '@material-ui/core/locale';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import IconButton from '@material-ui/core/IconButton';
import { Icon } from 'react-icons-kit'
import { ic_delete } from 'react-icons-kit/md/ic_delete'
import { ic_mode_edit } from 'react-icons-kit/md/ic_mode_edit'
import { ic_local_printshop } from 'react-icons-kit/md/ic_local_printshop'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Swal from 'sweetalert2'
import Checkbox from '@material-ui/core/Checkbox';
import withReactContent from 'sweetalert2-react-content'
import { ic_gavel } from 'react-icons-kit/md/ic_gavel'
import { ic_thumbs_up_down } from 'react-icons-kit/md/ic_thumbs_up_down'
import { ic_work } from 'react-icons-kit/md/ic_work'
import TableDetail from '../TableDetail'
import Dialog from '@material-ui/core/Dialog';
import { MDBContainer, MDBIframe } from "mdbreact";

import api from '../../services/api'


import './index.css';

const icons = [
  { fk_sne: 1, icon: (<Icon size={32} style={{ color: '#2A9D8F' }} icon={ic_thumbs_up_down} />) },
  { fk_sne: 2, icon: (<Icon size={32} style={{ color: '#E9C46A' }} icon={ic_work} />) },
  { fk_sne: 3, icon: (<Icon size={32} style={{ color: '#EF233C' }} icon={ic_gavel} />) },
]

const swal = withReactContent(Swal)

const theme = createMuiTheme({
  palette: {
    primary: { main: '#9c9595' },
  },
}, ptBR);

const FrCheckBox = withStyles({
  root: {
    color: 'black',
    '&$checked': {
      color: 'black',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);



const useStyles = makeStyles(theme => ({
  root: {
    "& .Mui-selected": {
      // backgroundColor: '#beccaf'
      backgroundColor: 'rgba(245, 160, 100, 0.5)'

    },
    "& .Mui-selected:hover": {
      // backgroundColor: '#beccaf'
      backgroundColor: 'rgba(245, 160, 100, 0.8)'

    },
    width: '100%',
  },
  container: {
    maxHeight: ({ frame, relacionados }) => relacionados ? 'none' : (frame ? '40vh' : '58vh'),
    minHeight: ({ frame, relacionados }) => relacionados ? 'none' : (frame ? '40vh' : '58vh'),
    maxWidth: ({ frame, relacionados }) => relacionados ? 'none' : (frame ? 'calc(80vw - 60px)' : 'none')
  },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    // backgroundColor: '#9c9595',
    backgroundColor: '#9c9595',
    color: theme.palette.common.white,
    // color: theme.palette.common.black,

  },
  body: {
    fontSize: 14,
  },
}))(TableCell);



export default function FrTablePalestras(props) {
  const classes = useStyles({ frame: props.frame, relacionados: props.relacionados });
  const createSortHandler = (property) => (event) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(props.frame ? 25 : 10);
  const [data, setData] = useState(props.data)
  const [columns, setColumns] = useState(props.columns)
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState(props.columns[0].id);
  const [selected, setSelected] = React.useState([]);
  const [opened, setOpened] = React.useState([]);
  const [arquivo, setArquivo] = React.useState("");
  const [showModal, setshowModal] = React.useState(false);

  useEffect(() => {
    setData(props.data)
    setPage(0)
  }, [props.data])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    if (props.onChangeCheckbox) props.onChangeCheckbox(selected)
  }, [selected])

  function handleDelete(e, id, table) {
    e.preventDefault()
    swal.fire({
      title: 'ATENÇÃO',
      text: "Deseja excluir PERMANENTEMENTE o registro selecionado?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        api.post('/' + table + '/delete/' + id).then(r => {
          // console.log(r.data)
          if ((r.status === 200) && (r.data.substr(0, 5) !== 'Erro!')) {
            swal.fire({
              title: 'Registro Excluído',
              text: "O registro foi excluído com sucesso!",
              icon: 'success',
            }).then((result) => {
              if (result.value) {
                window.location.reload()
              }
            })
          } else {
            swal.fire({
              title: 'Erro!',
              text: "O registro não foi excluído! " + r.data,
              icon: 'warning',
            })
          }
        })
      }
    })
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n[props.codeName]);
      setSelected(newSelecteds);
      if (props.setSelected)
        props.setSelected(newSelecteds)

      return;
    }
    setSelected([]);
    if (props.setSelected)
      props.setSelected([])

  };

  const handleClick = (event, name) => {
    console.log(name)
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
    if (props.setSelected)
      props.setSelected(newSelected)
  };

  const handleClickDetail = (event, name) => {
    console.log(name)
    const selectedIndex = opened.indexOf(name);
    let newOpened = [];

    if (selectedIndex === -1) {
      newOpened = newOpened.concat(opened, name);
    } else if (selectedIndex === 0) {
      newOpened = newOpened.concat(opened.slice(1));
    } else if (selectedIndex === opened.length - 1) {
      newOpened = newOpened.concat(opened.slice(0, -1));
    } else if (selectedIndex > 0) {
      newOpened = newOpened.concat(
        opened.slice(0, selectedIndex),
        opened.slice(selectedIndex + 1),
      );
    }

    setOpened(newOpened);
  }

  function imprimeData(e, id) {
    e.preventDefault()
    setArquivo(api.backendUrl + '/api/palestra/relatorio_palestra?pk_pal=' + id)
    setshowModal(true)
  }

  function escondeModal(e) {
    e.preventDefault()
    setshowModal(false)
  }

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const isOpened = (name) => opened.indexOf(name) !== -1;

  return (
    <div>
      <div>
        <Dialog aria-labelledby="simple-dialog-title" open={showModal} fullWidth={true} maxWidth={'lg'}>
          <div className="FrModalFooter">
            <div className="FrModalButtonsDiv">
              <MDBContainer className="text-center">
                <MDBIframe src={arquivo} />
              </MDBContainer>
              <button onClick={escondeModal}> Sair </button>
            </div>
          </div>
        </Dialog>
      </div>


      <ThemeProvider theme={theme}>
        {(data.length === 0) ? (
          <div>{props.searched ? 'Registro(s) não encontrado(s)!' : ''}</div>
        ) : (
          <Paper className={classes.root}>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table" size={((props.frame || props.readOnly) ? "small" : "medium")}>
                <TableHead>
                  <TableRow>
                    {(!props.readOnly) ? (
                      <StyledTableCell padding="checkbox">
                        <FrCheckBox
                          indeterminate={selected.length > 0 && selected.length < data.length}
                          checked={data.length > 0 && selected.length === data.length}
                          onChange={handleSelectAllClick}
                          inputProps={{ 'aria-label': 'select all desserts' }}
                        />
                      </StyledTableCell>) : (<></>)}

                    {props.detail ? <StyledTableCell style={{ minWidth: 30, width: 30 }} /> : (<></>)}
                    {(!props.readOnly) ? (
                      <StyledTableCell
                        style={{ minWidth: 100, width: 120 }}
                      >
                        Opções
                      </StyledTableCell>) : (<></>)}
                    {columns.map((column) => (
                      <StyledTableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        <TableSortLabel
                          active={orderBy === column.id}
                          direction={orderBy === column.id ? order : 'asc'}
                          onClick={createSortHandler(column.id)}
                        >
                          {column.label}
                        </TableSortLabel>
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {stableSort(data, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                    const isItemSelected = isSelected(row[props.codeName]);
                    const isItemOpened = isOpened(row[props.codeName]);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    if (props.detail) {
                      return (
                        <>
                          <TableRow aria-checked={isItemSelected} hover role="checkbox" tabIndex={-1} key={row[props.codeName]} selected={isItemSelected}>
                            {(!props.readOnly) ? (
                              <TableCell padding="checkbox" onClick={(event) => handleClick(event, row[props.codeName])} >
                                <FrCheckBox
                                  checked={isItemSelected}
                                  inputProps={{ 'aria-labelledby': labelId }}
                                />
                              </TableCell>) : (<></>)}
                            <TableCell>
                              <IconButton aria-label="expand row" size="small" onClick={(event) => handleClickDetail(event, row[props.codeName])}>
                                {isItemOpened ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                              </IconButton>
                            </TableCell>
                            {(!props.readOnly) ? ((props.frame) ? (
                              <TableCell>
                                <Icon className="tableEdit" icon={ic_delete} size={22} onClick={(e) => handleDelete(e, row[props.codeName], props.regName)} />
                              </TableCell>
                            ) : (
                              <TableCell>
                                <Link to={"/ejr/" + props.regName + "/registro?codigo=" + row[props.codeName]}>
                                  <Icon className="tableEdit" icon={ic_mode_edit} size={22} />
                                </Link>
                                <Icon className="tableDelete" icon={ic_delete} size={22} onClick={(e) => handleDelete(e, row[props.codeName], props.regName)} />
                              </TableCell>
                            )) : (<></>)}
                            {columns.map((column) => {
                              const value = row[column.id];
                              if (column.image) {
                                return (
                                  <TableCell key={column.id} align={column.align}>
                                    <image src={value ? api.backendUrl + '/static/upload/lotes/' + row[props.codeName] + '/' + value : api.backendUrl + '/static/upload/lotes/no-image.jpg'} />
                                  </TableCell>
                                )
                              } else if (column.icon) {
                                let iconComponent = icons.find((item) => item.fk_sne === value)
                                return (
                                  <TableCell key={column.id} align={column.align}>
                                    {iconComponent.icon}
                                  </TableCell>
                                )
                              } else {
                                return (
                                  <TableCell key={column.id} align={column.align}>
                                    {column.format && typeof value === 'number' ? column.format(value) : value}
                                  </TableCell>
                                );
                              }
                            })}
                          </TableRow>
                          <TableRow>
                            <TableDetail isOpen={isItemOpened} detail={row[props.codeName]} />
                          </TableRow>
                        </>
                      );

                    } else {
                      return (
                        <TableRow onClick={(event) => { if (!props.readOnly) handleClick(event, row[props.codeName]) }} aria-checked={isItemSelected} hover role="checkbox" tabIndex={-1} key={row[props.codeName]} selected={isItemSelected}>
                          {(!props.readOnly) ? (
                            <TableCell padding="checkbox" onClick={(event) => handleClick(event, row[props.codeName])} >
                              <FrCheckBox
                                checked={isItemSelected}
                                inputProps={{ 'aria-labelledby': labelId }}
                              />
                            </TableCell>) : (<></>)}
                          {(!props.readOnly) ? (
                            <TableCell>

                              <Link to={"/ejr/" + props.regName + "/registro?codigo=" + row[props.codeName]}>

                                <Icon className="tableEdit" icon={ic_mode_edit} size={16} />
                              </Link>

                              {(props.enableDel == 'S') ? (<Icon className="tableDelete" icon={ic_delete} size={16} onClick={(e) => handleDelete(e, row[props.codeName], props.regName)} />)
                                : (<></>)}
                              {(props.pdf == 'S') ? (<Icon className="tableDelete" icon={ic_local_printshop} size={16} onClick={(e) => imprimeData(e, row[props.codeName])} />)
                                : (<></>)}
                            </TableCell>

                          ) : (<></>)}



                          {columns.map((column) => {
                            const value = row[column.id];
                            if (column.image) {
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  <img src={value ? api.backendUrl + '/static/upload/lotes/' + row[props.codeName] + '/' + value : api.backendUrl + '/static/upload/lotes/no-image.jpg'} style={{ maxHeight: '60px' }} />
                                </TableCell>
                              )
                            } else if (column.icon) {
                              let iconComponent = icons.find((item) => item.fk_sne === value)
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  {iconComponent.icon}
                                </TableCell>
                              )
                            } else {
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  {column.format && typeof value === 'number' ? column.format(value) : value}
                                </TableCell>
                              );
                            }
                          })}
                        </TableRow>
                      );
                    }
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              className="no-print"
            />
          </Paper>


        )}
      </ThemeProvider>
    </div>

  );
}
