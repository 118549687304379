import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { Icon } from 'react-icons-kit'
import { ic_add_box } from 'react-icons-kit/md/ic_add_box'
import { ic_search } from 'react-icons-kit/md/ic_search'
import { ic_delete } from 'react-icons-kit/md/ic_delete'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import NavBar from '../components/NavBar'
import FrTablePalestras from '../components/FrTablePalestras'
import FrTextInput from '../components/FrTextInput'
import FrSelect from '../components/FrSelect'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import FooterDelphus from '../components/FooterDelphus'
import FrDatePicker from '../components/FrDatePicker'


import api from '../services/api'
import { paramsToObject, formToObject, populateForm, objectToQueryString } from '../utils/functions'

import './styles/DefaultMainScreen.css';
import './styles/Manut.css';
import FrTable from '../components/FrTable';

const swal = withReactContent(Swal)

function Palestra(props) {
    const [auth, setAuth] = useState(JSON.parse(localStorage['authejr'] || JSON.stringify({ authenticated: false, user: 0 })));   
    const [data, setData] = useState([])
    const [filter, setFilter] = useState({})
    const [filtered, setFiltered] = useState(false)
    const [enableAdd, setenableAdd] = useState('N')
    const [enableEdit, setenableEdit] = useState('N')
    const [enableDel, setenableDel] = useState('N')
    const [comboTemaPalestra, setComboTemaPalestra] = useState([{ value: '', display: '' }])
    const [comboStatus, setComboStatus] = useState([{ value: '', display: '' }])
    const [comboClientes, setComboClientes] = useState([{ value: '', display: '' }])
    const [comboMinistrante, setComboMinistrante] = useState([{ value: '', display: '' }])


    const columns = [
        { id: 'nomecli', numeric: false, label: 'Empresa', align: 'left', minWidth: 100 },
        { id: 'nomemin', numeric: false, label: 'Ministrante', align: 'left', minWidth: 100 },
        { id: 'nomecid', numeric: false, label: 'Cidade', align: 'left', minWidth: 100 },
        { id: 'local', numeric: false, label: 'Local', align: 'left', minWidth: 100 },
        { id: 'nomestp', numeric: false, label: 'Status', align: 'left', minWidth: 100 },
        { id: 'format_dataprev', numeric: false, label: 'Data Prevista', align: 'left', minWidth: 100, orderBy: 'data_prevista'},
        { id: 'format_datareal', numeric: false, label: 'Data Realizada', align: 'left', minWidth: 100, orderBy: 'data_realizada'},

    ]

    useEffect(() => {
        const fetchData = async () => {
            let tela = 'Palestra'
      let consulta = await api.getAcessos(tela, 'Consulta')
      if (consulta !== 'S') {
        return swal.fire('Usuário sem acesso', 'Usuário sem acesso a essa tela!', 'warning')
          .then((result) => {
            if (result.value) {
              window.history.back()
            }
          })
      }
            swal.fire({
                html: <FrLoading text="Carregando..." />,
                showConfirmButton: false,
                allowOutsideClick: false,
                allowEscapeKey: false
            })
            if (!props.frame) {
                setenableAdd(await api.getAcessos('Palestra', 'Inclusao'))
                setenableEdit(await api.getAcessos('Palestra', 'Alteracao'))
                setenableDel(await api.getAcessos('Palestra', 'Exclusao'))
                setComboTemaPalestra(await api.getComboData('Tema_Palestra'))
                setComboStatus(await api.getComboData('Situacao_Palestra'))
                setComboClientes(await api.getComboData('cliente',(auth.grupo == 2 ? auth.user : 0)))
                setComboMinistrante(await api.getComboData('Palestra_Ministrante'))


                const urlParams = new URLSearchParams(window.location.search);
                let query = paramsToObject(urlParams)
                setFilter(query)
                populateForm(document.getElementById('searchForm'), query)
                if (query.buscar === 'true') {
                    let event = new Event('build');
                    getData(event)
                }
            }
            swal.close()
        }

        fetchData()
    }, [])

    function getData(e) {
        e.preventDefault()
        var formData = new FormData(document.getElementById('searchForm'))
        var queryObject = formToObject(formData)
        // if (!isEmpty(queryObject)) {
        if (true) {
            queryObject.buscar = true
            setFiltered(true)
            if (!props.frame) {
                window.history.replaceState({ filtered: true }, 'filter', "/ejr/Palestra?" + objectToQueryString(queryObject));
            }
            swal.fire({
                html: <FrLoading text="Buscando dados..." />,
                showConfirmButton: false,
                allowOutsideClick: false,
                allowEscapeKey: false
            })
            
            if(auth.grupo == 2) {
                queryObject.fk_usu = auth.user
                api.get('/Palestra', { params: queryObject }).then(r => {
                    setData(r.data)
                    swal.close()
                })

            } else {
                api.get('/Palestra', { params: queryObject }).then(r => {
                    console.log(r.data)
                    setData(r.data)
                    swal.close()
                })
            }
        
          
        } else {
            swal.fire('Nenhum filtro informado!', 'Informe ao menos um filtro para realizar a busca.', 'warning')
        }
    }

    function clearForm(e) {
        e.preventDefault();
        console.log('limpa');
        window.history.replaceState({ filtered: false }, 'filter', '/ejr/Palestra');
        document.getElementById('searchForm').reset()
        setFilter({})
    }

    const handleChange = (e) => {
        const auxValues = { ...filter };
        console.log(auxValues)
        auxValues[e.target.name] = e.target.value;
        setFilter(auxValues);
    };

    return (
        <div className="Manut">
            {props.frame ? <div /> : <NavBar />}
            <div className={"ManutContent" + (props.frame ? '-frame' : '')}>
                <div className="defaultHeader">
                    <h3>Palestra</h3>
                    {props.frame ? <div /> : <UserOptions />}
                </div>
                <div className="filtersDiv">
                    <form id="searchForm" onSubmit={getData}>
                        <div>

                            <FrDatePicker
                                name="datainiprev"
                                id="datainiprev"
                                color="#9c9595"
                                label="Data Prevista"
                                variant="outlined"
                                size="small"
                                style={{ width: 200 }}
                                value={(filter.datainiprev || '').split('T')[0]}
                                onChange={handleChange}
                                required
                            />

                            <label style={{ paddingRight: '7px', paddingTop: '7px' }}>a</label>

                            <FrDatePicker
                                name="datafimprev"
                                id="datafimprev"
                                color="#9c9595"
                                label=""
                                variant="outlined"
                                size="small"
                                style={{ width: 200 }}
                                value={(filter.datafimprev || '').split('T')[0]}
                                onChange={handleChange}
                                required
                            />
                            <br />
                            <FrDatePicker
                                name="datainireal"
                                id="datainireal"
                                color="#9c9595"
                                label="Data Realizada"
                                variant="outlined"
                                size="small"
                                style={{ width: 200 }}
                                value={(filter.datainireal || '').split('T')[0]}
                                onChange={handleChange}
                                required
                            />

                            <label style={{ paddingRight: '7px', paddingTop: '7px' }}>a</label>

                            <FrDatePicker
                                name="datafimreal"
                                id="datafimreal"
                                color="#9c9595"
                                label=""
                                variant="outlined"
                                size="small"
                                style={{ width: 200 }}
                                value={(filter.datafimreal || '').split('T')[0]}
                                onChange={handleChange}
                                required
                            />

                            <br />
                            <FrSelect
                                maxLength='75'
                                value={filter.fk_pes_cli || ''}
                                onChange={handleChange}
                                name="fk_pes_cli"
                                id="fk_pes_cli"
                                color="#9c9595"
                                label="Empresa"
                                variant="outlined"
                                size="small"
                                required
                                style={{ width: 300 }}
                                data={comboClientes}
                            />

                            <FrSelect
                                maxLength='75'
                                value={filter.fk_min || ''}
                                onChange={handleChange}
                                name="fk_min"
                                id="fk_min"
                                color="#9c9595"
                                label="Ministrante"
                                variant="outlined"
                                size="small"
                                required
                                style={{ width: 200 }}
                                data={comboMinistrante}
                            />

                            <br />
                            <FrSelect
                                value={filter.fk_tem || []}
                                style={{ width: 300 }}
                                name="fk_tem"
                                id="fk_tem"
                                label='Tema'
                                variant='outlined'
                                size='small'
                                onChange={handleChange}
                                data={comboTemaPalestra}
                            />

                            <FrSelect
                                value={filter.fk_stp || []}
                                style={{ width: 200 }}
                                name="fk_stp"
                                id="fk_stp"
                                label='Status'
                                variant='outlined'
                                size='small'
                                onChange={handleChange}
                                data={comboStatus}
                            />
                        </div>

                        <div>
                            <button onClick={getData}>
                                <Icon icon={ic_search} size={18} />
                                Pesquisar
                            </button>
                            <button onClick={clearForm}>
                                <Icon icon={ic_delete} size={18} />
                                Limpar
                            </button>
                            {(enableAdd !== 'S') ? '' :
                                <Link to="/ejr/Palestra/registro">
                                    <button >
                                        <Icon icon={ic_add_box} size={18} />
                                        Novo Registro
                                    </button>
                                </Link>}
                        </div>
                    </form>
                </div>
                <br />
                <div className={"tableDiv" + (props.frame && data.length > 0 ? '-frame' : '')}>
                    <FrTable regName="palestra" frame={props.frame} columns={columns} searched={filtered} codeName="pk_pal" page={Palestra} data={data} enableEdit={enableEdit} enableDel={enableDel} pdf={"S"} />
                </div>
                {
                    props.frame ? (<div>

                    </div>) : <FooterDelphus />
                }
            </div >
        </div >
    );
}

export default Palestra;

