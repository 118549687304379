import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { Icon } from 'react-icons-kit'
import { ic_add_box } from 'react-icons-kit/md/ic_add_box'
import { ic_search } from 'react-icons-kit/md/ic_search'
import { ic_delete } from 'react-icons-kit/md/ic_delete'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import NavBar from '../components/NavBar'
import FrTable from '../components/FrTable'
import FrTextInput from '../components/FrTextInput'
import FrSelect from '../components/FrSelect'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import FooterDelphus from '../components/FooterDelphus'


import api from '../services/api'
import { paramsToObject, formToObject, populateForm, objectToQueryString } from '../utils/functions'

import './styles/DefaultMainScreen.css';
import './styles/Manut.css';

const swal = withReactContent(Swal)

function Cliente(props) {
  const [data, setData] = useState([])
  const [filter, setFilter] = useState({})
  const [filtered, setFiltered] = useState(false)
  const [enableAdd, setenableAdd] = useState('N')
  const [enableEdit, setenableEdit] = useState('N')
  const [enableDel, setenableDel] = useState('N')
  const [comboCidAdm, setComboCidAdm] = useState([{ value: '', display: '' }])
  const [comboCidOpe, setComboCidOpe] = useState([{ value: '', display: '' }])
  const [comboFases, setComboFases] = useState([{ value: '', display: '' }])

  const columns = [
    { id: 'razao_social', numeric: false, label: 'Razão Social', align: 'left', minWidth: 300 },
  ]

  useEffect(() => {
    const fetchData = async () => {
      let tela = 'Cliente'
      let consulta = await api.getAcessos(tela, 'Consulta')
      if (consulta !== 'S') {
        return swal.fire('Usuário sem acesso', 'Usuário sem acesso a essa tela!', 'warning')
          .then((result) => {
            if (result.value) {
              window.history.back()
            }
          })
      } 
      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)
      // if (query.iframe) require('./styles/iframeReg.css')
      if(!query.buscar){
        query.buscar = 'true'
        query.ativo = 'S'  

      }
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })

      await api.get('/cidades/ComboSedeOpe').then(r => {
        setComboCidOpe(r.data)
      })


      await api.get('/cidades/ComboSedeAdm').then(r => {
        setComboCidAdm(r.data)
      })


      setComboFases(await api.getComboData('situacao_fase'))

      setenableAdd(await api.getAcessos('Cliente', 'Inclusao'))
      setenableEdit(await api.getAcessos('Cliente', 'Alteracao'))
      setenableDel(await api.getAcessos('Cliente', 'Exclusao'))
      if (!props.frame) {

        setFilter(query)
        populateForm(document.getElementById('searchForm'), query)
        if (query.buscar === 'true') {
          let event = new Event('build');
          getData(event)
        }
      }
      swal.close()
    }

    fetchData()
  }, [])



  function getData(e) {
    console.log(comboCidAdm)
    e.preventDefault()
    var formData = new FormData(document.getElementById('searchForm'))
    var queryObject = formToObject(formData)
    // if (!isEmpty(queryObject)) {
    if (true) {
      queryObject.buscar = true
      setFiltered(true)
      if (!props.frame) {
        window.history.replaceState({ filtered: true }, 'filter', "/ejr/cliente?" + objectToQueryString(queryObject));
      }
      swal.fire({
        html: <FrLoading text="Buscando dados..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      api.get('/cliente', { params: queryObject }).then(r => {
        setData(r.data)
        swal.close()
      })
    } else {
      swal.fire('Nenhum filtro informado!', 'Informe ao menos um filtro para realizar a busca.', 'warning')
    }
  }

  function clearForm(e) {
    e.preventDefault();
    window.history.replaceState({ filtered: false }, 'filter', '/ejr/cliente');
    document.getElementById('searchForm').reset()
    setFilter({})
  }

  const handleChange = (e) => {
    const auxValues = { ...filter };
    console.log(auxValues)
    auxValues[e.target.name] = e.target.value;
    setFilter(auxValues);
  };

  return (
    <div className="Manut">
      {props.frame ? <div /> : <NavBar />}
      <div className={"ManutContent" + (props.frame ? '-frame' : '')}>
        <div className="defaultHeader">
          <h3>Clientes</h3>
          {props.frame ? <div /> : <UserOptions />}
        </div>
        <div className="filtersDiv">
          <form id="searchForm" onSubmit={getData}>
            <div>
              <FrTextInput
                value={filter.descricao || ''}
                style={{ width: 406 }}
                name="descricao"
                id="descricao"
                color="#9c9595"
                label="Descrição"
                variant="outlined"
                size="small"
                onChange={handleChange}
              />
              <FrSelect
                value={filter.ativo || []}
                style={{ width: 200 }}
                name="ativo"
                id="ativo"
                label='Ativo'
                data={[{ display: 'Sim', value: 'S' }, { display: 'Não', value: 'N' }]}
                onChange={handleChange}
              />

              <FrSelect
                value={filter.procuracao || []}
                style={{ width: 200 }}
                name="procuracao"
                id="procuracao"
                label='Possui Procuração'
                data={[{ display: 'Sim', value: 'S' }, { display: 'Não', value: 'N' }]}
                onChange={handleChange}
              />

              <br />

              <FrTextInput
                value={filter.cnpj || ''}
                style={{ width: 200 }}
                name="cnpj"
                id="cnpj"
                color="#9c9595"
                label="CNPJ"
                variant="outlined"
                size="small"
                onChange={handleChange}
                maxLength={14}
              />


              <FrTextInput
                value={filter.cpf || ''}
                style={{ width: 200 }}
                name="cpf"
                id="cpf"
                color="#9c9595"
                label="CPF"
                variant="outlined"
                size="small"
                onChange={handleChange}
                maxLength={11}
              />


              <FrSelect
                value={filter.fk_cid || []}
                style={{ width: 200 }}
                name="fk_cid"
                id="fk_cid"
                label='Sede Administrativa'
                data={comboCidAdm}
                onChange={handleChange}
              />

              <FrSelect
                value={filter.fk_cid_operacional || []}
                style={{ width: 200 }}
                name="fk_cid_operacional"
                id="fk_cid_operacional"
                label='Sede Operacional'
                data={comboCidOpe}
                onChange={handleChange}
              />
              <br />
              <FrSelect
                value={filter.f1_fk_sif || []}
                style={{ width: 200 }}
                name="f1_fk_sif"
                id="f1_fk_sif"
                label='Status Fase 1'
                data={comboFases}
                onChange={handleChange}
              />


              <FrSelect
                value={filter.f2_fk_sif || []}
                style={{ width: 200 }}
                name="f2_fk_sif"
                id="f2_fk_sif"
                label='Status Fase 2'
                data={comboFases}
                onChange={handleChange}
              />

              <FrSelect
                value={filter.f3_fk_sif || []}
                style={{ width: 200 }}
                name="f3_fk_sif"
                id="f3_fk_sif"
                label='Status Fase 3'
                data={comboFases}
                onChange={handleChange}
              />

              <FrSelect
                value={filter.f4_fk_sif || []}
                style={{ width: 200 }}
                name="f4_fk_sif"
                id="f4_fk_sif"
                label='Status Fase 4'
                data={comboFases}
                onChange={handleChange}
              />

            </div>
            <div>
              <button onClick={getData}>
                <Icon icon={ic_search} size={18} />
                Pesquisar
              </button>
              <button onClick={clearForm}>
                <Icon icon={ic_delete} size={18} />
                Limpar
              </button>
              {(enableAdd !== 'S') ? '' :
                <Link to="/ejr/cliente/registro">
                  <button >
                    <Icon icon={ic_add_box} size={18} />
                    Novo Registro
                  </button>
                </Link>}
            </div>
          </form>
        </div>
        <br />
        <div className={"tableDiv" + (props.frame && data.length > 0 ? '-frame' : '')}>
          <FrTable regName="cliente" frame={props.frame} columns={columns} searched={filtered} codeName="pk_pes_cli" page={Cliente} data={data} enableEdit={enableEdit} enableDel={enableDel} />
        </div>
        {props.frame ? (<div>

        </div>) : <FooterDelphus />}

      </div>
    </div>
  );
}

export default Cliente;
