import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { ptBR } from '@material-ui/core/locale';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content/'
import api from '../../services/api'
import './index.css';
import { Icon } from 'react-icons-kit'
import { ic_delete } from 'react-icons-kit/md/ic_delete'
import { ic_description } from 'react-icons-kit/md/ic_description'
import { getUserCode } from '../../utils/functions'
import { ic_mode_edit } from 'react-icons-kit/md/ic_mode_edit'
import { folderDownload } from 'react-icons-kit/icomoon/folderDownload'
import FrTextInput from '../FrTextInput'
import TableSortLabel from '@material-ui/core/TableSortLabel';

const swal = withReactContent(Swal)

const theme = createMuiTheme({
  palette: {
    primary: { main: '#1976d2' },
  },
}, ptBR);



const useStyles = makeStyles({
  root: {
    "& .Mui-selected": {
      backgroundColor: 'rgba(245, 160, 100, 0.5)'
    },
    "& .Mui-selected:hover": {
      backgroundColor: 'rgba(245, 160, 100, 0.8)'
    },
    width: '100%',
  },
  container: {
    borderRadius: '4px',
    maxHeight: '45vh',
    minHeight: '45vh'
  },
});

function descendingComparator(a, b, orderBy) {

  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;


}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#9c9595',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 12,

  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);


export default function FrTable(props) {
  const classes = useStyles();
  const createSortHandler = (property) => (event) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  // let history = useHistory();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [data, setData] = useState(props.data)
  const [columns, setColumns] = useState(props.columns)
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState(props.columns[0].id);

  useEffect(() => {
    setData(props.data)
    setPage(0)

  }, [props.data])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  function handleEdit(e, row) {
    e.preventDefault()

    console.log(row)

    if (props.setdata_resolucao)
      props.setdata_resolucao(row['data_resolucao'])
    if (props.setprazo_resolucao)
      props.setprazo_resolucao(row['prazo_resolucao'])
    if (props.setprovidencia)
      props.setprovidencia(row['providencia'])
    if (props.setfk_ncf)
      props.setfk_ncf(row['fk_ncf'])
    if (props.setnomencf)
      props.setnomencf(row['nomencf'])
    if (props.setfk_par)
      props.setfk_par(row['fk_par'])
    if (props.setfk_tem)
      props.setfk_tem(row['fk_tem'])
    if (props.setobservacoes)
      props.setobservacoes(row['observacoes'])
    if (props.setdetalhamento_nao_conforme)
      props.setdetalhamento_nao_conforme(row['detalhamento_nao_conforme'])


    props.setPkModal(row['pk'])
    props.setUpdateModal(true)
    props.setModal(true)

  }

  function handleDelete(e, name, pk, row) {
    e.preventDefault()
    swal.fire({
      title: 'ATENÇÃO',
      text: "Deseja excluir o registro selecionado?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        if(props.regName == 'clienteUsu' && props.updateType){
          console.log(row.value)
          
          api.post('/usuarios/deleteCliente/' + row.value).then(r => {
            // console.log(r.data)
            if ((r.status === 200) && (r.data.substr(0, 5) !== 'Erro!')) {
              swal.fire({
                title: 'Registro Excluído',
                text: "O registro foi excluído com sucesso!",
                icon: 'success',
              }).then((result) => {
                if (result.value) {
                  window.location.reload()
                }
              })
            } else {
              swal.fire({
                title: 'Erro!',
                text: "O registro não foi excluído! " + r.data,
                icon: 'warning',
              })
            }
          }).catch(e => {
            console.log(e.response.data)
            if (e.response?.data?.sqlMessage && e.response?.data?.sqlMessage.indexOf('Cannot delete or update a parent row: a foreign key constraint fails') >= 0) {
              swal.fire({
                title: 'Erro!',
                text: "O registro não foi excluído pois possui outros registros vinculados! ",
                icon: 'warning',
              })
            } else {
              swal.fire({
                title: 'Erro!',
                text: "Houve um problema na exclusão do registro!",
                icon: 'warning',
              })
            }
          })
        } else {

          var sliced = [];
          for (var i = 0; i < data.length; i++)
  
            if (pk > 0) {
              if ((data[i].pk !== pk) && (data[i].pk > 0)) {
                sliced.push(data[i])
              }
            } else {
              if ((data[i].display !== name) && (data[i].display !== '')) {
                sliced.push({
                  value: data[i].value,
                  display: data[i].display
  
                })
              }
            }
          if (sliced.length === 0) {
            sliced.push({
              value: '',
              display: '',
              pk: 0
            })
  
          }
  
          if (props.setDataDoe) {
            props.setDataDoe(sliced)
          }
  
          if (props.setDataGrid) {
            props.setDataGrid(sliced)
          }
        }

      }
    })
  }


  async function handleAtendeReq(e, pk, pendente, fk_mdc, fk_mat, fk_pac, fk_uni) {
    e.preventDefault()
    const { value: formValues } = await swal.fire({
      title: 'Atendimento de Requisção',
      html:
        <div style={{ display: 'flex', flex: 1, marginTop: '20px', flexDirection: 'column', alignItems: 'flex-start' }}>


          <FrTextInput
            maxLength='150'
            name='qtd'
            id='qtd'
            color='#c0c0c0'
            label='Qtd.a Atender'
            variant='outlined'
            size='small'
            style={{ width: 100 }}
          />

        </div>,
      focusConfirm: false,
      preConfirm: () => {
        return {
          qtd: document.getElementById('qtd').value,
        }
      },
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'OK',
      confirmButtonColor: `#9c9595`,
      allowOutsideClick: false
    })

    if ((formValues) && (formValues['qtd'])) {
      if (formValues['qtd'] > pendente)
        swal.fire({
          title: 'Erro!',
          text: "Quantidade a atender não pode ser superior à quantidade pendente!",
          icon: 'warning',
        })

      else
        await api.post('/requisicoes/atendeRequisicao?pk_rei=' + pk + '&qtd=' + formValues['qtd'] + '&fk_usu=' + getUserCode() + '&fk_mat=' + fk_mat + '&fk_mdc=' + fk_mdc + '&fk_pac=' + fk_pac + '&fk_uni=' + fk_uni).then(r => {
          if ((r.status === 200) && (r.data == '')) {
            swal.fire({
              text: "Atendimento concluído com sucesso!",
              icon: 'success',
            }).then((result) => {
              if (result.value) {
                window.location.reload()
              }
            })
          } else {
            swal.fire({
              title: 'Erro!',
              text: r.data,
              icon: 'warning',
            })
          }
        })
    } else
      if (formValues) {
        swal.fire({
          title: 'Erro!',
          text: "Quantidade a atender deve ser preenchida!",
          icon: 'warning',
        })
      }
  }

  return (
    <ThemeProvider theme={theme}>
      {(data.length === 0) ? (
        <div>{props.searched ? props.emptyMessage : ''}</div>
      ) : (
        <Paper className={classes.root}>
          <TableContainer className={classes.container}>
            {/* <Table stickyHeader aria-label="sticky table"> */}
            <Table className={classes.table} size="small" aria-label="a dense table">

              <TableHead>
                <StyledTableRow>
                  {(!props.readonly) ?
                    <StyledTableCell
                      style={{ minWidth: 100, width: 100 }}
                    >

                    </StyledTableCell>
                    : <></>}
                  {columns.map((column) => (
                    <StyledTableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      <TableSortLabel
                        active={orderBy === column.id}
                        direction={orderBy === column.id ? order : 'asc'}
                        onClick={createSortHandler((column.order) ? column.order : column.id)}
                      >
                        {column.label}
                      </TableSortLabel>
                    </StyledTableCell>
                  ))}

                  <StyledTableCell style={{ minWidth: 30, width: 30 }} />

                </StyledTableRow>

              </TableHead>
              <TableBody>
                {stableSort(data, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                  //  {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {

                  console.log(row[props.codeName], props.codeName, row)
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (

                    <StyledTableRow >
                      {((!props.readonly) && (row[props.codeName])) || props.regName=='clienteUsu' ?
                        <TableCell>
                          <Icon className="tableDelete" icon={ic_delete} size={18} onClick={(e) => handleDelete(e, row[props.codeName], row['pk'], row)} />
                          {(props.editar) ?
                            <Icon className="tableDelete" icon={ic_mode_edit} size={18} onClick={(e) => handleEdit(e, row)} />
                            : <></>}
                        </TableCell> : <></>}

                      {columns.map((column) => {
                        const value = row[column.id];
                        if (column.icon) {
                          if (props.regName == 'pk_req')
                            return (
                              <TableCell key={column.id} align={column.align}>

                                <Icon className={"tableOk"} icon={folderDownload} size={20} onClick={(e) => handleAtendeReq(e, row['pk_rei'], row['pendente'], row['fk_mdc'], row['fk_mat'], row['fk_pac'], row['fk_uni'])} />

                              </TableCell>

                            )

                        } else {
                          return (
                            <TableCell key={column.id} align={column.align}>

                              {column.format && typeof value === 'number' ? column.format(value) : value}
                            </TableCell>
                          );
                        }
                      })}
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      )
      }
    </ThemeProvider >
  );
}
