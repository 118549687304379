import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import NavBar from '../components/NavBar'
import FrTextInput from '../components/FrTextInput'
import FrSelect from '../components/FrSelect'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import FrDatePicker from '../components/FrDatePicker'
import Tooltip from '@material-ui/core/Tooltip'

import api from '../services/api'
import { paramsToObject } from '../utils/functions'

import './styles/DefaultReg.css';
import FooterDelphus from '../components/FooterDelphus';

const swal = withReactContent(Swal)

function RegNotificacoes() {
  const [data, setData] = useState({ativo: 'S'})
  const [updateType, setUpdateType] = useState(false)
  const [enableEdit, setEnableEdit] = useState('N')
  const [comboCliente, setComboCliente] = useState([])
  const [comboPiloto, setComboPiloto] = useState([])
  const [blockAutuado, setBlockAutuado] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)

      if (query.codigo > 0) {
        setEnableEdit(await api.getAcessos('Notificacoes', 'Alteracao'))
      } else {
        setEnableEdit(await api.getAcessos('Notificacoes', 'Inclusao'))
      }

      if (query.codigo > 0) {
        setUpdateType(true)
        api.get('/notificacoes?pk_not=' + query.codigo).then(r => {
          if (r.data[0]) {
            console.log(r.data[0])
            setData(r.data[0])
            swal.close()
          } else {
            swal.fire('Código inválido', 'Código informado não encontrado!', 'warning')
              .then((result) => {
                if (result.value) {
                  window.history.back()
                }
              })
          }
        })
      } else swal.close()
    }

    fetchData()
  }, [])

  useEffect(() => {
    const fetchAutuado = async () => {
      if (data.tipo) {
        swal.fire({
          html: <FrLoading text={"Buscando "+(data.tipo === 'E' ? 'Empresas' : 'Pilotos')+"..."} />,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false
        })
        if (!updateType) {
          data.fk_pes_pil = null
          data.fk_pes_cli = null
        }
        if (data.tipo === 'E') {
          let cliente = await api.getComboData('cliente')
          setComboCliente(cliente)
        } else {
          let piloto = await api.getComboData('piloto')
          setComboPiloto(piloto)
        }
        swal.close()
        setBlockAutuado(false)
      } else setBlockAutuado(true)
    }

    fetchAutuado()
  }, [data.tipo])

  function consistData(data) {
    let required = [
      'numero',
      'data',
      'irregularidade'
    ]

    let errors = []

    required.forEach((curr, index) => {
      let fieldName = curr
      if (!data[curr]) return errors.push(fieldName)
    })

    if ((!data.fk_pes_pil) && (!data.fk_pes_cli)) errors.push('Empresa ou Piloto')

    return errors
  }

  function handleSubmit(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Deseja salvar " + (updateType ? 'as alterações' : 'a inclusão') + ' do registro?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        const auxValues = { ...data };
        let errors = consistData(auxValues)
        console.log(errors.length)
        if (errors.length === 0) {
          if (updateType) {
            api.post('/notificacoes/edit', auxValues).then(r => {
              if (r.status === 200) {
                swal.fire({
                  title: 'Registro Alteraddo',
                  text: "O registro foi alterado com sucesso!",
                  icon: 'success',
                }).then((result) => {
                  if (result.value) {
                    window.history.back()
                  }
                })
              } else {
                swal.fire({
                  title: 'Erro!',
                  text: "O registro não foi alterado!",
                  icon: 'warning',
                })
              }
            })
          } else {
            api.post('/notificacoes/add', auxValues).then(r => {
              if (r.status === 200) {
                swal.fire({
                  title: 'Registro Adicionado',
                  text: "O registro foi adicionado com sucesso!",
                  icon: 'success',
                }).then((result) => {
                  if (result.value) {
                    window.history.back()
                  }
                })
              } else {
                swal.fire({
                  title: 'Erro!',
                  text: "O registro não foi adicionado!",
                  icon: 'warning',
                })
              }
            })
          }
        } else {
          swal.fire({
            title: 'Erro!',
            text: "Verifique o(s) campo(s) " + errors.join(','),
            icon: 'warning',
          })
        }
      }
    })
  }

  function handleCancel(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        window.history.back()
      }
    })
  }

  const handleChange = (e) => {
    const auxValues = { ...data };
    console.log(e.target.name, e.target.value)
    switch (e.target.name) {
      case 'CNPJ':
      default: auxValues[e.target.name] = e.target.value;
    }
    setData(auxValues);
  };

  return (
    <div className="defaultReg">
      <NavBar />
      <div className="defaultRegContent">
        <div className="defaultHeader">
          <h3>{updateType ? 'Edição' : 'Inclusão'} de Notificações</h3>
          <UserOptions />
        </div>
        <br />
        <form id="regForm" onSubmit={handleSubmit}>

          <FrTextInput
            maxLength='50'
            value={data.numero || ''}
            onChange={handleChange}
            name="numero"
            id="numero"
            color="#9c9595"
            label="Número"
            variant="outlined"
            size="small"
            required
            style={{ width: 330 }}
            
          />
          <br/>
          <FrDatePicker
            maxLength='50'
            value={(data.data || '').split('T')[0]}
            onChange={handleChange}
            name="data"
            id="data"
            color="#9c9595"
            label="Data"
            variant="outlined"
            size="small"
            style={{ width: 200 }}
          />
          <br/>
          <FrSelect
            value={data.tipo || ''}
            onChange={handleChange}
            name="tipo"
            id="tipo"
            label='Autuado'
            data={[ {display: 'Empresa', value: 'E'}, {display: 'Piloto', value: 'P'} ]}
            style={{ width: 120 }}
            disabled={updateType}
          />
          <Tooltip title={blockAutuado ? 'Informe o tipo de Autuado!' : ''}>
            <FrSelect
              value={data.tipo === 'E' ? (data.fk_pes_cli || '') : (data.fk_pes_pil ||'')}
              onChange={handleChange}
              name={data.tipo === 'E' ? 'fk_pes_cli' : 'fk_pes_pil'}
              id={data.tipo === 'E' ? 'fk_pes_cli' : 'fk_pes_pil'}
              label={data.tipo === 'E' ? 'Empresa' : 'Piloto'}
              data={data.tipo === 'E' ? comboCliente : comboPiloto}
              style={{ width: 300 }}
              disabled={blockAutuado || updateType}
            />
          </Tooltip>
          <FrTextInput
            maxLength='1000'
            value={data.irregularidade || ''}
            onChange={handleChange}
            name="irregularidade"
            id="irregularidade"
            color="#9c9595"
            label="Irregularidade"
            variant="outlined"
            size="small"
            style={{ width: '100%' }}
            rows={5}
            maxRows={5}
            multiline
          />
        </form>
        <div className="regButtonsDiv">
          {enableEdit === 'S' ?
            (<button onClick={handleSubmit}>Salvar</button>) : (<></>)}
          <button onClick={handleCancel}>{enableEdit === 'S' ? 'Cancelar' : 'Fechar'}</button>
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default RegNotificacoes;
