import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { FixedSizeList } from 'react-window';
import { Icon } from 'react-icons-kit'
import { iosSearchStrong } from 'react-icons-kit/ionicons/iosSearchStrong'
import { ic_playlist_add } from 'react-icons-kit/md/ic_playlist_add'
import {trashO} from 'react-icons-kit/fa/trashO'


import FrTextInput from '../FrTextInput'
import FrDatePicker from '../FrDatePicker'
import FrSelect from '../FrSelect'

import './index.css';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    // height: 400,
    // maxWidth: 300,
    backgroundColor: '#F7EFEF',
    color: '#9c9595',
  },
}));


const useStylesRow = makeStyles((theme) => ({
  root: {
    '&.Mui-selected': {
      backgroundColor: '#9c9595',
      color: '#F7EFEF',
      '&:hover': {
        backgroundColor: '#9c9595',
        color: '#F7EFEF',
      }
    },
    '&:hover': {
      backgroundColor: '#9c9595',
      color: '#F7EFEF',
    }
  },
}))

function renderRow(props) {
  const { index, style, data } = props;
  if (data.items.length > 0 && data.data(index)) {
    return (
      <ListItem className={data.classesRow.root} button style={style} selected={data.selected === (data.data(index))['codigo']} key={index} onClick={(event) =>  data.handleListItemClick(event, (data.data(index))['codigo'])}>
        <ListItemText primary={(data.items.length > 0) ? (data.data(index)[data.textField]) : ''} />
      </ListItem>
    );
  } else return null
}

renderRow.propTypes = {
  index: PropTypes.number.isRequired,
  style: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  classesRow: PropTypes.object.isRequired,
};

export default function ListRegisters(props) {
  const classes = useStyles();
  const classesRow = useStylesRow();
  const [showData, setShowData] = React.useState(props.items)
  const [filter, setFilter] = React.useState({})
  const [filters, setFilters] = React.useState(props.filters)


  const handleListItemClick = props.handleClickItem
  const textField = props.textField
  const thisItem = (index) => showData[index]
  const getSelected = (codigo) => {
    props.items.find((item) => item.codigo === codigo)
  }

  useEffect(() => {
    // let filtered = props.items.filter(item => String(item[textField]).toLowerCase().indexOf(filter.toLowerCase()) > -1)

    let filtered = props.items.filter(item => filters.length === filters.reduce((value, itemFilter) => {
      if (itemFilter.type === 'select') {
        if (!(filter[itemFilter.field]) || (Number(item[itemFilter.field]) === Number(filter[itemFilter.field]))) return value + 1
        else return value + 0
      } else if (itemFilter.type === 'date') {
        if (!(filter[itemFilter.field]) || (new Date(item[itemFilter.field])) === new Date(filter[itemFilter.field])) return value + 1
        else return value + 0
      } else if (itemFilter.type === 'period') {
        let result = 0
        if (!(filter[itemFilter.field+'_ini']) || (new Date(item[itemFilter.field])) >= new Date(new Date(filter[itemFilter.field+'_ini']).setHours(24,0,0,0))) result = 1 
        else result = 0
        if (!(filter[itemFilter.field+'_fim']) || (new Date(item[itemFilter.field])) <= new Date(new Date(filter[itemFilter.field+'_fim']).setHours(24,0,0,0))) result = result * 1 
        else result = 0
        return value + result
      } else {
        if (!(filter[itemFilter.field]) || (String(item[itemFilter.field]).toLowerCase().indexOf(filter[itemFilter.field].toLowerCase()) > -1)) return value + 1
        else return value + 0
      }
    }, 0))
    setShowData(filtered)
  }, [filter])

  useEffect(() => {
    setShowData(props.items)
  }, [props.items])


  const handleChange = (e) => {
    const auxValues = { ...filter };
    console.log(auxValues)
    auxValues[e.target.name] = e.target.value;
    setFilter(auxValues);
  };

  let filtersComponents = filters.map((item, index) => { 
    if (item.type === 'date') {
      return (
        <FrDatePicker 
            maxLength='10' 
            onChange={handleChange}
            name={item.field}
            id={item.field}
            color="#49573B" 
            label={item.name}
            variant="outlined" 
            size="small"
            noClear
            value={filter[item.field]}
            style={{ flex: 1, marginRight: 0 }}
        />
      )
    } else if (item.type === 'select') {
      return (
        <FrSelect 
            style={{ flex: 1, marginRight: 0 }} 
            name={item.field}
            id={item.field}
            label={item.name} 
            data={item.data}
            value={filter[item.field]}
            onChange={handleChange}
        />
      )
    } else if (item.type === 'period') {
      return (
        <div style={{ display: 'flex' }}>
          <FrDatePicker 
            maxLength='10' 
            onChange={handleChange}
            name={item.field+'_ini'}
            id={item.field+'_ini'}
            color="#49573B" 
            label={item.name+' - Inicial'}
            variant="outlined" 
            size="small"
            noClear
            value={filter[item.field+'_ini']}
            style={{ flex: 1 }}
          />
          <FrDatePicker 
            maxLength='10' 
            onChange={handleChange}
            name={item.field+'_fim'}
            id={item.field+'_fim'}
            color="#49573B" 
            label={item.name+' - Final'}
            variant="outlined" 
            size="small"
            noClear
            value={filter[item.field+'_fim']}
            style={{ flex: 1, marginRight: 0 }}
          />
        </div>
      )
    } else {
      return (
        <FrTextInput
          name={item.field}
          id={item.field}
          color="#9c9595"
          label={item.name}
          variant="outlined"
          size="small"
          onChange={handleChange}
          value={filter[item.field]}
          style={{ width: '100%' }}
        />
      )
    }
  })


  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: props.width+'px' }}>
      <form id="searchForm" style={{ paddingBottom: 0, border: 'none' }}>
        <div className="detailedFiltersDiv">
          <p><Icon icon={iosSearchStrong} size={15} style={{ color: '#9c9595' }}/>Pesquisar</p>
          
            {filtersComponents}
          
          <button className="clearFilterButton" onClick={(e) => {e.preventDefault(); document.getElementById('searchForm').reset(); setFilter({}); props.reload()}}><Icon icon={trashO} size={18} style={{ color: 'white' }}/> Limpar Filtro</button>
        </div>
      </form>
      <button className="listAddButton" onClick={(e) => {e.preventDefault(); props.handleAdd(props.tab)}}><Icon icon={ic_playlist_add} size={20} style={{ color: '#9c9595' }}/> Inserir</button>
      <div className={classes.root}>
        {console.log(props.items)}
        <FixedSizeList className="listRegister" width={props.width} height={props.height ? props.height : 400} itemSize={30} itemCount={showData.length} itemData={{ getSelected, textField, classesRow, selected: props.selected, handleListItemClick, data: thisItem, items: props.items }}>
          {renderRow}
        </FixedSizeList>
      </div>
    </div>
  );
}