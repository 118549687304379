import React, { useState } from 'react';
import Swal from 'sweetalert2'
import Nav from 'react-bootstrap/Nav'
import withReactContent from 'sweetalert2-react-content'
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import FrSwitch from '../FrSwitch'
import FrTextInput from '../FrTextInput'
import FrSelect from '../FrSelect'
import FrDatePicker from '../FrDatePicker'
import ListRegisters from '../ListRegisters'
import ListRegistersDetailedFilter from '../ListRegistersDetailedFilter'
import { FiUpload } from "react-icons/fi";
import { FiDownload } from 'react-icons/fi';
import FrLoading from '../FrLoading'
import { Icon } from 'react-icons-kit'
import {ic_save} from 'react-icons-kit/md/ic_save'
import {ic_mode_edit} from 'react-icons-kit/md/ic_mode_edit'
import {ic_delete} from 'react-icons-kit/md/ic_delete'
import {ic_undo} from 'react-icons-kit/md/ic_undo'
import {ic_launch} from 'react-icons-kit/md/ic_launch'
import download from 'downloadjs'
import Tooltip from '@material-ui/core/Tooltip';

import consultaCep from '../../services/consultaCep'
import { paramsToObject, validarcnpj, validarCPF, onlyNumbers, removeSpecialChar } from '../../utils/functions'
import { mcnpj, mTel, mCEP, mCPF } from '../../utils/masks'

import api from '../../services/api'

import './index.css';

const swal = withReactContent(Swal)

function TabPanel(props) {
    const { children, value, index, relacionados, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}${relacionados ? '-relacionados' : ''}`}
        aria-labelledby={`scrollable-auto-tab-${index}${relacionados ? '-relacionados' : ''}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography >{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
  
function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
}

function a11yPropsRelacionados(index) {
    return {
      id: `simple-tab-${index}-relacionados`,
      'aria-controls': `simple-tabpanel-${index}-relacionados`,
    };
}
  
const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTabs-root': {
          backgroundColor: '#9c9595',
          '& .Mui-disabled': {
            color: '#9c9595', 
            }
        },
        flexGrow: 1,
        width: '100%',
        border: '2px solid #9c9595',
        borderRadius: 4,
        '& .MuiTabs-indicator': {
          backgroundColor: 'white',
          height: '5px'
        },
        '& .MuiTabs-scrollButtonsDesktop': {
          backgroundColor: '#9c9595',
        },
      //   backgroundColor: theme.palette.background.paper,
    },
}));

function TabsCliente(props) {
    const classes = useStyles();

    //Tabs
    const [value, setValue] = React.useState(0);
    const [valueRelacionados, setValueRelacionados] = React.useState(0)
    const handleChangeTabs = (event, newValue) => {
        switch (newValue) {
            case 1:
                setPropsTableSocios({... propsTabSocios, selected: null })
                props.reloadSocios()
            break

            case 2:
                setPropsTableContatos({... propsTabContatos, selected: null })
                props.reloadContatos()
            break

            case 5:
                setPropsTabDocumentos({... propsTabDocumentos, selected: null })
                props.reloadDocumentos()
            break

            case 6:
                setPropsTabDocumentosLicenca({... propsTabDocumentosLicenca, selected: null })
                props.reloadDocumentosLicenca()
            break

            case 7:
                setPropsTabTanqueCombustivel({... propsTabTanqueCombustivel, selected: null })
                props.reloadTanqueCombustivel()
            break



        }

        setValue(newValue);
    };

    const handleChangeTabsRelacionados = (event, newValue) => {
        setValueRelacionados(newValue);
    }

    //Sedes
    let data = props.data
    let dataPiloto = props.dataPiloto
    const setData = props.setData
    const setDataPiloto = props.setDataPiloto
    const handleChange = props.handleChange
    const handleChangePiloto = props.handleChangePiloto
    const handleChangeGestorResponsavel = props.handleChangeGestorResponsavel
    const handleChangeGestorOperacional = props.handleChangeGestorOperacional
    const [erroCep, setErroCep] = useState(false)
    const [erroCepPiloto, setErroCepPiloto] = useState(false)
    const [erroCepOperacional, setErroCepOperacional] = useState(false)
    const [erroCepDesconta, setErroCepDesconta] = useState(false)
    const [erroCepAbastec, setErroCepAbastec] = useState(false)
    const [erroCepSocios, setErroCepSocios] = useState(false)
    const [erroCepContatos, setErroCepContatos] = useState(false)
    const [erroCpfSocios, setErroCpfSocios] = useState(false)
    const [erroCpfPiloto, setErroCpfPiloto] = useState(false)

    function handleCepSocios(e) {
        e.preventDefault();
        if (e.target.value) {
          if (e.target.value) {
            consultaCep.get('/' + onlyNumbers(e.target.value) + '/json').then(r => {
              console.log(r)
              if (r.data.erro) {
                setErroCepSocios(true)
                swal.fire('CEP inválido', 'Informe um CEP válido!', 'warning')
              } else {
                setErroCepSocios(false)
                const auxValues = { ...currentDataSocios };
                auxValues['logradouro'] = r.data.logradouro;
                auxValues['bairro'] = r.data.bairro;
                let cidade = props.comboCidades.find(item => String(item.display).normalize('NFD').replace(/[\u0300-\u036f]/g, "") === String(r.data.localidade).toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ""))
                if (cidade) auxValues['fk_cid'] = cidade.value
                else alert('Cidade não cadastrada no sistema!')
                setCurrentDataSocios(auxValues)
              }
            }).catch(err => {
                setErroCepSocios(true)
                    swal.fire('CEP inválido', 'Informe um CEP válido!', 'warning')
                })
          }
        }
    }

    

    function handleCepContatos(e) {
        e.preventDefault();
        if (e.target.value) {
          if (e.target.value) {
            consultaCep.get('/' + onlyNumbers(e.target.value) + '/json').then(r => {
              console.log(r)
              if (r.data.erro) {
                setErroCepContatos(true)
                swal.fire('CEP inválido', 'Informe um CEP válido!', 'warning')
              } else {
                setErroCepContatos(false)
                const auxValues = { ...currentDataContatos };
                auxValues['logradouro'] = r.data.logradouro;
                auxValues['bairro'] = r.data.bairro;
                let cidade = props.comboCidades.find(item => String(item.display).normalize('NFD').replace(/[\u0300-\u036f]/g, "") === String(r.data.localidade).toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ""))
                if (cidade) auxValues['fk_cid'] = cidade.value
                else alert('Cidade não cadastrada no sistema!')
                setCurrentDataContatos(auxValues)
              }
            }).catch(err => {
            setErroCepContatos(true)
              swal.fire('CEP inválido', 'Informe um CEP válido!', 'warning')
            })
          }
        }
    }

    const replicaEndereco = (e) => {
        e.preventDefault();
        const auxValues = { ...data };
        auxValues['cep_operacional'] = auxValues['cep']
        auxValues['logradouro_operacional'] = auxValues['logradouro']
        auxValues['nr_operacional'] = auxValues['numero']
        auxValues['compl_operacional'] = auxValues['complemento']
        auxValues['bairro_operacional'] = auxValues['bairro']
        auxValues['fk_cid_operacional'] =auxValues['fk_cid']
        setData(auxValues)
    }

    const replicaGestor = (e) => {
        e.preventDefault();
        const auxValues = { ...props.dataGestorResponsavel };
        props.setDataGestorOperacional(auxValues)
    }

    function handleCep(e) {
        e.preventDefault();
        if (e.target.value) {
          if (e.target.value) {
            consultaCep.get('/' + onlyNumbers(e.target.value) + '/json').then(r => {
              console.log(r)
              if (r.data.erro) {
                setErroCep(true)
                swal.fire('CEP inválido', 'Informe um CEP válido!', 'warning')
              } else {
                setErroCep(false)
                const auxValues = { ...data };
                auxValues['logradouro'] = r.data.logradouro;
                auxValues['bairro'] = r.data.bairro;
                let cidade = props.comboCidades.find(item => String(item.display).normalize('NFD').replace(/[\u0300-\u036f]/g, "") === String(r.data.localidade).toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ""))
                if (cidade) auxValues['fk_cid'] = cidade.value
                else alert('Cidade não cadastrada no sistema!')
                setData(auxValues)
              }
            }).catch(err => {
              setErroCep(true)
              swal.fire('CEP inválido', 'Informe um CEP válido!', 'warning')
            })
          }
        }
      }

      function handleCepDesconta(e) {
        e.preventDefault();
        if (e.target.value) {
          if (e.target.value) {
            consultaCep.get('/' + onlyNumbers(e.target.value) + '/json').then(r => {
              console.log(r)
              if (r.data.erro) {
                setErroCepDesconta(true)
                swal.fire('CEP inválido', 'Informe um CEP válido!', 'warning')
              } else {
                setErroCepDesconta(false)
                const auxValues = { ...data };
                auxValues['logradouro_desconta'] = r.data.logradouro;
                auxValues['bairro_desconta'] = r.data.bairro;
                let cidade = props.comboCidades.find(item => String(item.display).normalize('NFD').replace(/[\u0300-\u036f]/g, "") === String(r.data.localidade).toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ""))
                if (cidade) auxValues['fk_cid_desconta'] = cidade.value
                else alert('Cidade não cadastrada no sistema!')
                setData(auxValues)
              }
            }).catch(err => {
              setErroCepDesconta(true)
              swal.fire('CEP inválido', 'Informe um CEP válido!', 'warning')
            })
          }
        }
      }

      function handleCepAbastec(e) {
        e.preventDefault();
        if (e.target.value) {
          if (e.target.value) {
            consultaCep.get('/' + onlyNumbers(e.target.value) + '/json').then(r => {
              console.log(r)
              if (r.data.erro) {
                setErroCepAbastec(true)
                swal.fire('CEP inválido', 'Informe um CEP válido!', 'warning')
              } else {
                setErroCepAbastec(false)
                const auxValues = { ...data };
                auxValues['logradouro_abastec'] = r.data.logradouro;
                auxValues['bairro_abastec'] = r.data.bairro;
                let cidade = props.comboCidades.find(item => String(item.display).normalize('NFD').replace(/[\u0300-\u036f]/g, "") === String(r.data.localidade).toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ""))
                if (cidade) auxValues['fk_cid_abastec'] = cidade.value
                else alert('Cidade não cadastrada no sistema!')
                setData(auxValues)
              }
            }).catch(err => {
              setErroCepAbastec(true)
              swal.fire('CEP inválido', 'Informe um CEP válido!', 'warning')
            })
          }
        }
      }

      function handleCepPiloto(e) {
        e.preventDefault();
        if (e.target.value) {
          if (e.target.value) {
            consultaCep.get('/' + onlyNumbers(e.target.value) + '/json').then(r => {
              console.log(r)
              if (r.data.erro) {
                setErroCepPiloto(true)
                swal.fire('CEP inválido', 'Informe um CEP válido!', 'warning')
              } else {
                setErroCepPiloto(false)
                const auxValues = { ...dataPiloto };
                auxValues['logradouro'] = r.data.logradouro;
                auxValues['bairro'] = r.data.bairro;
                let cidade = props.comboCidades.find(item => String(item.display).normalize('NFD').replace(/[\u0300-\u036f]/g, "") === String(r.data.localidade).toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ""))
                if (cidade) auxValues['fk_cid'] = cidade.value
                else alert('Cidade não cadastrada no sistema!')
                setDataPiloto(auxValues)
              }
            }).catch(err => {
              setErroCepPiloto(true)
              swal.fire('CEP inválido', 'Informe um CEP válido!', 'warning')
            })
          }
        }
      }
    
    function handleCepOperacional(e) {
        e.preventDefault();
        if (e.target.value) {
            if (e.target.value) {
            consultaCep.get('/' + onlyNumbers(e.target.value) + '/json').then(r => {
                console.log(r)
                if (r.data.erro) {
                    setErroCepOperacional(true)
                    swal.fire('CEP inválido', 'Informe um CEP válido!', 'warning')
                } else {
                    setErroCepOperacional(false)
                    const auxValues = { ...data };
                    auxValues['logradouro_operacional'] = r.data.logradouro;
                    auxValues['bairro_operacional'] = r.data.bairro;
                    let cidade = props.comboCidades.find(item => String(item.display).normalize('NFD').replace(/[\u0300-\u036f]/g, "") === String(r.data.localidade).toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ""))
                    if (cidade) auxValues['fk_cid_operacional'] = cidade.value
                    else alert('Cidade não cadastrada no sistema!')
                    setData(auxValues)
                }
            }).catch(err => {
                setErroCepOperacional(true)
                swal.fire('CEP inválido', 'Informe um CEP válido!', 'warning')
            })
            }
        }
    }
    
    //Sócios
    const [currentDataSocios, setCurrentDataSocios] = React.useState(null)
    const [propsTabSocios, setPropsTableSocios] = React.useState({
        selected: null,
    })
    const [editSocios, setEditSocios] = React.useState(false)
    const [newSocio, setNewSocio] = React.useState(false)

    const selectSocio = (event, codigo) => {
        setEditSocios(false)
        setNewSocio(false)
        setPropsTableSocios({... propsTabSocios, selected: codigo })
    }

    React.useEffect(() => {
        let selectedSocio = props.dataSocios.find((item) => item.codigo === propsTabSocios.selected)
        console.log(selectedSocio)
        if (selectedSocio) {
            selectedSocio.cpf = mCPF(selectedSocio.cpf)
            selectedSocio.cep = mCEP(selectedSocio.cep)
            selectedSocio.fone1 = mTel(selectedSocio.fone1)
            selectedSocio.fone2 = mTel(selectedSocio.fone2)
            selectedSocio.celular = mTel(selectedSocio.celular)
        }    
        setCurrentDataSocios(selectedSocio||{})
    }, [propsTabSocios.selected])

    function handleCpfSocio(e) {
        e.preventDefault();
        let cpf = e.target.value
        if(cpf) {
          if(!validarCPF(cpf)) {
            setErroCpfSocios(true)
            swal.fire('CPF inválido', 'Informe um CPF válido!', 'warning')
          } else {
            setErroCpfSocios(false)
            api.get('/pessoa?cpf=' + onlyNumbers(e.target.value)).then(r => {
                if (r.data[0]) {
                    r.data[0].cpf = mCPF(r.data[0].cpf)
                    r.data[0].fone1 = mTel(r.data[0].fone1)
                    r.data[0].fone2 = mTel(r.data[0].fone2)
                    r.data[0].cep = mCEP(r.data[0].cep)
                    setCurrentDataSocios(r.data[0])
                }
            })
          }
        }
       
    }

    function handleCpfPiloto(e) {
        e.preventDefault();
        let cpf = e.target.value
        if(cpf) {
          if(!validarCPF(cpf)) {
            setErroCpfPiloto(true)
            swal.fire('CPF inválido', 'Informe um CPF válido!', 'warning')
          } else {
            setErroCpfPiloto(false)
            api.get('/pessoa?cpf=' + onlyNumbers(e.target.value)).then(r => {
                if (r.data[0]) {
                    r.data[0].cpf = mCPF(r.data[0].cpf)
                    r.data[0].fone1 = mTel(r.data[0].fone1)
                    r.data[0].fone2 = mTel(r.data[0].fone2)
                    r.data[0].cep = mCEP(r.data[0].cep)
                    setDataPiloto(r.data[0])
                }
            })
          }
        }
       
    }

    const handleChangeSocios = (e) => {
        const auxValues = { ...currentDataSocios };
        switch (e.target.name) {
            case 'fone1':
            case 'fone2':
            case 'celular':
                auxValues[e.target.name] = mTel(e.target.value)
            break
        
            case 'cpf': 
                auxValues[e.target.name] = mCPF(e.target.value)
            break
        
            case 'cep':
                auxValues[e.target.name] = mCEP(e.target.value)
            break

            default: auxValues[e.target.name] = e.target.value;
        }
        setCurrentDataSocios(auxValues);
    }

    const saveSocios = () => {
        const auxValues = { ...currentDataSocios };
        auxValues.fk_pes_cli = props.pk_pes_cli
        auxValues.fk_trc = 1
        auxValues.cpf = onlyNumbers(auxValues.cpf)
        auxValues.cep = onlyNumbers(auxValues.cep)
        auxValues.fone1 = onlyNumbers(auxValues.fone1)
        auxValues.fone2 = onlyNumbers(auxValues.fone2)
        auxValues.celular = onlyNumbers(auxValues.celular)
        console.log(auxValues)
        if (erroCpfSocios) {
            swal.fire('CPF inválido', 'Informe um CPF válido!', 'warning')
        } else {
            if (newSocio) {
                api.post('/cliente/pessoa/add', auxValues).then(r => {
                    if (r.status === 200) {
                        swal.fire({
                            title: 'Registro Adicionado',
                            text: "O registro foi incluído com sucesso!",
                            icon: 'success',
                        }).then((result) => {
                            if (result.value) {
                                setPropsTableSocios({... propsTabSocios, selected: null })
                                setCurrentDataSocios({})
                                setEditSocios(false)
                                setNewSocio(false)
                                props.reloadSocios()
                            }
                        })
                    } else {
                        swal.fire({
                            title: 'Erro!',
                            text: "O registro não foi alterado!",
                            icon: 'warning',
                        })
                    }
                })
            } else {
                api.post('/cliente/pessoa/edit', auxValues).then(r => {
                    if (r.status === 200) {
                        swal.fire({
                            title: 'Registro Alteraddo',
                            text: "O registro foi alterado com sucesso!",
                            icon: 'success',
                        }).then((result) => {
                            if (result.value) {
                                setPropsTableSocios({... propsTabSocios, selected: null })
                                setCurrentDataSocios({})
                                setEditSocios(false)
                                setNewSocio(false)
                                props.reloadSocios()
                            }
                        })
                    } else {
                        swal.fire({
                            title: 'Erro!',
                            text: "O registro não foi alterado!",
                            icon: 'warning',
                        })
                    }
                })
            }
        }
    }

    const deleteSocios = () => {
        const auxValues = { ...currentDataSocios };
        swal.fire({
            title: 'ATENÇÃO',
            text: 'Deseja excluir o registro?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                api.post('/cliente/pessoa/delete/'+auxValues.pk_pes, { fk_trc: 1 }).then(r => {
                    if (r.status === 200) {
                        swal.fire({
                            title: 'Registro Excluído',
                            text: "O registro foi excluído com sucesso!",
                            icon: 'success',
                        }).then((result) => {
                            if (result.value) {
                                setPropsTableSocios({... propsTabSocios, selected: null })
                                setCurrentDataSocios({})
                                setEditSocios(false)
                                setNewSocio(false)
                                props.reloadSocios()
                            }
                        })
                    } else {
                        swal.fire({
                            title: 'Erro!',
                            text: "O registro não foi alterado!",
                            icon: 'warning',
                        })
                    }
                })
            }
        })
    }    

    //Contatos
    const [currentDataContatos, setCurrentDataContatos] = React.useState(null)
    const [propsTabContatos, setPropsTableContatos] = React.useState({
        selected: null,
    })
    const [editContatos, setEditContatos] = React.useState(false)
    const [newContato, setNewContato] = React.useState(false)

    const selectContato = (event, codigo) => {
        setEditContatos(false)
        setNewContato(false)
        setPropsTableContatos({... propsTabContatos, selected: codigo })
    }

    React.useEffect(() => {
        let selectedContato = props.dataContatos.find((item) => item.codigo === propsTabContatos.selected)
        console.log(selectedContato)
        if (selectedContato) {
            selectedContato.fone1 = mTel(selectedContato.fone1)
            selectedContato.fone2 = mTel(selectedContato.fone2)
            selectedContato.celular = mTel(selectedContato.celular)
        }    
        setCurrentDataContatos(selectedContato||{})
    }, [propsTabContatos.selected])

    const handleChangeContatos = (e) => {
        const auxValues = { ...currentDataContatos };
        switch (e.target.name) {
            case 'fone1':
            case 'fone2':
            case 'celular':
                auxValues[e.target.name] = mTel(e.target.value)
            break
        
            default: auxValues[e.target.name] = e.target.value;
        }
        setCurrentDataContatos(auxValues);
    }

    const saveContatos = () => {
        const auxValues = { ...currentDataContatos };
        auxValues.fk_pes_cli = props.pk_pes_cli
        auxValues.fone1 = onlyNumbers(auxValues.fone1)
        auxValues.fone2 = onlyNumbers(auxValues.fone2)
        auxValues.celular = onlyNumbers(auxValues.celular)
        console.log(auxValues)
        if (newContato) {
            api.post('/cliente/contato/add', auxValues).then(r => {
                if (r.status === 200) {
                    swal.fire({
                        title: 'Registro Adicionado',
                        text: "O registro foi incluído com sucesso!",
                        icon: 'success',
                    }).then((result) => {
                        if (result.value) {
                            setPropsTableContatos({... propsTabContatos, selected: null })
                            setCurrentDataContatos({})
                            setEditContatos(false)
                            setNewContato(false)
                            props.reloadContatos()
                        }
                    })
                } else {
                    swal.fire({
                        title: 'Erro!',
                        text: "O registro não foi alterado!",
                        icon: 'warning',
                    })
                }
            })
        } else {
            api.post('/cliente/contato/edit', auxValues).then(r => {
                if (r.status === 200) {
                    swal.fire({
                        title: 'Registro Alteraddo',
                        text: "O registro foi alterado com sucesso!",
                        icon: 'success',
                    }).then((result) => {
                        if (result.value) {
                            setPropsTableContatos({... propsTabContatos, selected: null })
                            setCurrentDataContatos({})
                            setEditContatos(false)
                            setNewContato(false)
                            props.reloadContatos()
                        }
                    })
                } else {
                    swal.fire({
                        title: 'Erro!',
                        text: "O registro não foi alterado!",
                        icon: 'warning',
                    })
                }
            })
        }
    }

    const deleteContatos = () => {
        const auxValues = { ...currentDataContatos };
        swal.fire({
            title: 'ATENÇÃO',
            text: 'Deseja excluir o registro?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                api.post('/cliente/contato/delete/'+auxValues.pk_ccl).then(r => {
                    if (r.status === 200) {
                        swal.fire({
                            title: 'Registro Excluído',
                            text: "O registro foi excluído com sucesso!",
                            icon: 'success',
                        }).then((result) => {
                            if (result.value) {
                                setPropsTableContatos({... propsTabContatos, selected: null })
                                setCurrentDataContatos({})
                                setEditContatos(false)
                                setNewContato(false)
                                props.reloadContatos()
                            }
                        })
                    } else {
                        swal.fire({
                            title: 'Erro!',
                            text: "O registro não foi alterado!",
                            icon: 'warning',
                        })
                    }
                })
            }
        })
    }    

    //TanqueCombustivel
    const [currentDataTanqueCombustivel, setCurrentDataTanqueCombustivel] = React.useState(null)
    const [propsTabTanqueCombustivel, setPropsTabTanqueCombustivel] = React.useState({
        selected: null,
    })
    const [editTanqueCombustivel, setEditTanqueCombustivel] = React.useState(false)
    const [newTanqueCombustivel, setNewTanqueCombustivel] = React.useState(false)

    const selectTanqueCombustivel = (event, codigo) => {
        setEditTanqueCombustivel(false)
        setNewTanqueCombustivel(false)
        setPropsTabTanqueCombustivel({... propsTabTanqueCombustivel, selected: codigo })
    }

    React.useEffect(() => {
        setCurrentDataTanqueCombustivel(props.dataTanqueCombustivel.find((item) => item.codigo === propsTabTanqueCombustivel.selected))
    }, [propsTabTanqueCombustivel.selected])

    React.useEffect(() => {
        
    }, [props.dataTanqueCombustivel])

    const handleChangeTanqueCombustivel = (e) => {
        const auxValues = { ...currentDataTanqueCombustivel };
        switch (e.target.name) {
            case 'CNPJ':
            default: auxValues[e.target.name] = e.target.value;
        }
        setCurrentDataTanqueCombustivel(auxValues);
    }

    const saveTanqueCombustivel = () => {
        const auxValues = { ...currentDataTanqueCombustivel };
        auxValues.fk_pes_cli = props.pk_pes_cli
        console.log(auxValues)
        if (newTanqueCombustivel) {
            api.post('/cliente/tanque_combustivel/add', auxValues).then(r => {
                if (r.status === 200) {
                    swal.fire({
                        title: 'Registro Adicionado',
                        text: "O registro foi incluído com sucesso!",
                        icon: 'success',
                    }).then((result) => {
                        if (result.value) {
                            setPropsTabTanqueCombustivel({... propsTabTanqueCombustivel, selected: null })
                            setCurrentDataTanqueCombustivel({})
                            setEditTanqueCombustivel(false)
                            setNewTanqueCombustivel(false)
                            props.reloadTanqueCombustivel()
                        }
                    })
                } else {
                    swal.fire({
                        title: 'Erro!',
                        text: "O registro não foi alterado!",
                        icon: 'warning',
                    })
                }
            })
        } else {
            api.post('/cliente/tanque_combustivel/edit', auxValues).then(r => {
                if (r.status === 200) {
                    swal.fire({
                        title: 'Registro Alteraddo',
                        text: "O registro foi alterado com sucesso!",
                        icon: 'success',
                    }).then((result) => {
                        if (result.value) {
                            setPropsTabTanqueCombustivel({... propsTabTanqueCombustivel, selected: null })
                            setCurrentDataTanqueCombustivel({})
                            setEditTanqueCombustivel(false)
                            setNewTanqueCombustivel(false)
                            props.reloadTanqueCombustivel()
                        }
                    })
                } else {
                    swal.fire({
                        title: 'Erro!',
                        text: "O registro não foi alterado!",
                        icon: 'warning',
                    })
                }
            })
        }
    }

    const deleteTanqueCombustivel = () => {
        const auxValues = { ...currentDataTanqueCombustivel };
        swal.fire({
            title: 'ATENÇÃO',
            text: 'Deseja excluir o registro?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                api.post('/cliente/tanque_combustivel/delete/'+auxValues.pk_tan).then(r => {
                    if (r.status === 200) {
                        swal.fire({
                            title: 'Registro Excluído',
                            text: "O registro foi excluído com sucesso!",
                            icon: 'success',
                        }).then((result) => {
                            if (result.value) {
                                setPropsTabTanqueCombustivel({... propsTabTanqueCombustivel, selected: null })
                                setCurrentDataTanqueCombustivel({})
                                setEditTanqueCombustivel(false)
                                setNewTanqueCombustivel(false)
                                props.reloadTanqueCombustivel()
                            }
                        })
                    } else {
                        swal.fire({
                            title: 'Erro!',
                            text: "O registro não foi alterado!",
                            icon: 'warning',
                        })
                    }
                })
            }
        })
    }    

     //RelacionadosPiloto
     const [currentDataRelacionadosPiloto, setCurrentDataRelacionadosPiloto] = React.useState(null)
     const [propsTabRelacionadosPiloto, setPropsTabRelacionadosPiloto] = React.useState({
         selected: null,
     })
     const [editRelacionadosPiloto, setEditRelacionadosPiloto] = React.useState(false)
     const [newRelacionadosPiloto, setNewRelacionadosPiloto] = React.useState(false)
     
    const filtersRelacionadosPiloto = [
        { type: 'text', field: 'descricao', name: 'Piloto' },
        { type: 'period', field: 'data_admissao', name: 'Data Admissão' },
        { type: 'period', field: 'data_desligamento', name: 'Data Desligamento'},
        { type: 'select', field: 'fk_sit', name: 'Situacao', data: props.comboSituacaoPiloto}
    ]
 
     const selectRelacionadosPiloto = (event, codigo) => {
        setEditRelacionadosPiloto(false)
        setNewRelacionadosPiloto(false)
        setPropsTabRelacionadosPiloto({... propsTabRelacionadosPiloto, selected: codigo })
    }
    
    React.useEffect(() => {
        setCurrentDataRelacionadosPiloto(props.dataRelacionadosPiloto.find((item) => item.codigo === propsTabRelacionadosPiloto.selected))
    }, [propsTabRelacionadosPiloto.selected])
    
    React.useEffect(() => {
        
    }, [props.dataRelacionadosPiloto])
    
    const handleChangeRelacionadosPiloto = (e) => {
        const auxValues = { ...currentDataRelacionadosPiloto };
        switch (e.target.name) {
            case 'CNPJ':
            default: auxValues[e.target.name] = e.target.value;
        }
        setCurrentDataRelacionadosPiloto(auxValues);
    }
    
    const saveRelacionadosPiloto = () => {
        const auxValues = { ...currentDataRelacionadosPiloto };
        auxValues.fk_pes_cli = props.pk_pes_cli
        auxValues.data_admissao = auxValues.data_admissao ? auxValues.data_admissao.split('T')[0] : null
        auxValues.data_desligamento = auxValues.data_desligamento ? auxValues.data_desligamento.split('T')[0] : null
        console.log(auxValues)
        if (newRelacionadosPiloto) {
            api.post('/cliente/piloto/add', auxValues).then(r => {
                if (r.status === 200) {
                    swal.fire({
                        title: 'Registro Adicionado',
                        text: "O registro foi incluído com sucesso!",
                        icon: 'success',
                    }).then((result) => {
                        if (result.value) {
                            setPropsTabRelacionadosPiloto({... propsTabRelacionadosPiloto, selected: null })
                            setCurrentDataRelacionadosPiloto({})
                            setEditRelacionadosPiloto(false)
                            setNewRelacionadosPiloto(false)
                            props.reloadRelacionadosPiloto()
                        }
                    })
                } else {
                    swal.fire({
                        title: 'Erro!',
                        text: "O registro não foi alterado!",
                        icon: 'warning',
                    })
                }
            })
        } else {
            api.post('/cliente/piloto/edit', auxValues).then(r => {
                if (r.status === 200) {
                    swal.fire({
                        title: 'Registro Alteraddo',
                        text: "O registro foi alterado com sucesso!",
                        icon: 'success',
                    }).then((result) => {
                        if (result.value) {
                            setPropsTabRelacionadosPiloto({... propsTabRelacionadosPiloto, selected: null })
                            setCurrentDataRelacionadosPiloto({})
                            setEditRelacionadosPiloto(false)
                            setNewRelacionadosPiloto(false)
                            props.reloadRelacionadosPiloto()
                        }
                    })
                } else {
                    swal.fire({
                        title: 'Erro!',
                        text: "O registro não foi alterado!",
                        icon: 'warning',
                    })
                }
            })
        }
    }
    
    const deleteRelacionadosPiloto = () => {
        const auxValues = { ...currentDataRelacionadosPiloto };
        swal.fire({
            title: 'ATENÇÃO',
            text: 'Deseja excluir o registro?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                api.post('/cliente/piloto/delete/'+auxValues.pk_cpi).then(r => {
                    if (r.status === 200) {
                        swal.fire({
                            title: 'Registro Excluído',
                            text: "O registro foi excluído com sucesso!",
                            icon: 'success',
                        }).then((result) => {
                            if (result.value) {
                                setPropsTabRelacionadosPiloto({... propsTabRelacionadosPiloto, selected: null })
                                setCurrentDataRelacionadosPiloto({})
                                setEditRelacionadosPiloto(false)
                                setNewRelacionadosPiloto(false)
                                props.reloadRelacionadosPiloto()
                            }
                        })
                    } else {
                        swal.fire({
                            title: 'Erro!',
                            text: "O registro não foi alterado!",
                            icon: 'warning',
                        })
                    }
                })
            }
        })
    }

    //RelacionadosMecanico
    const [currentDataRelacionadosMecanico, setCurrentDataRelacionadosMecanico] = React.useState(null)
    const [propsTabRelacionadosMecanico, setPropsTabRelacionadosMecanico] = React.useState({
        selected: null,
    })
    const [editRelacionadosMecanico, setEditRelacionadosMecanico] = React.useState(false)
    const [newRelacionadosMecanico, setNewRelacionadosMecanico] = React.useState(false)
    const filtersRelacionadosMecanico = [
        { type: 'text', field: 'descricao', name: 'Mecânico' },
        { type: 'period', field: 'data_admissao', name: 'Data Admissão' },
        { type: 'period', field: 'data_desligamento', name: 'Data Desligamento'},
        { type: 'select', field: 'fk_sit', name: 'Situacao', data: props.comboSituacaoMecanico}
    ]

    const selectRelacionadosMecanico = (event, codigo) => {
        setEditRelacionadosMecanico(false)
        setNewRelacionadosMecanico(false)
        setPropsTabRelacionadosMecanico({... propsTabRelacionadosMecanico, selected: codigo })
    }

    React.useEffect(() => {
        setCurrentDataRelacionadosMecanico(props.dataRelacionadosMecanico.find((item) => item.codigo === propsTabRelacionadosMecanico.selected))
    }, [propsTabRelacionadosMecanico.selected])

    React.useEffect(() => {
    
    }, [props.dataRelacionadosMecanico])

    const handleChangeRelacionadosMecanico = (e) => {
        const auxValues = { ...currentDataRelacionadosMecanico };
        switch (e.target.name) {
            case 'CNPJ':
            default: auxValues[e.target.name] = e.target.value;
        }
        setCurrentDataRelacionadosMecanico(auxValues);
    }

    const saveRelacionadosMecanico = () => {
        const auxValues = { ...currentDataRelacionadosMecanico };
        auxValues.fk_pes_cli = props.pk_pes_cli
        auxValues.data_admissao = auxValues.data_admissao ? auxValues.data_admissao.split('T')[0] : null
        auxValues.data_desligamento = auxValues.data_desligamento ? auxValues.data_desligamento.split('T')[0] : null
        console.log(auxValues)
        if (newRelacionadosMecanico) {
            api.post('/cliente/mecanico/add', auxValues).then(r => {
                if (r.status === 200) {
                    swal.fire({
                        title: 'Registro Adicionado',
                        text: "O registro foi incluído com sucesso!",
                        icon: 'success',
                    }).then((result) => {
                        if (result.value) {
                            setPropsTabRelacionadosMecanico({... propsTabRelacionadosMecanico, selected: null })
                            setCurrentDataRelacionadosMecanico({})
                            setEditRelacionadosMecanico(false)
                            setNewRelacionadosMecanico(false)
                            props.reloadRelacionadosMecanico()
                        }
                    })
                } else {
                    swal.fire({
                        title: 'Erro!',
                        text: "O registro não foi alterado!",
                        icon: 'warning',
                    })
                }
            })
        } else {
            api.post('/cliente/mecanico/edit', auxValues).then(r => {
                if (r.status === 200) {
                    swal.fire({
                        title: 'Registro Alteraddo',
                        text: "O registro foi alterado com sucesso!",
                        icon: 'success',
                    }).then((result) => {
                        if (result.value) {
                            setPropsTabRelacionadosMecanico({... propsTabRelacionadosMecanico, selected: null })
                            setCurrentDataRelacionadosMecanico({})
                            setEditRelacionadosMecanico(false)
                            setNewRelacionadosMecanico(false)
                            props.reloadRelacionadosMecanico()
                        }
                    })
                } else {
                    swal.fire({
                        title: 'Erro!',
                        text: "O registro não foi alterado!",
                        icon: 'warning',
                    })
                }
            })
        }
    }

    const deleteRelacionadosMecanico = () => {
        const auxValues = { ...currentDataRelacionadosMecanico };
        swal.fire({
            title: 'ATENÇÃO',
            text: 'Deseja excluir o registro?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                api.post('/cliente/mecanico/delete/'+auxValues.pk_cme).then(r => {
                    if (r.status === 200) {
                        swal.fire({
                            title: 'Registro Excluído',
                            text: "O registro foi excluído com sucesso!",
                            icon: 'success',
                        }).then((result) => {
                            if (result.value) {
                                setPropsTabRelacionadosMecanico({... propsTabRelacionadosMecanico, selected: null })
                                setCurrentDataRelacionadosMecanico({})
                                setEditRelacionadosMecanico(false)
                                setNewRelacionadosMecanico(false)
                                props.reloadRelacionadosMecanico()
                            }
                        })
                    } else {
                        swal.fire({
                            title: 'Erro!',
                            text: "O registro não foi alterado!",
                            icon: 'warning',
                        })
                    }
                })
            }
        })
    }

    //RelacionadosAeronave
    const [currentDataRelacionadosAeronave, setCurrentDataRelacionadosAeronave] = React.useState(null)
    const [propsTabRelacionadosAeronave, setPropsTabRelacionadosAeronave] = React.useState({
        selected: null,
    })
    const [editRelacionadosAeronave, setEditRelacionadosAeronave] = React.useState(false)
    const [newRelacionadosAeronave, setNewRelacionadosAeronave] = React.useState(false)
    const filtersRelacionadosAeronave = [
        { type: 'text', field: 'descricao', name: 'Aeronave' },
        { type: 'period', field: 'data_aquisicao', name: 'Data Aquisição' },
        { type: 'period', field: 'data_baixa', name: 'Data Baixa'},
        { type: 'select', field: 'fk_sit', name: 'Situacao', data: props.comboSituacaoAeronave}
    ]

    const selectRelacionadosAeronave = (event, codigo) => {
        setEditRelacionadosAeronave(false)
        setNewRelacionadosAeronave(false)
        setPropsTabRelacionadosAeronave({... propsTabRelacionadosAeronave, selected: codigo })
    }

    React.useEffect(() => {
        setCurrentDataRelacionadosAeronave(props.dataRelacionadosAeronave.find((item) => item.codigo === propsTabRelacionadosAeronave.selected))
    }, [propsTabRelacionadosAeronave.selected])

    React.useEffect(() => {

    }, [props.dataRelacionadosAeronave])

    const handleChangeRelacionadosAeronave = (e) => {
        const auxValues = { ...currentDataRelacionadosAeronave };
        switch (e.target.name) {
            case 'CNPJ':
            default: auxValues[e.target.name] = e.target.value;
        }
        setCurrentDataRelacionadosAeronave(auxValues);
    }

    const saveRelacionadosAeronave = () => {
        const auxValues = { ...currentDataRelacionadosAeronave };
        auxValues.fk_pes_cli = props.pk_pes_cli
        auxValues.data_aquisicao = auxValues.data_aquisicao ? auxValues.data_aquisicao.split('T')[0] : null
        auxValues.data_baixa = auxValues.data_baixa ? auxValues.data_baixa.split('T')[0] : null
        console.log(auxValues)
        if (newRelacionadosAeronave) {
            api.post('/cliente/aeronave/add', auxValues).then(r => {
                if (r.status === 200) {
                    swal.fire({
                        title: 'Registro Adicionado',
                        text: "O registro foi incluído com sucesso!",
                        icon: 'success',
                    }).then((result) => {
                        if (result.value) {
                            setPropsTabRelacionadosAeronave({... propsTabRelacionadosAeronave, selected: null })
                            setCurrentDataRelacionadosAeronave({})
                            setEditRelacionadosAeronave(false)
                            setNewRelacionadosAeronave(false)
                            props.reloadRelacionadosAeronave()
                        }
                    })
                } else {
                    swal.fire({
                        title: 'Erro!',
                        text: "O registro não foi alterado!",
                        icon: 'warning',
                    })
                }
            })
        } else {
            api.post('/cliente/aeronave/edit', auxValues).then(r => {
                if (r.status === 200) {
                    swal.fire({
                        title: 'Registro Alteraddo',
                        text: "O registro foi alterado com sucesso!",
                        icon: 'success',
                    }).then((result) => {
                        if (result.value) {
                            setPropsTabRelacionadosAeronave({... propsTabRelacionadosAeronave, selected: null })
                            setCurrentDataRelacionadosAeronave({})
                            setEditRelacionadosAeronave(false)
                            setNewRelacionadosAeronave(false)
                            props.reloadRelacionadosAeronave()
                        }
                    })
                } else {
                    swal.fire({
                        title: 'Erro!',
                        text: "O registro não foi alterado!",
                        icon: 'warning',
                    })
                }
            })
        }
    }

    const deleteRelacionadosAeronave = () => {
        const auxValues = { ...currentDataRelacionadosAeronave };
        swal.fire({
            title: 'ATENÇÃO',
            text: 'Deseja excluir o registro?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                api.post('/cliente/aeronave/delete/'+auxValues.pk_cae).then(r => {
                    if (r.status === 200) {
                        swal.fire({
                            title: 'Registro Excluído',
                            text: "O registro foi excluído com sucesso!",
                            icon: 'success',
                        }).then((result) => {
                            if (result.value) {
                                setPropsTabRelacionadosAeronave({... propsTabRelacionadosAeronave, selected: null })
                                setCurrentDataRelacionadosAeronave({})
                                setEditRelacionadosAeronave(false)
                                setNewRelacionadosAeronave(false)
                                props.reloadRelacionadosAeronave()
                            }
                        })
                    } else {
                        swal.fire({
                            title: 'Erro!',
                            text: "O registro não foi alterado!",
                            icon: 'warning',
                        })
                    }
                })
            }
        })
    }

    //RelacionadosApua
    const [currentDataRelacionadosApua, setCurrentDataRelacionadosApua] = React.useState(null)
    const [propsTabRelacionadosApua, setPropsTabRelacionadosApua] = React.useState({
        selected: null,
    })
    const [editRelacionadosApua, setEditRelacionadosApua] = React.useState(false)
    const [newRelacionadosApua, setNewRelacionadosApua] = React.useState(false)
    const filtersRelacionadosApua = [
        { type: 'text', field: 'descricao', name: 'Apua' },
        { type: 'text', field: 'denominacao', name: 'Denominacao' },
        { type: 'text', field: 'analise', name: 'Análise'},
    ]

    const selectRelacionadosApua = (event, codigo) => {
        setEditRelacionadosApua(false)
        setNewRelacionadosApua(false)
        setPropsTabRelacionadosApua({... propsTabRelacionadosApua, selected: codigo })
    }

    React.useEffect(() => {
        setCurrentDataRelacionadosApua(props.dataRelacionadosApua.find((item) => item.codigo === propsTabRelacionadosApua.selected))
    }, [propsTabRelacionadosApua.selected])

    React.useEffect(() => {

    }, [props.dataRelacionadosApua])

    const handleChangeRelacionadosApua = (e) => {
        const auxValues = { ...currentDataRelacionadosApua };
        switch (e.target.name) {
            case 'CNPJ':
            default: auxValues[e.target.name] = e.target.value;
        }
        setCurrentDataRelacionadosApua(auxValues);
    }

    const saveRelacionadosApua = () => {
        const auxValues = { ...currentDataRelacionadosApua };
        auxValues.fk_pes_cli = props.pk_pes_cli
        console.log(auxValues)
        if (newRelacionadosApua) {
            api.post('/cliente/apua/add', auxValues).then(r => {
                if (r.status === 200) {
                    swal.fire({
                        title: 'Registro Adicionado',
                        text: "O registro foi incluído com sucesso!",
                        icon: 'success',
                    }).then((result) => {
                        if (result.value) {
                            setPropsTabRelacionadosApua({... propsTabRelacionadosApua, selected: null })
                            setCurrentDataRelacionadosApua({})
                            setEditRelacionadosApua(false)
                            setNewRelacionadosApua(false)
                            props.reloadRelacionadosApua()
                        }
                    })
                } else {
                    swal.fire({
                        title: 'Erro!',
                        text: "O registro não foi alterado!",
                        icon: 'warning',
                    })
                }
            })
        } else {
            api.post('/cliente/apua/edit', auxValues).then(r => {
                if (r.status === 200) {
                    swal.fire({
                        title: 'Registro Alteraddo',
                        text: "O registro foi alterado com sucesso!",
                        icon: 'success',
                    }).then((result) => {
                        if (result.value) {
                            setPropsTabRelacionadosApua({... propsTabRelacionadosApua, selected: null })
                            setCurrentDataRelacionadosApua({})
                            setEditRelacionadosApua(false)
                            setNewRelacionadosApua(false)
                            props.reloadRelacionadosApua()
                        }
                    })
                } else {
                    swal.fire({
                        title: 'Erro!',
                        text: "O registro não foi alterado!",
                        icon: 'warning',
                    })
                }
            })
        }
    }

    const deleteRelacionadosApua = () => {
        const auxValues = { ...currentDataRelacionadosApua };
        swal.fire({
            title: 'ATENÇÃO',
            text: 'Deseja excluir o registro?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                api.post('/cliente/apua/delete/'+auxValues.pk_cap).then(r => {
                    if (r.status === 200) {
                        swal.fire({
                            title: 'Registro Excluído',
                            text: "O registro foi excluído com sucesso!",
                            icon: 'success',
                        }).then((result) => {
                            if (result.value) {
                                setPropsTabRelacionadosApua({... propsTabRelacionadosApua, selected: null })
                                setCurrentDataRelacionadosApua({})
                                setEditRelacionadosApua(false)
                                setNewRelacionadosApua(false)
                                props.reloadRelacionadosApua()
                            }
                        })
                    } else {
                        swal.fire({
                            title: 'Erro!',
                            text: "O registro não foi alterado!",
                            icon: 'warning',
                        })
                    }
                })
            }
        })
    }

    //RelacionadosEngenheiro
    const [currentDataRelacionadosEngenheiro, setCurrentDataRelacionadosEngenheiro] = React.useState(null)
     const [propsTabRelacionadosEngenheiro, setPropsTabRelacionadosEngenheiro] = React.useState({
         selected: null,
     })
     const [editRelacionadosEngenheiro, setEditRelacionadosEngenheiro] = React.useState(false)
     const [newRelacionadosEngenheiro, setNewRelacionadosEngenheiro] = React.useState(false)
     
    const filtersRelacionadosEngenheiro = [
        { type: 'text', field: 'descricao', name: 'Engenheiro' },
        { type: 'period', field: 'data_admissao', name: 'Data Admissão' },
        { type: 'period', field: 'data_desligamento', name: 'Data Desligamento'},
        { type: 'select', field: 'fk_sit', name: 'Situacao', data: props.comboSituacaoEngenheiro}
    ]
 
     const selectRelacionadosEngenheiro = (event, codigo) => {
        setEditRelacionadosEngenheiro(false)
        setNewRelacionadosEngenheiro(false)
        setPropsTabRelacionadosEngenheiro({... propsTabRelacionadosEngenheiro, selected: codigo })
    }
    
    React.useEffect(() => {
        setCurrentDataRelacionadosEngenheiro(props.dataRelacionadosEngenheiro.find((item) => item.codigo === propsTabRelacionadosEngenheiro.selected))
    }, [propsTabRelacionadosEngenheiro.selected])
    
    React.useEffect(() => {
        
    }, [props.dataRelacionadosEngenheiro])
    
    const handleChangeRelacionadosEngenheiro = (e) => {
        const auxValues = { ...currentDataRelacionadosEngenheiro };
        switch (e.target.name) {
            case 'CNPJ':
            default: auxValues[e.target.name] = e.target.value;
        }
        setCurrentDataRelacionadosEngenheiro(auxValues);
    }
    
    const saveRelacionadosEngenheiro = () => {
        const auxValues = { ...currentDataRelacionadosEngenheiro };
        auxValues.fk_pes_cli = props.pk_pes_cli
        auxValues.data_admissao = auxValues.data_admissao ? auxValues.data_admissao.split('T')[0] : null
        auxValues.data_desligamento = auxValues.data_desligamento ? auxValues.data_desligamento.split('T')[0] : null
        console.log(auxValues)
        if (newRelacionadosEngenheiro) {
            api.post('/cliente/engenheiro/add', auxValues).then(r => {
                if (r.status === 200) {
                    swal.fire({
                        title: 'Registro Adicionado',
                        text: "O registro foi incluído com sucesso!",
                        icon: 'success',
                    }).then((result) => {
                        if (result.value) {
                            setPropsTabRelacionadosEngenheiro({... propsTabRelacionadosEngenheiro, selected: null })
                            setCurrentDataRelacionadosEngenheiro({})
                            setEditRelacionadosEngenheiro(false)
                            setNewRelacionadosEngenheiro(false)
                            props.reloadRelacionadosEngenheiro()
                        }
                    })
                } else {
                    swal.fire({
                        title: 'Erro!',
                        text: "O registro não foi alterado!",
                        icon: 'warning',
                    })
                }
            })
        } else {
            api.post('/cliente/engenheiro/edit', auxValues).then(r => {
                if (r.status === 200) {
                    swal.fire({
                        title: 'Registro Alteraddo',
                        text: "O registro foi alterado com sucesso!",
                        icon: 'success',
                    }).then((result) => {
                        if (result.value) {
                            setPropsTabRelacionadosEngenheiro({... propsTabRelacionadosEngenheiro, selected: null })
                            setCurrentDataRelacionadosEngenheiro({})
                            setEditRelacionadosEngenheiro(false)
                            setNewRelacionadosEngenheiro(false)
                            props.reloadRelacionadosEngenheiro()
                        }
                    })
                } else {
                    swal.fire({
                        title: 'Erro!',
                        text: "O registro não foi alterado!",
                        icon: 'warning',
                    })
                }
            })
        }
    }
    
    const deleteRelacionadosEngenheiro = () => {
        const auxValues = { ...currentDataRelacionadosEngenheiro };        
        swal.fire({
            title: 'ATENÇÃO',
            text: 'Deseja excluir o registro?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                api.post('/cliente/engenheiro/delete/'+auxValues.pk_cen).then(r => {
                    if (r.status === 200) {
                        swal.fire({
                            title: 'Registro Excluído',
                            text: "O registro foi excluído com sucesso!",
                            icon: 'success',
                        }).then((result) => {
                            if (result.value) {
                                setPropsTabRelacionadosEngenheiro({... propsTabRelacionadosEngenheiro, selected: null })
                                setCurrentDataRelacionadosEngenheiro({})
                                setEditRelacionadosEngenheiro(false)
                                setNewRelacionadosEngenheiro(false)
                                props.reloadRelacionadosEngenheiro()
                            }
                        })
                    } else {
                        swal.fire({
                            title: 'Erro!',
                            text: "O registro não foi alterado!",
                            icon: 'warning',
                        })
                    }
                })
            }
        })
    }

    //RelacionadosTecnico
    const [currentDataRelacionadosTecnico, setCurrentDataRelacionadosTecnico] = React.useState(null)
     const [propsTabRelacionadosTecnico, setPropsTabRelacionadosTecnico] = React.useState({
         selected: null,
     })
     const [editRelacionadosTecnico, setEditRelacionadosTecnico] = React.useState(false)
     const [newRelacionadosTecnico, setNewRelacionadosTecnico] = React.useState(false)
     
    const filtersRelacionadosTecnico = [
        { type: 'text', field: 'descricao', name: 'Tecnico' },
        { type: 'period', field: 'data_admissao', name: 'Data Admissão' },
        { type: 'period', field: 'data_desligamento', name: 'Data Desligamento'},
        { type: 'select', field: 'fk_sit', name: 'Situacao', data: props.comboSituacaoTecnico}
    ]
 
     const selectRelacionadosTecnico = (event, codigo) => {
        setEditRelacionadosTecnico(false)
        setNewRelacionadosTecnico(false)
        setPropsTabRelacionadosTecnico({... propsTabRelacionadosTecnico, selected: codigo })
    }
    
    React.useEffect(() => {
        setCurrentDataRelacionadosTecnico(props.dataRelacionadosTecnico.find((item) => item.codigo === propsTabRelacionadosTecnico.selected))
    }, [propsTabRelacionadosTecnico.selected])
    
    React.useEffect(() => {
        
    }, [props.dataRelacionadosTecnico])
    
    const handleChangeRelacionadosTecnico = (e) => {
        const auxValues = { ...currentDataRelacionadosTecnico };
        switch (e.target.name) {
            case 'CNPJ':
            default: auxValues[e.target.name] = e.target.value;
        }
        setCurrentDataRelacionadosTecnico(auxValues);
    }
    
    const saveRelacionadosTecnico = () => {
        const auxValues = { ...currentDataRelacionadosTecnico };
        auxValues.fk_pes_cli = props.pk_pes_cli
        auxValues.data_admissao = auxValues.data_admissao ? auxValues.data_admissao.split('T')[0] : null
        auxValues.data_desligamento = auxValues.data_desligamento ? auxValues.data_desligamento.split('T')[0] : null
        console.log(auxValues)
        if (newRelacionadosTecnico) {
            api.post('/cliente/tecnico_agricola/add', auxValues).then(r => {
                if (r.status === 200) {
                    swal.fire({
                        title: 'Registro Adicionado',
                        text: "O registro foi incluído com sucesso!",
                        icon: 'success',
                    }).then((result) => {
                        if (result.value) {
                            setPropsTabRelacionadosTecnico({... propsTabRelacionadosTecnico, selected: null })
                            setCurrentDataRelacionadosTecnico({})
                            setEditRelacionadosTecnico(false)
                            setNewRelacionadosTecnico(false)
                            props.reloadRelacionadosTecnico()
                        }
                    })
                } else {
                    swal.fire({
                        title: 'Erro!',
                        text: "O registro não foi alterado!",
                        icon: 'warning',
                    })
                }
            })
        } else {
            api.post('/cliente/tecnico_agricola/edit', auxValues).then(r => {
                if (r.status === 200) {
                    swal.fire({
                        title: 'Registro Alteraddo',
                        text: "O registro foi alterado com sucesso!",
                        icon: 'success',
                    }).then((result) => {
                        if (result.value) {
                            setPropsTabRelacionadosTecnico({... propsTabRelacionadosTecnico, selected: null })
                            setCurrentDataRelacionadosTecnico({})
                            setEditRelacionadosTecnico(false)
                            setNewRelacionadosTecnico(false)
                            props.reloadRelacionadosTecnico()
                        }
                    })
                } else {
                    swal.fire({
                        title: 'Erro!',
                        text: "O registro não foi alterado!",
                        icon: 'warning',
                    })
                }
            })
        }
    }
    
    const deleteRelacionadosTecnico = () => {
        const auxValues = { ...currentDataRelacionadosTecnico };
        console.log(auxValues);
        swal.fire({
            title: 'ATENÇÃO',
            text: 'Deseja excluir o registro?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                api.post('/cliente/tecnico_agricola/delete/'+auxValues.pk_cta).then(r => {
                    if (r.status === 200) {
                        swal.fire({
                            title: 'Registro Excluído',
                            text: "O registro foi excluído com sucesso!",
                            icon: 'success',
                        }).then((result) => {
                            if (result.value) {
                                setPropsTabRelacionadosTecnico({... propsTabRelacionadosTecnico, selected: null })
                                setCurrentDataRelacionadosTecnico({})
                                setEditRelacionadosTecnico(false)
                                setNewRelacionadosTecnico(false)
                                props.reloadRelacionadosTecnico()
                            }
                        })
                    } else {
                        swal.fire({
                            title: 'Erro!',
                            text: "O registro não foi alterado!",
                            icon: 'warning',
                        })
                    }
                })
            }
        })
    }

    //Documentos
    const [currentDataDocumentos, setCurrentDataDocumentos] = React.useState(null)
    const [propsTabDocumentos, setPropsTabDocumentos] = React.useState({
        selected: null,
    })
    const [editDocumentos, setEditDocumentos] = React.useState(false)
    const [newDocumento, setNewDocumento] = React.useState(false)
    const [blockTipoDocumento, setBlockTipoDocumento] = React.useState(true)
    const [selectedTdo, setSelectedTdo] = React.useState({})

    const selectDocumento = (event, codigo) => {
        setEditDocumentos(false)
        setNewDocumento(false)
        setPropsTabDocumentos({... propsTabDocumentos, selected: codigo })
    }

    React.useEffect(() => {
        setCurrentDataDocumentos(props.dataDocumentos.find((item) => item.codigo === propsTabDocumentos.selected))
    }, [propsTabDocumentos.selected])

    React.useEffect(() => {
        
    }, [props.dataDocumentos])

    const handleChangeDocumentos = async (e) => {
        const auxValues = { ...currentDataDocumentos };
        
       
        switch (e.target.name) {     
            case 'arquivo':     
            let ext = e.target.files[0].type
            console.log(ext)
            if( e.target.files[0].size > 2097152){
                swal.fire({
                    title: 'Erro!',
                    text: "Limite de upload - 2 Mb!",
                    icon: 'warning',
                });
                return false
                
            }else if(!(ext == 'application/pdf' || ext == 'image/jpeg')){
                    swal.fire({
                        title: 'Arquivo inválido!',
                        text: "Extensões permitidas: pdf, jpg ou jpeg",
                        icon: 'warning',
                    });
                    return false
                }
                
                else {
                    uploadArquivo(e.target.value, e);
                    auxValues[e.target.name] = e.target.value ;
                }
                break;
            default: auxValues[e.target.name] = e.target.value;
        }
        console.log(auxValues['arquivo'])
        console.log(e.target.value)
        
        setCurrentDataDocumentos(auxValues);
    }

    const uploadArquivo = (arquivo, e) => {
        console.log(arquivo)

        if( e.target.files[0].size > 2097152){
            swal.fire({
                title: 'Erro!',
                text: "O arquivo não ter mais de 2MB!",
                icon: 'warning',
            });
            return false
            
         };
        swal.fire({html: <FrLoading text="Atualizando Arquivo..."/>,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false})
        let file = e.target.files[0];
        let formData = new FormData();
        console.log(file)
        formData.append('file', file, file.name)
        api.post('cliente/postArquivo/', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        }).then(r=>{
            console.log(r.data)
            if(r.data == 'File Changed!'){
                swal.fire({
                    title: 'Arquivo Importado',
                    text: "Arquivo alterado com sucesso!",
                    icon: 'success',
                })
                return true
            } else {
                swal.fire({
                    title: 'Erro!',
                    text: "O arquivo não foi atualizado!",
                    icon: 'warning',
                })
                return false
            }
        })
    }

    const handleArquivo = (e) => {
        if(editDocumentos){
            document.getElementById('arquivo').click()
        }
       
    }

    const handleDownload = (e,  arquivo) =>{
        e.preventDefault()
        api.post('cliente/downloadArquivo?&arquivo='+currentDataDocumentos?.arquivo, {}, {responseType: 'blob'}).then((response) => {
          if(response.data.type == 'application/json'){
            swal.fire({
                title: 'Arquivo Não Encontrado',
                text: "Arquivo não foi encontrado no servidor!",
                icon: 'error',
            })
          }  else {
            console.log(response, arquivo)
            let name = response.data.type == 'application/pdf' ? 'download.pdf' : 'download.jpg' 
            let type = ( response.data.type == 'application/pdf' ?'applicaton/pdf': 'application/jpeg')
            console.log(type)
              var blob = new Blob([response.data], {
                type: type
              });
              download(blob, name)
          }
        })
      }

    React.useEffect(() => {
        const fetchModelos = async () => {
            if (currentDataDocumentos?.fk_mdl > 0) {
                swal.fire({
                    html: <FrLoading text="Buscando Tipos de Documento..."/>,
                    showConfirmButton: false,
                    allowOutsideClick: false,
                    allowEscapeKey: false
                })
                let comboTdo = await api.getTipoDocumento(currentDataDocumentos?.fk_mdl)
                props.setComboTipoDocumento(comboTdo)
                if (Number(currentDataDocumentos?.fk_tdo) > 0) {
                    let auxTdo = comboTdo.find((item) => Number(item.value) === Number(currentDataDocumentos?.fk_tdo))
                    setSelectedTdo(auxTdo)
                    console.log(currentDataDocumentos?.fk_tdo, auxTdo)
                }
                swal.close()
                setBlockTipoDocumento(false)
            } else {
                props.setComboTipoDocumento([{ value: '', display: '' }])
                setBlockTipoDocumento(true)
            }
        }
    
        fetchModelos()
        
    }, [currentDataDocumentos?.fk_mdl])

    React.useEffect(() => {
        let auxTdo = props.comboTipoDocumento.find((item) => Number(item.value) === Number(currentDataDocumentos?.fk_tdo))
        setSelectedTdo(auxTdo)
        console.log(currentDataDocumentos?.fk_tdo, auxTdo)
    }, [currentDataDocumentos?.fk_tdo])

    const saveDocumentos = () => {
        const auxValues = { ...currentDataDocumentos };
        auxValues.fk_pes_cli = props.pk_pes_cli
        auxValues.dt_emissao = auxValues.dt_emissao ? auxValues.dt_emissao.split('T')[0] : null
        auxValues.dt_validade = auxValues.dt_validade ? auxValues.dt_validade.split('T')[0] : null
        console.log(auxValues)
        if (newDocumento) {
            api.post('/cliente/documentos/add', auxValues).then(r => {
                if (r.status === 200) {
                    swal.fire({
                        title: 'Registro Adicionado',
                        text: "O registro foi incluído com sucesso!",
                        icon: 'success',
                    }).then((result) => {
                        if (result.value) {
                            setPropsTabDocumentos({... propsTabDocumentos, selected: null })
                            setCurrentDataDocumentos({})
                            setEditDocumentos(false)
                            setNewDocumento(false)
                            props.reloadDocumentos()
                        }
                    })
                } else {
                    swal.fire({
                        title: 'Erro!',
                        text: "O registro não foi alterado!",
                        icon: 'warning',
                    })
                }
            })
        } else {
            api.post('/cliente/documentos/edit', auxValues).then(r => {
                if (r.status === 200) {
                    swal.fire({
                        title: 'Registro Alteraddo',
                        text: "O registro foi alterado com sucesso!",
                        icon: 'success',
                    }).then((result) => {
                        if (result.value) {
                            setPropsTabDocumentos({... propsTabDocumentos, selected: null })
                            setCurrentDataDocumentos({})
                            setEditDocumentos(false)
                            setNewDocumento(false)
                            props.reloadDocumentos()
                        }
                    })
                } else {
                    swal.fire({
                        title: 'Erro!',
                        text: "O registro não foi alterado!",
                        icon: 'warning',
                    })
                }
            })
        }
    }

    const deleteDocumentos = () => {
        const auxValues = { ...currentDataDocumentos };
        swal.fire({
            title: 'ATENÇÃO',
            text: 'Deseja excluir o registro?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                api.post('/cliente/documentos/delete/'+auxValues.pk_cdo).then(r => {
                    if (r.status === 200) {
                        swal.fire({
                            title: 'Registro Excluído',
                            text: "O registro foi excluído com sucesso!",
                            icon: 'success',
                        }).then((result) => {
                            if (result.value) {
                                setPropsTabDocumentos({... propsTabDocumentos, selected: null })
                                setCurrentDataDocumentos({})
                                setEditDocumentos(false)
                                setNewDocumento(false)
                                props.reloadDocumentos()
                            }
                        })
                    } else {
                        swal.fire({
                            title: 'Erro!',
                            text: "O registro não foi alterado!",
                            icon: 'warning',
                        })
                    }
                })
            }
        })
    }

    //Licenças
    const [currentDataDocumentosLicenca, setCurrentDataDocumentosLicenca] = React.useState(null)
    const [propsTabDocumentosLicenca, setPropsTabDocumentosLicenca] = React.useState({
        selected: null,
    })
    const [editDocumentosLicenca, setEditDocumentosLicenca] = React.useState(false)
    const [newDocumentoLicenca, setNewDocumentoLicenca] = React.useState(false)

    const selectDocumentoLicenca = (event, codigo) => {
        setEditDocumentosLicenca(false)
        setNewDocumentoLicenca(false)
        setPropsTabDocumentosLicenca({... propsTabDocumentosLicenca, selected: codigo })
    }

    React.useEffect(() => {
        setCurrentDataDocumentosLicenca(props.dataDocumentosLicenca.find((item) => item.codigo === propsTabDocumentosLicenca.selected))
    }, [propsTabDocumentosLicenca.selected])

    React.useEffect(() => {
        
    }, [props.dataDocumentosLicenca])

    const handleChangeDocumentosLicenca = (e) => {
        const auxValues = { ...currentDataDocumentosLicenca };
        switch (e.target.name) {
            case 'CNPJ':
            default: auxValues[e.target.name] = e.target.value;
        }
        setCurrentDataDocumentosLicenca(auxValues);
    }

    const saveDocumentosLicenca = () => {
        const auxValues = { ...currentDataDocumentosLicenca };
        auxValues.modelo_doc = 'L'
        auxValues.fk_pes_cli = props.pk_pes_cli
        auxValues.dt_emissao = auxValues.dt_emissao ? auxValues.dt_emissao.split('T')[0] : null
        auxValues.dt_validade = auxValues.dt_validade ? auxValues.dt_validade.split('T')[0] : null
        console.log(auxValues)
        if (newDocumentoLicenca) {
            api.post('/cliente/documentos/add', auxValues).then(r => {
                if (r.status === 200) {
                    swal.fire({
                        title: 'Registro Adicionado',
                        text: "O registro foi incluído com sucesso!",
                        icon: 'success',
                    }).then((result) => {
                        if (result.value) {
                            setPropsTabDocumentosLicenca({... propsTabDocumentosLicenca, selected: null })
                            setCurrentDataDocumentosLicenca({})
                            setEditDocumentosLicenca(false)
                            setNewDocumentoLicenca(false)
                            props.reloadDocumentosLicenca()
                        }
                    })
                } else {
                    swal.fire({
                        title: 'Erro!',
                        text: "O registro não foi alterado!",
                        icon: 'warning',
                    })
                }
            })
        } else {
            api.post('/cliente/documentos/edit', auxValues).then(r => {
                if (r.status === 200) {
                    swal.fire({
                        title: 'Registro Alteraddo',
                        text: "O registro foi alterado com sucesso!",
                        icon: 'success',
                    }).then((result) => {
                        if (result.value) {
                            setPropsTabDocumentosLicenca({... propsTabDocumentosLicenca, selected: null })
                            setCurrentDataDocumentosLicenca({})
                            setEditDocumentosLicenca(false)
                            setNewDocumentoLicenca(false)
                            props.reloadDocumentosLicenca()
                        }
                    })
                } else {
                    swal.fire({
                        title: 'Erro!',
                        text: "O registro não foi alterado!",
                        icon: 'warning',
                    })
                }
            })
        }
    }

    const deleteDocumentosLicenca = () => {
        const auxValues = { ...currentDataDocumentosLicenca };
        swal.fire({
            title: 'ATENÇÃO',
            text: 'Deseja excluir o registro?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                api.post('/cliente/documentos/delete/'+auxValues.pk_cdo).then(r => {
                    if (r.status === 200) {
                        swal.fire({
                            title: 'Registro Excluído',
                            text: "O registro foi excluído com sucesso!",
                            icon: 'success',
                        }).then((result) => {
                            if (result.value) {
                                setPropsTabDocumentosLicenca({... propsTabDocumentosLicenca, selected: null })
                                setCurrentDataDocumentosLicenca({})
                                setEditDocumentosLicenca(false)
                                setNewDocumentoLicenca(false)
                                props.reloadDocumentosLicenca()
                            }
                        })
                    } else {
                        swal.fire({
                            title: 'Erro!',
                            text: "O registro não foi alterado!",
                            icon: 'warning',
                        })
                    }
                })
            }
        })
    }
 
    //Edit Tabs
    const handleEdit = (e, tab) => {
        e.preventDefault()
        switch (tab) {
            case 'Socios':
                setEditSocios(!editSocios)
            break

            case 'Contatos':
                setEditContatos(!editContatos)
            break

            case 'Documentos':
                    setEditDocumentos(!editDocumentos)
            break

            case 'Licenca':
                setEditDocumentosLicenca(!editDocumentosLicenca)
            break

            case 'TanqueCombustivel':
                setEditTanqueCombustivel(!editTanqueCombustivel)
            break

            case 'RelacionadosPiloto':
                setEditRelacionadosPiloto(!editRelacionadosPiloto)
            break

            case 'RelacionadosMecanico':
                setEditRelacionadosMecanico(!editRelacionadosMecanico)
            break

            case 'RelacionadosAeronave':
                setEditRelacionadosAeronave(!editRelacionadosAeronave)
            break

            case 'RelacionadosApua':
                setEditRelacionadosApua(!editRelacionadosApua)
            break

            case 'RelacionadosEngenheiro':
                setEditRelacionadosEngenheiro(!editRelacionadosEngenheiro)
            break

            case 'RelacionadosTecnico':
                setEditRelacionadosTecnico(!editRelacionadosTecnico)
            break
        }
    }

    //Add Tabs
    const handleAdd = (tab) => {
        switch (tab) {
            case 'Socios':
                setEditSocios(true)
                setCurrentDataSocios({})
                setPropsTableSocios({... propsTabSocios, selected: null })
                setNewSocio(true)
            break

            case 'Contatos':
                setEditContatos(true)
                setCurrentDataContatos({})
                setPropsTableContatos({... propsTabContatos, selected: null })
                setNewContato(true)
            break

            case 'Documentos':
                setEditDocumentos(true)
                setCurrentDataDocumentos({})
                setPropsTabDocumentos({... propsTabDocumentos, selected: null })
                setNewDocumento(true)
            break    

            case 'Licenca':
                setEditDocumentosLicenca(true)
                setCurrentDataDocumentosLicenca({})
                setPropsTabDocumentosLicenca({... propsTabDocumentosLicenca, selected: null })
                setNewDocumentoLicenca(true)
            break    

            case 'TanqueCombustivel':
                setEditTanqueCombustivel(true)
                setCurrentDataTanqueCombustivel({})
                setPropsTabTanqueCombustivel({... propsTabTanqueCombustivel, selected: null })
                setNewTanqueCombustivel(true)
            break    

            case 'RelacionadosPiloto':
                setEditRelacionadosPiloto(true)
                setCurrentDataRelacionadosPiloto({})
                setPropsTabRelacionadosPiloto({... propsTabRelacionadosPiloto, selected: null })
                setNewRelacionadosPiloto(true)
            break    

            case 'RelacionadosMecanico':
                setEditRelacionadosMecanico(true)
                setCurrentDataRelacionadosMecanico({})
                setPropsTabRelacionadosMecanico({... propsTabRelacionadosMecanico, selected: null })
                setNewRelacionadosMecanico(true)
            break    

            case 'RelacionadosAeronave':
                setEditRelacionadosAeronave(true)
                setCurrentDataRelacionadosAeronave({})
                setPropsTabRelacionadosAeronave({... propsTabRelacionadosAeronave, selected: null })
                setNewRelacionadosAeronave(true)
            break    

            case 'RelacionadosApua':
                setEditRelacionadosApua(true)
                setCurrentDataRelacionadosApua({})
                setPropsTabRelacionadosApua({... propsTabRelacionadosApua, selected: null })
                setNewRelacionadosApua(true)
            break  

            case 'RelacionadosEngenheiro':
                setEditRelacionadosEngenheiro(true)
                setCurrentDataRelacionadosEngenheiro({})
                setPropsTabRelacionadosEngenheiro({... propsTabRelacionadosEngenheiro, selected: null })
                setNewRelacionadosEngenheiro(true)
            break 
            
            case 'RelacionadosTecnico':
                setEditRelacionadosTecnico(true)
                setCurrentDataRelacionadosTecnico({})
                setPropsTabRelacionadosTecnico({... propsTabRelacionadosTecnico, selected: null })
                setNewRelacionadosTecnico(true)
            break    
            
        }
    }

    //Save Tabs
    const handleSave = (e, tab) => {
        e.preventDefault()
        switch (tab) {
            case 'Socios': 
                saveSocios()
            break

            case 'Contatos':
                saveContatos()
            break

            case 'Documentos':
                saveDocumentos()
            break

            case 'Licenca':
                saveDocumentosLicenca()
            break

            case 'TanqueCombustivel':
                saveTanqueCombustivel()
            break

            case 'RelacionadosPiloto':
                saveRelacionadosPiloto()
            break

            case 'RelacionadosEngenheiro':
                saveRelacionadosEngenheiro()
            break

            case 'RelacionadosTecnico':
                saveRelacionadosTecnico()
            break

            case 'RelacionadosMecanico':
                saveRelacionadosMecanico()
            break

            case 'RelacionadosAeronave':
                saveRelacionadosAeronave()
            break

            case 'RelacionadosApua':
                saveRelacionadosApua()
            break
        }
    }

    //Delete Tabs
    const handleDelete = (e, tab) => {
        e.preventDefault()
        switch (tab) {
            case 'Socios':
                deleteSocios()
            break

            case 'Contatos':
                deleteContatos()
            break

            case 'Licenca':
                deleteDocumentosLicenca()
            break

            case 'Documentos':
                deleteDocumentos()
            break

            case 'TanqueCombustivel': 
                deleteTanqueCombustivel()
            break

            case 'RelacionadosPiloto': 
                deleteRelacionadosPiloto()
            break
            
            case 'RelacionadosMecanico': 
                deleteRelacionadosMecanico()
            break

            case 'RelacionadosAeronave': 
                deleteRelacionadosAeronave()
            break

            case 'RelacionadosApua': 
                deleteRelacionadosApua()
            break

            case 'RelacionadosEngenheiro': 
                deleteRelacionadosEngenheiro()
            break

            case 'RelacionadosTecnico': 
                deleteRelacionadosTecnico()
            break

        }
    }





    
    return (
        <div className={classes.root}>
        <AppBar position="static">
            <Tabs
                value={value}
                onChange={handleChangeTabs}
                indicatorColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
            >
                <Tab label="Sedes" {...a11yProps(0)} />
                <Tab label="Sócios" disabled={!props.updateType} {...a11yProps(1)} />
                <Tab label="Contatos" disabled={!props.updateType} {...a11yProps(2)} />
                <Tab label="Gestores" disabled={!props.updateType} {...a11yProps(3)} />
                <Tab label="Piloto Chefe" disabled={!props.updateType} {...a11yProps(4)} />
                <Tab label="Documentação" disabled={!props.updateType} {...a11yProps(5)} />
                <Tab label="Descontaminação" disabled={!props.updateType} {...a11yProps(6)} />
                <Tab label="Parque Abast." disabled={!props.updateType} {...a11yProps(7)} />
                <Tab label="Fases" disabled={!props.updateType} {...a11yProps(8)} />
                {/* <Tab label="Fases" {...a11yProps(8)} /> */}
                <Tab label="Relacionados" disabled={!props.updateType} {...a11yProps(9)} />
            </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
            <div className="grupoTitulo">
                Administrativa
            </div>
            <div className="grupoEdits">
                <FrTextInput
                    maxLength='10'
                    value={data.cep || ''}
                    error={erroCep}
                    onChange={handleChange}
                    onBlur={handleCep}
                    name="cep"
                    id="cep"
                    color="#9c9595"
                    label="CEP"
                    variant="outlined"
                    size="small"
                    style={{ width: 130 }}
                    
                />
                <FrTextInput
                    maxLength='50'
                    value={data.logradouro || ''}
                    onChange={handleChange}
                    name="logradouro"
                    id="logradouro"
                    color="#9c9595"
                    label="Endereço"
                    variant="outlined"
                    size="small"
                    
                    style={{ width: 400 }}
                />
                <FrTextInput
                    maxLength='50'
                    value={data.numero || ''}
                    onChange={handleChange}
                    name="numero"
                    id="numero"
                    color="#9c9595"
                    label="Número"
                    variant="outlined"
                    size="small"
                    
                    style={{ width: 100 }}
                />
                <FrTextInput
                    maxLength='50'
                    value={data.bairro || ''}
                    onChange={handleChange}
                    name="bairro"
                    id="bairro"
                    color="#9c9595"
                    label="Bairro"
                    variant="outlined"
                    size="small"
                    
                    style={{ width: 250 }}
                />
                <FrTextInput
                    maxLength='50'
                    value={data.complemento || ''}
                    onChange={handleChange}
                    name="complemento"
                    id="complemento"
                    color="#9c9595"
                    label="Complemento"
                    variant="outlined"
                    size="small"
                    
                    style={{ width: 330 }}
                />
                <FrSelect 
                    value={data.fk_cid || []} 
                    style={{ width: 300 }} 
                    name="fk_cid" 
                    id="fk_cid" 
                    label='Cidade' 
                    data={props.comboCidades}
                    onChange={handleChange}
                />
                <FrTextInput
                    maxLength='50'
                    value={data.fone1 || ''}
                    onChange={handleChange}
                    name="fone1"
                    id="fone1"
                    color="#9c9595"
                    label="Telefone"
                    variant="outlined"
                    size="small"
                    
                    style={{ width: 160 }}
                />
                <FrTextInput
                    maxLength='50'
                    value={data.fone2 || ''}
                    onChange={handleChange}
                    name="fone2"
                    id="fone2"
                    color="#9c9595"
                    label="Telefone"
                    variant="outlined"
                    size="small"
                    
                    style={{ width: 160 }}
                />
                <FrTextInput
                    maxLength='50'
                    value={data.celular || ''}
                    onChange={handleChange}
                    name="celular"
                    id="celular"
                    color="#9c9595"
                    label="Celular"
                    variant="outlined"
                    size="small"
                    
                    style={{ width: 160 }}
                />
                <FrTextInput
                    maxLength='50'
                    value={data.email || ''}
                    onChange={handleChange}
                    name="email"
                    id="email"
                    color="#9c9595"
                    label="Email"
                    variant="outlined"
                    size="small"
                    
                    style={{ width: 350 }}
                />
                <br/>
                <FrTextInput
                    maxLength='1000'
                    value={data.observacao || ''}
                    onChange={handleChange}
                    name="observacao"
                    id="observacao"
                    color="#9c9595"
                    label="Observações"
                    variant="outlined"
                    size="small"
                    style={{ width: '100%' }}
                    rows={5}
                    maxRows={5}
                    multiline
                />
            </div>
            <div className="grupoTitulo" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>Operacional</div> 
                <button className="buttonReplica" onClick={replicaEndereco}>Replicar Endereço Administrativa</button>
            </div>
            <div className="grupoEdits">
                <FrTextInput
                    maxLength='10'
                    value={data.cep_operacional || ''}
                    error={erroCepOperacional}
                    onChange={handleChange}
                    onBlur={handleCepOperacional}
                    name="cep_operacional"
                    id="cep_operacional"
                    color="#9c9595"
                    label="CEP"
                    variant="outlined"
                    size="small"
                    style={{ width: 130 }}
                    
                />
                <FrTextInput
                    maxLength='50'
                    value={data.logradouro_operacional || ''}
                    onChange={handleChange}
                    name="logradouro_operacional"
                    id="logradouro_operacional"
                    color="#9c9595"
                    label="Endereço"
                    variant="outlined"
                    size="small"
                    
                    style={{ width: 400 }}
                />
                <FrTextInput
                    maxLength='50'
                    value={data.nr_operacional || ''}
                    onChange={handleChange}
                    name="nr_operacional"
                    id="nr_operacional"
                    color="#9c9595"
                    label="Número"
                    variant="outlined"
                    size="small"
                    
                    style={{ width: 100 }}
                />
                <FrTextInput
                    maxLength='50'
                    value={data.bairro_operacional || ''}
                    onChange={handleChange}
                    name="bairro_operacional"
                    id="bairro_operacional"
                    color="#9c9595"
                    label="Bairro"
                    variant="outlined"
                    size="small"
                    
                    style={{ width: 250 }}
                />
                <FrTextInput
                    maxLength='50'
                    value={data.compl_operacional || ''}
                    onChange={handleChange}
                    name="compl_operacional"
                    id="compl_operacional"
                    color="#9c9595"
                    label="Complemento"
                    variant="outlined"
                    size="small"
                    
                    style={{ width: 330 }}
                />
                <FrSelect 
                    value={data.fk_cid_operacional || []} 
                    style={{ width: 300 }} 
                    name="fk_cid_operacional" 
                    id="fk_cid_operacional" 
                    label='Cidade' 
                    data={props.comboCidades}
                    onChange={handleChange}
                />
                <FrTextInput
                    maxLength='50'
                    value={data.fone1_operacional || ''}
                    onChange={handleChange}
                    name="fone1_operacional"
                    id="fone1_operacional"
                    color="#9c9595"
                    label="Telefone"
                    variant="outlined"
                    size="small"
                    
                    style={{ width: 160 }}
                />
                <FrTextInput
                    maxLength='50'
                    value={data.fone2_operacional || ''}
                    onChange={handleChange}
                    name="fone2_operacional"
                    id="fone2_operacional"
                    color="#9c9595"
                    label="Telefone"
                    variant="outlined"
                    size="small"
                    
                    style={{ width: 160 }}
                />
                <FrTextInput
                    maxLength='50'
                    value={data.celular_operacional || ''}
                    onChange={handleChange}
                    name="celular_operacional"
                    id="celular_operacional"
                    color="#9c9595"
                    label="Celular"
                    variant="outlined"
                    size="small"
                    
                    style={{ width: 160 }}
                />
                <FrTextInput
                    maxLength='50'
                    value={data.email_operacional || ''}
                    onChange={handleChange}
                    name="email_operacional"
                    id="email_operacional"
                    color="#9c9595"
                    label="Email"
                    variant="outlined"
                    size="small"
                    
                    style={{ width: 350 }}
                />
                <br/>
                <FrTextInput
                    maxLength='1000'
                    value={data.observacao_operacional || ''}
                    onChange={handleChange}
                    name="observacao_operacional"
                    id="observacao_operacional"
                    color="#9c9595"
                    label="Observações"
                    variant="outlined"
                    size="small"
                    style={{ width: '100%' }}
                    rows={5}
                    maxRows={5}
                    multiline
                />
                <br/>
                <FrSelect 
                    value={data.end_operacional_aero || []} 
                    style={{ width: 200 }} 
                    name="end_operacional_aero" 
                    id="end_operacional_aero" 
                    label='Aeroporto/Aeródromo' 
                    data={[{value: 'S', display: 'Sim'}, {value: 'N', display: 'Não'}]}
                    onChange={handleChange}
                />
                
            </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
            <div className="manutReg">
                <ListRegisters hideValidos width={300} tab="Socios" handleClickItem={selectSocio} selected={propsTabSocios.selected} items={props.dataSocios} textField={'razao_social'} handleAdd={handleAdd} />
                <div className="register">
                    <div className="buttons">
                        <Tooltip title="Salvar">
                            <button onClick={(e) => {handleSave(e, 'Socios')}} disabled={!editSocios}>
                                <Icon icon={ic_save} size={20}/>
                            </button>
                        </Tooltip>
                        <Tooltip title={editSocios ? 'Desfazer' : 'Editar'}>
                            <button onClick={(e) => {handleEdit(e, 'Socios')}} disabled={newSocio}>
                                <Icon icon={editSocios ? ic_undo : ic_mode_edit} size={20}/>
                            </button>
                        </Tooltip>
                        <Tooltip title="Excluir">
                            <button onClick={(e) => {handleDelete(e, 'Socios')}} disabled={!(Number(propsTabSocios.selected) > 0) || (newSocio)}>
                                <Icon icon={ic_delete} size={20}/>
                            </button>
                        </Tooltip>
                    </div>
                    <div className="fields">
                        <FrTextInput 
                            // error={erroCnpj} 
                            maxLength='18'
                            value={currentDataSocios?.cpf || ''} 
                            onChange={handleChangeSocios} 
                            onBlur={handleCpfSocio}  
                            name="cpf" 
                            id="cpf" 
                            color="#49573B" 
                            label="CPF" 
                            variant="outlined" 
                            size="small"
                            disabled={!editSocios}
                            
                            style={{width: 180}}
                        />
                        <br/>
                        <FrTextInput
                            maxLength='150'
                            value={currentDataSocios?.razao_social || ''} 
                            onChange={handleChangeSocios}  
                            name="razao_social" 
                            id="razao_social" 
                            color="#49573B" 
                            label="Nome" 
                            variant="outlined" 
                            size="small"
                            disabled={!editSocios}
                            
                            style={{width: 500}}
                        />
                        <br/>
                        <FrTextInput 
                            maxLength='20' 
                            value={currentDataSocios?.rg || ''} 
                            onChange={handleChangeSocios} 
                            name="rg" 
                            id="rg" 
                            color="#49573B" 
                            label="RG" 
                            variant="outlined" 
                            size="small"
                            
                            disabled={!editSocios}
                            style={{width: 200}}
                        />
                        <FrSelect 
                            value={currentDataSocios?.fk_sit || []} 
                            style={{ width: 300 }} 
                            name="fk_sit" 
                            id="fk_sit" 
                            label='Situação' 
                            data={props.comboSituacao}
                            onChange={handleChangeSocios}
                            disabled={!editSocios}
                        />
                        <br/>
                        <FrTextInput
                            maxLength='10'
                            value={currentDataSocios?.cep || ''}
                            error={erroCepSocios}
                            onChange={handleChangeSocios}
                            onBlur={handleCepSocios}
                            name="cep"
                            id="cep"
                            color="#9c9595"
                            label="CEP"
                            variant="outlined"
                            size="small"
                            style={{ width: 130 }}
                            
                            disabled={!editSocios}
                        />
                        <FrTextInput
                            maxLength='50'
                            value={currentDataSocios?.logradouro || ''}
                            onChange={handleChangeSocios}
                            name="logradouro"
                            id="logradouro"
                            color="#9c9595"
                            label="Endereço"
                            variant="outlined"
                            size="small"
                            
                            style={{ width: 400 }}
                            disabled={!editSocios}
                        />
                        <FrTextInput
                            maxLength='50'
                            value={currentDataSocios?.numero || ''}
                            onChange={handleChangeSocios}
                            name="numero"
                            id="numero"
                            color="#9c9595"
                            label="Número"
                            variant="outlined"
                            size="small"
                            
                            style={{ width: 100 }}
                            disabled={!editSocios}
                        />
                        <FrTextInput
                            maxLength='50'
                            value={currentDataSocios?.bairro || ''}
                            onChange={handleChangeSocios}
                            name="bairro"
                            id="bairro"
                            color="#9c9595"
                            label="Bairro"
                            variant="outlined"
                            size="small"
                            
                            style={{ width: 250 }}
                            disabled={!editSocios}
                        />
                        <FrTextInput
                            maxLength='50'
                            value={currentDataSocios?.complemento || ''}
                            onChange={handleChangeSocios}
                            name="complemento"
                            id="complemento"
                            color="#9c9595"
                            label="Complemento"
                            variant="outlined"
                            size="small"
                            
                            style={{ width: 330 }}
                            disabled={!editSocios}
                        />
                        <br/>
                        <FrSelect 
                            value={currentDataSocios?.fk_cid || []} 
                            style={{ width: 300 }} 
                            name="fk_cid" 
                            id="fk_cid" 
                            label='Cidade' 
                            data={props.comboCidades}
                            onChange={handleChangeSocios}
                            disabled={!editSocios}
                        />
                        <br/>
                        <FrTextInput
                            maxLength='50'
                            value={currentDataSocios?.fone1 || ''}
                            onChange={handleChangeSocios}
                            name="fone1"
                            id="fone1"
                            color="#9c9595"
                            label="Telefone"
                            variant="outlined"
                            size="small"
                            
                            style={{ width: 160 }}
                            disabled={!editSocios}
                        />
                        <FrTextInput
                            maxLength='50'
                            value={currentDataSocios?.fone2 || ''}
                            onChange={handleChangeSocios}
                            name="fone2"
                            id="fone2"
                            color="#9c9595"
                            label="Telefone"
                            variant="outlined"
                            size="small"
                            
                            style={{ width: 160 }}
                            disabled={!editSocios}
                        />
                        <FrTextInput
                            maxLength='50'
                            value={currentDataSocios?.celular || ''}
                            onChange={handleChangeSocios}
                            name="celular"
                            id="celular"
                            color="#9c9595"
                            label="Celular"
                            variant="outlined"
                            size="small"
                            
                            style={{ width: 160 }}
                            disabled={!editSocios}
                        />
                        <FrTextInput
                            maxLength='50'
                            value={currentDataSocios?.email || ''}
                            onChange={handleChangeSocios}
                            name="email"
                            id="email"
                            color="#9c9595"
                            label="Email"
                            variant="outlined"
                            size="small"
                            
                            style={{ width: 320 }}
                            disabled={!editSocios}
                        />
                        <br/>
                        <FrTextInput
                            maxLength='1000'
                            value={currentDataSocios?.observacoes || ''}
                            onChange={handleChangeSocios}
                            name="observacoes"
                            id="observacoes"
                            color="#9c9595"
                            label="Observações"
                            variant="outlined"
                            size="small"
                            
                            style={{ width: '100%' }}
                            disabled={!editSocios}
                            rows={5}
                            maxRows={5}
                            multiline
                        />
                    </div>
                </div>
            </div>
        </TabPanel>
        <TabPanel value={value} index={2}>
            <div className="manutReg">
                <ListRegisters hideValidos width={300} tab="Contatos" handleClickItem={selectContato} selected={propsTabContatos.selected} items={props.dataContatos} textField={'nome'} handleAdd={handleAdd} />
                <div className="register">
                    <div className="buttons">
                        <Tooltip title="Salvar">
                            <button onClick={(e) => {handleSave(e, 'Contatos')}} disabled={!editContatos}>
                                <Icon icon={ic_save} size={20}/>
                            </button>
                        </Tooltip>
                        <Tooltip title={editContatos ? 'Desfazer' : 'Editar'}>
                            <button onClick={(e) => {handleEdit(e, 'Contatos')}} disabled={newContato}>
                                <Icon icon={editContatos ? ic_undo : ic_mode_edit} size={20}/>
                            </button>
                        </Tooltip>
                        <Tooltip title="Excluir">
                            <button onClick={(e) => {handleDelete(e, 'Contatos')}} disabled={!(Number(propsTabContatos.selected) > 0) || (newContato)}>
                                <Icon icon={ic_delete} size={20}/>
                            </button>
                        </Tooltip>
                    </div>
                    <div className="fields">
                        <FrTextInput
                            maxLength='150'
                            value={currentDataContatos?.nome || ''} 
                            onChange={handleChangeContatos}  
                            name="nome" 
                            id="nome" 
                            color="#49573B" 
                            label="Nome" 
                            variant="outlined" 
                            size="small"
                            disabled={!editContatos}
                            
                            style={{width: 500}}
                        />
                        <br/>
                        <FrTextInput
                            maxLength='50'
                            value={currentDataContatos?.fone1 || ''}
                            onChange={handleChangeContatos}
                            name="fone1"
                            id="fone1"
                            color="#9c9595"
                            label="Telefone"
                            variant="outlined"
                            size="small"
                            
                            style={{ width: 160 }}
                            disabled={!editContatos}
                        />
                        <FrTextInput
                            maxLength='50'
                            value={currentDataContatos?.fone2 || ''}
                            onChange={handleChangeContatos}
                            name="fone2"
                            id="fone2"
                            color="#9c9595"
                            label="Telefone"
                            variant="outlined"
                            size="small"
                            
                            style={{ width: 160 }}
                            disabled={!editContatos}
                        />
                        <FrTextInput
                            maxLength='50'
                            value={currentDataContatos?.celular || ''}
                            onChange={handleChangeContatos}
                            name="celular"
                            id="celular"
                            color="#9c9595"
                            label="Celular"
                            variant="outlined"
                            size="small"
                            
                            style={{ width: 160 }}
                            disabled={!editContatos}
                        />
                        <FrTextInput
                            maxLength='50'
                            value={currentDataContatos?.email || ''}
                            onChange={handleChangeContatos}
                            name="email"
                            id="email"
                            color="#9c9595"
                            label="Email"
                            variant="outlined"
                            size="small"
                            
                            style={{ width: 320 }}
                            disabled={!editContatos}
                        />
                        <br/>
                        <FrTextInput
                            maxLength='1000'
                            value={currentDataContatos?.observacao || ''}
                            onChange={handleChangeContatos}
                            name="observacao"
                            id="observacao"
                            color="#9c9595"
                            label="Observações"
                            variant="outlined"
                            size="small"
                            
                            style={{ width: '100%' }}
                            disabled={!editContatos}
                            rows={5}
                            maxRows={5}
                            multiline
                        />
                    </div>
                </div>
            </div>
        </TabPanel>
        <TabPanel value={value} index={3}>
            <div className="grupoTitulo">
                Gestor Responsável
            </div>
            <div className="grupoEdits">
                <FrTextInput 
                    error={props.erroCpfGestorResponsavel} 
                    maxLength='18'
                    value={props.dataGestorResponsavel?.cpf || ''} 
                    onChange={handleChangeGestorResponsavel} 
                    onBlur={props.handleCpfGestorResponsavel}  
                    name="cpf" 
                    id="cpf" 
                    color="#49573B" 
                    label="CPF" 
                    variant="outlined" 
                    size="small"
                    style={{width: 180}}
                />
                <FrTextInput
                    maxLength='150'
                    value={props.dataGestorResponsavel?.razao_social || ''} 
                    onChange={handleChangeGestorResponsavel}  
                    name="razao_social" 
                    id="razao_social" 
                    color="#49573B" 
                    label="Nome" 
                    variant="outlined" 
                    size="small"
                    style={{width: 500}}
                />
                <FrTextInput 
                    maxLength='20' 
                    value={props.dataGestorResponsavel?.rg || ''} 
                    onChange={handleChangeGestorResponsavel} 
                    name="rg" 
                    id="rg" 
                    color="#49573B" 
                    label="RG" 
                    variant="outlined" 
                    size="small"
                    style={{width: 200}}
                />
                <br/>
                <FrTextInput
                    maxLength='10'
                    value={props.dataGestorResponsavel?.cep || ''}
                    error={props.erroCepGestorResponsavel}
                    onChange={handleChangeGestorResponsavel}
                    onBlur={props.handleCepGestorResponsavel}
                    name="cep"
                    id="cep"
                    color="#9c9595"
                    label="CEP"
                    variant="outlined"
                    size="small"
                    style={{ width: 130 }}
                />
                <FrTextInput
                    maxLength='50'
                    value={props.dataGestorResponsavel?.logradouro || ''}
                    onChange={handleChangeGestorResponsavel}
                    name="logradouro"
                    id="logradouro"
                    color="#9c9595"
                    label="Endereço"
                    variant="outlined"
                    size="small"
                    
                    style={{ width: 400 }}
                />
                <FrTextInput
                    maxLength='50'
                    value={props.dataGestorResponsavel?.numero || ''}
                    onChange={handleChangeGestorResponsavel}
                    name="numero"
                    id="numero"
                    color="#9c9595"
                    label="Número"
                    variant="outlined"
                    size="small"
                    
                    style={{ width: 100 }}
                />
                <FrTextInput
                    maxLength='50'
                    value={props.dataGestorResponsavel?.bairro || ''}
                    onChange={handleChangeGestorResponsavel}
                    name="bairro"
                    id="bairro"
                    color="#9c9595"
                    label="Bairro"
                    variant="outlined"
                    size="small"
                    
                    style={{ width: 250 }}
                />
                <FrTextInput
                    maxLength='50'
                    value={props.dataGestorResponsavel?.complemento || ''}
                    onChange={handleChangeGestorResponsavel}
                    name="complemento"
                    id="complemento"
                    color="#9c9595"
                    label="Complemento"
                    variant="outlined"
                    size="small"
                    
                    style={{ width: 330 }}
                />
                <FrSelect 
                    value={props.dataGestorResponsavel?.fk_cid || []} 
                    style={{ width: 300 }} 
                    name="fk_cid" 
                    id="fk_cid" 
                    label='Cidade' 
                    data={props.comboCidades}
                    onChange={handleChangeGestorResponsavel}
                />
                <br/>
                <FrTextInput
                    maxLength='50'
                    value={props.dataGestorResponsavel?.fone1 || ''}
                    onChange={handleChangeGestorResponsavel}
                    name="fone1"
                    id="fone1"
                    color="#9c9595"
                    label="Telefone"
                    variant="outlined"
                    size="small"
                    
                    style={{ width: 160 }}
                />
                <FrTextInput
                    maxLength='50'
                    value={props.dataGestorResponsavel?.fone2 || ''}
                    onChange={handleChangeGestorResponsavel}
                    name="fone2"
                    id="fone2"
                    color="#9c9595"
                    label="Telefone"
                    variant="outlined"
                    size="small"
                    
                    style={{ width: 160 }}
                />
                <FrTextInput
                    maxLength='50'
                    value={props.dataGestorResponsavel?.celular || ''}
                    onChange={handleChangeGestorResponsavel}
                    name="celular"
                    id="celular"
                    color="#9c9595"
                    label="Celular"
                    variant="outlined"
                    size="small"
                    
                    style={{ width: 160 }}
                />
                <FrTextInput
                    maxLength='50'
                    value={props.dataGestorResponsavel?.email || ''}
                    onChange={handleChangeGestorResponsavel}
                    name="email"
                    id="email"
                    color="#9c9595"
                    label="Email"
                    variant="outlined"
                    size="small"
                    
                    style={{ width: 320 }}
                />
            </div>
            <div className="grupoTitulo" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>Gestor de Segurança Operacional </div> 
                <button className="buttonReplica" onClick={replicaGestor}>Replicar Gestor Responsável</button>
            </div>
            <div className="grupoEdits">
                <FrTextInput 
                    error={props.erroCpfGestorOperacional} 
                    maxLength='18'
                    value={props.dataGestorOperacional?.cpf || ''} 
                    onChange={handleChangeGestorOperacional} 
                    onBlur={props.handleCpfGestorOperacional}  
                    name="cpf" 
                    id="cpf" 
                    color="#49573B" 
                    label="CPF" 
                    variant="outlined" 
                    size="small"
                    style={{width: 180}}
                />
                <FrTextInput
                    maxLength='150'
                    value={props.dataGestorOperacional?.razao_social || ''} 
                    onChange={handleChangeGestorOperacional}  
                    name="razao_social" 
                    id="razao_social" 
                    color="#49573B" 
                    label="Nome" 
                    variant="outlined" 
                    size="small"
                    style={{width: 500}}
                />
                <FrTextInput 
                    maxLength='20' 
                    value={props.dataGestorOperacional?.rg || ''} 
                    onChange={handleChangeGestorOperacional} 
                    name="rg" 
                    id="rg" 
                    color="#49573B" 
                    label="RG" 
                    variant="outlined" 
                    size="small"
                    style={{width: 200}}
                />
                <br/>
                <FrTextInput
                    maxLength='10'
                    value={props.dataGestorOperacional?.cep || ''}
                    error={props.erroCepGestorOperacional}
                    onChange={handleChangeGestorOperacional}
                    onBlur={props.handleCepGestorOperacional}
                    name="cep"
                    id="cep"
                    color="#9c9595"
                    label="CEP"
                    variant="outlined"
                    size="small"
                    style={{ width: 130 }}
                />
                <FrTextInput
                    maxLength='50'
                    value={props.dataGestorOperacional?.logradouro || ''}
                    onChange={handleChangeGestorOperacional}
                    name="logradouro"
                    id="logradouro"
                    color="#9c9595"
                    label="Endereço"
                    variant="outlined"
                    size="small"
                    
                    style={{ width: 400 }}
                />
                <FrTextInput
                    maxLength='50'
                    value={props.dataGestorOperacional?.numero || ''}
                    onChange={handleChangeGestorOperacional}
                    name="numero"
                    id="numero"
                    color="#9c9595"
                    label="Número"
                    variant="outlined"
                    size="small"
                    style={{ width: 100 }}
                />
                <FrTextInput
                    maxLength='50'
                    value={props.dataGestorOperacional?.bairro || ''}
                    onChange={handleChangeGestorOperacional}
                    name="bairro"
                    id="bairro"
                    color="#9c9595"
                    label="Bairro"
                    variant="outlined"
                    size="small"
                    style={{ width: 250 }}
                />
                <FrTextInput
                    maxLength='50'
                    value={props.dataGestorOperacional?.complemento || ''}
                    onChange={handleChangeGestorOperacional}
                    name="complemento"
                    id="complemento"
                    color="#9c9595"
                    label="Complemento"
                    variant="outlined"
                    size="small"
                    style={{ width: 330 }}
                />
                <FrSelect 
                    value={props.dataGestorOperacional?.fk_cid || []} 
                    style={{ width: 300 }} 
                    name="fk_cid" 
                    id="fk_cid" 
                    label='Cidade' 
                    data={props.comboCidades}
                    onChange={handleChangeGestorOperacional}
                />
                <br/>
                <FrTextInput
                    maxLength='50'
                    value={props.dataGestorOperacional?.fone1 || ''}
                    onChange={handleChangeGestorOperacional}
                    name="fone1"
                    id="fone1"
                    color="#9c9595"
                    label="Telefone"
                    variant="outlined"
                    size="small"
                    style={{ width: 160 }}
                />
                <FrTextInput
                    maxLength='50'
                    value={props.dataGestorOperacional?.fone2 || ''}
                    onChange={handleChangeGestorOperacional}
                    name="fone2"
                    id="fone2"
                    color="#9c9595"
                    label="Telefone"
                    variant="outlined"
                    size="small"
                    
                    style={{ width: 160 }}
                />
                <FrTextInput
                    maxLength='50'
                    value={props.dataGestorOperacional?.celular || ''}
                    onChange={handleChangeGestorOperacional}
                    name="celular"
                    id="celular"
                    color="#9c9595"
                    label="Celular"
                    variant="outlined"
                    size="small"
                    style={{ width: 160 }}
                />
                <FrTextInput
                    maxLength='50'
                    value={props.dataGestorOperacional?.email || ''}
                    onChange={handleChangeGestorOperacional}
                    name="email"
                    id="email"
                    color="#9c9595"
                    label="Email"
                    variant="outlined"
                    size="small"
                    style={{ width: 320 }}
                />
            </div>
        </TabPanel>
        <TabPanel value={value} index={4}>
            <div>
                <FrTextInput 
                    // error={erroCnpj} 
                    maxLength='18'
                    value={dataPiloto.cpf || ''} 
                    onChange={handleChangePiloto} 
                    onBlur={handleCpfPiloto}  
                    name="cpf" 
                    id="cpf" 
                    color="#49573B" 
                    label="CPF" 
                    variant="outlined" 
                    size="small"
                    
                    style={{width: 180}}
                />
                <FrTextInput
                    maxLength='150'
                    value={dataPiloto.razao_social || ''} 
                    onChange={handleChangePiloto}  
                    name="razao_social" 
                    id="razao_social" 
                    color="#49573B" 
                    label="Nome" 
                    variant="outlined" 
                    size="small"
                    
                    style={{width: 500}}
                />
                <FrTextInput 
                    maxLength='10' 
                    value={dataPiloto.rg || ''} 
                    onChange={handleChangePiloto} 
                    name="rg" 
                    id="rg" 
                    color="#49573B" 
                    label="RG" 
                    variant="outlined" 
                    size="small"
                    
                    style={{width: 200}}
                />
                <FrDatePicker
                    maxLength='50'
                    value={(dataPiloto.data_nasc || '').split('T')[0]}
                    onChange={handleChangePiloto}
                    name="data_nasc"
                    id="data_nasc"
                    color="#9c9595"
                    label="Data de Nascimento"
                    variant="outlined"
                    size="small"
                    
                    style={{ width: 200 }}
                />
                <br/>
                <FrTextInput
                    maxLength='10'
                    value={dataPiloto.cep || ''}
                    error={erroCepPiloto}
                    onChange={handleChangePiloto}
                    onBlur={handleCepPiloto}
                    name="cep"
                    id="cep"
                    color="#9c9595"
                    label="CEP"
                    variant="outlined"
                    size="small"
                    style={{ width: 130 }}
                    
                />
                <FrTextInput
                    maxLength='50'
                    value={dataPiloto.logradouro || ''}
                    onChange={handleChangePiloto}
                    name="logradouro"
                    id="logradouro"
                    color="#9c9595"
                    label="Endereço"
                    variant="outlined"
                    size="small"
                    
                    style={{ width: 400 }}
                />
                <FrTextInput
                    maxLength='50'
                    value={dataPiloto.numero || ''}
                    onChange={handleChangePiloto}
                    name="numero"
                    id="numero"
                    color="#9c9595"
                    label="Número"
                    variant="outlined"
                    size="small"
                    
                    style={{ width: 100 }}
                />
                <FrTextInput
                    maxLength='50'
                    value={dataPiloto.bairro || ''}
                    onChange={handleChangePiloto}
                    name="bairro"
                    id="bairro"
                    color="#9c9595"
                    label="Bairro"
                    variant="outlined"
                    size="small"
                    
                    style={{ width: 250 }}
                />
                <FrTextInput
                    maxLength='50'
                    value={dataPiloto.complemento || ''}
                    onChange={handleChangePiloto}
                    name="complemento"
                    id="complemento"
                    color="#9c9595"
                    label="Complemento"
                    variant="outlined"
                    size="small"
                    
                    style={{ width: 330 }}
                />
                <FrSelect 
                    value={dataPiloto.fk_cid || []} 
                    style={{ width: 300 }} 
                    name="fk_cid" 
                    id="fk_cid" 
                    label='Cidade' 
                    data={props.comboCidades}
                    onChange={handleChangePiloto}
                />
                <br/>
                <FrTextInput
                    maxLength='50'
                    value={dataPiloto.fone1 || ''}
                    onChange={handleChangePiloto}
                    name="fone1"
                    id="fone1"
                    color="#9c9595"
                    label="Telefone"
                    variant="outlined"
                    size="small"
                    
                    style={{ width: 160 }}
                />
                <FrTextInput
                    maxLength='50'
                    value={dataPiloto.fone2 || ''}
                    onChange={handleChangePiloto}
                    name="fone2"
                    id="fone2"
                    color="#9c9595"
                    label="Telefone"
                    variant="outlined"
                    size="small"
                    
                    style={{ width: 160 }}
                />
                <FrTextInput
                    maxLength='50'
                    value={dataPiloto.celular || ''}
                    onChange={handleChangePiloto}
                    name="celular"
                    id="celular"
                    color="#9c9595"
                    label="Celular"
                    variant="outlined"
                    size="small"
                    
                    style={{ width: 160 }}
                />
                <FrTextInput
                    maxLength='50'
                    value={dataPiloto.email || ''}
                    onChange={handleChangePiloto}
                    name="email"
                    id="email"
                    color="#9c9595"
                    label="Email"
                    variant="outlined"
                    size="small"
                    
                    style={{ width: 350 }}
                />
                <br/>
                <br/>
                <FrTextInput
                    maxLength='50'
                    value={dataPiloto.canac || ''}
                    onChange={handleChangePiloto}
                    name="canac"
                    id="canac"
                    color="#9c9595"
                    label="CANAC"
                    variant="outlined"
                    size="small"
                    
                    style={{ width: 200 }}
                />
                <FrDatePicker
                    maxLength='50'
                    value={(dataPiloto.data_cma || '').split('T')[0]}
                    onChange={handleChangePiloto}
                    name="data_cma"
                    id="data_cma"
                    color="#9c9595"
                    label="CMA"
                    variant="outlined"
                    size="small"
                    
                    style={{ width: 200 }}
                />
                <FrSelect 
                    value={dataPiloto.fk_pcl || []} 
                    style={{ width: 300 }} 
                    name="fk_pcl" 
                    id="fk_pcl" 
                    label='Classe' 
                    data={props.comboClasses}
                    onChange={handleChangePiloto}
                />
                <br/>
                {/* <FrTextInput
                    maxLength='50'
                    value={dataPiloto.certif_militar || ''}
                    onChange={handleChangePiloto}
                    name="certif_militar"
                    id="certif_militar"
                    color="#9c9595"
                    label="Certificado Militar"
                    variant="outlined"
                    size="small"
                    
                    style={{ width: 230 }}
                />
                <FrTextInput
                    maxLength='50'
                    value={dataPiloto.categoria_certfi || ''}
                    onChange={handleChangePiloto}
                    name="categoria_certfi"
                    id="categoria_certfi"
                    color="#9c9595"
                    label="Categoria"
                    variant="outlined"
                    size="small"
                    
                    style={{ width: 250 }}
                />
                <FrDatePicker
                    maxLength='50'
                    value={(dataPiloto.data_exp_certif || '').split('T')[0]}
                    onChange={handleChangePiloto}
                    name="data_exp_certif"
                    id="data_exp_certif"
                    color="#9c9595"
                    label="Expedição"
                    variant="outlined"
                    size="small"
                    
                    style={{ width: 200 }}
                />
                <br/> */}
                <FrDatePicker
                    maxLength='50'
                    value={(dataPiloto.data_conclusao_cavag || '').split('T')[0]}
                    onChange={handleChangePiloto}
                    name="data_conclusao_cavag"
                    id="data_conclusao_cavag"
                    color="#9c9595"
                    label="Conlusão CAVAG"
                    variant="outlined"
                    size="small"
                    
                    style={{ width: 200 }}
                />
                <br/>
                <FrSelect 
                    value={dataPiloto.acidente || []} 
                    style={{ width: 150 }} 
                    name="acidente" 
                    id="acidente" 
                    label='Acidente' 
                    data={[{value: 'S', display: 'Sim'}, {value: 'N', display: 'Não'}]}
                    onChange={handleChangePiloto}
                />
                <br/>
                <FrTextInput
                    maxLength='1000'
                    value={dataPiloto.observacoes || ''}
                    onChange={handleChangePiloto}
                    name="observacoes"
                    id="observacoes"
                    color="#9c9595"
                    label="Observações"
                    variant="outlined"
                    size="small"
                    
                    style={{ width: '100%' }}
                    rows={5}
                    maxRows={5}
                    multiline
                />
            </div>
        </TabPanel>
        <TabPanel value={value} index={5}>
            {/* <div style={{ display: 'flex' }}> */}
                <div>
                    <div className="grupoTitulo">
                        Procuração
                    </div>
                    <div className="grupoEdits">
                        <FrSelect 
                            value={data.procuracao || []} 
                            style={{ width: 120 }} 
                            name="procuracao" 
                            id="procuracao" 
                            label='' 
                            data={[{value: 'S', display: 'Possui'}, {value: 'N', display: 'Não possui'}]}
                            onChange={handleChange}
                        />    
                        <FrDatePicker
                            value={(data.data_procuracao || '').split('T')[0]} 
                            onChange={handleChange}  
                            name="data_procuracao" 
                            id="data_procuracao" 
                            color="#49573B" 
                            label="Validade" 
                            variant="outlined" 
                            size="small"
                            style={{width: 200}}
                        />
                    </div>
                </div>
                {/* <div style={{ marginRight: '20px' }}>
                    <div className="grupoTitulo">
                        COA
                    </div>
                    <div className="grupoEdits">
                        <FrTextInput
                            maxLength='150'
                            value={data.nr_coa || ''} 
                            onChange={handleChange}  
                            name="nr_coa" 
                            id="nr_coa" 
                            color="#49573B" 
                            label="Nº" 
                            variant="outlined" 
                            size="small"
                            style={{width: 160}}
                        />
                        <FrDatePicker
                            value={(data.data_coa || '').split('T')[0]} 
                            onChange={handleChange}  
                            name="data_coa" 
                            id="data_coa" 
                            color="#49573B" 
                            label="Emissão" 
                            variant="outlined" 
                            size="small"
                            style={{width: 200}}
                        />
                    </div>
                </div>
                <div >
                    <div className="grupoTitulo">
                        MAPA
                    </div>
                    <div className="grupoEdits">
                        <FrTextInput
                            maxLength='150'
                            value={data.nr_mapa || ''} 
                            onChange={handleChange}  
                            name="nr_mapa" 
                            id="nr_mapa" 
                            color="#49573B" 
                            label="Nº" 
                            variant="outlined" 
                            size="small"
                            style={{width: 120}}
                        />
                        <FrDatePicker
                            value={(data.data_mapa || '').split('T')[0]} 
                            onChange={handleChange}  
                            name="data_mapa" 
                            id="data_mapa" 
                            color="#49573B" 
                            label="Emissão" 
                            variant="outlined" 
                            size="small"
                            style={{width: 200}}
                        />
                    </div>
                </div>*/}
            {/* </div>  */}
            <div className="grupoTitulo">
                Documentos
            </div>
            <div className="grupoEdits">
                <div className="manutReg">
                    <ListRegisters width={800} height={300} tab="Documentos" handleClickItem={selectDocumento} selected={propsTabDocumentos.selected} items={props.dataDocumentos} textField={'nomedoc'} handleAdd={handleAdd} />
                    <div className="register">
                        <div className="buttons">
                            <Tooltip title="Salvar">
                                <button onClick={(e) => {handleSave(e, 'Documentos')}} disabled={!editDocumentos}>
                                    <Icon icon={ic_save} size={20}/>
                                </button>
                            </Tooltip>
                            <Tooltip title={editDocumentos ? 'Desfazer' : 'Editar'}>
                                <button onClick={(e) => {handleEdit(e, 'Documentos')}} disabled={newDocumento}>
                                    <Icon icon={editDocumentos ? ic_undo : ic_mode_edit} size={20}/>
                                </button>
                            </Tooltip>
                            <Tooltip title="Excluir">
                                <button onClick={(e) => {handleDelete(e, 'Documentos')}} disabled={(!(Number(propsTabDocumentos.selected) > 0)) || (newDocumento)}>
                                    <Icon icon={ic_delete} size={20}/>
                                </button>
                            </Tooltip>
                        </div>
                        <div className="fields">
                            <FrSelect 
                                value={currentDataDocumentos?.fk_mdl || []} 
                                style={{ flex: 1, minWidth: '200px' }} 
                                name="fk_mdl" 
                                id="fk_mdl" 
                                label='Modelo' 
                                data={props.comboModeloDocumento}
                                onChange={handleChangeDocumentos}
                                disabled={!editDocumentos}
                            />
                            <br/>
                            <FrSelect 
                                value={currentDataDocumentos?.fk_tdo || []} 
                                style={{ flex: 1, minWidth: '200px' }} 
                                name="fk_tdo" 
                                id="fk_tdo" 
                                label='Tipo de Documento' 
                                data={props.comboTipoDocumento}
                                onChange={handleChangeDocumentos}
                                disabled={!editDocumentos || blockTipoDocumento}
                            />
                            <br/>
                            <FrDatePicker 
                                maxLength='10' 
                                value={(currentDataDocumentos?.dt_emissao || '').split('T')[0]} 
                                onChange={handleChangeDocumentos} 
                                name="dt_emissao" 
                                id="dt_emissao" 
                                color="#49573B" 
                                label="Emissão" 
                                variant="outlined" 
                                size="small"
                                disabled={!editDocumentos}
                                
                                style={{width: 200}}
                            />
                            <br/>
                            <FrTextInput
                                maxLength='150'
                                value={currentDataDocumentos?.nr_doc || ''} 
                                onChange={handleChangeDocumentos}  
                                name="nr_doc" 
                                id="nr_doc" 
                                color="#49573B" 
                                label="Número" 
                                variant="outlined" 
                                size="small"
                                disabled={!editDocumentos}
                                hidden={!(selectedTdo?.exige_numero  === 'S')}
                                style={{width: 200}}
                            />
                            {/* <br/> */}
                            <FrDatePicker
                                maxLength='50'
                                value={(currentDataDocumentos?.dt_validade || '').split('T')[0]}
                                onChange={handleChangeDocumentos}
                                name="dt_validade"
                                id="dt_validade"
                                color="#9c9595"
                                label="Validade"
                                variant="outlined"
                                size="small"
                                style={{ width: 200 }}
                                hidden={!(selectedTdo?.exige_validade  === 'S')}
                                disabled={!editDocumentos}
                            />
                            {/* <br/> */}
                            <FrSelect 
                                value={currentDataDocumentos?.revalidado || []} 
                                style={{ width: 200 }} 
                                name="revalidado" 
                                id="revalidado" 
                                label='Revalidado' 
                                data={[{value: 'S', display: 'Sim'}, {value: 'N', display: 'Não'}]}
                                onChange={handleChangeDocumentos}
                                hidden={!(selectedTdo?.exige_revalidacao === 'S')}
                                disabled={!editDocumentos}
                            />
                            <div style={{display: 'flex', alignItems: 'center'}}>

                                <Tooltip title="Importar Arquivo">
                                    
                                    <div 
                                    style={{backgroundColor: '#9c9595', width: 140, heigth: 21, padding: 5, marginRight: 5}}
                                    onClick={handleArquivo}
                                    
                                    >
                                        
                                        <FiUpload size={20}/>
                                        Importar Arquivo
                                        <input 
                                            type='file'
                                            accept='.pdf, .jpg, .jpeg, .png'
                                            id='arquivo'
                                            name='arquivo'
                                            hidden
                                            onChange={handleChangeDocumentos}
                                            
                                        />
                                        <FrTextInput
                                            maxLength='150'
                                            value={currentDataDocumentos?.arquivo || ''} 
                                            onChange={handleChangeDocumentos}  
                                            name="arquivo" 
                                            id="arquivo" 
                                            color="#49573B" 
                                            label="arquivo" 
                                            variant="outlined" 
                                            size="small"
                                            hidden
                                        />

                                        

                                    </div>
                                
                                </Tooltip> 
                                {currentDataDocumentos?.arquivo?.split('\\')[currentDataDocumentos?.arquivo.split('\\').length-1]}
                            </div>
                            {currentDataDocumentos?.arquivo? 
                            <>
                                <Tooltip title="Importar Arquivo">
                                    
                                    <div 
                                    style={{backgroundColor: '#9c9595', width: 140, heigth: 21, padding: 5, marginRight: 5 ,marginTop: 5}}
                                    onClick={handleDownload}
                                    
                                    >
                                        <FiDownload size={20}/>
                                        Baixar Arquivo
                                                                           

                                    </div>
                                
                                </Tooltip> 
                            </> 
                            
                            : <></>}
                        </div>
                    </div>
                </div>
            </div>
        </TabPanel>
        <TabPanel value={value} index={6}>
                <FrTextInput
                        maxLength='10'
                        value={data.cep_desconta || ''}
                        error={erroCepDesconta}
                        onChange={handleChange}
                        onBlur={handleCepDesconta}
                        name="cep_desconta"
                        id="cep_desconta"
                        color="#9c9595"
                        label="CEP"
                        variant="outlined"
                        size="small"
                        style={{ width: 130 }}
                        
                    />
                    <FrTextInput
                        maxLength='50'
                        value={data.logradouro_desconta || ''}
                        onChange={handleChange}
                        name="logradouro_desconta"
                        id="logradouro_desconta"
                        color="#9c9595"
                        label="Endereço"
                        variant="outlined"
                        size="small"
                        
                        style={{ width: 400 }}
                    />
                    <FrTextInput
                        maxLength='50'
                        value={data.nr_desconta || ''}
                        onChange={handleChange}
                        name="nr_desconta"
                        id="nr_desconta"
                        color="#9c9595"
                        label="Número"
                        variant="outlined"
                        size="small"
                        
                        style={{ width: 100 }}
                    />
                    <FrTextInput
                        maxLength='50'
                        value={data.bairro_desconta || ''}
                        onChange={handleChange}
                        name="bairro_desconta"
                        id="bairro_desconta"
                        color="#9c9595"
                        label="Bairro"
                        variant="outlined"
                        size="small"
                        
                        style={{ width: 250 }}
                    />
                    <FrTextInput
                        maxLength='50'
                        value={data.comple_desconta || ''}
                        onChange={handleChange}
                        name="comple_desconta"
                        id="comple_desconta"
                        color="#9c9595"
                        label="Complemento"
                        variant="outlined"
                        size="small"
                        
                        style={{ width: 330 }}
                    />
                    <FrSelect 
                        value={data.fk_cid_desconta || []} 
                        style={{ width: 300 }} 
                        name="fk_cid_desconta" 
                        id="fk_cid_desconta" 
                        label='Cidade' 
                        data={props.comboCidades}
                        onChange={handleChange}
                    />
                    <FrTextInput
                        maxLength='50'
                        value={data.fone1_desconta || ''}
                        onChange={handleChange}
                        name="fone1_desconta"
                        id="fone1_desconta"
                        color="#9c9595"
                        label="Telefone"
                        variant="outlined"
                        size="small"
                        
                        style={{ width: 160 }}
                    />
                    <FrTextInput
                        maxLength='50'
                        value={data.fone2_desconta || ''}
                        onChange={handleChange}
                        name="fone2_desconta"
                        id="fone2_desconta"
                        color="#9c9595"
                        label="Telefone"
                        variant="outlined"
                        size="small"
                        
                        style={{ width: 160 }}
                    />
                    <FrTextInput
                        maxLength='50'
                        value={data.celular_desconta || ''}
                        onChange={handleChange}
                        name="celular_desconta"
                        id="celular_desconta"
                        color="#9c9595"
                        label="Celular"
                        variant="outlined"
                        size="small"
                        
                        style={{ width: 160 }}
                    />
                    <br/>
                    <br/>
                    <FrSwitch
                        checked={(data.piscina_coberta||null) === 'S'} 
                        name="piscina_coberta" 
                        id="piscina_coberta" 
                        label='Piscina Coberta' 
                        onChange={handleChange}
                    />  
                    <FrSwitch
                        checked={(data.ozonizador||null) === 'S'} 
                        name="ozonizador" 
                        id="ozonizador" 
                        label='Ozonizador' 
                        onChange={handleChange}
                    /> 
                    <FrTextInput
                        value={data.capac_aeronaves || ''}
                        onChange={handleChange}
                        name="capac_aeronaves"
                        id="capac_aeronaves"
                        color="#9c9595"
                        label="Capac. Aeronaves"
                        variant="outlined"
                        size="small"
                        
                        style={{ width: 170 }}
                    />
                    <FrSwitch
                        checked={(data.opera_terceiros||null) === 'S'} 
                        name="opera_terceiros" 
                        id="opera_terceiros" 
                        label='Operação de Aeronave de Terceiros' 
                        onChange={handleChange}
                    /> 
                    <FrTextInput
                        value={data.prefixos || ''}
                        onChange={handleChange}
                        name="prefixos"
                        id="prefixos"
                        color="#9c9595"
                        label="Prefixos"
                        variant="outlined"
                        size="small"
                        disabled={!(data.opera_terceiros === 'S')}
                        style={{ width: 200 }}
                    />

            
        </TabPanel>
        <TabPanel value={value} index={7}>
        <div className="grupoTitulo">
                 
                 </div>
                 <div className="grupoEdits">
                     <FrTextInput
                             maxLength='10'
                             value={data.cep_abastec || ''}
                             error={erroCepAbastec}
                             onChange={handleChange}
                             onBlur={handleCepAbastec}
                             name="cep_abastec"
                             id="cep_abastec"
                             color="#9c9595"
                             label="CEP"
                             variant="outlined"
                             size="small"
                             style={{ width: 130 }}
                             
                         />
                         <FrTextInput
                             maxLength='50'
                             value={data.logradouro_abastec || ''}
                             onChange={handleChange}
                             name="logradouro_abastec"
                             id="logradouro_abastec"
                             color="#9c9595"
                             label="Endereço"
                             variant="outlined"
                             size="small"
                             
                             style={{ width: 400 }}
                         />
                         <FrTextInput
                             maxLength='50'
                             value={data.nr_abastec || ''}
                             onChange={handleChange}
                             name="nr_abastec"
                             id="nr_abastec"
                             color="#9c9595"
                             label="Número"
                             variant="outlined"
                             size="small"
                             
                             style={{ width: 100 }}
                         />
                         <FrTextInput
                             maxLength='50'
                             value={data.bairro_abastec || ''}
                             onChange={handleChange}
                             name="bairro_abastec"
                             id="bairro_abastec"
                             color="#9c9595"
                             label="Bairro"
                             variant="outlined"
                             size="small"
                             
                             style={{ width: 250 }}
                         />
                         <FrTextInput
                             maxLength='50'
                             value={data.comple_abastec || ''}
                             onChange={handleChange}
                             name="comple_abastec"
                             id="comple_abastec"
                             color="#9c9595"
                             label="Complemento"
                             variant="outlined"
                             size="small"
                             
                             style={{ width: 330 }}
                         />
                         <FrSelect 
                             value={data.fk_cid_abastec || []} 
                             style={{ width: 300 }} 
                             name="fk_cid_abastec" 
                             id="fk_cid_abastec" 
                             label='Cidade' 
                             data={props.comboCidades}
                             onChange={handleChange}
                         />
                         <FrTextInput
                             maxLength='50'
                             value={data.fone1_abastec || ''}
                             onChange={handleChange}
                             name="fone1_abastec"
                             id="fone1_abastec"
                             color="#9c9595"
                             label="Telefone"
                             variant="outlined"
                             size="small"
                             
                             style={{ width: 160 }}
                         />
                         <FrTextInput
                             maxLength='50'
                             value={data.fone2_abastec || ''}
                             onChange={handleChange}
                             name="fone2_abastec"
                             id="fone2_abastec"
                             color="#9c9595"
                             label="Telefone"
                             variant="outlined"
                             size="small"
                             
                             style={{ width: 160 }}
                         />
                         <FrTextInput
                             maxLength='50'
                             value={data.celular_abastec || ''}
                             onChange={handleChange}
                             name="celular_abastec"
                             id="celular_abastec"
                             color="#9c9595"
                             label="Celular"
                             variant="outlined"
                             size="small"
                             
                             style={{ width: 160 }}
                         />
                     </div>
                 <br/>
                 <br/>
                 <br/>
                 <div className="manutReg">
                     <ListRegisters width={800} height={300} tab="TanqueCombustivel" handleClickItem={selectTanqueCombustivel} selected={propsTabTanqueCombustivel.selected} items={props.dataTanqueCombustivel} textField={'descricao'} handleAdd={handleAdd} />
                     <div className="register">
                         <div className="buttons">
                             <Tooltip title="Salvar">
                                 <button onClick={(e) => {handleSave(e, 'TanqueCombustivel')}} disabled={!editTanqueCombustivel}>
                                     <Icon icon={ic_save} size={20}/>
                                 </button>
                             </Tooltip>
                             <Tooltip title={editTanqueCombustivel ? 'Desfazer' : 'Editar'}>
                                 <button onClick={(e) => {handleEdit(e, 'TanqueCombustivel')}} disabled={newTanqueCombustivel}>
                                     <Icon icon={editTanqueCombustivel ? ic_undo : ic_mode_edit} size={20}/>
                                 </button>
                             </Tooltip>
                             <Tooltip title="Excluir">
                                 <button onClick={(e) => {handleDelete(e, 'TanqueCombustivel')}} disabled={(!(Number(propsTabTanqueCombustivel.selected) > 0)) || (newTanqueCombustivel)}>
                                     <Icon icon={ic_delete} size={20}/>
                                 </button>
                             </Tooltip>
                         </div>
                         <div className="fields">
                            <FrTextInput
                                 maxLength='150'
                                 value={currentDataTanqueCombustivel?.descricao || ''} 
                                 onChange={handleChangeTanqueCombustivel}  
                                 name="descricao" 
                                 id="descricao" 
                                 color="#49573B" 
                                 label="Descrição" 
                                 variant="outlined" 
                                 size="small"
                                 disabled={!editTanqueCombustivel}
                                 style={{width: 250}}
                             />
                             <br/>
                             <FrTextInput
                                 maxLength='150'
                                 value={currentDataTanqueCombustivel?.capacidade || ''} 
                                 onChange={handleChangeTanqueCombustivel}  
                                 name="capacidade" 
                                 id="capacidade" 
                                 color="#49573B" 
                                 label="Capacidade" 
                                 variant="outlined" 
                                 size="small"
                                 disabled={!editTanqueCombustivel}
                                 style={{width: 200}}
                             />
                             <br/>
                             <FrSelect 
                                value={currentDataTanqueCombustivel?.fk_tcm || []} 
                                style={{ width: 200 }} 
                                name="fk_tcm" 
                                id="fk_tcm" 
                                label='Tipo de Combustível' 
                                data={props.comboTipoCombustivel}
                                onChange={handleChangeTanqueCombustivel}
                                disabled={!editTanqueCombustivel}
                            />
                            <br/>
                             <FrSelect 
                                 value={currentDataTanqueCombustivel?.ativo || []} 
                                 style={{ width: 200 }} 
                                 name="ativo" 
                                 id="ativo" 
                                 label='Ativo' 
                                 data={[{value: 'S', display: 'Sim'}, {value: 'N', display: 'Não'}]}
                                 onChange={handleChangeTanqueCombustivel}
                                 disabled={!editTanqueCombustivel}
                             />
                         </div>
                     </div>
                 </div>
        </TabPanel>
        <TabPanel value={value} index={8}>
        <div style={{ display: 'flex', flex: 1 }}> 
                <div style={{ flex: 1, marginRight: '20px' }}>
                    <div className="grupoTitulo">
                        Fase 1
                    </div>
                    <div className="grupoEdits">
                        <FrDatePicker
                            value={(data.f1_data_inicio || '').split('T')[0]} 
                            onChange={handleChange}  
                            name="f1_data_inicio" 
                            id="f1_data_inicio" 
                            color="#49573B" 
                            label="Início" 
                            variant="outlined" 
                            size="small"
                            style={{width: 200}}
                        />
                        <FrDatePicker
                            value={(data.f1_data_previsao || '').split('T')[0]} 
                            onChange={handleChange}  
                            name="f1_data_previsao" 
                            id="f1_data_previsao" 
                            color="#49573B" 
                            label="Previsão" 
                            variant="outlined" 
                            size="small"
                            style={{width: 200}}
                        />
                        <FrDatePicker
                            value={(data.f1_data_conclusao || '').split('T')[0]} 
                            onChange={handleChange}  
                            name="f1_data_conclusao" 
                            id="f1_data_conclusao" 
                            color="#49573B" 
                            label="Conclusão" 
                            variant="outlined" 
                            size="small"
                            style={{width: 200}}
                        />
                        <FrSelect 
                            value={data.f1_fk_sif || []} 
                            style={{ width: 180 }} 
                            name="f1_fk_sif" 
                            id="f1_fk_sif" 
                            label='Status' 
                            data={props.comboSituacaoFase}
                            onChange={handleChange}
                        />
                        <FrTextInput
                            maxLength='1000'
                            value={data.f1_observacao || ''}
                            onChange={handleChange}
                            name="f1_observacao"
                            id="f1_observacao"
                            color="#9c9595"
                            label="Observação"
                            variant="outlined"
                            size="small"
                            style={{ width: '100%' }}
                            rows={5}
                            maxRows={5}
                            multiline
                        />    
                    </div>
                </div>
                <div style={{ flex: 1 }}>
                    <div className="grupoTitulo">
                        Fase 2
                    </div>
                    <div className="grupoEdits">
                    <FrDatePicker
                            value={(data.f2_data_inicio || '').split('T')[0]} 
                            onChange={handleChange}  
                            name="f2_data_inicio" 
                            id="f2_data_inicio" 
                            color="#49573B" 
                            label="Início" 
                            variant="outlined" 
                            size="small"
                            style={{width: 200}}
                        />
                        <FrDatePicker
                            value={(data.f2_data_previsao || '').split('T')[0]} 
                            onChange={handleChange}  
                            name="f2_data_previsao" 
                            id="f2_data_previsao" 
                            color="#49573B" 
                            label="Previsão" 
                            variant="outlined" 
                            size="small"
                            style={{width: 200}}
                        />
                        <FrDatePicker
                            value={(data.f2_data_conclusao || '').split('T')[0]} 
                            onChange={handleChange}  
                            name="f2_data_conclusao" 
                            id="f2_data_conclusao" 
                            color="#49573B" 
                            label="Conclusão" 
                            variant="outlined" 
                            size="small"
                            style={{width: 200}}
                        />
                        <FrSelect 
                            value={data.f2_fk_sif || []} 
                            style={{ width: 180 }} 
                            name="f2_fk_sif" 
                            id="f2_fk_sif" 
                            label='Status' 
                            data={props.comboSituacaoFase}
                            onChange={handleChange}
                        />
                        <FrTextInput
                            maxLength='1000'
                            value={data.f2_observacao || ''}
                            onChange={handleChange}
                            name="f2_observacao"
                            id="f2_observacao"
                            color="#9c9595"
                            label="Observação"
                            variant="outlined"
                            size="small"
                            style={{ width: '100%' }}
                            rows={5}
                            maxRows={5}
                            multiline
                        />    
                    </div>
                </div>
            </div>

            <div style={{ display: 'flex', flex: 1 }}> 
                <div style={{ flex: 1, marginRight: '20px' }}>
                    <div className="grupoTitulo">
                        Fase 3
                    </div>
                    <div className="grupoEdits">
                    <FrDatePicker
                            value={(data.f3_data_inicio || '').split('T')[0]} 
                            onChange={handleChange}  
                            name="f3_data_inicio" 
                            id="f3_data_inicio" 
                            color="#49573B" 
                            label="Início" 
                            variant="outlined" 
                            size="small"
                            style={{width: 200}}
                        />
                        <FrDatePicker
                            value={(data.f3_data_previsao || '').split('T')[0]} 
                            onChange={handleChange}  
                            name="f3_data_previsao" 
                            id="f3_data_previsao" 
                            color="#49573B" 
                            label="Previsão" 
                            variant="outlined" 
                            size="small"
                            style={{width: 200}}
                        />
                        <FrDatePicker
                            value={(data.f3_data_conclusao || '').split('T')[0]} 
                            onChange={handleChange}  
                            name="f3_data_conclusao" 
                            id="f3_data_conclusao" 
                            color="#49573B" 
                            label="Conclusão" 
                            variant="outlined" 
                            size="small"
                            style={{width: 200}}
                        />
                        <FrSelect 
                            value={data.f3_fk_sif || []} 
                            style={{ width: 180 }} 
                            name="f3_fk_sif" 
                            id="f3_fk_sif" 
                            label='Status' 
                            data={props.comboSituacaoFase}
                            onChange={handleChange}
                        />
                        <FrTextInput
                            maxLength='1000'
                            value={data.f3_observacao || ''}
                            onChange={handleChange}
                            name="f3_observacao"
                            id="f3_observacao"
                            color="#9c9595"
                            label="Observação"
                            variant="outlined"
                            size="small"
                            style={{ width: '100%' }}
                            rows={5}
                            maxRows={5}
                            multiline
                        />    
                    </div>
                </div>
                <div style={{ flex: 1 }}>
                    <div className="grupoTitulo">
                        Fase 4
                    </div>
                    <div className="grupoEdits">
                    <FrDatePicker
                            value={(data.f4_data_inicio || '').split('T')[0]} 
                            onChange={handleChange}  
                            name="f4_data_inicio" 
                            id="f4_data_inicio" 
                            color="#49573B" 
                            label="Início" 
                            variant="outlined" 
                            size="small"
                            style={{width: 200}}
                        />
                        <FrDatePicker
                            value={(data.f4_data_previsao || '').split('T')[0]} 
                            onChange={handleChange}  
                            name="f4_data_previsao" 
                            id="f4_data_previsao" 
                            color="#49573B" 
                            label="Previsão" 
                            variant="outlined" 
                            size="small"
                            style={{width: 200}}
                        />
                        <FrDatePicker
                            value={(data.f4_data_conclusao || '').split('T')[0]} 
                            onChange={handleChange}  
                            name="f4_data_conclusao" 
                            id="f4_data_conclusao" 
                            color="#49573B" 
                            label="Conclusão" 
                            variant="outlined" 
                            size="small"
                            style={{width: 200}}
                        />
                        <FrSelect 
                            value={data.f4_fk_sif || []} 
                            style={{ width: 180 }} 
                            name="f4_fk_sif" 
                            id="f4_fk_sif" 
                            label='Status' 
                            data={props.comboSituacaoFase}
                            onChange={handleChange}
                        />
                        <FrTextInput
                            maxLength='1000'
                            value={data.f4_observacao || ''}
                            onChange={handleChange}
                            name="f4_observacao"
                            id="f4_observacao"
                            color="#9c9595"
                            label="Observação"
                            variant="outlined"
                            size="small"
                            style={{ width: '100%' }}
                            rows={5}
                            maxRows={5}
                            multiline
                        />    
                    </div>
                </div>
            </div>

        </TabPanel>
        <TabPanel value={value} index={9}>
            <div className={classes.root}>
                <AppBar position="static">
                    <Tabs
                        value={valueRelacionados}
                        onChange={handleChangeTabsRelacionados}
                        indicatorColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                    >
                        <Tab label="Piloto" {...a11yPropsRelacionados(0)} />
                        <Tab label="Mecânico" {...a11yPropsRelacionados(1)} />
                        <Tab label="Aeronave" {...a11yPropsRelacionados(2)} />
                        <Tab label="Apua" {...a11yPropsRelacionados(3)} />
                        <Tab label='Engenheiro' {...a11yPropsRelacionados(4)} />
                        <Tab label='Técnico Agrícola' {...a11yPropsRelacionados(5)} />
                    </Tabs>
                </AppBar>
                <TabPanel value={valueRelacionados} index={0} relacionados={true}>
                    <div className="regRelacionados">
                        <Nav.Link href="/ejr/piloto" target="_blank" className="atalhoRelacionados">
                            <Icon icon={ic_launch} size={20}/>
                            <strong>{'Pilotos'}</strong> 
                        </Nav.Link>

                        <div className="manutReg Relacionados">
                            <ListRegistersDetailedFilter reload={props.reloadRelacionadosPiloto} filters={filtersRelacionadosPiloto} width={800} height={300} tab="RelacionadosPiloto" handleClickItem={selectRelacionadosPiloto} selected={propsTabRelacionadosPiloto.selected} items={props.dataRelacionadosPiloto} textField={'descricao'} handleAdd={handleAdd} />
                            <div className="register">
                                <div className="buttons">
                                    <Tooltip title="Salvar">
                                        <button onClick={(e) => {handleSave(e, 'RelacionadosPiloto')}} disabled={!editRelacionadosPiloto}>
                                            <Icon icon={ic_save} size={20}/>
                                        </button>
                                    </Tooltip>
                                    <Tooltip title={editRelacionadosPiloto ? 'Desfazer' : 'Editar'}>
                                        <button onClick={(e) => {handleEdit(e, 'RelacionadosPiloto')}} disabled={newRelacionadosPiloto}>
                                            <Icon icon={editRelacionadosPiloto ? ic_undo : ic_mode_edit} size={20}/>
                                        </button>
                                    </Tooltip>
                                    <Tooltip title="Excluir">
                                        <button onClick={(e) => {handleDelete(e, 'RelacionadosPiloto')}} disabled={(!(Number(propsTabRelacionadosPiloto.selected) > 0)) || (newRelacionadosPiloto)}>
                                            <Icon icon={ic_delete} size={20}/>
                                        </button>
                                    </Tooltip>
                                </div>
                                <div className="fields">
                                    <FrSelect 
                                        value={currentDataRelacionadosPiloto?.fk_pes_pil || []} 
                                        name="fk_pes_pil" 
                                        id="fk_pes_pil" 
                                        label='Piloto' 
                                        style={{ width: '100%' }}
                                        data={props.comboPilotos}
                                        onChange={handleChangeRelacionadosPiloto}
                                        disabled={!editRelacionadosPiloto}
                                    />
                                    <br/>
                                    <FrDatePicker 
                                        maxLength='10' 
                                        value={(currentDataRelacionadosPiloto?.data_admissao || '').split('T')[0]} 
                                        onChange={handleChangeRelacionadosPiloto} 
                                        name="data_admissao" 
                                        id="data_admissao" 
                                        color="#49573B" 
                                        label="Admissão" 
                                        variant="outlined" 
                                        size="small"
                                        disabled={!editRelacionadosPiloto}
                                        style={{width: 200}}
                                    />
                                    <br/>
                                    <FrDatePicker 
                                        maxLength='10' 
                                        value={(currentDataRelacionadosPiloto?.data_desligamento || '').split('T')[0]} 
                                        onChange={handleChangeRelacionadosPiloto} 
                                        name="data_desligamento" 
                                        id="data_desligamento" 
                                        color="#49573B" 
                                        label="Desligamento" 
                                        variant="outlined" 
                                        size="small"
                                        disabled={!editRelacionadosPiloto}
                                        style={{width: 200}}
                                    />
                                    <br/>
                                    <FrSelect 
                                        value={currentDataRelacionadosPiloto?.fk_sit || []} 
                                        style={{ width: 200 }} 
                                        name="fk_sit" 
                                        id="fk_sit" 
                                        label='Situação' 
                                        data={props.comboSituacaoPiloto}
                                        onChange={handleChangeRelacionadosPiloto}
                                        disabled={!editRelacionadosPiloto}
                                    />
                                    <br/>
                                    <FrTextInput
                                        maxLength='1000'
                                        value={currentDataRelacionadosPiloto?.observacoes || ''}
                                        onChange={handleChangeRelacionadosPiloto}
                                        name="observacoes"
                                        id="observacoes"
                                        color="#9c9595"
                                        label="Observações"
                                        variant="outlined"
                                        size="small"
                                        
                                        style={{ width: '100%' }}
                                        disabled={!editRelacionadosPiloto}
                                        rows={5}
                                        maxRows={5}
                                        multiline
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel value={valueRelacionados} index={1} relacionados={true}>
                    <div className="regRelacionados">
                        <Nav.Link href="/ejr/mecanicos" target="_blank" className="atalhoRelacionados">
                            <Icon icon={ic_launch} size={20}/>
                            <strong>{'Mecanicos'}</strong> 
                        </Nav.Link>

                        <div className="manutReg Relacionados">
                            <ListRegistersDetailedFilter reload={props.reloadRelacionadosMecanico} filters={filtersRelacionadosMecanico} width={800} height={300} tab="RelacionadosMecanico" handleClickItem={selectRelacionadosMecanico} selected={propsTabRelacionadosMecanico.selected} items={props.dataRelacionadosMecanico} textField={'descricao'} handleAdd={handleAdd} />
                            <div className="register">
                                <div className="buttons">
                                    <Tooltip title="Salvar">
                                        <button onClick={(e) => {handleSave(e, 'RelacionadosMecanico')}} disabled={!editRelacionadosMecanico}>
                                            <Icon icon={ic_save} size={20}/>
                                        </button>
                                    </Tooltip>
                                    <Tooltip title={editRelacionadosMecanico ? 'Desfazer' : 'Editar'}>
                                        <button onClick={(e) => {handleEdit(e, 'RelacionadosMecanico')}} disabled={newRelacionadosMecanico}>
                                            <Icon icon={editRelacionadosMecanico ? ic_undo : ic_mode_edit} size={20}/>
                                        </button>
                                    </Tooltip>
                                    <Tooltip title="Excluir">
                                        <button onClick={(e) => {handleDelete(e, 'RelacionadosMecanico')}} disabled={(!(Number(propsTabRelacionadosMecanico.selected) > 0)) || (newRelacionadosMecanico)}>
                                            <Icon icon={ic_delete} size={20}/>
                                        </button>
                                    </Tooltip>
                                </div>
                                <div className="fields">
                                    <FrSelect 
                                        value={currentDataRelacionadosMecanico?.fk_pes_mec || []} 
                                        name="fk_pes_mec" 
                                        id="fk_pes_mec" 
                                        label='Mecânico' 
                                        style={{ width: '100%' }}
                                        data={props.comboMecanicos}
                                        onChange={handleChangeRelacionadosMecanico}
                                        disabled={!editRelacionadosMecanico}
                                    />
                                    <br/>
                                    <FrDatePicker 
                                        maxLength='10' 
                                        value={(currentDataRelacionadosMecanico?.data_admissao || '').split('T')[0]} 
                                        onChange={handleChangeRelacionadosMecanico} 
                                        name="data_admissao" 
                                        id="data_admissao" 
                                        color="#49573B" 
                                        label="Admissão" 
                                        variant="outlined" 
                                        size="small"
                                        disabled={!editRelacionadosMecanico}
                                        style={{width: 200}}
                                    />
                                    <br/>
                                    <FrDatePicker 
                                        maxLength='10' 
                                        value={(currentDataRelacionadosMecanico?.data_desligamento || '').split('T')[0]} 
                                        onChange={handleChangeRelacionadosMecanico} 
                                        name="data_desligamento" 
                                        id="data_desligamento" 
                                        color="#49573B" 
                                        label="Desligamento" 
                                        variant="outlined" 
                                        size="small"
                                        disabled={!editRelacionadosMecanico}
                                        style={{width: 200}}
                                    />
                                    <br/>
                                    <FrSelect 
                                        value={currentDataRelacionadosMecanico?.fk_sit || []} 
                                        style={{ width: 200 }} 
                                        name="fk_sit" 
                                        id="fk_sit" 
                                        label='Situação' 
                                        data={props.comboSituacaoMecanico}
                                        onChange={handleChangeRelacionadosMecanico}
                                        disabled={!editRelacionadosMecanico}
                                    />
                                    <br/>
                                    <FrTextInput
                                        maxLength='1000'
                                        value={currentDataRelacionadosMecanico?.observacoes || ''}
                                        onChange={handleChangeRelacionadosMecanico}
                                        name="observacoes"
                                        id="observacoes"
                                        color="#9c9595"
                                        label="Observações"
                                        variant="outlined"
                                        size="small"
                                        
                                        style={{ width: '100%' }}
                                        disabled={!editRelacionadosMecanico}
                                        rows={5}
                                        maxRows={5}
                                        multiline
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel value={valueRelacionados} index={2} relacionados={true}>
                    <div className="regRelacionados">
                        <Nav.Link href="/ejr/aeronave" target="_blank" className="atalhoRelacionados">
                            <Icon icon={ic_launch} size={20}/>
                            <strong>{'Aeronaves'}</strong> 
                        </Nav.Link>

                        <div className="manutReg Relacionados">
                            <ListRegistersDetailedFilter reload={props.reloadRelacionadosAeronave} filters={filtersRelacionadosAeronave} width={800} height={300} tab="RelacionadosAeronave" handleClickItem={selectRelacionadosAeronave} selected={propsTabRelacionadosAeronave.selected} items={props.dataRelacionadosAeronave} textField={'descricao'} handleAdd={handleAdd} />
                            <div className="register">
                                <div className="buttons">
                                    <Tooltip title="Salvar">
                                        <button onClick={(e) => {handleSave(e, 'RelacionadosAeronave')}} disabled={!editRelacionadosAeronave}>
                                            <Icon icon={ic_save} size={20}/>
                                        </button>
                                    </Tooltip>
                                    <Tooltip title={editRelacionadosAeronave ? 'Desfazer' : 'Editar'}>
                                        <button onClick={(e) => {handleEdit(e, 'RelacionadosAeronave')}} disabled={newRelacionadosAeronave}>
                                            <Icon icon={editRelacionadosAeronave ? ic_undo : ic_mode_edit} size={20}/>
                                        </button>
                                    </Tooltip>
                                    <Tooltip title="Excluir">
                                        <button onClick={(e) => {handleDelete(e, 'RelacionadosAeronave')}} disabled={(!(Number(propsTabRelacionadosAeronave.selected) > 0)) || (newRelacionadosAeronave)}>
                                            <Icon icon={ic_delete} size={20}/>
                                        </button>
                                    </Tooltip>
                                </div>
                                <div className="fields">
                                    <FrSelect 
                                        value={currentDataRelacionadosAeronave?.fk_aer || []} 
                                        name="fk_aer" 
                                        id="fk_aer" 
                                        label='Aeronave' 
                                        style={{ width: '100%' }}
                                        data={props.comboAeronave}
                                        onChange={handleChangeRelacionadosAeronave}
                                        disabled={!editRelacionadosAeronave}
                                    />
                                    <br/>
                                    <FrDatePicker 
                                        maxLength='10' 
                                        value={(currentDataRelacionadosAeronave?.data_aquisicao || '').split('T')[0]} 
                                        onChange={handleChangeRelacionadosAeronave} 
                                        name="data_aquisicao" 
                                        id="data_aquisicao" 
                                        color="#49573B" 
                                        label="Aquisição" 
                                        variant="outlined" 
                                        size="small"
                                        disabled={!editRelacionadosAeronave}
                                        style={{width: 200}}
                                    />
                                    <br/>
                                    <FrDatePicker 
                                        maxLength='10' 
                                        value={(currentDataRelacionadosAeronave?.data_baixa || '').split('T')[0]} 
                                        onChange={handleChangeRelacionadosAeronave} 
                                        name="data_baixa" 
                                        id="data_baixa" 
                                        color="#49573B" 
                                        label="Baixa" 
                                        variant="outlined" 
                                        size="small"
                                        disabled={!editRelacionadosAeronave}
                                        style={{width: 200}}
                                    />
                                    <br/>
                                    <FrSelect 
                                        value={currentDataRelacionadosAeronave?.fk_sit || []} 
                                        style={{ width: 200 }} 
                                        name="fk_sit" 
                                        id="fk_sit" 
                                        label='Situação' 
                                        data={props.comboSituacaoAeronave}
                                        onChange={handleChangeRelacionadosAeronave}
                                        disabled={!editRelacionadosAeronave}
                                    />
                                    <br/>
                                    <FrTextInput
                                        maxLength='1000'
                                        value={currentDataRelacionadosAeronave?.observacoes || ''}
                                        onChange={handleChangeRelacionadosAeronave}
                                        name="observacoes"
                                        id="observacoes"
                                        color="#9c9595"
                                        label="Observações"
                                        variant="outlined"
                                        size="small"
                                        
                                        style={{ width: '100%' }}
                                        disabled={!editRelacionadosAeronave}
                                        rows={5}
                                        maxRows={5}
                                        multiline
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel value={valueRelacionados} index={3} relacionados={true}>
                    <div className="regRelacionados">
                        <Nav.Link href="/ejr/apua" target="_blank" className="atalhoRelacionados">
                            <Icon icon={ic_launch} size={20}/>
                            <strong>{'Apuas'}</strong> 
                        </Nav.Link>

                        <div className="manutReg Relacionados">
                            <ListRegistersDetailedFilter reload={props.reloadRelacionadosApua} filters={filtersRelacionadosApua} width={800} height={300} tab="RelacionadosApua" handleClickItem={selectRelacionadosApua} selected={propsTabRelacionadosApua.selected} items={props.dataRelacionadosApua} textField={'descricao'} handleAdd={handleAdd} />
                            <div className="register">
                                <div className="buttons">
                                    <Tooltip title="Salvar">
                                        <button onClick={(e) => {handleSave(e, 'RelacionadosApua')}} disabled={!editRelacionadosApua}>
                                            <Icon icon={ic_save} size={20}/>
                                        </button>
                                    </Tooltip>
                                    <Tooltip title={editRelacionadosApua ? 'Desfazer' : 'Editar'}>
                                        <button onClick={(e) => {handleEdit(e, 'RelacionadosApua')}} disabled={newRelacionadosApua}>
                                            <Icon icon={editRelacionadosApua ? ic_undo : ic_mode_edit} size={20}/>
                                        </button>
                                    </Tooltip>
                                    <Tooltip title="Excluir">
                                        <button onClick={(e) => {handleDelete(e, 'RelacionadosApua')}} disabled={(!(Number(propsTabRelacionadosApua.selected) > 0)) || (newRelacionadosApua)}>
                                            <Icon icon={ic_delete} size={20}/>
                                        </button>
                                    </Tooltip>
                                </div>
                                <div className="fields">
                                    <FrSelect 
                                        value={currentDataRelacionadosApua?.fk_apu || []} 
                                        name="fk_apu" 
                                        id="fk_apu" 
                                        label='Apua' 
                                        style={{ width: '100%' }}
                                        data={props.comboApua}
                                        onChange={handleChangeRelacionadosApua}
                                        disabled={!editRelacionadosApua}
                                    />
                                    <br/>
                                    <FrTextInput
                                        maxLength='150'
                                        value={currentDataRelacionadosApua?.denominacao || ''}
                                        onChange={handleChangeRelacionadosApua}
                                        name="denominacao"
                                        id="denominacao"
                                        color="#9c9595"
                                        label="Denominação"
                                        variant="outlined"
                                        size="small"
                                        style={{ width: 160 }}
                                        disabled={!editRelacionadosApua}
                                    />
                                    <br/>
                                    <FrTextInput
                                        maxLength='2'
                                        value={currentDataRelacionadosApua?.analise || ''}
                                        onChange={handleChangeRelacionadosApua}
                                        name="analise"
                                        id="analise"
                                        color="#9c9595"
                                        label="Análise"
                                        variant="outlined"
                                        size="small"
                                        style={{ width: 70 }}
                                        disabled={!editRelacionadosApua}
                                    />
                                    <br/>
                                    <FrTextInput
                                        maxLength='1000'
                                        value={currentDataRelacionadosApua?.obstaculos || ''}
                                        onChange={handleChangeRelacionadosApua}
                                        name="obstaculos"
                                        id="obstaculos"
                                        color="#9c9595"
                                        label="Obstáculos"
                                        variant="outlined"
                                        size="small"
                                        
                                        style={{ width: '100%' }}
                                        disabled={!editRelacionadosApua}
                                        rows={5}
                                        maxRows={5}
                                        multiline
                                    />
                                    <br/>
                                    <FrTextInput
                                        maxLength='1000'
                                        value={currentDataRelacionadosApua?.recomendacoes || ''}
                                        onChange={handleChangeRelacionadosApua}
                                        name="recomendacoes"
                                        id="recomendacoes"
                                        color="#9c9595"
                                        label="Recomendação"
                                        variant="outlined"
                                        size="small"
                                        
                                        style={{ width: '100%' }}
                                        disabled={!editRelacionadosApua}
                                        rows={5}
                                        maxRows={5}
                                        multiline
                                    />
                                    <br/>
                                    <FrTextInput
                                        maxLength='1000'
                                        value={currentDataRelacionadosApua?.observacoes || ''}
                                        onChange={handleChangeRelacionadosApua}
                                        name="observacoes"
                                        id="observacoes"
                                        color="#9c9595"
                                        label="Observações"
                                        variant="outlined"
                                        size="small"
                                        
                                        style={{ width: '100%' }}
                                        disabled={!editRelacionadosApua}
                                        rows={5}
                                        maxRows={5}
                                        multiline
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel value={valueRelacionados} index={4} relacionados={true}>
                    <div className="regRelacionados">
                        <Nav.Link href="/ejr/engenheiro" target="_blank" className="atalhoRelacionados">
                            <Icon icon={ic_launch} size={20}/>
                            <strong>{'Engenheiros'}</strong> 
                        </Nav.Link>

                        <div className="manutReg Relacionados">
                            <ListRegistersDetailedFilter reload={props.reloadRelacionadosEngenheiro} filters={filtersRelacionadosEngenheiro} width={800} height={300} tab="RelacionadosEngenheiro" handleClickItem={selectRelacionadosEngenheiro} selected={propsTabRelacionadosEngenheiro.selected} items={props.dataRelacionadosEngenheiro} textField={'descricao'} handleAdd={handleAdd} />
                            <div className="register">
                                <div className="buttons">
                                    <Tooltip title="Salvar">
                                        <button onClick={(e) => {handleSave(e, 'RelacionadosEngenheiro')}} disabled={!editRelacionadosEngenheiro}>
                                            <Icon icon={ic_save} size={20}/>
                                        </button>
                                    </Tooltip>
                                    <Tooltip title={editRelacionadosEngenheiro ? 'Desfazer' : 'Editar'}>
                                        <button onClick={(e) => {handleEdit(e, 'RelacionadosEngenheiro')}} disabled={newRelacionadosEngenheiro}>
                                            <Icon icon={editRelacionadosEngenheiro ? ic_undo : ic_mode_edit} size={20}/>
                                        </button>
                                    </Tooltip>
                                    <Tooltip title="Excluir">
                                        <button onClick={(e) => {handleDelete(e, 'RelacionadosEngenheiro')}} disabled={(!(Number(propsTabRelacionadosEngenheiro.selected) > 0)) || (newRelacionadosEngenheiro)}>
                                            <Icon icon={ic_delete} size={20}/>
                                        </button>
                                    </Tooltip>
                                </div>
                                <div className="fields">
                                    <FrSelect 
                                        value={currentDataRelacionadosEngenheiro?.fk_pes_eng || []} 
                                        name="fk_pes_eng" 
                                        id="fk_pes_eng" 
                                        label='Engenheiro' 
                                        style={{ width: '100%' }}
                                        data={props.comboEngenheiros}
                                        onChange={handleChangeRelacionadosEngenheiro}
                                        disabled={!editRelacionadosEngenheiro}
                                    />
                                    <br/>
                                    <FrDatePicker 
                                        maxLength='10' 
                                        value={(currentDataRelacionadosEngenheiro?.data_admissao || '').split('T')[0]} 
                                        onChange={handleChangeRelacionadosEngenheiro} 
                                        name="data_admissao" 
                                        id="data_admissao" 
                                        color="#49573B" 
                                        label="Admissão" 
                                        variant="outlined" 
                                        size="small"
                                        disabled={!editRelacionadosEngenheiro}
                                        style={{width: 200}}
                                    />
                                    <br/>
                                    <FrDatePicker 
                                        maxLength='10' 
                                        value={(currentDataRelacionadosEngenheiro?.data_desligamento || '').split('T')[0]} 
                                        onChange={handleChangeRelacionadosEngenheiro} 
                                        name="data_desligamento" 
                                        id="data_desligamento" 
                                        color="#49573B" 
                                        label="Desligamento" 
                                        variant="outlined" 
                                        size="small"
                                        disabled={!editRelacionadosEngenheiro}
                                        style={{width: 200}}
                                    />
                                    <br/>
                                    <FrSelect 
                                        value={currentDataRelacionadosEngenheiro?.fk_sit || []} 
                                        style={{ width: 200 }} 
                                        name="fk_sit" 
                                        id="fk_sit" 
                                        label='Situação' 
                                        data={props.comboSituacaoEngenheiro}
                                        onChange={handleChangeRelacionadosEngenheiro}
                                        disabled={!editRelacionadosEngenheiro}
                                    />
                                    <br/>
                                    <FrTextInput
                                        maxLength='1000'
                                        value={currentDataRelacionadosEngenheiro?.observacoes || ''}
                                        onChange={handleChangeRelacionadosEngenheiro}
                                        name="observacoes"
                                        id="observacoes"
                                        color="#9c9595"
                                        label="Observações"
                                        variant="outlined"
                                        size="small"
                                        
                                        style={{ width: '100%' }}
                                        disabled={!editRelacionadosEngenheiro}
                                        rows={5}
                                        maxRows={5}
                                        multiline
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel value={valueRelacionados} index={5} relacionados={true}>
                    <div className="regRelacionados">
                        <Nav.Link href="/ejr/tecnico_agricola" target="_blank" className="atalhoRelacionados">
                            <Icon icon={ic_launch} size={20}/>
                            <strong>{'Tecnicos'}</strong> 
                        </Nav.Link>

                        <div className="manutReg Relacionados">
                            <ListRegistersDetailedFilter reload={props.reloadRelacionadosTecnico} filters={filtersRelacionadosTecnico} width={800} height={300} tab="RelacionadosTecnico" handleClickItem={selectRelacionadosTecnico} selected={propsTabRelacionadosTecnico.selected} items={props.dataRelacionadosTecnico} textField={'descricao'} handleAdd={handleAdd} />
                            <div className="register">
                                <div className="buttons">
                                    <Tooltip title="Salvar">
                                        <button onClick={(e) => {handleSave(e, 'RelacionadosTecnico')}} disabled={!editRelacionadosTecnico}>
                                            <Icon icon={ic_save} size={20}/>
                                        </button>
                                    </Tooltip>
                                    <Tooltip title={editRelacionadosTecnico ? 'Desfazer' : 'Editar'}>
                                        <button onClick={(e) => {handleEdit(e, 'RelacionadosTecnico')}} disabled={newRelacionadosTecnico}>
                                            <Icon icon={editRelacionadosTecnico ? ic_undo : ic_mode_edit} size={20}/>
                                        </button>
                                    </Tooltip>
                                    <Tooltip title="Excluir">
                                        <button onClick={(e) => {handleDelete(e, 'RelacionadosTecnico')}} disabled={(!(Number(propsTabRelacionadosTecnico.selected) > 0)) || (newRelacionadosTecnico)}>
                                            <Icon icon={ic_delete} size={20}/>
                                        </button>
                                    </Tooltip>
                                </div>
                                <div className="fields">
                                    <FrSelect 
                                        value={currentDataRelacionadosTecnico?.fk_pes_tag || []} 
                                        name="fk_pes_tag" 
                                        id="fk_pes_tag" 
                                        label='Tecnico' 
                                        style={{ width: '100%' }}
                                        data={props.comboTecnicos}
                                        onChange={handleChangeRelacionadosTecnico}
                                        disabled={!editRelacionadosTecnico}
                                    />
                                    <br/>
                                    <FrDatePicker 
                                        maxLength='10' 
                                        value={(currentDataRelacionadosTecnico?.data_admissao || '').split('T')[0]} 
                                        onChange={handleChangeRelacionadosTecnico} 
                                        name="data_admissao" 
                                        id="data_admissao" 
                                        color="#49573B" 
                                        label="Admissão" 
                                        variant="outlined" 
                                        size="small"
                                        disabled={!editRelacionadosTecnico}
                                        style={{width: 200}}
                                    />
                                    <br/>
                                    <FrDatePicker 
                                        maxLength='10' 
                                        value={(currentDataRelacionadosTecnico?.data_desligamento || '').split('T')[0]} 
                                        onChange={handleChangeRelacionadosTecnico} 
                                        name="data_desligamento" 
                                        id="data_desligamento" 
                                        color="#49573B" 
                                        label="Desligamento" 
                                        variant="outlined" 
                                        size="small"
                                        disabled={!editRelacionadosTecnico}
                                        style={{width: 200}}
                                    />
                                    <br/>
                                    <FrSelect 
                                        value={currentDataRelacionadosTecnico?.fk_sit || []} 
                                        style={{ width: 200 }} 
                                        name="fk_sit" 
                                        id="fk_sit" 
                                        label='Situação' 
                                        data={props.comboSituacaoTecnico}
                                        onChange={handleChangeRelacionadosTecnico}
                                        disabled={!editRelacionadosTecnico}
                                    />
                                    <br/>
                                    <FrTextInput
                                        maxLength='1000'
                                        value={currentDataRelacionadosTecnico?.observacoes || ''}
                                        onChange={handleChangeRelacionadosTecnico}
                                        name="observacoes"
                                        id="observacoes"
                                        color="#9c9595"
                                        label="Observações"
                                        variant="outlined"
                                        size="small"
                                        
                                        style={{ width: '100%' }}
                                        disabled={!editRelacionadosTecnico}
                                        rows={5}
                                        maxRows={5}
                                        multiline
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </TabPanel>   
                
            </div>
        </TabPanel>
        </div>
    );
}

export default TabsCliente;