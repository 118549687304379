import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { Icon } from 'react-icons-kit'
import { ic_add_box } from 'react-icons-kit/md/ic_add_box'
import {ic_search} from 'react-icons-kit/md/ic_search'
import {ic_delete} from 'react-icons-kit/md/ic_delete'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import NavBar from '../components/NavBar'
import FrTable from '../components/FrTable'
import FrTextInput from '../components/FrTextInput'
import FrSelect from '../components/FrSelect'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import FooterDelphus from '../components/FooterDelphus'


import api from '../services/api'
import { paramsToObject, formToObject, populateForm, objectToQueryString } from '../utils/functions'

import './styles/DefaultMainScreen.css';
import './styles/Manut.css';

const swal = withReactContent(Swal)

function Aeronave(props) {
  const [data, setData] = useState([])
  const [filter, setFilter] = useState({})
  const [filtered, setFiltered] = useState(false)
  const [enableAdd, setenableAdd] = useState('N')
  const [enableEdit, setenableEdit] = useState('N')
  const [enableDel, setenableDel] = useState('N')
  const [comboFabricante, setComboFabricante] = useState([{ value: '', display: '' }])
  const [comboModelo, setComboModelo] = useState([{ value: '', display: '' }])
  // const [blockModelo, setBlockModelo] = useState(true)
  const [comboCategoria, setComboCategoria] = useState([{ value: '', display: '' }])
  const [comboSituacao, setComboSituacao] = useState([{ value: '', display: '' }])
  const [comboClientes, setComboClientes] = useState([{ value: '', display: '' }])
 
  const columns = [
    {id: 'prefixo', numeric: false, label: 'Prefixo', align: 'left', minWidth: 300},
    {id: 'nr_serie', numeric: false, label: 'Nº Série', align: 'left', minWidth: 300},
  ]

  useEffect(() => {
    const fetchData = async () => {

      let tela = 'Aeronave'
      let consulta = await api.getAcessos(tela, 'Consulta')
      if (consulta !== 'S') {
        swal.fire('Usuário sem acesso', 'Usuário sem acesso a essa tela!', 'warning')
          .then((result) => {
            if (result.value) {
              window.history.back()
            }
          })
      } else {

        const urlParams = new URLSearchParams(window.location.search);
        let query = paramsToObject(urlParams)
        if(!query.buscar){
          query.buscar = 'true'
          query.ativo = 'S'  
  
        }
        
        // if (query.iframe) require('./styles/iframeReg.css')
  
        swal.fire({
          html: <FrLoading text="Carregando..."/>,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false
        })
        if (!props.frame) {
          setenableAdd(await api.getAcessos('Aeronave', 'Inclusao'))
          setenableEdit(await api.getAcessos('Aeronave', 'Alteracao'))
          setenableDel(await api.getAcessos('Aeronave', 'Exclusao'))
  
          setComboFabricante(await api.getComboData('aeronave_fabricante'))
          setComboModelo(await api.getComboData('aeronave_modelo', data.fk_afb))
          setComboCategoria(await api.getComboData('aeronave_categoria'))
          setComboSituacao(await api.getComboData('situacao_aeronave'))
          setComboClientes(await api.getComboData('cliente'))
  
          setFilter(query)
          populateForm(document.getElementById('searchForm'), query)
          if (query.buscar === 'true') {
            let event = new Event('build');
            getData(event)
          }
        }
        swal.close()
      }
      }

    
    fetchData()
  }, [])

  // React.useEffect(() => {
  //   const fetchModelos = async () => {
  //       if (data.fk_afb > 0) {
  //           swal.fire({
  //           html: <FrLoading text="Buscando Modelos..."/>,
  //           showConfirmButton: false,
  //           allowOutsideClick: false,
  //           allowEscapeKey: false
  //           })
  //           setComboModelo(await api.getComboData('aeronave_modelo', data.fk_afb))
  //           swal.close()
  //           setBlockModelo(false)
  //       } else {
  //           const auxValues = { ...data };
  //           auxValues.fk_amo = null;
  //           setData(auxValues)
  //           setBlockModelo(true)
  //       }
  //   }

  //   fetchModelos()
  // }, [props.data.fk_afb])

  function getData(e) {
    e.preventDefault()
    var formData = new FormData(document.getElementById('searchForm'))
    var queryObject = formToObject(formData)
    // if (!isEmpty(queryObject)) {
      if (true){
      queryObject.buscar = true
      setFiltered(true)
      if (!props.frame) {
        window.history.replaceState({ filtered: true }, 'filter', "/ejr/aeronave?" + objectToQueryString(queryObject));
      }
      swal.fire({
        html: <FrLoading text="Buscando dados..."/>,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      api.get('/aeronave', { params: queryObject }).then(r=> {
        setData(r.data)
        swal.close()
      })
    } else {
      swal.fire('Nenhum filtro informado!', 'Informe ao menos um filtro para realizar a busca.', 'warning')
    }
  }

  function clearForm(e) {
    e.preventDefault();
		console.log('limpa');
    window.history.replaceState({ filtered: false }, 'filter', '/ejr/aeronave');
    document.getElementById('searchForm').reset()
    setFilter({})
  }

  const handleChange = (e) => {
    const auxValues = { ...filter };
    console.log(auxValues)
    auxValues[e.target.name] = e.target.value;
    setFilter(auxValues);
  };

  return (
    <div className="Manut">
      {props.frame ? <div/> : <NavBar/>}
      <div className={"ManutContent"+(props.frame ? '-frame' : '')}>
        <div className="defaultHeader">
          <h3>Aeronaves</h3>
          {props.frame ? <div/> : <UserOptions/>}
        </div>
        <div className="filtersDiv">
          <form id="searchForm" onSubmit={getData}>
          <div>
            <FrTextInput 
              value={filter.prefixo || ''} 
              style={{width: 275}} 
              name="prefixo" 
              id="prefixo" 
              color="#9c9595" 
              label="Prefixo" 
              variant="outlined" 
              size="small"
              onChange={handleChange} 
              />
              
              <FrSelect 
              value={filter.ativo || []} 
              style={{width: 100}} 
              name="ativo" 
              id="ativo" 
              label='Ativo' 
              data={[{ display: 'Sim', value: 'S' }, { display: 'Não', value: 'N' }]}
              onChange={handleChange}
              />
            
              <FrSelect 
              value={filter.fk_afb || []} 
              style={{width: 125}} 
              name="fk_afb" 
              id="fk_afb" 
              label='Fabricante' 
              data={comboFabricante}
              onChange={handleChange}
              />
                
              <FrSelect 
              value={filter.fk_amo || []} 
              style={{width: 127}} 
              name="fk_amo" 
              id="fk_amo" 
              label='Modelo' 
              data={comboModelo}
              onChange={handleChange}
              // disabled={blockModelo}
              />
               
              <br/>
               <FrSelect 
              value={filter.fk_aca || []} 
              style={{width: 100}} 
              name="fk_aca" 
              id="fk_aca" 
              label='Categoria' 
              data={comboCategoria}
              onChange={handleChange}
              /> 
              
              <FrSelect 
              value={filter.acidentada || []} 
              style={{width: 110}} 
              name="acidentada" 
              id="acidentada" 
              label='Acidentada' 
              data={[{ display: 'Sim', value: 'S' }, { display: 'Não', value: 'N' }]}
              onChange={handleChange}
              />
              
              <FrSelect 
              value={filter.nao_aeronavega || []} 
              style={{width: 159}} 
              name="nao_aeronavega" 
              id="nao_aeronavega" 
              label='Aeronavegável' 
              data={[{ display: 'Aeronavegável', value: 'N' }, { display: 'Não-aeronavegável', value: 'S' }]}
              onChange={handleChange}
              />
            
              <FrSelect 
              value={filter.fk_sae || []} 
              style={{width: 125}} 
              name="fk_sae" 
              id="fk_sae" 
              label='Situação' 
              data={comboSituacao}
              onChange={handleChange}
              />
              
              <FrSelect 
              value={filter.fk_pes_cli || []} 
              style={{width: 127}} 
              name="fk_pes_cli" 
              id="fk_pes_cli" 
              label='Cliente' 
              data={comboClientes}
              onChange={handleChange}
            /> 
             
            </div>
            <div>
              <button onClick={getData}>
                <Icon icon={ic_search} size={18}/>
                Pesquisar
              </button>
              <button onClick={clearForm}>
                <Icon icon={ic_delete} size={18}/>
                Limpar
              </button>
              {(enableAdd !== 'S') ? '' :
                <Link to="/ejr/aeronave/registro">
                  <button >
                    <Icon icon={ic_add_box} size={18} />
                  Novo Registro
                </button>
                </Link>}
            </div>
          </form>
        </div>
        <br/>
        <div className={"tableDiv"+(props.frame && data.length>0 ? '-frame' : '')}>
          <FrTable regName="aeronave" frame={props.frame} columns={columns} searched={filtered} codeName="pk_aer" page={Aeronave} data={data} enableEdit={enableEdit} enableDel={enableDel}/>
        </div>
        {props.frame ? (<div>

        </div>) : <FooterDelphus/>}
        
      </div>
    </div>
  );
}

export default Aeronave;
