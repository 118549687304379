import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { Icon } from 'react-icons-kit'
import { ic_add_box } from 'react-icons-kit/md/ic_add_box'
import {ic_search} from 'react-icons-kit/md/ic_search'
import {ic_delete} from 'react-icons-kit/md/ic_delete'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import NavBar from '../components/NavBar'
import FrTable from '../components/FrTable'
import FrTextInput from '../components/FrTextInput'
import FrSelect from '../components/FrSelect'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import FooterDelphus from '../components/FooterDelphus'


import api from '../services/api'
import { paramsToObject, formToObject, populateForm, objectToQueryString } from '../utils/functions'

import './styles/DefaultMainScreen.css';
import './styles/Manut.css';

const swal = withReactContent(Swal)

function Tecnico_Agricola(props) {
  const [data, setData] = useState([])
  const [comboCidades, setComboCidades] = useState([{value: '', display: ''}]);
  const [comboClientes, setComboClientes] = useState([{value: '', display: ''}]);
  const [comboTipoDoc, setComboTipoDoc] = useState([{value: '', display: ''}]);
  const [comboModeloDoc, setComboModeloDoc] = useState([{value: '', display: ''}]);
  const [filter, setFilter] = useState({})
  const [filtered, setFiltered] = useState(false)
  const [enableAdd, setenableAdd] = useState('N')
  const [enableEdit, setenableEdit] = useState('N')
  const [enableDel, setenableDel] = useState('N')

 
  const columns = [
    {id: 'razao_social', numeric: false, label: 'Razão Social', align: 'left', minWidth: 300},
  ]

  useEffect(() => {
    const fetchData = async () => {
      let tela = 'Tecnico_agricola'
      let consulta = await api.getAcessos(tela, 'Consulta')
      if (consulta !== 'S') {
        return swal.fire('Usuário sem acesso', 'Usuário sem acesso a essa tela!', 'warning')
          .then((result) => {
            if (result.value) {
              window.history.back()
            }
          })
      }
      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)
      // if (query.iframe) require('./styles/iframeReg.css')
      if(!query.buscar){
        query.buscar = 'true'
        query.ativo = 'S'  

      }
      console.log(urlParams)
      setComboCidades(await api.getComboData('cidades'))
      setComboClientes(await api.getComboData('cliente'));
      setComboTipoDoc(await api.getComboData('tipo_documento'));
      setComboModeloDoc(await api.getComboData('modelo_documento'));

      swal.fire({
        html: <FrLoading text="Carregando..."/>,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })

      if (!props.frame) {
        setenableAdd(await api.getAcessos('Tecnico_agricola', 'Inclusao'))
        setenableEdit(await api.getAcessos('Tecnico_agricola', 'Alteracao'))
        setenableDel(await api.getAcessos('Tecnico_agricola', 'Exclusao'))
        setFilter(query)
        populateForm(document.getElementById('searchForm'), query)
        if (query.buscar === 'true') {
          let event = new Event('build');
          getData(event)
        }
      }
      swal.close()
    }
    
    fetchData()
  }, [])

  function getData(e) {
    e.preventDefault()
    var formData = new FormData(document.getElementById('searchForm'))
    var queryObject = formToObject(formData)
    // if (!isEmpty(queryObject)) {
      if (true){
      queryObject.buscar = true
      setFiltered(true)
      if (!props.frame) {
        window.history.replaceState({ filtered: true }, 'filter', "/ejr/tecnico_agricola?" + objectToQueryString(queryObject));
      }
      swal.fire({
        html: <FrLoading text="Buscando dados..."/>,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      api.get('/tecnico_agricola', { params: queryObject }).then(r=> {
        setData(r.data)
        console.log(r.data)
        console.log(queryObject);
        swal.close()
      })
    } else {
      swal.fire('Nenhum filtro informado!', 'Informe ao menos um filtro para realizar a busca.', 'warning')
    }
  }

  function clearForm(e) {
    e.preventDefault();
		console.log('limpa');
    window.history.replaceState({ filtered: false }, 'filter', '/ejr/tecnico_agricola');
    document.getElementById('searchForm').reset()
    setFilter({})
  }

  const handleChange = (e) => {
    const auxValues = { ...filter };
    auxValues[e.target.name] = e.target.value;
    console.log(auxValues)
    setFilter(auxValues);
  };

  return (
    <div className="Manut">
      {props.frame ? <div/> : <NavBar/>}
      <div className={"ManutContent"+(props.frame ? '-frame' : '')}>
        <div className="defaultHeader">
          <h3>Técnico Agrícola</h3>
          {props.frame ? <div/> : <UserOptions/>}
        </div>
        <div className="filtersDiv">
          <form id="searchForm" onSubmit={getData}>
          <div>
            <FrTextInput 
              value={filter.razao_social || ''} 
              style={{width: 330}} 
              name="razao_social" 
              id="razao_social" 
              color="#9c9595" 
              label="Nome" 
              variant="outlined" 
              size="small"
              onChange={handleChange} 
            />
            <FrTextInput 
              value={filter.cpf || ''} 
              style={{width: 150}} 
              name="cpf" 
              id="cpf" 
              color="#9c9595" 
              label="CPF" 
              variant="outlined" 
              size="small"
              onChange={handleChange} 
            />
            <FrSelect 
              value={filter.fk_pes_cli2 || []} 
              style={{width: 300}} 
              name="fk_pes_cli2" 
              id="fk_pes_cli2" 
              label='Cliente' 
              data={comboClientes}
              onChange={handleChange}
            />
            <FrSelect 
              value={filter.cidade || []} 
              style={{width: 250}} 
              name="cidade" 
              id="cidade" 
              label='Cidade' 
              data={comboCidades}
              onChange={handleChange}
            />
            <FrTextInput 
              value={filter.cfta || ''} 
              style={{width: 100}} 
              name="cfta" 
              id="cfta" 
              color="#9c9595" 
              label="CFTA" 
              variant="outlined" 
              size="small"
              onChange={handleChange} 
            />
            <FrSelect 
              value={filter.modelo_doc || []} 
              style={{width: 200}} 
              name="modelo_doc" 
              id="modelo_doc" 
              label='Modelo de Documento.' 
              data={comboModeloDoc}
              onChange={handleChange}
            />
            <FrSelect 
              value={filter.fk_tdo || []} 
              style={{width: 200}} 
              name="fk_tdo" 
              id="fk_tdo" 
              label='Tipo de Documento' 
              data={comboTipoDoc}
              onChange={handleChange}
            />
  
            <FrSelect 
              value={filter.ativo || []} 
              style={{width: 100}} 
              name="ativo" 
              id="ativo" 
              label='Ativo' 
              data={[{ display: 'Sim', value: 'S' }, { display: 'Não', value: 'N' }]}
              onChange={handleChange}
            />
            
            </div>
            <div>
              <button onClick={getData}>
                <Icon icon={ic_search} size={18}/>
                Pesquisar
              </button>
              <button onClick={clearForm}>
                <Icon icon={ic_delete} size={18}/>
                Limpar
              </button>
              {(enableAdd !== 'S') ? '' :
                <Link to="/ejr/tecnico_agricola/registro">
                  <button >
                    <Icon icon={ic_add_box} size={18} />
                  Novo Registro
                </button>
                </Link>}
            </div>
          </form>
        </div>
        <br/>
        <div className={"tableDiv"+(props.frame && data.length>0 ? '-frame' : '')}>
          <FrTable regName="tecnico_agricola" frame={props.frame} columns={columns} searched={filtered} codeName="pk_pes_tag" page={Tecnico_Agricola} data={data} enableEdit={enableEdit} enableDel={enableDel}/>
        </div>
        {props.frame ? (<div>

        </div>) : <FooterDelphus/>}
        
      </div>
    </div>
  );
}

export default Tecnico_Agricola;