import React, { useState } from 'react';
import Swal from 'sweetalert2'
import Nav from 'react-bootstrap/Nav'
import withReactContent from 'sweetalert2-react-content'
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import FrTextInput from '../FrTextInput'
import FrImageEdit from '../FrImageEdit'
import FrDatePicker from '../FrDatePicker'
import FrSelect from '../FrSelect'
import FrLoading from '../FrLoading'
import ListRegisters from '../ListRegisters'
import ListRegistersDetailedFilter from '../ListRegistersDetailedFilter'
import { Icon } from 'react-icons-kit'
import {ic_save} from 'react-icons-kit/md/ic_save'
import {ic_mode_edit} from 'react-icons-kit/md/ic_mode_edit'
import {ic_delete} from 'react-icons-kit/md/ic_delete'
import {ic_undo} from 'react-icons-kit/md/ic_undo'
import {ic_launch} from 'react-icons-kit/md/ic_launch'
import Tooltip from '@material-ui/core/Tooltip';
import { FiUpload } from "react-icons/fi";
import { FiDownload } from 'react-icons/fi';
import download from 'downloadjs'

import consultaCep from '../../services/consultaCep'
import { paramsToObject, validarcnpj, onlyNumbers, removeSpecialChar } from '../../utils/functions'
import { mcnpj, mTel, mCEP } from '../../utils/masks'

import api from '../../services/api'

import './index.css';

const swal = withReactContent(Swal)

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
  
function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
}
  
const useStyles = makeStyles((theme) => ({
    root: {
      '& .MuiTabs-root': {
        backgroundColor: '#9c9595',
        '& .Mui-disabled': {
            color: '#9c9595', 
            }
      },
      flexGrow: 1,
      width: '100%',
      border: '2px solid #9c9595',
      borderRadius: 4,
      '& .MuiTabs-indicator': {
        backgroundColor: 'white',
        height: '5px'
      },
      '& .MuiTabs-scrollButtonsDesktop': {
        backgroundColor: '#9c9595',
      }
    //   backgroundColor: theme.palette.background.paper,
    },
}));

function TabsTecnico_agricola(props) {
    const classes = useStyles();

    //Tabs
    const [value, setValue] = React.useState(0);
    const handleChangeTabs = (event, newValue) => {
        setValue(newValue);
    };

    //Dados
    let data = props.data
    const setData = props.setData
    const handleChange = props.handleChange
    const handleCpf = props.handleCpf
    const [erroCep, setErroCep] = useState(false)
    const [erroCepOperacional, setErroCepOperacional] = useState(false)

    function handleCep(e) {
        e.preventDefault();
        if (e.target.value) {
          if (e.target.value) {
            consultaCep.get('/' + onlyNumbers(e.target.value) + '/json').then(r => {
              console.log(r)
              if (r.data.erro) {
                setErroCep(true)
                swal.fire('CEP inválido', 'Informe um CEP válido!', 'warning')
              } else {
                setErroCep(false)
                const auxValues = { ...data };
                auxValues['logradouro'] = r.data.logradouro;
                auxValues['bairro'] = r.data.bairro;
                let cidade = props.comboCidades.find(item => String(item.display).normalize('NFD').replace(/[\u0300-\u036f]/g, "") === String(r.data.localidade).toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ""))
                if (cidade) auxValues['fk_cid'] = cidade.value
                else alert('Cidade não cadastrada no sistema!')
                setData(auxValues)
              }
            }).catch(err => {
              setErroCep(true)
              swal.fire('CEP inválido', 'Informe um CEP válido!', 'warning')
            })
          }
        }
      }
    
    function handleCepOperacional(e) {
        e.preventDefault();
        if (e.target.value) {
            if (e.target.value) {
            consultaCep.get('/' + onlyNumbers(e.target.value) + '/json').then(r => {
                console.log(r)
                if (r.data.erro) {
                    setErroCepOperacional(true)
                    swal.fire('CEP inválido', 'Informe um CEP válido!', 'warning')
                } else {
                    setErroCepOperacional(false)
                    const auxValues = { ...data };
                    auxValues['logradouro_operacional'] = r.data.logradouro;
                    auxValues['bairro_operacional'] = r.data.bairro;
                    setData(auxValues)
                }
            }).catch(err => {
                setErroCepOperacional(true)
                swal.fire('CEP inválido', 'Informe um CEP válido!', 'warning')
            })
            }
        }
    }

    //Documentos
    const [currentDataDocumentos, setCurrentDataDocumentos] = React.useState(null)
    const [propsTabDocumentos, setPropsTableDocumentos] = React.useState({
        selected: null,
    })
    const [editDocumentos, setEditDocumentos] = React.useState(false)
    const [newDocumento, setNewDocumento] = React.useState(false)
    const [blockTipoDocumento, setBlockTipoDocumento] = React.useState(true)
    const [selectedTdo, setSelectedTdo] = React.useState({})

    const selectDocumento = (event, codigo) => {
        setEditDocumentos(false)
        setNewDocumento(false)
        setPropsTableDocumentos({... propsTabDocumentos, selected: codigo })
    }

    React.useEffect(() => {
        setCurrentDataDocumentos(props.dataDocumentos.find((item) => item.codigo === propsTabDocumentos.selected))
    }, [propsTabDocumentos.selected])

    React.useEffect(() => {
        
    }, [props.dataDocumentos])

    const handleChangeDocumentos = async (e) => {
        const auxValues = { ...currentDataDocumentos };
        
       
        switch (e.target.name) {     
            case 'arquivo':     
            let ext = e.target.files[0].type
            if( e.target.files[0].size > 2097152){
                swal.fire({
                    title: 'Erro!',
                    text: "Limite de upload - 2 Mb!",
                    icon: 'warning',
                });
                return false
                
            }else if(!(ext == 'application/pdf' || ext == 'image/jpeg')){
                    swal.fire({
                        title: 'Arquivo inválido!',
                        text: "Extensões permitidas: pdf, jpg ou jpeg",
                        icon: 'warning',
                    });
                    return false
                }
                
                else {
                    uploadArquivo(e.target.value, e);
                    auxValues[e.target.name] = e.target.value ;
                }
                break;
            default: auxValues[e.target.name] = e.target.value;
        }
        console.log(auxValues['arquivo'])
        console.log(e.target.value)
        
        setCurrentDataDocumentos(auxValues);
    }

    
    const uploadArquivo = (arquivo, e) => {
        console.log(arquivo)

        if( e.target.files[0].size > 2097152){
            swal.fire({
                title: 'Erro!',
                text: "O arquivo não ter mais de 2MB!",
                icon: 'warning',
            });
            return false
            
         };
        swal.fire({html: <FrLoading text="Atualizando Arquivo..."/>,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false})
        let file = e.target.files[0];
        let formData = new FormData();
        console.log(file)
        formData.append('file', file, file.name)
        api.post('tecnico_agricola/postArquivo/', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        }).then(r=>{
            console.log(r.data)
            if(r.data == 'File Changed!'){
                swal.fire({
                    title: 'Arquivo Importado',
                    text: "Arquivo alterado com sucesso!",
                    icon: 'success',
                })
                return true
            } else {
                swal.fire({
                    title: 'Erro!',
                    text: "O arquivo não foi atualizado!",
                    icon: 'warning',
                })
                return false
            }
        })
    }

    const handleArquivo = (e) => {
        if(editDocumentos){
            document.getElementById('arquivo').click()
        }
       
    }

    const handleDownload = (e,  arquivo) =>{
        e.preventDefault()
        api.post('tecnico_agricola/downloadArquivo?&arquivo='+currentDataDocumentos?.arquivo, {}, {responseType: 'blob'}).then((response) => {
            if(response.data.type == 'application/json'){
                swal.fire({
                    title: 'Arquivo Não Encontrado',
                    text: "Arquivo não foi encontrado no servidor!",
                    icon: 'error',
                })
              }  else {
                console.log(response, arquivo)
                let name = response.data.type == 'application/pdf' ? 'download.pdf' : 'download.jpg' 
                let type = ( response.data.type == 'application/pdf' ?'applicaton/pdf': 'application/jpeg')
                console.log(type)
                  var blob = new Blob([response.data], {
                    type: type
                  });
                  download(blob, name)
              }
        })
      }

    React.useEffect(() => {
        const fetchModelos = async () => {
            if (currentDataDocumentos?.fk_mdl > 0) {
                swal.fire({
                    html: <FrLoading text="Buscando Tipos de Documento..."/>,
                    showConfirmButton: false,
                    allowOutsideClick: false,
                    allowEscapeKey: false
                })
                let comboTdo = await api.getTipoDocumento(currentDataDocumentos?.fk_mdl)
                props.setComboTipoDocumento(comboTdo)
                if (Number(currentDataDocumentos?.fk_tdo) > 0) {
                    let auxTdo = comboTdo.find((item) => Number(item.value) === Number(currentDataDocumentos?.fk_tdo))
                    setSelectedTdo(auxTdo)
                    console.log(currentDataDocumentos?.fk_tdo, auxTdo)
                }
                swal.close()
                setBlockTipoDocumento(false)
            } else {
                props.setComboTipoDocumento([{ value: '', display: '' }])
                setBlockTipoDocumento(true)
            }
        }
    
        fetchModelos()
        
    }, [currentDataDocumentos?.fk_mdl])

    React.useEffect(() => {
        let auxTdo = props.comboTipoDocumento.find((item) => Number(item.value) === Number(currentDataDocumentos?.fk_tdo))
        setSelectedTdo(auxTdo)
        console.log(currentDataDocumentos?.fk_tdo, auxTdo)
    }, [currentDataDocumentos?.fk_tdo])

    const saveDocumentos = () => {
        const auxValues = { ...currentDataDocumentos };
        auxValues.fk_pes_tag = props.pk_pes_tag
        auxValues.dt_emissao = auxValues.dt_emissao ? auxValues.dt_emissao.split('T')[0] : null
        auxValues.dt_validade = auxValues.dt_validade ? auxValues.dt_validade.split('T')[0] : null
        console.log(auxValues)
        if (newDocumento) {
            api.post('/tecnico_agricola/documentos/add', auxValues).then(r => {
                if (r.status === 200) {
                    swal.fire({
                        title: 'Registro Adicionado',
                        text: "O registro foi incluído com sucesso!",
                        icon: 'success',
                    }).then((result) => {
                        if (result.value) {
                            setPropsTableDocumentos({... propsTabDocumentos, selected: null })
                            setCurrentDataDocumentos({})
                            setEditDocumentos(false)
                            setNewDocumento(false)
                            props.reloadDocumentos()
                        }
                    })
                } else {
                    swal.fire({
                        title: 'Erro!',
                        text: "O registro não foi alterado!",
                        icon: 'warning',
                    })
                }
            })
        } else {
            api.post('/tecnico_agricola/documentos/edit', auxValues).then(r => {
                if (r.status === 200) {
                    swal.fire({
                        title: 'Registro Alteraddo',
                        text: "O registro foi alterado com sucesso!",
                        icon: 'success',
                    }).then((result) => {
                        if (result.value) {
                            setPropsTableDocumentos({... propsTabDocumentos, selected: null })
                            setCurrentDataDocumentos({})
                            setEditDocumentos(false)
                            setNewDocumento(false)
                            props.reloadDocumentos()
                        }
                    })
                } else {
                    swal.fire({
                        title: 'Erro!',
                        text: "O registro não foi alterado!",
                        icon: 'warning',
                    })
                }
            })
        }
    }

    const deleteDocumentos = () => {
        const auxValues = { ...currentDataDocumentos };
        swal.fire({
            title: 'ATENÇÃO',
            text: 'Deseja excluir o registro?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                api.post('/tecnico_agricola/documentos/delete/'+auxValues.pk_tad).then(r => {
                    if (r.status === 200) {
                        swal.fire({
                            title: 'Registro Excluído',
                            text: "O registro foi excluído com sucesso!",
                            icon: 'success',
                        }).then((result) => {
                            if (result.value) {
                                setPropsTableDocumentos({... propsTabDocumentos, selected: null })
                                setCurrentDataDocumentos({})
                                setEditDocumentos(false)
                                setNewDocumento(false)
                                props.reloadDocumentos()
                            }
                        })
                    } else {
                        swal.fire({
                            title: 'Erro!',
                            text: "O registro não foi alterado!",
                            icon: 'warning',
                        })
                    }
                })
            }
        })
    }

    //RelacionadosTecnico_agricola
    const [currentDataRelacionadosTecnico_agricola, setCurrentDataRelacionadosTecnico_agricola] = React.useState(null)
    const [propsTabRelacionadosTecnico_agricola, setPropsTabRelacionadosTecnico_agricola] = React.useState({
        selected: null,
    })
    const [editRelacionadosTecnico_agricola, setEditRelacionadosTecnico_agricola] = React.useState(false)
    const [newRelacionadosTecnico_agricola, setNewRelacionadosTecnico_agricola] = React.useState(false)
    const filtersRelacionadosTecnico_agricola = [
        { type: 'text', field: 'descricao', name: 'Empresa' },
        { type: 'period', field: 'data_admissao', name: 'Data Admissão' },
        { type: 'period', field: 'data_desligamento', name: 'Data Desligamento'},
        { type: 'select', field: 'fk_sit', name: 'Situacao', data: props.comboSituacaoGeral}
    ]

    const selectRelacionadosTecnico_agricola = (event, codigo) => {
        setEditRelacionadosTecnico_agricola(false)
        setNewRelacionadosTecnico_agricola(false)
        setPropsTabRelacionadosTecnico_agricola({... propsTabRelacionadosTecnico_agricola, selected: codigo })
    }

    React.useEffect(() => {
        setCurrentDataRelacionadosTecnico_agricola(props.dataRelacionadosTecnico_agricola.find((item) => item.codigo === propsTabRelacionadosTecnico_agricola.selected))
    }, [propsTabRelacionadosTecnico_agricola.selected])

    React.useEffect(() => {
    
    }, [props.dataRelacionadosTecnico_agricola])

    const handleChangeRelacionadosTecnico_agricola = (e) => {
        const auxValues = { ...currentDataRelacionadosTecnico_agricola };
        switch (e.target.name) {
            case 'CNPJ':
            default: auxValues[e.target.name] = e.target.value;
        }
        setCurrentDataRelacionadosTecnico_agricola(auxValues);
    }

    const saveRelacionadosTecnico_agricola = () => {
        const auxValues = { ...currentDataRelacionadosTecnico_agricola };
        auxValues.fk_pes_tag = props.pk_pes_tag
        auxValues.data_admissao = auxValues.data_admissao ? auxValues.data_admissao.split('T')[0] : null
        auxValues.data_desligamento = auxValues.data_desligamento ? auxValues.data_desligamento.split('T')[0] : null
        console.log(auxValues)
        if (newRelacionadosTecnico_agricola) {
            api.post('/cliente/tecnico_agricola/add', auxValues).then(r => {
                if (r.status === 200) {
                    swal.fire({
                        title: 'Registro Adicionado',
                        text: "O registro foi incluído com sucesso!",
                        icon: 'success',
                    }).then((result) => {
                        if (result.value) {
                            setPropsTabRelacionadosTecnico_agricola({... propsTabRelacionadosTecnico_agricola, selected: null })
                            setCurrentDataRelacionadosTecnico_agricola({})
                            setEditRelacionadosTecnico_agricola(false)
                            setNewRelacionadosTecnico_agricola(false)
                            props.reloadRelacionadosTecnico_agricola()
                        }
                    })
                } else {
                    swal.fire({
                        title: 'Erro!',
                        text: "O registro não foi alterado!",
                        icon: 'warning',
                    })
                }
            })
        } else {
            api.post('/cliente/tecnico_agricola/edit', auxValues).then(r => {
                if (r.status === 200) {
                    swal.fire({
                        title: 'Registro Alteraddo',
                        text: "O registro foi alterado com sucesso!",
                        icon: 'success',
                    }).then((result) => {
                        if (result.value) {
                            setPropsTabRelacionadosTecnico_agricola({... propsTabRelacionadosTecnico_agricola, selected: null })
                            setCurrentDataRelacionadosTecnico_agricola({})
                            setEditRelacionadosTecnico_agricola(false)
                            setNewRelacionadosTecnico_agricola(false)
                            props.reloadRelacionadosTecnico_agricola()
                        }
                    })
                } else {
                    swal.fire({
                        title: 'Erro!',
                        text: "O registro não foi alterado!",
                        icon: 'warning',
                    })
                }
            })
        }
    }

    const deleteRelacionadosTecnico_agricola = () => {
        const auxValues = { ...currentDataRelacionadosTecnico_agricola };
        swal.fire({
            title: 'ATENÇÃO',
            text: 'Deseja excluir o registro?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                api.post('/cliente/tecnico_agricola/delete/'+auxValues.pk_cta).then(r => {
                    if (r.status === 200) {
                        swal.fire({
                            title: 'Registro Excluído',
                            text: "O registro foi excluído com sucesso!",
                            icon: 'success',
                        }).then((result) => {
                            if (result.value) {
                                setPropsTabRelacionadosTecnico_agricola({... propsTabRelacionadosTecnico_agricola, selected: null })
                                setCurrentDataRelacionadosTecnico_agricola({})
                                setEditRelacionadosTecnico_agricola(false)
                                setNewRelacionadosTecnico_agricola(false)
                                props.reloadRelacionadosTecnico_agricola()
                            }
                        })
                    } else {
                        swal.fire({
                            title: 'Erro!',
                            text: "O registro não foi alterado!",
                            icon: 'warning',
                        })
                    }
                })
            }
        })
    }
 
    //Edit Tabs
    const handleEdit = (e, tab) => {
        e.preventDefault()
        switch (tab) {
            case 'Documentos':
                setEditDocumentos(!editDocumentos)
            break

            case 'RelacionadosTecnico_agricola':
                setEditRelacionadosTecnico_agricola(!editRelacionadosTecnico_agricola)
            break
        }
    }

    //Add Tabs
    const handleAdd = (tab) => {
        switch (tab) {
            case 'Documentos':
                setEditDocumentos(true)
                setCurrentDataDocumentos({})
                setPropsTableDocumentos({... propsTabDocumentos, selected: null })
                setNewDocumento(true)
            break            

            
            case 'RelacionadosTecnico_agricola':
                setEditRelacionadosTecnico_agricola(true)
                setCurrentDataRelacionadosTecnico_agricola({})
                setPropsTabRelacionadosTecnico_agricola({... propsTabRelacionadosTecnico_agricola, selected: null })
                setNewRelacionadosTecnico_agricola(true)
            break   
        }
    }

    //Save Tabs
    const handleSave = (e, tab) => {
        e.preventDefault()
        switch (tab) {
            case 'Documentos': 
                saveDocumentos()
            break

            case 'RelacionadosTecnico_agricola':
                saveRelacionadosTecnico_agricola()
            break
        }
    }

    //Delete Tabs
    const handleDelete = (e, tab) => {
        e.preventDefault()
        switch (tab) {
            case 'Documentos': 
                deleteDocumentos()
            break

            case 'RelacionadosTecnico_agricola': 
                deleteRelacionadosTecnico_agricola()
            break
        }
    }



    
    return (
        <div className={classes.root}>
        <AppBar position="static">
            <Tabs
                value={value}
                onChange={handleChangeTabs}
                indicatorColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
            >
                <Tab label="Dados" {...a11yProps(0)} />
                <Tab label="Documentos" disabled={!props.updateType} {...a11yProps(1)} />
                <Tab label="Relacionados" disabled={!props.updateType} {...a11yProps(2)} />
            </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
            <div className="flexImageFields">
                <FrImageEdit image={data.foto} title="Foto" onImageChange={props.handleImageChange} visible={true} regName={'tecnico_agricola'} pk={props.pk_pes_tag ? props.pk_pes_tag : 999999} />
                <div>
                    <FrTextInput 
                        error={props.erroCpf} 
                        maxLength='18'
                        value={data.cpf || ''} 
                        onChange={handleChange} 
                        onBlur={handleCpf}  
                        name="cpf" 
                        id="cpf" 
                        color="#49573B" 
                        label="CPF" 
                        variant="outlined" 
                        size="small"
                        required
                        style={{width: 180}}
                    />
                    <FrTextInput
                        maxLength='150'
                        value={data.razao_social || ''} 
                        onChange={handleChange}  
                        name="razao_social" 
                        id="razao_social" 
                        color="#49573B" 
                        label="Nome" 
                        variant="outlined" 
                        size="small"
                        required
                        style={{width: 500}}
                    />
                    <FrTextInput 
                        maxLength='10' 
                        value={data.rg || ''} 
                        onChange={handleChange} 
                        name="rg" 
                        id="rg" 
                        color="#49573B" 
                        label="RG" 
                        variant="outlined" 
                        size="small"
                        required
                        style={{width: 150}}
                    />
                    <FrDatePicker
                        maxLength='50'
                        value={(data.data_nasc || '').split('T')[0]}
                        onChange={handleChange}
                        name="data_nasc"
                        id="data_nasc"
                        color="#9c9595"
                        label="Data de Nascimento"
                        variant="outlined"
                        size="small"
                        required
                        style={{ width: 200 }}
                    />
                     <FrSelect 
                        value={data.fk_cid_natural || []} 
                        style={{ width: 300 }} 
                        name="fk_cid_natural" 
                        id="fk_cid_natural" 
                        label='Naturalidade' 
                        data={props.comboCidades}
                        onChange={handleChange}
                    />
                    <br/>
                    <FrTextInput
                        maxLength='10'
                        value={data.cep || ''}
                        error={erroCep}
                        onChange={handleChange}
                        onBlur={handleCep}
                        name="cep"
                        id="cep"
                        color="#9c9595"
                        label="CEP"
                        variant="outlined"
                        size="small"
                        style={{ width: 130 }}
                        required
                    />
                    <FrTextInput
                        maxLength='50'
                        value={data.logradouro || ''}
                        onChange={handleChange}
                        name="logradouro"
                        id="logradouro"
                        color="#9c9595"
                        label="Endereço"
                        variant="outlined"
                        size="small"
                        required
                        style={{ width: 400 }}
                    />
                    <FrTextInput
                        maxLength='50'
                        value={data.numero || ''}
                        onChange={handleChange}
                        name="numero"
                        id="numero"
                        color="#9c9595"
                        label="Número"
                        variant="outlined"
                        size="small"
                        required
                        style={{ width: 100 }}
                    />
                    <FrTextInput
                        maxLength='50'
                        value={data.bairro || ''}
                        onChange={handleChange}
                        name="bairro"
                        id="bairro"
                        color="#9c9595"
                        label="Bairro"
                        variant="outlined"
                        size="small"
                        required
                        style={{ width: 250 }}
                    />
                    <FrTextInput
                        maxLength='50'
                        value={data.complemento || ''}
                        onChange={handleChange}
                        name="complemento"
                        id="complemento"
                        color="#9c9595"
                        label="Complemento"
                        variant="outlined"
                        size="small"
                        required
                        style={{ width: 330 }}
                    />
                    <FrSelect 
                        value={data.fk_cid || []} 
                        style={{ width: 300 }} 
                        name="fk_cid" 
                        id="fk_cid" 
                        label='Cidade' 
                        data={props.comboCidades}
                        onChange={handleChange}
                    />
                    <br/>
                    <FrTextInput
                        maxLength='50'
                        value={data.fone1 || ''}
                        onChange={handleChange}
                        name="fone1"
                        id="fone1"
                        color="#9c9595"
                        label="Telefone"
                        variant="outlined"
                        size="small"
                        required
                        style={{ width: 150 }}
                    />
                    <FrTextInput
                        maxLength='50'
                        value={data.fone2 || ''}
                        onChange={handleChange}
                        name="fone2"
                        id="fone2"
                        color="#9c9595"
                        label="Telefone"
                        variant="outlined"
                        size="small"
                        required
                        style={{ width: 150 }}
                    />
                    <FrTextInput
                        maxLength='50'
                        value={data.celular || ''}
                        onChange={handleChange}
                        name="celular"
                        id="celular"
                        color="#9c9595"
                        label="Celular"
                        variant="outlined"
                        size="small"
                        required
                        style={{ width: 150 }}
                    />
                    <FrTextInput
                        maxLength='50'
                        value={data.email || ''}
                        onChange={handleChange}
                        name="email"
                        id="email"
                        color="#9c9595"
                        label="Email"
                        variant="outlined"
                        size="small"
                        required
                        style={{ width: 350 }}
                    />
                    <br/>
                    <br/>
                  
                    
                  
                    <br/>
                    <FrTextInput
                        maxLength='50'
                        value={data.cfta || ''}
                        onChange={handleChange}
                        name="cfta"
                        id="cfta"
                        color="#9c9595"
                        label="CFTA"
                        variant="outlined"
                        size="small"
                        required
                        style={{ width: 200 }}
                    />
                    <FrDatePicker
                        maxLength='50'
                        value={(data.validade_crea || '').split('T')[0]}
                        onChange={handleChange}
                        name="validade_crea"
                        id="validade_crea"
                        color="#9c9595"
                        label="Validade"
                        variant="outlined"
                        size="small"
                        required
                        style={{ width: 200 }}
                    />

                    <FrSelect 
                        value={data.fk_sit || []} 
                        style={{ width: 150 }} 
                        name="fk_sit" 
                        id="fk_sit" 
                        label='Situação' 
                        data={props.comboSituacaoTecnico_agricola}
                        onChange={handleChange}
                    />

                    <br/>
                    <FrTextInput
                        maxLength='1000'
                        value={data.observacoes || ''}
                        onChange={handleChange}
                        name="observacoes"
                        id="observacoes"
                        color="#9c9595"
                        label="Observações"
                        variant="outlined"
                        size="small"
                        required
                        style={{ width: '100%' }}
                        rows={5}
                        maxRows={5}
                        multiline
                    />
                </div>
            </div>

        </TabPanel>
        <TabPanel value={value} index={1}>
            <div className="manutReg">
                <ListRegisters width={800} height={300} tab="Documentos" handleClickItem={selectDocumento} selected={propsTabDocumentos.selected} items={props.dataDocumentos} textField={'nomedoc'} handleAdd={handleAdd} />
                <div className="register">
                    <div className="buttons">
                        <Tooltip title="Salvar">
                            <button onClick={(e) => {handleSave(e, 'Documentos')}} disabled={!editDocumentos}>
                                <Icon icon={ic_save} size={20}/>
                            </button>
                        </Tooltip>
                        <Tooltip title={editDocumentos ? 'Desfazer' : 'Editar'}>
                            <button onClick={(e) => {handleEdit(e, 'Documentos')}} disabled={newDocumento}>
                                <Icon icon={editDocumentos ? ic_undo : ic_mode_edit} size={20}/>
                            </button>
                        </Tooltip>
                        <Tooltip title="Excluir">
                            <button onClick={(e) => {handleDelete(e, 'Documentos')}} disabled={(!(Number(propsTabDocumentos.selected) > 0)) || (newDocumento)}>
                                <Icon icon={ic_delete} size={20}/>
                            </button>
                        </Tooltip>
                    </div>
                    <div className="fields">
                            <FrSelect 
                                value={currentDataDocumentos?.fk_mdl || []} 
                                style={{ flex: 1, minWidth: '200px' }} 
                                name="fk_mdl" 
                                id="fk_mdl" 
                                label='Modelo' 
                                data={props.comboModeloDocumento}
                                onChange={handleChangeDocumentos}
                                disabled={!editDocumentos}
                            />
                            <br/>
                            <FrSelect 
                                value={currentDataDocumentos?.fk_tdo || []} 
                                style={{ flex: 1, minWidth: '200px' }} 
                                name="fk_tdo" 
                                id="fk_tdo" 
                                label='Tipo de Documento' 
                                data={props.comboTipoDocumento}
                                onChange={handleChangeDocumentos}
                                disabled={!editDocumentos || blockTipoDocumento}
                            />
                            <br/>
                            <FrDatePicker 
                                maxLength='10' 
                                value={(currentDataDocumentos?.dt_emissao || '').split('T')[0]} 
                                onChange={handleChangeDocumentos} 
                                name="dt_emissao" 
                                id="dt_emissao" 
                                color="#49573B" 
                                label="Emissão" 
                                variant="outlined" 
                                size="small"
                                disabled={!editDocumentos}
                                
                                style={{width: 200}}
                            />
                            <br/>
                            <FrTextInput
                                maxLength='150'
                                value={currentDataDocumentos?.nr_doc || ''} 
                                onChange={handleChangeDocumentos}  
                                name="nr_doc" 
                                id="nr_doc" 
                                color="#49573B" 
                                label="Número" 
                                variant="outlined" 
                                size="small"
                                disabled={!editDocumentos}
                                hidden={!(selectedTdo?.exige_numero  === 'S')}
                                style={{width: 200}}
                            />
                            {/* <br/> */}
                            <FrDatePicker
                                maxLength='50'
                                value={(currentDataDocumentos?.dt_validade || '').split('T')[0]}
                                onChange={handleChangeDocumentos}
                                name="dt_validade"
                                id="dt_validade"
                                color="#9c9595"
                                label="Validade"
                                variant="outlined"
                                size="small"
                                style={{ width: 200 }}
                                hidden={!(selectedTdo?.exige_validade  === 'S')}
                                disabled={!editDocumentos}
                            />
                            {/* <br/> */}
                            <FrSelect 
                                value={currentDataDocumentos?.revalidado || []} 
                                style={{ width: 200 }} 
                                name="revalidado" 
                                id="revalidado" 
                                label='Revalidado' 
                                data={[{value: 'S', display: 'Sim'}, {value: 'N', display: 'Não'}]}
                                onChange={handleChangeDocumentos}
                                hidden={!(selectedTdo?.exige_revalidacao  === 'S')}
                                disabled={!editDocumentos}
                            />
                            <div style={{display: 'flex', alignItems: 'center'}}>

                                <Tooltip title="Importar Arquivo">
                                    
                                    <div 
                                    style={{backgroundColor: '#9c9595', width: 140, heigth: 21, padding: 5, marginRight: 5}}
                                    onClick={handleArquivo}
                                    >
                                        
                                        <FiUpload size={20}/>
                                        Importar Arquivo
                                        <input 
                                            type='file'
                                            accept='.pdf, .jpg, .jpeg, .png'
                                            id='arquivo'
                                            name='arquivo'
                                            hidden
                                            onChange={handleChangeDocumentos}
                                            
                                        />
                                        <FrTextInput
                                            maxLength='150'
                                            value={currentDataDocumentos?.arquivo || ''} 
                                            onChange={handleChangeDocumentos}  
                                            name="arquivo" 
                                            id="arquivo" 
                                            color="#49573B" 
                                            label="arquivo" 
                                            variant="outlined" 
                                            size="small"
                                            hidden
                                        />

                                        

                                    </div>

                                </Tooltip> 
                                {currentDataDocumentos?.arquivo?.split('\\')[currentDataDocumentos?.arquivo.split('\\').length-1]}
                            </div>
                            {currentDataDocumentos?.arquivo? 
                            <>
                                <Tooltip title="Importar Arquivo">
                                    
                                    <div 
                                    style={{backgroundColor: '#9c9595', width: 140, heigth: 21, padding: 5, marginRight: 5 ,marginTop: 5}}
                                    onClick={handleDownload}
                                    
                                    >
                                        <FiDownload size={20}/>
                                        Baixar Arquivo
                                                                        

                                    </div>

                                </Tooltip> 
                            </> 

                            : 

                            <></>

                            }
                    </div>
                </div>
            </div>
        </TabPanel>
        <TabPanel value={value} index={2} >
            <div className="regRelacionados">
                <Nav.Link href="/ejr/cliente" target="_blank" className="atalhoRelacionados">
                    <Icon icon={ic_launch} size={20}/>
                    <strong>{'Clientes'}</strong> 
                </Nav.Link>

                <div className="manutReg Relacionados">
                    <ListRegistersDetailedFilter reload={props.reloadRelacionadosTecnico_agricola} filters={filtersRelacionadosTecnico_agricola} width={800} height={300} tab="RelacionadosTecnico_agricola" handleClickItem={selectRelacionadosTecnico_agricola} selected={propsTabRelacionadosTecnico_agricola.selected} items={props.dataRelacionadosTecnico_agricola} textField={'descricao'} handleAdd={handleAdd} />
                    <div className="register">
                        <div className="buttons">
                            <Tooltip title="Salvar">
                                <button onClick={(e) => {handleSave(e, 'RelacionadosTecnico_agricola')}} disabled={!editRelacionadosTecnico_agricola}>
                                    <Icon icon={ic_save} size={20}/>
                                </button>
                            </Tooltip>
                            <Tooltip title={editRelacionadosTecnico_agricola ? 'Desfazer' : 'Editar'}>
                                <button onClick={(e) => {handleEdit(e, 'RelacionadosTecnico_agricola')}} disabled={newRelacionadosTecnico_agricola}>
                                    <Icon icon={editRelacionadosTecnico_agricola ? ic_undo : ic_mode_edit} size={20}/>
                                </button>
                            </Tooltip>
                            <Tooltip title="Excluir">
                                <button onClick={(e) => {handleDelete(e, 'RelacionadosTecnico_agricola')}} disabled={(!(Number(propsTabRelacionadosTecnico_agricola.selected) > 0)) || (newRelacionadosTecnico_agricola)}>
                                    <Icon icon={ic_delete} size={20}/>
                                </button>
                            </Tooltip>
                        </div>
                        <div className="fields">
                            <FrSelect 
                                value={currentDataRelacionadosTecnico_agricola?.fk_pes_cli || []} 
                                name="fk_pes_cli" 
                                id="fk_pes_cli" 
                                label='Empresa' 
                                style={{ width: '100%' }}
                                data={props.comboClientes}
                                onChange={handleChangeRelacionadosTecnico_agricola}
                                disabled={!editRelacionadosTecnico_agricola}
                            />
                            <br/>
                            <FrDatePicker 
                                maxLength='10' 
                                value={(currentDataRelacionadosTecnico_agricola?.data_admissao || '').split('T')[0]} 
                                onChange={handleChangeRelacionadosTecnico_agricola} 
                                name="data_admissao" 
                                id="data_admissao" 
                                color="#49573B" 
                                label="Admissão" 
                                variant="outlined" 
                                size="small"
                                disabled={!editRelacionadosTecnico_agricola}
                                style={{width: 200}}
                            />
                            <br/>
                            <FrDatePicker 
                                maxLength='10' 
                                value={(currentDataRelacionadosTecnico_agricola?.data_desligamento || '').split('T')[0]} 
                                onChange={handleChangeRelacionadosTecnico_agricola} 
                                name="data_desligamento" 
                                id="data_desligamento" 
                                color="#49573B" 
                                label="Desligamento" 
                                variant="outlined" 
                                size="small"
                                disabled={!editRelacionadosTecnico_agricola}
                                style={{width: 200}}
                            />
                            <br/>
                            <FrSelect 
                                value={currentDataRelacionadosTecnico_agricola?.fk_sit || []} 
                                style={{ width: 200 }} 
                                name="fk_sit" 
                                id="fk_sit" 
                                label='Situação' 
                                data={props.comboSituacaoGeral}
                                onChange={handleChangeRelacionadosTecnico_agricola}
                                disabled={!editRelacionadosTecnico_agricola}
                            />
                            <br/>
                            <FrTextInput
                                maxLength='1000'
                                value={currentDataRelacionadosTecnico_agricola?.observacoes || ''}
                                onChange={handleChangeRelacionadosTecnico_agricola}
                                name="observacoes"
                                id="observacoes"
                                color="#9c9595"
                                label="Observações"
                                variant="outlined"
                                size="small"
                                
                                style={{ width: '100%' }}
                                disabled={!editRelacionadosTecnico_agricola}
                                rows={5}
                                maxRows={5}
                                multiline
                            />
                        </div>
                    </div>
                </div>
            </div>
        </TabPanel>
        </div>
    );
}

export default TabsTecnico_agricola;