import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { Icon } from 'react-icons-kit'
import { ic_add_box } from 'react-icons-kit/md/ic_add_box'
import {ic_search} from 'react-icons-kit/md/ic_search'
import {ic_delete} from 'react-icons-kit/md/ic_delete'
import { ic_local_printshop } from 'react-icons-kit/md/ic_local_printshop'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import NavBar from '../components/NavBar'
import FrTable from '../components/FrTable'
import FrTextInput from '../components/FrTextInput'
import FrDatePicker from '../components/FrDatePicker'
import FrSelect from '../components/FrSelect'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import FooterDelphus from '../components/FooterDelphus'
import FrSwitch from '../components/FrSwitch'

import Dialog from '@material-ui/core/Dialog';
import { MDBContainer, MDBIframe } from "mdbreact";


import api from '../services/api'
import { paramsToObject, formToObject, populateForm, objectToQueryString } from '../utils/functions'

import './styles/DefaultMainScreen.css';
import './styles/Manut.css';

const swal = withReactContent(Swal)

function Anotacoes(props) {
  const [data, setData] = useState([])
  const [filter, setFilter] = useState({ recurso: 'S' })
  const [filtered, setFiltered] = useState(false)
  const [comboCliente, setComboCliente] = useState([{ display: '', value: '' }])
  const [comboPiloto, setComboPiloto] = useState([{ display: '', value: '' }])
  const [comboAeroporto, setComboAeroporto] = useState([{ display: '', value: '' }])
  const [comboApua, setComboApua] = useState([{ display: '', value: '' }])
  const [comboMecanico, setComboMecanico] = useState([{ display: '', value: '' }])
  const [comboOrgao, setComboOrgao] = useState([{ display: '', value: '' }])
  const [comboAeronave, setComboAeronave] = useState([{ display: '', value: '' }])
  const [enableAdd, setenableAdd] = useState('N')
  const [enableEdit, setenableEdit] = useState('N')
  const [enableDel, setenableDel] = useState('N')
  const [showModal, setShowModal] = useState(false)
  const [arquivo, setArquivo] = useState();

 
  const columns = [
    {id: 'numero', numeric: false, label: 'Número', align: 'left', minWidth: 120},
    {id: 'data_formatada', numeric: false, label: 'Data', align: 'left', minWidth: 120},
    {id: 'tipo', numeric: false, label: 'Relacionado', align: 'left', minWidth: 400},
    {id: 'descricao', numeric: false, label: 'Descrição', align: 'left', minWidth: 300}
  ]

  useEffect(() => {
    const fetchData = async () => {
      let tela = 'Anotacoes'
      let consulta = await api.getAcessos(tela, 'Consulta')
      if (consulta !== 'S') {
        swal.fire('Usuário sem acesso', 'Usuário sem acesso a essa tela!', 'warning')
          .then((result) => {
            if (result.value) {
              window.history.back()
            }
          })
      } else {

        const urlParams = new URLSearchParams(window.location.search);
        let query = paramsToObject(urlParams)
        // if (query.iframe) require('./styles/iframeReg.css')
  
        swal.fire({
          html: <FrLoading text="Carregando..."/>,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false
        })
        if (!props.frame) {
          setenableAdd(await api.getAcessos('Anotacoes', 'Inclusao'))
          setenableEdit(await api.getAcessos('Anotacoes', 'Alteracao'))
          setenableDel(await api.getAcessos('Anotacoes', 'Exclusao'))
  
          setComboCliente(await api.getComboData('cliente'))
          setComboPiloto(await api.getComboData('piloto'))
          setComboAeronave(await api.getComboData('aeronave'))
          setComboMecanico(await api.getComboData('mecanicos'))
          setComboApua(await api.getComboData('apua'))
          setComboOrgao(await api.getComboData('orgao'))
          setComboAeroporto(await api.getComboData('aeroporto'))
  
          if (!query.recurso) query.recurso = 'S'
          setFilter(query)
          populateForm(document.getElementById('searchForm'), query)
          if (query.buscar === 'true') {
            let event = new Event('start');
            getData(event)
          }
        }
        swal.close()
      }
      }
    
    fetchData()
  }, [])

  function getData(e) {
    e.preventDefault()
    var formData = new FormData(document.getElementById('searchForm'))
    var queryObject = formToObject(formData)
    if (filter.recurso) queryObject.recurso = filter.recurso
    // if (!isEmpty(queryObject)) {
      if (true){
      queryObject.buscar = true
      if(e.type=='start') queryObject.fk_asi=1;
      setFiltered(true)
      if (!props.frame) {
        window.history.replaceState({ filtered: true }, 'filter', "/ejr/anotacoes?" + objectToQueryString(queryObject));
      }
      swal.fire({
        html: <FrLoading text="Buscando dados..."/>,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      api.get('/anotacoes', { params: queryObject }).then(r=> {
        setData(r.data)
        swal.close()
      })
    } else {
      swal.fire('Nenhum filtro informado!', 'Informe ao menos um filtro para realizar a busca.', 'warning')
    }
  }

  function clearForm(e) {
    e.preventDefault();
		console.log('limpa');
    window.history.replaceState({ filtered: false }, 'filter', '/ejr/anotacoes');
    document.getElementById('searchForm').reset()
    setFilter({})
  }

  const handleChange = (e) => {
    const auxValues = { ...filter };
    if (e.target.name === 'recurso') auxValues[e.target.name] = e.target.checked ? 'S' : 'N'
    else auxValues[e.target.name] = e.target.value;
    setFilter(auxValues);
  };

  function escondeModal(e) {
    e.preventDefault()
    setShowModal(false)
  }

  const imprimeRel = (e) => {
    e.preventDefault();
    
    let arq;
    var formData = new FormData(document.getElementById('searchForm'))
    var queryObject = formToObject(formData)
    api.get('/anotacoes/relatorio',{ params: queryObject, responseType: 'blob' }).then(r => { //api.backendUrl + path + id
      arq = URL.createObjectURL(r.data)
      setArquivo(arq)
      setShowModal(true)
    })
  }

  return (
    <div className="Manut">

      <div>
        <Dialog aria-labelledby="simple-dialog-title" open={showModal} fullWidth={true} maxWidth={'lg'}>
          <div className="FrModalFooter">
            <div className="FrModalButtonsDiv">
              <MDBContainer className="text-center">
                <MDBIframe src={arquivo} />
              </MDBContainer>
              <button onClick={escondeModal}> Sair </button>
            </div>
          </div>
        </Dialog>
      </div>

      {props.frame ? <div/> : <NavBar/>}
      <div className={"ManutContent"+(props.frame ? '-frame' : '')}>
        <div className="defaultHeader">
          <h3>Anotações</h3>
          {props.frame ? <div/> : <UserOptions/>}
        </div>
        <div className="filtersDiv">
          <form id="searchForm" onSubmit={getData}>
          <div>
            <FrDatePicker
              value={(filter.data_ini || '').split('T')[0] } 
              onChange={handleChange} 
              name="data_ini" 
              id="data_ini" 
              color="#9c9595" 
              label="Data Inicial" 
              variant="outlined" 
              size="small"
              style={{width: 190}}
            />
            <FrDatePicker
              value={(filter.data_fim || '').split('T')[0] } 
              onChange={handleChange} 
              name="data_fim" 
              id="data_fim" 
              color="#9c9595" 
              label="Data Final" 
              variant="outlined" 
              size="small"
              style={{width: 190}}
            />
            <FrSelect
              value={filter.fk_pes_cli || ''}
              onChange={handleChange}
              name='fk_pes_cli'
              id='fk_pes_cli'
              label='Empresa'
              data={comboCliente}
              style={{ width: 300 }}
            />
            <FrSelect
              value={filter.fk_pes_pil || ''}
              onChange={handleChange}
              name='fk_pes_pil'
              id='fk_pes_pil'
              label='Piloto'
              data={comboPiloto}
              style={{ width: 300 }}
            />
            <FrSelect
              value={filter.fk_pes_mec || ''}
              onChange={handleChange}
              name='fk_pes_mec'
              id='fk_pes_mec'
              label='Mecânico'
              data={comboMecanico}
              style={{ width: 300 }}
            />
            <FrSelect
              value={filter.fk_aer || ''}
              onChange={handleChange}
              name='fk_aer'
              id='fk_aer'
              label='Aeronave'
              data={comboAeronave}
              style={{ width: 300 }}
            />
            <FrSelect
              value={filter.fk_aro || ''}
              onChange={handleChange}
              name='fk_aro'
              id='fk_aro'
              label='Aeroporto'
              data={comboAeroporto}
              style={{ width: 300 }}
            />
            <FrSelect
              value={filter.fk_apu || ''}
              onChange={handleChange}
              name='fk_apu'
              id='fk_apu'
              label='Apua'
              data={comboApua}
              style={{ width: 300 }}
            />
            <FrSelect
              value={filter.fk_org || ''}
              onChange={handleChange}
              name='fk_org'
              id='fk_org'
              label='Órgão'
              data={comboOrgao}
              style={{ width: 300 }}
            />
            </div>
            <div>
              <button onClick={getData}>
                <Icon icon={ic_search} size={18}/>
                Pesquisar
              </button>
              <button onClick={clearForm}>
                <Icon icon={ic_delete} size={18}/>
                Limpar
              </button>
              {(enableAdd !== 'S') ? '' :
                <Link to="/ejr/anotacoes/registro">
                  <button >
                    <Icon icon={ic_add_box} size={18} />
                  Novo Registro
                </button>
                </Link>}
              <button onClick={imprimeRel}>
                <Icon icon={ic_local_printshop} size={18}/>
                Imprimir
              </button>
            </div>
          </form>
        </div>
        <br/>
        <div className={"tableDiv"+(props.frame && data.length>0 ? '-frame' : '')}>
          <FrTable 
            regName="anotacoes" 
            frame={props.frame} 
            columns={columns} 
            searched={filtered} 
            codeName="pk_ano" 
            page={Anotacoes} 
            data={data} 
            enableEdit={enableEdit} 
            enableDel={enableDel}
          />
        </div>
        {props.frame ? (<div>

        </div>) : <FooterDelphus/>}
        
      </div>
    </div>
  );
}

export default Anotacoes;
