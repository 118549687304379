import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import NavBar from '../components/NavBar'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import TabsMecanico from '../components/TabsMecanico'

import api from '../services/api'
import consultaCNPJ from '../services/consultaCNPJ'
import { paramsToObject, validarCNPJ, onlyNumbers, removeSpecialChar, validarCPF } from '../utils/functions'
import { mCNPJ, mTel, mCEP, mCPF } from '../utils/masks'

import './styles/DefaultReg.css';
import FooterDelphus from '../components/FooterDelphus';

const swal = withReactContent(Swal)

function RegMecanico() {
  const [data, setData] = useState({ ativo: 'S' })
  const [dataDocumentos, setDataDocumentos] = useState([])
  const [updateType, setUpdateType] = useState(false)
  const [enableEdit, setEnableEdit] = useState('N')
  const [erroCnpj, setErroCnpj] = useState(false)
  const [dataRelacionadosMecanico, setDataRelacionadosMecanico] = useState([])
  const [erroCpf, setErroCpf] = useState(false)
  const [comboCidades, setComboCidades] = useState([{ value: '', display: '' }])
  const [comboClasses, setComboClasses] = useState([{ value: '', display: '' }])
  const [comboSituacao, setComboSituacao] = useState([{ value: '', display: '' }])
  const [comboClientes, setComboClientes] = useState([{ value: '', display: '' }])
  const [codigo, setCodigo] = useState(null)
  const [comboTipoDocumento, setComboTipoDocumento] = useState([{ value: '', display: '' }])
  const [comboSituacaoGeral, setComboSituacaoGeral] = useState([{ value: '', display: '' }])
  const [comboModeloDocumento, setComboModeloDocumento] = useState([{ value: '', display: '' }])

  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })

      setComboClasses(await api.getComboData('mecanico_classe'))
      setComboCidades(await api.getComboData('cidades'))
      setComboSituacao(await api.getComboData('situacao_mecanico'))
      setComboTipoDocumento(await api.getComboData('tipo_documento'))
      setComboSituacaoGeral(await api.getComboData('situacao_padrao'))
      setComboClientes(await api.getComboData('cliente'))
      setComboModeloDocumento(await api.getComboData('modelo_documento'))

      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)

      if (query.codigo > 0) {
        setEnableEdit(await api.getAcessos('Mecânicos', 'Alteracao'))
      } else {
        setEnableEdit(await api.getAcessos('Mecânicos', 'Inclusao'))
      }

      if (query.codigo > 0) {
        setUpdateType(true)
        setCodigo(query.codigo)
        api.get('/mecanicos?pk_pes_mec=' + query.codigo).then(r => {
          if (r.data[0]) {
            r.data[0].cpf = mCPF(r.data[0].cpf || '')
            r.data[0].fone1 = mTel(r.data[0].fone1 || '')
            r.data[0].fone2 = mTel(r.data[0].fone2 || '')
            r.data[0].celular = mTel(r.data[0].celular || '')
            r.data[0].cep = mCEP(r.data[0].cep || '')
            setData(r.data[0])
            api.get('/mecanicos/documentos?pk_pes_mec=' + query.codigo).then(r => {
              if (r.data.length > 0) {
                setDataDocumentos(r.data)
              }
              api.get('/mecanico/cliente?pk_pes_mec=' + query.codigo).then(r => {
                if (r.data) {
                  console.log(r.data)
                  setDataRelacionadosMecanico(r.data)
                }
                swal.close()
              })
            })
          } else {
            swal.fire('Código inválido', 'Código informado não encontrado!', 'warning')
              .then((result) => {
                if (result.value) {
                  window.location.href = '/ejr/mecanicos'
                }
              })
          }
        })
      } else swal.close()
    }

    fetchData()
  }, [])

  const reloadDocumentos = () => {
    swal.fire({
      html: <FrLoading text="Carregando..." />,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false
    })
    api.get('/mecanicos/documentos?pk_pes_mec=' + data.pk_pes_mec).then(r => {
      if (r.data.length > 0) {
        // console.log(r.data)
        setDataDocumentos(r.data)
      } else
        setDataDocumentos([{ nomedoc: '', codigo: 0 }])
      swal.close()
    })
  }

  const reloadRelacionadosMecanico = () => {
    swal.fire({
      html: <FrLoading text="Carregando..." />,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false
    })
    api.get('/mecanico/cliente?pk_pes_mec=' + data.pk_pes_mec).then(r => {
      swal.close()
      if (r.data) {
        console.log(r.data)
        setDataRelacionadosMecanico(r.data)
      }
    })
  }

  function consistData(data) {
    let required = [
      'CNPJ'
    ]

    let errors = []

    required.forEach((curr, index) => {
      let fieldName = curr
      if (!data[curr]) return errors.push(fieldName)
    })

    return errors
  }

  const handleImageChange = (image, fieldName) => {
    const auxValues = { ...data };
    auxValues['foto'] = image
    setData(auxValues)
  }

  function handleSubmit(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Deseja salvar " + (updateType ? 'as alterações' : 'a inclusão') + ' do registro?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        const auxValues = { ...data };
        auxValues.data_conclusao_cavag = auxValues.data_conclusao_cavag ? auxValues.data_conclusao_cavag.split('T')[0] : null
        auxValues.cpf = onlyNumbers(auxValues.cpf)
        auxValues.cep = onlyNumbers(auxValues.cep)
        auxValues.fone1 = onlyNumbers(auxValues.fone1)
        auxValues.fone2 = onlyNumbers(auxValues.fone2)
        auxValues.celular = onlyNumbers(auxValues.celular)
        // let errors = consistData(auxValues)
        let errors = []
        console.log(errors.length)
        if (errors.length === 0) {
          if (updateType) {
            api.post('/mecanicos/edit', auxValues).then(r => {
              if (r.status === 200) {
                swal.fire({
                  title: 'Registro Alterado',
                  text: "O registro foi alterado com sucesso!",
                  icon: 'success',
                }).then((result) => {
                  if (result.value) {
                    window.location.href = '/ejr/mecanicos'
                  }
                })
              } else {
                swal.fire({
                  title: 'Erro!',
                  text: "O registro não foi alterado!",
                  icon: 'warning',
                })
              }
            })
          } else {
            api.post('/mecanicos/add', auxValues).then(r => {
              if (r.status === 200) {
                let pk_pes_mec = r.data
                swal.fire({
                  title: 'Registro adicionado com sucesso!',
                  text: "Deseja editar agora as outras abas deste cadastro?",
                  icon: 'success',
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: 'Sim',
                  cancelButtonText: 'Não'
                }).then((result) => {
                  console.log(result)
                  if (result) {
                    window.location.href = '/ejr/mecanicos/registro?codigo='+pk_pes_mec
                  } else {
                    window.location.href = '/ejr/mecanicos'
                  }
                })
              } else {
                swal.fire({
                  title: 'Erro!',
                  text: "O registro não foi adicionado!",
                  icon: 'warning',
                })
              }
            }).catch(e => {
              swal.fire({
                title: 'Erro!',
                text: "O registro não foi adicionado!",
                icon: 'warning',
              })
            })
          }
        } else {
          swal.fire({
            title: 'Erro!',
            text: "Verifique o(s) campo(s) " + errors.join(','),
            icon: 'warning',
          })
        }
      }
    })
  }

  function handleCancel(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        window.location.href = '/ejr/mecanicos'
      }
    })
  }

  const handleChange = (e) => {
    const auxValues = { ...data };
    switch (e.target.name) {
      case 'fone1':
      case 'fone2':
      case 'celular':
        auxValues[e.target.name] = mTel(e.target.value)
      break

      case 'cpf':
        auxValues[e.target.name] = mCPF(e.target.value)
      break

      case 'cep':
        auxValues[e.target.name] = mCEP(e.target.value)
      break

      default: auxValues[e.target.name] = e.target.value;
    }
    setData(auxValues);
  };

  function handleCnpj(e) {
    e.preventDefault();
    let cnpj = e.target.value
    if (cnpj) {
      if (!validarCNPJ(cnpj)) {
        setErroCnpj(true)
        swal.fire('CNPJ inválido', 'Informe um CNPJ válido!', 'warning')
      } else {
        swal.fire({
          text: "Deseja buscar os dados pelo CNPJ?",
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não'
        }).then((result) => {
          if (result.value) {
            consultaCNPJ.get(String(onlyNumbers(cnpj)), { "Access-Control-Allow-Origin": "*" }).then(r => {
              console.log(r)
              if (r.data.erro) {
                setErroCnpj(true)
                swal.fire('CNPJ inválido', 'Informe um CNPJ válido!', 'warning')
              } else {
                setErroCnpj(false)
                const auxValues = { ...data };
                auxValues['razao_social'] = r.data.nome;
                auxValues['fantasia'] = r.data.fantasia;
                setData(auxValues)
              }
            }).catch(err => {
              setErroCnpj(true)
              swal.fire('CNPJ inválido', 'Informe um CNPJ válido!', 'warning')
            })
          }
        })
      }
    }
  }

  function handleCpf(e) {
    e.preventDefault();
    let cpf = e.target.value
    if(cpf) {
      if(!validarCPF(cpf)) {
        setErroCpf(true)
        swal.fire('CPF inválido', 'Informe um CPF válido!', 'warning')
      } else {
        setErroCpf(false)
        api.get('/pessoa?cpf=' + onlyNumbers(e.target.value)).then(r => {
          if (r.data[0]) {
              r.data[0].cpf = mCPF(r.data[0].cpf)
              r.data[0].fone1 = mTel(r.data[0].fone1)
              r.data[0].fone2 = mTel(r.data[0].fone2)
              r.data[0].cep = mCEP(r.data[0].cep)
              setData(r.data[0])
          }
        })
      }
    }
  }

  return (
    <div className="defaultReg">
      <NavBar />
      <div className="defaultRegContent">
        <div className="defaultHeader">
          <h3>{updateType ? 'Edição' : 'Inclusão'} de Mecânico</h3>
          <UserOptions />
        </div>
        <br />
        <form id="regForm" onSubmit={handleSubmit}>
          <TabsMecanico
            data={data}
            handleChange={handleChange}
            setData={setData}
            comboSituacao={comboSituacao}
            comboTipoDocumento={comboTipoDocumento}
            comboClasses={comboClasses}
            comboCidades={comboCidades}
            reloadDocumentos={reloadDocumentos}
            dataDocumentos={dataDocumentos}
            handleImageChange={handleImageChange}
            comboSituacaoGeral={comboSituacaoGeral}
            comboClientes={comboClientes}
            dataRelacionadosMecanico={dataRelacionadosMecanico}
            reloadRelacionadosMecanico={reloadRelacionadosMecanico}
            comboModeloDocumento={comboModeloDocumento}
            setComboTipoDocumento={setComboTipoDocumento}
            handleCpf={handleCpf}
            erroCpf={erroCpf}
            updateType={updateType}
            pk_pes_mec={codigo} />
        </form>
        <div className="regButtonsDiv">
          {enableEdit === 'S' ?
            (<button onClick={handleSubmit}>Salvar</button>) : (<></>)}
          <button onClick={handleCancel}>{enableEdit === 'S' ? 'Cancelar' : 'Fechar'}</button>
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default RegMecanico;
