import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { Icon } from 'react-icons-kit'
import { ic_add_box } from 'react-icons-kit/md/ic_add_box'
import {ic_search} from 'react-icons-kit/md/ic_search'
import {ic_delete} from 'react-icons-kit/md/ic_delete'
import Swal from 'sweetalert2'
import Tooltip from '@material-ui/core/Tooltip'
import withReactContent from 'sweetalert2-react-content'

import NavBar from '../components/NavBar'
import FrTable from '../components/FrTable'
import FrTextInput from '../components/FrTextInput'
import FrDatePicker from '../components/FrDatePicker'
import FrSelect from '../components/FrSelect'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import FooterDelphus from '../components/FooterDelphus'


import api from '../services/api'
import { paramsToObject, formToObject, populateForm, objectToQueryString } from '../utils/functions'

import './styles/DefaultMainScreen.css';
import './styles/Manut.css';

const swal = withReactContent(Swal)

function ConsultaRelacionados(props) {
  const [data, setData] = useState({ cliente: [], aeronave: [], piloto: [], mecanicos: [] })
  const [filter, setFilter] = useState({})
  const [filtered, setFiltered] = useState(false)
  const [comboCliente, setComboCliente] = useState([])
  const [comboPiloto, setComboPiloto] = useState([])
  const [comboMecanico, setComboMecanico] = useState([])
  const [comboAeronave, setComboAeronave] = useState([])
  const [enableAdd, setenableAdd] = useState('N')
  const [enableEdit, setenableEdit] = useState('N')
  const [enableDel, setenableDel] = useState('N')
  const [blockTipo, setBlockTipo] = useState(true)
  const [tipoRelacionado, setTipoRelacionado] = useState({})

 
  const columnsCliente = [
    {id: 'razao_social', numeric: false, label: 'Cliente', align: 'left', minWidth: 400},
    {id: 'data_inicio', numeric: false, label: 'Data Ínicio', align: 'left', minWidth: 150},
    {id: 'data_fim', numeric: false, label: 'Data Fim', align: 'left', minWidth: 150},
  ]

  const columnsAeronave = [
    {id: 'prefixo', numeric: false, label: 'Aeronave', align: 'left', minWidth: 400},
    {id: 'data_inicio', numeric: false, label: 'Data Ínicio', align: 'left', minWidth: 150},
    {id: 'data_fim', numeric: false, label: 'Data Fim', align: 'left', minWidth: 150},
    {id: 'situacao', numeric: false, label: 'Situacao', align: 'left', minWidth: 150}
  ]

  const columnsPiloto = [
    {id: 'razao_social', numeric: false, label: 'Piloto', align: 'left', minWidth: 400},
    {id: 'data_inicio', numeric: false, label: 'Data Ínicio', align: 'left', minWidth: 150},
    {id: 'data_fim', numeric: false, label: 'Data Fim', align: 'left', minWidth: 150},
    {id: 'situacao', numeric: false, label: 'Situacao', align: 'left', minWidth: 150}
  ]

  const columnsMecanico = [
    {id: 'fantasia', numeric: false, label: 'Mecanico', align: 'left', minWidth: 400},
    {id: 'data_inicio', numeric: false, label: 'Data Ínicio', align: 'left', minWidth: 150},
    {id: 'data_fim', numeric: false, label: 'Data Fim', align: 'left', minWidth: 150},
    {id: 'situacao', numeric: false, label: 'Situacao', align: 'left', minWidth: 150}
  ]

  useEffect(() => {
    const fetchData = async () => {
      let tela = 'ConsultaRelacionados'
      let consulta = await api.getAcessos(tela, 'Consulta')
      if (consulta !== 'S') {
        return swal.fire('Usuário sem acesso', 'Usuário sem acesso a essa tela!', 'warning')
          .then((result) => {
            if (result.value) {
              window.history.back()
            }
          })
      }
      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)
      // if (query.iframe) require('./styles/iframeReg.css')

      swal.fire({
        html: <FrLoading text="Carregando..."/>,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      if (!props.frame) {
        setenableAdd(await api.getAcessos('ConsultaRelacionados', 'Inclusao'))
        setenableEdit(await api.getAcessos('ConsultaRelacionados', 'Alteracao'))
        setenableDel(await api.getAcessos('ConsultaRelacionados', 'Exclusao'))

        // setFilter(query)
        // populateForm(document.getElementById('searchForm'), query)
        // if (query.buscar === 'true') {
          // let event = new Event('build');
          // getData(event)
        // }
      }
      swal.close()
    }
    
    fetchData()
  }, [])

  function getData(e) {
    e.preventDefault()
    var formData = new FormData(document.getElementById('searchForm'))
    var queryObject = formToObject(formData)
    // if (!isEmpty(queryObject)) {
      if (true){
      queryObject.buscar = true
      setFiltered(true)
      // if (!props.frame) {
        // window.history.replaceState({ filtered: true }, 'filter', "/ejr/relacionados?" + objectToQueryString(queryObject));
      // }
      swal.fire({
        html: <FrLoading text="Buscando dados..."/>,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      api.get('/'+tipoRelacionado.tabela+'/relacionados', { params: queryObject }).then(r=> {
        setData(r.data)
        swal.close()
      })
    } else {
      swal.fire('Nenhum filtro informado!', 'Informe ao menos um filtro para realizar a busca.', 'warning')
    }
  }

  function clearForm(e) {
    e.preventDefault();
		console.log('limpa');
    window.history.replaceState({ filtered: false }, 'filter', '/ejr/relacionados');
    document.getElementById('searchForm').reset()
    setFilter({})
  }

  const handleChange = (e) => {
    const auxValues = { ...filter };
    auxValues[e.target.name] = e.target.value;
    setFilter(auxValues);
  };

  useEffect(() => {
    const fetchTipo = async () => {
      if (filter.tipo) {
        let relacionado = {}

        switch (filter.tipo) {
          case 'C':
            relacionado = { tipo: 'C', descricao: 'Cliente', tabela: 'cliente', campo: 'fk_pes_cli', setCombo: setComboCliente, combo: comboCliente }
          break

          case 'P':
            relacionado = { tipo: 'P', descricao: 'Piloto', tabela: 'piloto', campo: 'fk_pes_pil', setCombo: setComboPiloto, combo: comboPiloto }
          break

          case 'M':
            relacionado = { tipo: 'M', descricao: 'Mecânico', tabela: 'mecanicos', campo: 'fk_pes_mec', setCombo: setComboMecanico, combo: comboMecanico }
          break

          case 'A':
            relacionado = { tipo: 'A', descricao: 'Aeronave', tabela: 'aeronave', campo: 'fk_aer', setCombo: setComboAeronave, combo: comboAeronave }
          break
        }

        swal.fire({
          html: <FrLoading text={"Buscando "+relacionado.descricao+"..."} />,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false
        })

        let combo = await api.getComboData(relacionado.tabela)
        relacionado.setCombo(combo)
        relacionado.combo = combo
        setTipoRelacionado(relacionado)
        setBlockTipo(false)
        swal.close()
      } else setBlockTipo(true)
    }

    fetchTipo()
  }, [filter.tipo])


  return (
    <div className="Manut">
      {props.frame ? <div/> : <NavBar/>}
      <div className={"ManutContent"+(props.frame ? '-frame' : '')}>
        <div className="defaultHeader">
          <h3>Consulta Geral de Relacionados</h3>
          {props.frame ? <div/> : <UserOptions/>}
        </div>
        <div className="filtersDiv">
          <form id="searchForm" onSubmit={getData}>
          <div>
            <FrSelect
              value={filter.tipo || ''}
              onChange={handleChange}
              name="tipo"
              id="tipo"
              label='Tipo de Relacionado'
              data={[ {display: 'Cliente', value: 'C'}, {display: 'Aeronave', value: 'A'}, {display: 'Piloto', value: 'P'},  {display: 'Mecânico', value: 'M'} ]}
              style={{ width: 200 }}
            />
            <Tooltip title={blockTipo ? 'Informe o Tipo de Relacionado!' : ''}>
              <FrSelect
                value={filter[tipoRelacionado.campo] || null}
                onChange={handleChange}
                name={tipoRelacionado.campo}
                id={tipoRelacionado.campo}
                label={tipoRelacionado.descricao}
                data={tipoRelacionado.combo || []}
                style={{ width: 300 }}
                disabled={blockTipo}
              />
            </Tooltip>
            </div>
            <div>
              <button onClick={getData}>
                <Icon icon={ic_search} size={18}/>
                Pesquisar
              </button>
              <button onClick={clearForm}>
                <Icon icon={ic_delete} size={18}/>
                Limpar
              </button>
              {(enableAdd !== 'S') ? '' :
                <Link to="/ejr/relacionados/registro">
                  <button >
                    <Icon icon={ic_add_box} size={18} />
                  Novo Registro
                </button>
                </Link>}
            </div>
          </form>
        </div>
        <div className={"tableDivRelacionados"} style={{ display: data.cliente.length>0 ? 'block' : 'none' }}>
          <h2>Clientes</h2>
          <FrTable regName="relacionados" frame={props.frame} columns={columnsCliente} searched={filtered} codeName="pk_pes_cli" page={ConsultaRelacionados} data={data.cliente} relacionados={true} readOnly/>
        </div>
        <div className={"tableDivRelacionados"} style={{ display: data.aeronave.length>0 ? 'block' : 'none' }}>
          <h2>Aeronaves</h2>
          <FrTable regName="relacionados" frame={props.frame} columns={columnsAeronave} searched={filtered} codeName="pk_aer" page={ConsultaRelacionados} data={data.aeronave} relacionados={true} readOnly/>
        </div>
        <div className={"tableDivRelacionados"} style={{ display: data.piloto.length>0 ? 'block' : 'none' }}>
          <h2>Pilotos</h2>
          <FrTable regName="relacionados" frame={props.frame} columns={columnsPiloto} searched={filtered} codeName="pk_pes_pil" page={ConsultaRelacionados} data={data.piloto} relacionados={true} readOnly/>
        </div>
        <div className={"tableDivRelacionados"} style={{ display: data.mecanicos.length>0 ? 'block' : 'none' }}>
          <h2>Mecanicos</h2>
          <FrTable regName="relacionados" frame={props.frame} columns={columnsMecanico} searched={filtered} codeName="pk_pes_mec" page={ConsultaRelacionados} data={data.mecanicos} relacionados={true} readOnly/>
        </div>
        {props.frame ? (<div>

        </div>) : <FooterDelphus/>}
        
      </div>
    </div>
  );
}

export default ConsultaRelacionados;
