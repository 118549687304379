import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { Icon } from 'react-icons-kit'
import { ic_add_box } from 'react-icons-kit/md/ic_add_box'
import { ic_search } from 'react-icons-kit/md/ic_search'
import { ic_delete } from 'react-icons-kit/md/ic_delete'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import NavBar from '../components/NavBar'
import FrTable from '../components/FrTable'
import FrTextInput from '../components/FrTextInput'
import FrSelect from '../components/FrSelect'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import FooterDelphus from '../components/FooterDelphus'


import api from '../services/api'
import { paramsToObject, formToObject, populateForm, objectToQueryString } from '../utils/functions'

import './styles/DefaultMainScreen.css';
import './styles/Manut.css';

const swal = withReactContent(Swal)

function Aeroporto(props) {
  const [data, setData] = useState([])
  const [filter, setFilter] = useState({})
  const [filtered, setFiltered] = useState(false)
  const [enableAdd, setenableAdd] = useState('N')
  const [enableEdit, setenableEdit] = useState('N')
  const [enableDel, setenableDel] = useState('N')
  const [comboTipo, setComboTipo] = useState([{ value: '', display: '' }])
  const [comboSituacao, setComboSituacao] = useState([{ value: '', display: '' }])
  const [comboCidAero, setComboCidAero] = useState([{ value: '', display: '' }])


  const columns = [
    { id: 'descricao', numeric: false, label: 'Descrição', align: 'left', minWidth: 300 },
  ]

  useEffect(() => {
    const fetchData = async () => {
      let tela = 'Aeroporto'
      let consulta = await api.getAcessos(tela, 'Consulta')
      if (consulta !== 'S') {
        swal.fire('Usuário sem acesso', 'Usuário sem acesso a essa tela!', 'warning')
          .then((result) => {
            if (result.value) {
              window.history.back()
            }
          })
      } else {

        swal.fire({
          html: <FrLoading text="Carregando..." />,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false
        })
        
        
        if (!props.frame) {
          setenableAdd(await api.getAcessos('Aeroporto', 'Inclusao'))
          setenableEdit(await api.getAcessos('Aeroporto', 'Alteracao'))
          setenableDel(await api.getAcessos('Aeroporto', 'Exclusao'))
          setComboTipo(await api.getComboData('tipo_aeroporto'))
  
          await api.get('/aeroporto/ComboCidAero').then(r => {
            setComboCidAero(r.data)
          })
  
  
  
          setComboSituacao(await api.getComboData('situacao_aeroporto'))
          const urlParams = new URLSearchParams(window.location.search);
          let query = paramsToObject(urlParams)
          if(!query.buscar){
            query.buscar = 'true'
            query.situacao = 2  
    
          }
          setFilter(query)
          populateForm(document.getElementById('searchForm'), query)
          if (query.buscar === 'true') {
            let event = new Event('build');
            getData(event)
          }
        }
        swal.close()
      }
      }

    fetchData()
  }, [])

  function getData(e) {
    e.preventDefault()
    var formData = new FormData(document.getElementById('searchForm'))
    var queryObject = formToObject(formData)
    // if (!isEmpty(queryObject)) {
    if (true) {
      queryObject.buscar = true
      setFiltered(true)
      if (!props.frame) {
        window.history.replaceState({ filtered: true }, 'filter', "/ejr/aeroporto?" + objectToQueryString(queryObject));
      }
      swal.fire({
        html: <FrLoading text="Buscando dados..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      api.get('/aeroporto', { params: queryObject }).then(r => {
        setData(r.data)
        swal.close()
      })
    } else {
      swal.fire('Nenhum filtro informado!', 'Informe ao menos um filtro para realizar a busca.', 'warning')
    }
  }

  function clearForm(e) {
    e.preventDefault();
    console.log('limpa');
    window.history.replaceState({ filtered: false }, 'filter', '/ejr/aeroporto');
    document.getElementById('searchForm').reset()
    setFilter({})
  }

  const handleChange = (e) => {
    const auxValues = { ...filter };
    console.log(auxValues)
    auxValues[e.target.name] = e.target.value;
    setFilter(auxValues);
  };

  return (
    <div className="Manut">
      {props.frame ? <div /> : <NavBar />}
      <div className={"ManutContent" + (props.frame ? '-frame' : '')}>
        <div className="defaultHeader">
          <h3>Aeroportos</h3>
          {props.frame ? <div /> : <UserOptions />}
        </div>
        <div className="filtersDiv">
          <form id="searchForm" onSubmit={getData}>
            <div>
              <FrTextInput
                value={filter.descricao || ''}
                style={{ width: 330 }}
                name="descricao"
                id="descricao"
                color="#9c9595"
                label="Nome"
                variant="outlined"
                size="small"
                onChange={handleChange}
              />
              {/* <FrSelect 
              value={filter.ativo || []} 
              style={{width: 100}} 
              name="ativo" 
              id="ativo" 
              label='Ativo' 
              data={[{ display: 'Sim', value: 'S' }, { display: 'Não', value: 'N' }]}
              onChange={handleChange}
            /> */}

              <FrSelect
                value={filter.fk_tar || []}
                style={{ width: 200 }}
                name="fk_tar"
                id="fk_tar"
                label='Tipo'
                data={comboTipo}
                onChange={handleChange}
              />

              <br />

              <FrSelect
                value={filter.fk_cid || []}
                style={{ width: 265 }}
                name="fk_cid"
                id="fk_cid"
                label='Cidade'
                data={comboCidAero}
                onChange={handleChange}
              />

              <FrSelect
                value={filter.fk_sar || []}
                style={{ width: 265 }}
                name="fk_sar"
                id="fk_sar"
                label='Situação'
                data={comboSituacao}
                onChange={handleChange}
              />

            </div>
            <div>
              <button onClick={getData}>
                <Icon icon={ic_search} size={18} />
                Pesquisar
              </button>
              <button onClick={clearForm}>
                <Icon icon={ic_delete} size={18} />
                Limpar
              </button>
              {(enableAdd !== 'S') ? '' :
                <Link to="/ejr/aeroporto/registro">
                  <button >
                    <Icon icon={ic_add_box} size={18} />
                    Novo Registro
                  </button>
                </Link>}
            </div>
          </form>
        </div>
        <br />
        <div className={"tableDiv" + (props.frame && data.length > 0 ? '-frame' : '')}>
          <FrTable regName="aeroporto" frame={props.frame} columns={columns} searched={filtered} codeName="pk_aro" page={Aeroporto} data={data} enableEdit={enableEdit} enableDel={enableDel} />
        </div>
        {props.frame ? (<div>

        </div>) : <FooterDelphus />}

      </div>
    </div>
  );
}

export default Aeroporto;
