import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import NavBar from '../components/NavBar'
import FrTextInput from '../components/FrTextInput'
import FrSelect from '../components/FrSelect'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import FrDatePicker from '../components/FrDatePicker'
import Tooltip from '@material-ui/core/Tooltip'
import FrSwitch from '../components/FrSwitch'

import api from '../services/api'
import { paramsToObject } from '../utils/functions'

import './styles/DefaultReg.css';
import FooterDelphus from '../components/FooterDelphus';

const swal = withReactContent(Swal)

function RegProcessos() {
  const [data, setData] = useState({ativo: 'S'})
  const [updateType, setUpdateType] = useState(false)
  const [enableEdit, setEnableEdit] = useState('N')
  const [comboCliente, setComboCliente] = useState([{ display: '', value: '' }])
  const [comboOrgaos, setComboOrgaos] = useState([{ display: '', value: '' }])
  const [comboUnidade, setComboUnidade] = useState([{ display: '', value: '' }])
  const [blockAutuado, setBlockAutuado] = useState(true)

  const auth = JSON.parse(localStorage['authejr'] || JSON.stringify({ authenticated: false, user: 0 }))

  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)

      if (query.codigo > 0) {
        setEnableEdit(await api.getAcessos('Processos', 'Alteracao'))
      } else {
        setEnableEdit(await api.getAcessos('Processos', 'Inclusao'))
      }

      setComboCliente(await api.getComboData('cliente'))
      setComboOrgaos(await api.getComboData('orgao'))
      setComboUnidade(await api.getComboData('orgao_unidade'))

      if (query.codigo > 0) {
        setUpdateType(true)
        api.get('/processos?pk_pro=' + query.codigo).then(r => {
          if (r.data[0]) {
            console.log(r.data[0])
            setData(r.data[0])
            swal.close()
          } else {
            swal.fire('Código inválido', 'Código informado não encontrado!', 'warning')
              .then((result) => {
                if (result.value) {
                  window.history.back()
                }
              })
          }
        })
      } else swal.close()
    }

    fetchData()
  }, [])

  useEffect(() => {
    console.log('combo unidade -', comboUnidade)
    console.log('combo clientes -', comboCliente)
    console.log('combo orgaos -', comboOrgaos)
  }, [comboCliente, comboOrgaos, comboUnidade])

  useEffect(() => {
    const fetchUnidade = async () => {
      if (data.fk_org > 0) {
        swal.fire({
          html: <FrLoading text="Carregando..." />,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false
        })
  
        setComboUnidade(await api.getComboData('orgao_unidade', data.fk_org))

        swal.close()
      }
    }
    
    fetchUnidade()
  }, [data.fk_org])


  function consistData(data) {
    let required = [
      'numero',
      'data_registro',
    ]

    let errors = []

    required.forEach((curr, index) => {
      let fieldName = curr
      if (!data[curr]) return errors.push(fieldName)
    })

    if (!data.fk_pes_cli) errors.push('Empresa')

    return errors
  }

  function handleSubmit(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Deseja salvar " + (updateType ? 'as alterações' : 'a inclusão') + ' do registro?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        const auxValues = { ...data };
        let errors = consistData(auxValues)
        console.log(errors.length)
        auxValues.data_registro = auxValues.data_registro ? auxValues.data_registro.split('T')[0] : null
        auxValues.data_entrega = auxValues.data_entrega ? auxValues.data_entrega.split('T')[0] : null
        auxValues.fk_usu = auth.user
        if (errors.length === 0) {
          if (updateType) {
            api.post('/processos/edit', auxValues).then(r => {
              if (r.status === 200) {
                swal.fire({
                  title: 'Registro Alteraddo',
                  text: "O registro foi alterado com sucesso!",
                  icon: 'success',
                }).then((result) => {
                  if (result.value) {
                    window.history.back()
                  }
                })
              } else {
                swal.fire({
                  title: 'Erro!',
                  text: "O registro não foi alterado!",
                  icon: 'warning',
                })
              }
            })
          } else {
            api.post('/processos/add', auxValues).then(r => {
              if (r.status === 200) {
                swal.fire({
                  title: 'Registro Adicionado',
                  text: "O registro foi adicionado com sucesso!",
                  icon: 'success',
                }).then((result) => {
                  if (result.value) {
                    window.history.back()
                  }
                })
              } else {
                swal.fire({
                  title: 'Erro!',
                  text: "O registro não foi adicionado!",
                  icon: 'warning',
                })
              }
            })
          }
        } else {
          swal.fire({
            title: 'Erro!',
            text: "Verifique o(s) campo(s) " + errors.join(','),
            icon: 'warning',
          })
        }
      }
    })
  }

  function handleCancel(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        window.history.back()
      }
    })
  }

  const handleChange = (e) => {
    const auxValues = { ...data };
    switch (e.target.name) {

      default: auxValues[e.target.name] = e.target.value;
    }
    setData(auxValues);
  };

  const handleNumDupli = (e) => {
    e.preventDefault();
    console.log(data)
    api.get('/processos?numero=' + data.numero).then(r => {
      console.log(r.data)
      if(r.data.length > 0 && r.data[0].pk_pro != data.pk_pro){
        swal.fire({
          title: 'ATENÇÃO',
          text: "Número de processo já registrado!",
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não'
        }).then((result) => {
          if(result.value){
            let auxValues = { ...data }
            auxValues.numero = null;
            setData(auxValues)
          }          
        })
      }
    })
  }

  

  return (
    <div className="defaultReg">
      <NavBar />
      <div className="defaultRegContent">
        <div className="defaultHeader">
          <h3>{updateType ? 'Edição' : 'Inclusão'} de Processos</h3>
          <UserOptions />
        </div>
        <br />
        <form id="regForm" onSubmit={handleSubmit}>

          <FrTextInput
            maxLength='50'
            value={data.numero || ''}
            onChange={handleChange}
            name="numero"
            id="numero"
            color="#9c9595"
            label="Número"
            variant="outlined"
            size="small"
            required
            style={{ width: 330 }}
            onBlur={handleNumDupli}
          />
          <FrDatePicker
            maxLength='50'
            value={(data.data_registro || '').split('T')[0]}
            onChange={handleChange}
            name="data_registro"
            id="data_registro"
            color="#9c9595"
            label="Data Abertura"
            variant="outlined"
            size="small"
            style={{ width: 200 }}
          />
          <br/>
          <FrSelect
            value={data.fk_pes_cli | []}
            onChange={handleChange}
            name='fk_pes_cli'
            id='fk_pes_cli'
            label='Empresa'
            data={comboCliente}
            style={{ width: 300 }}
          />
          <br/>
          <FrSelect
            value={data.fk_org || []}
            onChange={handleChange}
            name='fk_org'
            id='fk_org'
            label='Órgão'
            data={comboOrgaos}
            style={{ width: 300 }}
          />
          <br/>
          <FrSelect
            value={data.fk_oun || []}
            onChange={handleChange}
            name='fk_oun'
            id='fk_oun'
            label='Unidade'
            data={comboUnidade}
            disabled={!(Number(data.fk_org) > 0)}
            style={{ width: 300 }}
          />
          <FrTextInput
            maxLength='300'
            value={data.descricao || ''}
            onChange={handleChange}
            name="descricao"
            id="descricao"
            color="#9c9595"
            label="Descrição"
            variant="outlined"
            size="small"
            style={{ width: '100%' }}
            rows={5}
            maxRows={5}
            multiline
          />
          <FrTextInput
            maxLength='1000'
            value={data.observacoes || ''}
            onChange={handleChange}
            name="observacoes"
            id="observacoes"
            color="#9c9595"
            label="Observações"
            variant="outlined"
            size="small"
            style={{ width: '100%' }}
            rows={5}
            maxRows={10}
            multiline
          />
          <div className="grupoTitulo">
            Concluído
          </div>
          <div className="grupoEdits">
            <FrDatePicker
              maxLength='50'
              value={(data.data_entrega || '').split('T')[0]}
              onChange={handleChange}
              name="data_entrega"
              id="data_entrega"
              color="#9c9595"
              label="Data Conclusão"
              variant="outlined"
              size="small"
              style={{ width: 200 }}
            /> 
            
          </div>
        </form>
        <div className="regButtonsDiv">
          {enableEdit === 'S' ?
            (<button onClick={handleSubmit}>Salvar</button>) : (<></>)}
          <button onClick={handleCancel}>{enableEdit === 'S' ? 'Cancelar' : 'Fechar'}</button>
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default RegProcessos;
