import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import NavBar from '../components/NavBar'
import FrTextInput from '../components/FrTextInput'
import FrSelect from '../components/FrSelect'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'

import api from '../services/api'
import { paramsToObject } from '../utils/functions'

import './styles/DefaultReg.css';
import FooterDelphus from '../components/FooterDelphus';

const swal = withReactContent(Swal)

function RegParticipantes() {
    const [data, setData] = useState({})
    const [updateType, setUpdateType] = useState(false)
    const [enableEdit, setEnableEdit] = useState('')
    const [comboFuncoes, setComboFuncoes] = useState([{ value: '', display: '' }])

    useEffect(() => {
        const fetchData = async () => {
            swal.fire({
                html: <FrLoading text="Carregando..." />,
                showConfirmButton: false,
                allowOutsideClick: false,
                allowEscapeKey: false
            })
            const urlParams = new URLSearchParams(window.location.search);
            let query = paramsToObject(urlParams)

            if (query.codigo > 0) {
                setEnableEdit(await api.getAcessos('Participantes', 'Alteracao'))
            } else {
                setEnableEdit(await api.getAcessos('Participantes', 'Inclusao'))
            }

            setComboFuncoes(await api.getComboData('Funcao'))

            if (query.codigo > 0) {
                setUpdateType(true)
                api.get('/participantes?pk_par=' + query.codigo).then(r => {
                    if (r.data[0]) {
                        console.log(r.data[0])
                        setData(r.data[0])
                        swal.close()
                    } else {
                        swal.fire('Código inválido', 'Código informado não encontrado!', 'warning')
                            .then((result) => {
                                if (result.value) {
                                    window.history.back()
                                }
                            })
                    }
                })
            } else swal.close()
        }
        fetchData()
    }, [])

    function consistData(data) {
        let required = [
            'nome', 'fk_fnc'
        ]

        let errors = []

        required.forEach((curr, index) => {
            let fieldName = curr
            if (curr == 'nome') fieldName = 'Nome'
            if (curr == 'fk_fnc') fieldName = 'Função'

            if (!data[curr]) return errors.push(fieldName)
        })

        return errors
    }

    function handleSubmit(e) {
        e.preventDefault();
        swal.fire({
            title: 'ATENÇÃO',
            text: "Deseja salvar " + (updateType ? 'as alterações' : 'a inclusão') + ' do registro?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                const auxValues = { ...data };
                let errors = consistData(auxValues)
                console.log(errors.length)
                if (errors.length === 0) {
                    if (updateType) {
                        api.post('/participantes/edit', auxValues).then(r => {
                            if (r.status === 200) {
                                swal.fire({
                                    title: 'Registro Alterado',
                                    text: "O registro foi alterado com sucesso!",
                                    icon: 'success',
                                }).then((result) => {
                                    if (result.value) {
                                        window.history.back()
                                    }
                                })
                            } else {
                                swal.fire({
                                    title: 'Erro!',
                                    text: "O registro não foi alterado!",
                                    icon: 'warning',
                                })
                            }
                        })
                    } else {
                        api.post('/participantes/add', auxValues).then(r => {
                            if (r.status === 200) {
                                swal.fire({
                                    title: 'Registro Adicionado',
                                    text: "O registro foi adicionado com sucesso!",
                                    icon: 'success',
                                }).then((result) => {
                                    if (result.value) {
                                        window.history.back()
                                    }
                                })
                            } else {
                                swal.fire({
                                    title: 'Erro!',
                                    text: "O registro não foi adicionado!",
                                    icon: 'warning',
                                })
                            }
                        })
                    }
                } else {
                    swal.fire({
                        title: 'Erro!',
                        text: "Verifique o(s) campo(s) " + errors.join(','),
                        icon: 'warning',
                    })
                }
            }
        })
    }

    function handleCancel(e) {
        e.preventDefault();
        swal.fire({
            title: 'ATENÇÃO',
            text: "Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                window.history.back()
            }
        })
    }

    const handleChange = (e) => {
        const auxValues = { ...data };
        switch (e.target.name) {
            case '':
            default: auxValues[e.target.name] = e.target.value;
        }
        setData(auxValues);
    };

    return (
        <div className="defaultReg">
            <NavBar />
            <div className="defaultRegContent">
                <div className="defaultHeader">
                    <h3>{updateType ? 'Edição' : 'Inclusão'} de Participante</h3>
                    <UserOptions />
                </div>
                <br />
                <form id="regForm" onSubmit={handleSubmit}>

                    <FrTextInput
                        maxLength='75'
                        value={data.nome || ''}
                        onChange={handleChange}
                        name="nome"
                        id="nome"
                        color="#9c9595"
                        label="Nome"
                        variant="outlined"
                        size="small"
                        required
                        style={{ width: 350 }}
                    />

                    <FrSelect
                        maxLength='75'
                        value={data.fk_fnc || ''}
                        onChange={handleChange}
                        name="fk_fnc"
                        id="fk_fnc"
                        color="#9c9595"
                        label="Função"
                        variant="outlined"
                        size="small"
                        required
                        style={{ width: 350 }}
                        data={comboFuncoes}
                    />

                </form>
                <div className="regButtonsDiv">
                    {enableEdit === 'S' ?
                        (<button onClick={handleSubmit}>Salvar</button>) : (<></>)}
                    <button onClick={handleCancel}>{enableEdit === 'S' ? 'Cancelar' : 'Fechar'}</button>
                </div>
                <FooterDelphus />
            </div>
        </div >
    );
}

export default RegParticipantes