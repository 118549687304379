import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import NavBar from '../components/NavBar'
import FrTextInput from '../components/FrTextInput'
import FrSelect from '../components/FrSelect'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import { ic_add_box } from "react-icons-kit/md/ic_add_box";
import FrTableReg from "../components/FrTableReg";
import Dialog from '@material-ui/core/Dialog';
import { MDBContainer, MDBIframe } from "mdbreact";

import api from '../services/api'
import { paramsToObject } from '../utils/functions'
import { Icon } from "react-icons-kit";

import './styles/DefaultReg.css';
import FooterDelphus from '../components/FooterDelphus';
import { Hidden } from '@material-ui/core';

const swal = withReactContent(Swal)

function Usuarios() {
  const [data, setData] = useState({})
  const [updateType, setUpdateType] = useState(false)
  const [comboGrupoUsu, setComboGrupoUsu] = useState([{ value: '', display: '' }])
  const [comboCli, setComboCli] = useState([{ value: '', display: '' }])
  const [enableEdit, setEnableEdit] = useState('N')
  const [fk_cli, setFk_cli] = useState(null)
  const [fk_usu, setFk_usu] = useState(null)
  const [dataCli, setDataCli] = useState([{ display: '' }])
  const [modal, setModal] = useState(false)
  const [pkModal, setPkModal] = useState(0);
  const [updatemodal, setUpdateModal] = useState(false);
  const [atualiza, setAtualiza] = useState()

  const columnsCli = [
    ({ id: 'value', numeric: false, label: 'Código', align: 'left', minWidth: 100 }),
    { id: 'nomecli', numeric: false, label: 'Cliente', align: 'left', minWidth: 300 },

  ]


  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)

      if (query.codigo > 0) {
        setEnableEdit(await api.getAcessos('Usuários', 'Alteracao'))
        setFk_usu(query.codigo)
      } else {
        setEnableEdit(await api.getAcessos('Usuários', 'Inclusao'))
      }

      setComboGrupoUsu(await api.getComboData('grupo_usuarios'))
      setComboCli(await api.getComboData('cliente'))

      if (query.codigo > 0) {
        setUpdateType(true)
        api.get('/usuarios?pk_usu=' + query.codigo).then(r => {
          if (r.data[0]) {
            console.log(r.data[0])
            setData(r.data[0])
            api.get('/usuarios/cliente?fk_usu=' + query.codigo).then(r => {
              if (r.data.length > 0) {
                console.log('ta dando erro aqui')
                console.log(updateType)
                setDataCli(r.data);
              }
              swal.close()
              console.log('oi')
            })
          } else {
            swal.fire('Código inválido', 'Código informado não encontrado!', 'warning')
              .then((result) => {
                if (result.value) {
                  window.history.back()
                }
              })
          }
        })
      } else swal.close()
    }

    fetchData()
  }, [])

  function consistData(data) {
    let required = [
      'nome',
      'fk_gpu',
      'login',
    ]

    let errors = []

    required.forEach((curr, index) => {
      let fieldName = curr
      if (curr === 'fk_gpu') fieldName = 'GRUPO DE USUÁRIO'
      if (!data[curr]) return errors.push(fieldName)
    })

    return errors
  }

  function handleSubmit(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Deseja salvar " + (updateType ? 'as alterações' : 'a inclusão') + ' do registro?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        const auxValues = { ...data };
        auxValues.host = window.location.href;
        let errors = consistData(auxValues)
        console.log(errors.length)
        if (errors.length === 0) {
          if (updateType) {
            api.post('/usuarios/edit', auxValues).then(r => {
              if (r.status === 200) {
                swal.fire({
                  title: 'Registro Alterado',
                  text: "O registro foi alterado com sucesso!",
                  icon: 'success',
                }).then((result) => {
                  if (result.value) {
                    window.history.back()
                  }
                })
              } else {
                swal.fire({
                  title: 'Erro!',
                  text: "O registro não foi alterado!",
                  icon: 'warning',
                })
              }
            })
          } else {
            try {
              api.post('/usuarios/add', auxValues).then(r => {
                console.log(r.status)
                if (r.status === 200) {
                  swal.fire({
                    title: 'Registro Adicionado',
                    text: "O registro foi adicionado com sucesso!",
                    icon: 'success',
                  }).then((result) => {
                    if (result.value) {
                      window.history.back()
                    }
                  })
                } else {
                  swal.fire({
                    title: 'Erro!',
                    text: "O registro não foi adicionado!",
                    icon: 'warning',
                  })
                }
              })
            } catch {
              swal.fire({
                title: 'Erro!',
                text: "O registro não foi adicionado!",
                icon: 'warning',
              })

            }

          }
        } else {
          swal.fire({
            title: 'Erro!',
            text: "Verifique o(s) campo(s) " + errors.join(','),
            icon: 'warning',
          })
        }
      }
    })
  }

  function handleCancel(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        window.history.back()
      }
    })
  }

  const handleChange = (e) => {
    const auxValues = { ...data };
    // auxValues[e.target.name] = e.target.value;
    switch (e.target.name) {
      default: auxValues[e.target.name] = e.target.value;
    }
    setData(auxValues);
  };

  function mostraModal(e) {
    e.preventDefault();
    setFk_cli(null)
    setModal(true);
    setUpdateModal(false)
    setPkModal(0)
    console.log(updateType)
    console.log(dataCli)
  }

  const closeModal = (e) => {
    e.preventDefault()
    setFk_cli(0)
    setModal(false)
  }

  const handleChangeModal = (e) => {
    e.preventDefault()
    setFk_cli(e.target.value)
  }

  function handleSubmitModal(e) {
    e.preventDefault();
    if(updateType){
      if ((!fk_cli)) {
        swal.fire({
          title: "Erro!",
          text: "Informe todos os campos!",
          icon: "warning",
        });
      } else
        if (!updatemodal) {  
          incluiCli(e);
        }
        else updateCli(e);
      closeModal(e);
    } else {
      console.log('teste')
      if ((!fk_cli)) {
        swal.fire({
          title: "Erro!",
          text: "Informe todos os campos!",
          icon: "warning",
        });
      } else
        if (!updatemodal) {  
          incluiInsert(e);
        }
        else updateInsert(e);
      closeModal(e);
    }
  }

  function updateInsert(e) {
    e.preventDefault();

    let item = [];
    let i;
    let nome=''

    let duplicidade = false;
  

    if (!duplicidade) {
      api.get('/clientes?fk_cli='+fk_cli).then(r => {
        nome = r.data[0].razao_social
        for (i = 0; i < dataCli.length; i++) {
          if (dataCli[i].pk == pkModal) {
            item.push({
              value: fk_cli,
              nomecli: nome,
              pk: pkModal,
  
            });
          } else {
            item.push({
              value: dataCli[0].value,
              nomecli: dataCli[0].nomecli,
              pk: dataCli[0].pk
  
            });
          }
          setAtualiza(true)
          if (i + 1 == dataCli.length) setDataCli(item);
        }
      })
    } else
      swal.fire({
        title: "Erro!",
        text: "Material/Medicamento já lançado! Não é permitido lançar em duplicidade!",
        icon: "warning",
      });
  }


  const incluiInsert = (e) =>{
    e.preventDefault();
    const auxValues = { ...data };
    let pk = 1
    let nome=''
    api.get('/clientes?pk_cli='+fk_cli).then(r=>{
      nome=r.data[0].razao_social
      console.log(typeof dataCli[0].nomecli === "undefined")
      if (typeof dataCli[0].nomecli === "undefined") {
        console.log('entrou if')
        setAtualiza(true)
        setDataCli([{
          value: fk_cli,
          nomecli: nome,
          pk: pk
  
        }])
        pk++
      } else {
  
        console.log('entrou else')
        let item = [{
          value: dataCli[0].value,
          nomecli: dataCli[0].nomecli,
          pk: pk
  
        }]
        pk++
        var i;
        for (i = 1; i < dataCli.length; i++) {
          item.push({
            value: dataCli[i].value,
            nomecli: dataCli[i].nomecli,
            pk: pk
  
          })
          pk++
        }
        item.push({
          value: fk_cli,
          nomecli: nome,
          pk: pk
  
        })
        pk++
        setAtualiza(true)
        setDataCli(item)
      }    
    })

  
    
  }
  const incluiCli = (e) => {
    e.preventDefault();
    let pk = 1;
    let duplicidade = false;
    

    if (!duplicidade) {
      api.post('/usuarios/addCliente/', { fk_cli, fk_usu  }).then(r => {
        if (r.status === 200) {
          swal.fire({
            title: 'Registro Adicionado',
            text: "O registro foi adicionado com sucesso!",
            icon: 'success',
          }).then((result) => {
            if (result.value) {
              api.get('/usuarios/cliente?fk_usu=' + fk_usu).then(r => {
                if (r.data.length > 0) {
                  setAtualiza(true)
                  setDataCli(r.data);

                }
              })
            }
          })
        }
      })
    } else
      swal.fire({
        title: "Erro!",
        text: "Produto já lançado! Não é permitido lançar em duplicidade!",
        icon: "warning",
      });

  }

  useEffect(() => {
    console.log(dataCli)
    api.get('/usuarios/cliente?fk_usu=' + fk_usu).then(r => {
      if (r.data.length > 0) {
        console.log(r.data)
        console.log(updateType)
        setDataCli(r.data);
      }
      console.log('oi')
    })
  }, [atualiza, fk_usu])

  
  function updateCli(e) {
    e.preventDefault();

    let item = [];
    let i;

    let duplicidade = false;
    

    if (!duplicidade) {
      api.post('/usuarios/editCliente', { fk_cli,fk_usu }).then(r => {
        if (r.status === 200) {
          swal.fire({
            title: 'Registro Alterado',
            text: "O registro foi alterado com sucesso!",
            icon: 'success',
          }).then((result) => {
            if (result.value) {
              api.get('/cliente_produto?fk_cli=' + fk_cli).then(r => {
                if (r.data.length > 0) {
                  setAtualiza(true)
                  setDataCli(r.data);
                }
              })
            }
          })
        }
      })
    } else
      swal.fire({
        title: "Erro!",
        text: "Produto já lançado! Não é permitido lançar em duplicidade!",
        icon: "warning",
      });

  }

  return (
    <div className="defaultReg">
      <NavBar />
      <div className="defaultRegContent">
        <div className="defaultHeader">
          <h3>{updateType ? 'Edição' : 'Inclusão'} de Usuário</h3>
          <UserOptions />
        </div>
        <br />
        <form id="regForm" onSubmit={handleSubmit}>

          <FrTextInput
            maxLength='45'
            value={data.nome || ''}
            onChange={handleChange}
            name="nome"
            id="nome"
            color="#9c9595"
            label="Nome"
            variant="outlined"
            size="small"
            required
            style={{ width: 500 }}
          />
          <FrSelect
            value={data.fk_gpu || ''}
            onChange={handleChange}
            name="fk_gpu"
            id="fk_gpu"
            label='Grupo de Usuário'
            data={comboGrupoUsu}
            style={{ width: 250 }}
          />
          <br />
          <br />
          <p>Informações para Login</p>
          <FrTextInput
            maxLength='30'
            value={data.login || ''}
            onChange={handleChange}
            name="login"
            id="login"
            color="#9c9595"
            label="Login"
            variant="outlined"
            size="small"
            required
            style={{ width: 200 }}
            disabled={updateType}
          />
          <div className="grupoEdits">
            <button onClick={(e) => mostraModal(e)}>
              <Icon icon={ic_add_box} size={18} />
              Incluir
            </button>

            <div >
              <FrTableReg setPkModal={setPkModal}
                setUpdateModal={setUpdateModal}
                setModal={setModal}
          
                setFk_cli={setFk_cli}
                editar={false}
                fk_usu={fk_usu}
                setDataGrid={setDataCli}
                detail regName="clienteUsu"
                columns={columnsCli}
                searched={false}
                updateType={updateType}
                // setPage={setPage}
                codeName="display"
                data={dataCli}
              />



              <div>
                <Dialog aria-labelledby="simple-dialog-title" open={modal} fullWidth={false} maxWidth={'lg'}>
                  <div className="FrModalFooter">
                    <div className="FrModalButtonsDiv">
                      <MDBContainer className="text-center">

                        
                          <FrSelect
                            value={fk_cli}
                            onChange={handleChangeModal}
                            name="fk_cli"
                            id="fk_cli"
                            label="Cliente"
                            data={comboCli}
                            style={{ width: 300, marginTop: '10px', marginBottom: 0}}
                          />

                      
                      </MDBContainer>
                      <button onClick={handleSubmitModal}>Salvar</button>
                      <button onClick={closeModal}>Cancelar</button>
                    </div>
                  </div>
                </Dialog>
              </div>
            </div>
          </div>  


        </form>
        <div className="regButtonsDiv">
          {enableEdit === 'S' ?
            (<button onClick={handleSubmit}>Salvar</button>) : (<></>)}
          <button onClick={handleCancel}>{enableEdit === 'S' ? 'Cancelar' : 'Fechar'}</button>
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default Usuarios;
