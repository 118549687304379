import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import NavBar from '../components/NavBar'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import TabsApua from '../components/TabsApua'

import api from '../services/api'
import { paramsToObject} from '../utils/functions'

import './styles/DefaultReg.css';
import FooterDelphus from '../components/FooterDelphus';

const swal = withReactContent(Swal)

function RegApua() {
  const [data, setData] = useState({ ativo: 'S' })
  const [updateType, setUpdateType] = useState(false)
  const [enableEdit, setEnableEdit] = useState('N')
  const [comboCidades, setComboCidades] = useState([{ value: '', display: '' }])
  const [comboPiso, setComboPiso] = useState([{ value: '', display: '' }])
  const [comboSituacao, setComboSituacao] = useState([{ value: '', display: '' }])
  const [comboClientes, setComboClientes] = useState([{ value: '', display: '' }])
  const [dataRelacionadosApua, setDataRelacionadosApua] = useState([])
  
  const [codigo, setCodigo] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      setComboSituacao(await api.getComboData('situacao_padrao'))
      setComboPiso(await api.getComboData('tipo_piso'))
      setComboCidades(await api.getComboData('cidades'))
      setComboClientes(await api.getComboData('cliente'))

      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)

      if (query.codigo > 0) {
        setEnableEdit(await api.getAcessos('Apua', 'Alteracao'))
      } else {
        setEnableEdit(await api.getAcessos('Apua', 'Inclusao'))
      }

      if (query.codigo > 0) {
        setUpdateType(true)
        setCodigo(query.codigo)
        api.get('/apua?pk_apu=' + query.codigo).then(r => {
          if (r.data[0]) {
            // console.log(r.data[0])
            setData(r.data[0])
            api.get('/apua/cliente?pk_apu=' + query.codigo).then(r => {
              if (r.data) {
                console.log(r.data)
                setDataRelacionadosApua(r.data)
              }
              swal.close()
            })
          } else {
            swal.fire('Código inválido', 'Código informado não encontrado!', 'warning')
              .then((result) => {
                if (result.value) {
                  window.history.back()
                }
              })
          }
        })
      } else swal.close()
    }

    fetchData()
  }, [])

  function handleSubmit(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Deseja salvar " + (updateType ? 'as alterações' : 'a inclusão') + ' do registro?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        const auxValues = { ...data };
        let errors = []
        if (errors.length === 0) {
          auxValues.ica_validade = auxValues.ica_validade ? auxValues.ica_validade.split('T')[0] : null
          if (updateType) {
            api.post('/apua/edit', auxValues).then(r => {
              if (r.status === 200) {
                swal.fire({
                  title: 'Registro Alterado',
                  text: "O registro foi alterado com sucesso!",
                  icon: 'success',
                }).then((result) => {
                  if (result.value) {
                    window.history.back()
                  }
                })
              } else {
                swal.fire({
                  title: 'Erro!',
                  text: "O registro não foi alterado!",
                  icon: 'warning',
                })
              }
            })
          } else {
            api.post('/apua/add', auxValues).then(r => {
              if (r.status === 200) {
                swal.fire({
                  title: 'Registro Adicionado',
                  text: "O registro foi adicionado com sucesso!",
                  icon: 'success',
                }).then((result) => {
                  if (result.value) {
                    window.history.back()
                  }
                })
              } else {
                swal.fire({
                  title: 'Erro!',
                  text: "O registro não foi adicionado!",
                  icon: 'warning',
                })
              }
            })
          }
        } else {
          swal.fire({
            title: 'Erro!',
            text: "Verifique o(s) campo(s) " + errors.join(','),
            icon: 'warning',
          })
        }
      }
    })
  }

  const reloadRelacionadosApua = () => {
    swal.fire({
      html: <FrLoading text="Carregando..." />,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false
    })
    api.get('/apua/cliente?pk_apu=' + data.pk_apu).then(r => {
      swal.close()
      if (r.data) {
        console.log(r.data)
        setDataRelacionadosApua(r.data)
      }
    })
  }

  function handleCancel(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        window.history.back()
      }
    })
  }

  const handleChange = (e) => {
    const auxValues = { ...data };
    switch (e.target.name) {
      case 'CNPJ':
      default: auxValues[e.target.name] = e.target.value;
    }
    setData(auxValues);
  };

  
  return (
    <div className="defaultReg">
      <NavBar />
      <div className="defaultRegContent">
        <div className="defaultHeader">
          <h3>{updateType ? 'Edição' : 'Inclusão'} de Apua</h3>
          <UserOptions />
        </div>
        <br />
        <form id="regForm" onSubmit={handleSubmit}>
          <TabsApua
            data={data}
            handleChange={handleChange}
            setData={setData}
            comboSituacao={comboSituacao}
            comboPiso={comboPiso}
            comboCidades={comboCidades}
            comboClientes={comboClientes}
            updateType={updateType}
            dataRelacionadosApua={dataRelacionadosApua}
            reloadRelacionadosApua={reloadRelacionadosApua}
            pk_apu={codigo} />
        </form>
        <div className="regButtonsDiv">
          {enableEdit === 'S' ?
            (<button onClick={handleSubmit}>Salvar</button>) : (<></>)}
          <button onClick={handleCancel}>{enableEdit === 'S' ? 'Cancelar' : 'Fechar'}</button>
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default RegApua;
