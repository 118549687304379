import Login from '../pages/Login';
import Home from '../pages/Home';
import Cidades from '../pages/Cidades';
import RegCidades from '../pages/RegCidades';
import Estados from '../pages/Estados';
import RegEstados from '../pages/RegEstados';
import Grupo_Usuarios from '../pages/Grupo_Usuarios';
import RegGrupo_Usuarios from '../pages/RegGrupo_Usuarios';
import Usuarios from '../pages/Usuarios';
import RegUsuarios from '../pages/RegUsuarios';
import ChangePw from '../pages/ChangePw';
import ForgotPw from '../pages/ForgotPw';
import TipoLicenca from '../pages/TipoLicenca';
import RegTipoLicenca from '../pages/RegTipoLicenca';
import TipoPortaria from '../pages/TipoPortaria';
import RegTipoPortaria from '../pages/RegTipoPortaria';
import SituacaoAeronave from '../pages/SituacaoAeronave';
import RegSituacaoAeronave from '../pages/RegSituacaoAeronave';
import SituacaoCliente from '../pages/SituacaoCliente';
import RegSituacaoCliente from '../pages/RegSituacaoCliente';
import SituacaoPiloto from '../pages/SituacaoPiloto';
import RegSituacaoPiloto from '../pages/RegSituacaoPiloto';
import SituacaoPadrao from '../pages/SituacaoPadrao';
import RegSituacaoPadrao from '../pages/RegSituacaoPadrao';
import TipoDocumento from '../pages/TipoDocumento';
import RegTipoDocumento from '../pages/RegTipoDocumento';
import TipoRelacaoCliente from '../pages/TipoRelacaoCliente';
import RegTipoRelacaoCliente from '../pages/RegTipoRelacaoCliente';
import Cliente from '../pages/Cliente';
import RegCliente from '../pages/RegCliente';
import RegTipoCombustivel from '../pages/RegTipoCombustivel';
import TipoCombustivel from '../pages/TipoCombustivel';
import PilotoClasse from '../pages/TipoPilotoClasse';
import RegPilotoClasse from '../pages/RegPilotoClasse';
import RegAeronaveModelo from '../pages/RegAeronaveModelo';
import AeronaveModelo from '../pages/AeronaveModelo';
import RegAeronaveFabricante from '../pages/RegAeronaveFabricante';
import AeronaveFabricante from '../pages/AeronaveFabricante';
import RegAeronaveCategoria from '../pages/RegAeronaveCategoria';
import AeronaveCategoria from '../pages/AeronaveCategoria';
import RegAeronaveCor from '../pages/RegAeronaveCor';
import AeronaveCor from '../pages/AeronaveCor';
import RegPiloto from '../pages/RegPiloto';
import Piloto from '../pages/Piloto';
import RegAeronave from '../pages/RegAeronave';
import Aeronave from '../pages/Aeronave';
import RegMecanico from '../pages/RegMecanico';
import Mecanico from '../pages/Mecanico';
import RegMecanicoClasse from '../pages/RegMecanicoClasse';
import MecanicoClasse from '../pages/MecanicoClasse';
import RegSituacaoMecanico from '../pages/RegSituacaoMecanico';
import SituacaoMecanico from '../pages/SituacaoMecanico';
import SituacaoAeroporto from '../pages/SituacaoAeroporto';
import RegSituacaoAeroporto from '../pages/RegSituacaoAeroporto';
import TipoAeroporto from '../pages/TipoAeroporto';
import RegTipoAeroporto from '../pages/RegTipoAeroporto';
import Aeroporto from '../pages/Aeroporto';
import RegAeroporto from '../pages/RegAeroporto';
import Apua from '../pages/Apua';
import RegApua from '../pages/RegApua';
import Orgao from '../pages/Orgao';
import Vencimentos from '../pages/Vencimentos';
import RegOrgao from '../pages/RegOrgao';
import Notificacoes from '../pages/Notificacoes';
import RegNotificacoes from '../pages/RegNotificacoes';
import RegModeloDocumento from '../pages/RegModeloDocumento';
import ModeloDocumento from '../pages/ModeloDocumento';
import ConsultaRelacionados from '../pages/ConsultaRelacionados';
import Autuacoes from '../pages/Autuacoes'
import RegAutuacoes from '../pages/RegAutuacoes'
import Anotacoes from '../pages/Anotacoes';
import RegAnotacoes from '../pages/RegAnotacoes';
import OrgaoUnidade from '../pages/OrgaoUnidade';
import RegOrgaoUnidade from '../pages/RegOrgaoUnidade';
import Processos from '../pages/Processos';
import RegProcessos from '../pages/RegProcessos';
import SituacaoVisita from '../pages/SituacaoVisita';
import RegSituacaoVisita from '../pages/RegSituacaoVisita';
import TipoNConformidade from '../pages/TipoNConformidade';
import RegTipoNConformidade from '../pages/RegTipoNConformidade';
import Visitas from '../pages/Visitas';
import RegVisitas from '../pages/RegVisitas';
import TemaPalestras from '../pages/Tema_palestra';
import RegPalestras from '../pages/RegTemaPalestras';
import PalestraMinistrante from '../pages/Palestra_ministrante';
import RegMinistrante from '../pages/RegMinistrante';
import Participantes from '../pages/Participantes';
import RegParticipantes from '../pages/RegParticipantes';
import Funcao from '../pages/Funcao';
import RegFuncao from '../pages/RegFuncao';
import Palestra from '../pages/Palestra';
import RegPalestra from '../pages/RegPalestra';
import SituacaoPalestra from '../pages/SituacaoPalestra';
import RegSituacaoPalestra from '../pages/RegSituacaoPalestra';
import TiposPiso from '../pages/TiposPiso';
import RegTiposPiso from '../pages/RegTiposPiso';
import Engenheiro from '../pages/Engenheiro';
import RegEngenheiro from '../pages/RegEngenheiro';
import Tecnico_Agricola from '../pages/Tecnico_Agricola';
import RegTecnico_Agricola from '../pages/RegTecnico_Agricola';
import CategoriaAnotacao from '../pages/CategoriaAnotacao';
import SituacaoAnotacao from '../pages/SituacaoAnotacao';
import RegCategoriaAnotacao from '../pages/RegCategoriaAnotacao';
import RegSituacaoAnotacao from '../pages/RegSituacaoAnotacao';


export const routes = [
    {
        path: '/forgotPw',
        component: ForgotPw,
        protected: false,
        exact: true,
        checkPermission: false,
        register: false
    },

    {
        path: '/changePw',
        component: ChangePw,
        protected: false,
        exact: true,
        checkPermission: false,
        register: false
    },

    {
        path: '/login',
        component: Login,
        protected: false,
        exact: true,
        checkPermission: false,
        register: false
    },
    {
        path: '/home',
        component: Home,
        protected: true,
        exact: true,
        checkPermission: false,
        register: false
    },
    {
        path: '/cidades',
        component: Cidades,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Cidades'
    },
    {
        path: '/cidades/registro',
        component: RegCidades,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/estados',
        component: Estados,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Estados'
    },
    {
        path: '/estados/registro',
        component: RegEstados,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/usuarios',
        component: Usuarios,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Usuários'
    },
    {
        path: '/usuarios/registro',
        component: RegUsuarios,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/grupo_usuarios',
        component: Grupo_Usuarios,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Grupos de Usuários'
    },
    {
        path: '/grupo_usuarios/registro',
        component: RegGrupo_Usuarios,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/tipo_licenca/registro',
        component: RegTipoLicenca,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/tipo_licenca',
        component: TipoLicenca,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Tipo de Licença'
    },
    {
        path: '/tipo_combustivel/registro',
        component: RegTipoCombustivel,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/tipo_combustivel',
        component: TipoCombustivel,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Tipo de Combustível'
    },
    {
        path: '/piloto_classe/registro',
        component: RegPilotoClasse,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/piloto_classe',
        component: PilotoClasse,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Classe de Piloto'
    },
    {
        path: '/tipo_portaria/registro',
        component: RegTipoPortaria,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/tipo_portaria',
        component: TipoPortaria,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Tipo de Portaria'
    },
    {
        path: '/situacao_aeronave/registro',
        component: RegSituacaoAeronave,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/situacao_aeronave',
        component: SituacaoAeronave,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Situação Aeronave'
    },
    {
        path: '/aeronave_cor/registro',
        component: RegAeronaveCor,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/aeronave_cor',
        component: AeronaveCor,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Cor de Aeronave'
    },
    {
        path: '/aeronave_modelo/registro',
        component: RegAeronaveModelo,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/aeronave_modelo',
        component: AeronaveModelo,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Modelo de Aeronave'
    },
    {
        path: '/aeronave_fabricante/registro',
        component: RegAeronaveFabricante,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/aeronave_fabricante',
        component: AeronaveFabricante,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Fabricante de Aeronave'
    },
    {
        path: '/aeronave_categoria/registro',
        component: RegAeronaveCategoria,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/aeronave_categoria',
        component: AeronaveCategoria,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Categoria de Aeronave'
    },
    {
        path: '/situacao_cliente/registro',
        component: RegSituacaoCliente,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/situacao_cliente',
        component: SituacaoCliente,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Situação Cliente'
    },
    {
        path: '/situacao_piloto/registro',
        component: RegSituacaoPiloto,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/situacao_piloto',
        component: SituacaoPiloto,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Situação Piloto'
    },
    {
        path: '/situacao_visita/registro',
        component: RegSituacaoVisita,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/situacao_visita',
        component: SituacaoVisita,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Situação Visita'
    },
    {
        path: '/tipo_documento/registro',
        component: RegTipoDocumento,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/tipo_documento',
        component: TipoDocumento,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Tipo de Documento'
    },
    {
        path: '/tipo_relacao_cliente/registro',
        component: RegTipoRelacaoCliente,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/tipo_relacao_cliente',
        component: TipoRelacaoCliente,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Tipo de Relação de Cliente'
    },
    {
        path: '/situacao_padrao/registro',
        component: RegSituacaoPadrao,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/situacao_padrao',
        component: SituacaoPadrao,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Situação Padrão'
    },
    {
        path: '/cliente',
        component: Cliente,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Cliente'
    },
    {
        path: '/cliente/registro',
        component: RegCliente,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/piloto',
        component: Piloto,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Piloto'
    },
    {
        path: '/piloto/registro',
        component: RegPiloto,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/aeronave',
        component: Aeronave,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Aeronave'
    },
    {
        path: '/aeronave/registro',
        component: RegAeronave,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },

    {
        path: '/mecanicos',
        component: Mecanico,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Mecânicos'
    },
    {
        path: '/mecanicos/registro',
        component: RegMecanico,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/mecanico_classe',
        component: MecanicoClasse,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Classe de Mecânico'
    },
    {
        path: '/mecanico_classe/registro',
        component: RegMecanicoClasse,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/situacao_mecanico',
        component: SituacaoMecanico,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Situação Mecânico'
    },
    {
        path: '/situacao_mecanico/registro',
        component: RegSituacaoMecanico,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },

    {
        path: '/situacao_aeroporto',
        component: SituacaoAeroporto,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Situação Aeroporto'
    },
    {
        path: '/situacao_aeroporto/registro',
        component: RegSituacaoAeroporto,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/tipo_aeroporto',
        component: TipoAeroporto,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Tipo de Aeroporto'
    },
    {
        path: '/tipo_aeroporto/registro',
        component: RegTipoAeroporto,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },

    {
        path: '/aeroporto',
        component: Aeroporto,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Aeroporto'
    },
    {
        path: '/aeroporto/registro',
        component: RegAeroporto,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/apua',
        component: Apua,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Apua'
    },
    {
        path: '/apua/registro',
        component: RegApua,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/orgao',
        component: Orgao,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Orgao'
    },
    {
        path: '/orgao/registro',
        component: RegOrgao,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/notificacoes',
        component: Notificacoes,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Notificacoes'
    },
    {
        path: '/notificacoes/registro',
        component: RegNotificacoes,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/tipo_nconformidade/registro',
        component: RegTipoNConformidade,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/tipo_nconformidade',
        component: TipoNConformidade,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Tipo de Não Conformidade'
    },
    {
        path: '/modelo_documento',
        component: ModeloDocumento,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Modelo de Documento'
    },
    {
        path: '/modelo_documento/registro',
        component: RegModeloDocumento,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/relacionados',
        component: ConsultaRelacionados,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'ConsultaRelacionados'
    },
    {
        path: '/vencimentos',
        component: Vencimentos,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Vencimentos'
    },
    {
        path: '/autuacoes',
        component: Autuacoes,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Autuacoes'
    },
    {
        path: '/autuacoes/registro',
        component: RegAutuacoes,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/anotacoes',
        component: Anotacoes,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Anotacoes'
    },
    {
        path: '/anotacoes/registro',
        component: RegAnotacoes,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/orgao_unidade',
        component: OrgaoUnidade,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Orgao Unidade'
    },
    {
        path: '/orgao_unidade/registro',
        component: RegOrgaoUnidade,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/processos',
        component: Processos,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Processos'
    },
    {
        path: '/processos/registro',
        component: RegProcessos,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },

    {
        path: '/visitas',
        component: Visitas,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Visitas'
    },
    {
        path: '/visitas/registro',
        component: RegVisitas,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/tema_palestra',
        component: TemaPalestras,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Tema Palestra'
    },
    {
        path: '/tema_palestra/registro',
        component: RegPalestras,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/palestra_ministrante',
        component: PalestraMinistrante,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Palestra Ministrante'
    },
    {
        path: '/palestra_ministrante/registro',
        component: RegMinistrante,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true,
    },
    {
        path: '/participantes',
        component: Participantes,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Participantes'
    },
    {
        path: '/participantes/registro',
        component: RegParticipantes,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true,
    },
    {
        path: '/funcao',
        component: Funcao,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Funcao'
    },
    {
        path: '/funcao/registro',
        component: RegFuncao,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true,
    },
    {
        path: '/palestra',
        component: Palestra,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Palestra'
    },
    {
        path: '/palestra/registro',
        component: RegPalestra,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true,
    },
    {
        path: '/situacao_palestra',
        component: SituacaoPalestra,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Situação Palestra'
    },
    {
        path: '/situacao_palestra/registro',
        component: RegSituacaoPalestra,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true,
    },
    {
        path: '/TiposPiso',
        component: TiposPiso,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Tipos de Piso'
    },
    {
        path: '/TiposPiso/registro',
        component: RegTiposPiso,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
    },
    {
        path: '/engenheiro',
        component: Engenheiro,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Engenheiro'
    },
    {
        path: '/engenheiro/registro',
        component: RegEngenheiro,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Engenheiro'
    },
    {
        path: '/tecnico_agricola',
        component: Tecnico_Agricola,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Tecnico_agricola'
    },
    {
        path: '/tecnico_agricola/registro',
        component: RegTecnico_Agricola,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Tecnico_agricola'
    },
    {
        path: '/categoria_anotacao',
        component: CategoriaAnotacao,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Categoria Anotação'
    },
    {
        path: '/situacao_anotacao',
        component: SituacaoAnotacao,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Situação Anotação'
    },
    {
        path: '/categoria_anotacao/registro',
        component: RegCategoriaAnotacao,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Categoria Anotação'
    },
    {
        path: '/situacao_anotacao/registro',
        component: RegSituacaoAnotacao,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Situação Anotação'
    },

];

export const fallbackRoute = Home

