import React, { useEffect, useState } from 'react';
import Nav from 'react-bootstrap/Nav'
import NavBar from '../components/NavBar'
import { Icon } from 'react-icons-kit'

import { ic_work } from 'react-icons-kit/md/ic_work'
import { driversLicense } from 'react-icons-kit/fa/driversLicense'
import { ic_airplanemode_active } from 'react-icons-kit/md/ic_airplanemode_active'
import { ic_landscape } from 'react-icons-kit/md/ic_landscape'
import { ic_flight_takeoff } from 'react-icons-kit/md/ic_flight_takeoff'
import { ic_build } from 'react-icons-kit/md/ic_build'
import { ic_gavel } from 'react-icons-kit/md/ic_gavel'
import { ic_launch } from 'react-icons-kit/md/ic_launch'
import { ic_notifications } from 'react-icons-kit/md/ic_notifications'
import { ic_markunread_mailbox } from 'react-icons-kit/md/ic_markunread_mailbox'
import { ic_location_pin } from 'react-icons-kit/md/ic_location_pin'
import { videoCamera } from 'react-icons-kit/icomoon/videoCamera'
import {fileText} from 'react-icons-kit/icomoon/fileText'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import Tooltip from '@material-ui/core/Tooltip';
import UserOptions from '../components/UserOptions'
import './styles/Home.css';
import FooterDelphus from '../components/FooterDelphus';
import api from '../services/api'
import moment from 'moment';
import { paramsToObject } from '../utils/functions';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Home() {
  const [auth, setAuth] = useState(JSON.parse(localStorage['authejr'] || JSON.stringify({ authenticated: false, user: 0 })));
  const [notifications, setNotifications] = useState([])
  const [open, setOpen] = useState(false)

  moment.locale('pt-br')

  useEffect(() => {

    const urlParams = new URLSearchParams(window.location.search);
    let query = paramsToObject(urlParams)
    let data = new Date();
    let dataFormatada = (data.getFullYear() + "-" + ((data.getMonth() + 1)) + "-" + (data.getDate() ))
    api.get('/usuarios?pk_usu='+auth.user).then(r=> {
      let usu = r.data[0].pk_usu
      console.log('oi')
      // let dataLogin = dataFormatada = (r.data[0].ultimo_login.getFullYear() + "-" + ((r.data[0].ultimo_login.getMonth() + 1)) + "-" + (r.data[0].ultimo_login.getDate() ))      
      if (moment(data) != moment(r.data[0].ultimo_login) && query.login=="true"){
        api.get('/controle?notificacoes=true').then(r => {
          console.log(r.data)
          setNotifications(r.data)
          setOpen(r.data.length > 0)
          api.post('/usuarios/ultimo_login?pk_usu='+ usu);
        })
      }
    })

  }, [])

  const handleClose = (e) => {
    e.preventDefault()
    setOpen(false)
  }

  const controles = (item, index) => {
    return (
      <Nav.Link href={"/ejr/" + String(item.origem).toLowerCase() + '/registro?codigo=' + Number(item.cod_origem)} target="_blank" className="atalhoControle">
        <Icon icon={ic_launch} size={20} />
        <strong>{'[' + item.origem + '] ' + item.modelo + ' - ' + item.tipo_doc + ' (Prazo Limite: ' + item.prazo_limite + ')'}</strong>
      </Nav.Link>
    )
  }

  return (
    <div className="Home">
      <NavBar />
      <div className="HomeContent">
        <div className="defaultHeader">
          <h3>Seja bem vindo(a), <strong>{auth.name}</strong>. Seu último login foi em {auth.ultimo_login}.</h3>
          <UserOptions />
        </div>
        <br />
        <div>
          <Dialog
            open={open && auth.grupo == 1}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle id="alert-dialog-slide-title">{"CONTROLE DE PRAZOS"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                Os seguintes itens abaixo estão para vencer ou vencidos:
              </DialogContentText>
              {notifications.map(controles)}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Fechar
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        <div className="atalhos">
          <div className='grid'>
          {auth.grupo == 1 && 
            <>
              <Tooltip title="Segure Ctrl para abrir em nova aba">
                <Nav.Link href="/ejr/cliente" className="atalho">
                  <Icon icon={ic_work} size={40} />
                  <strong>{'Clientes'}</strong>
                </Nav.Link>
              </Tooltip>
            
              <Tooltip title="Segure Ctrl para abrir em nova aba">
                <Nav.Link href="/ejr/aeronave" className="atalho">
                  <Icon icon={ic_airplanemode_active} size={40} />
                  <strong>{'Aeronaves'}</strong>
                </Nav.Link>
              </Tooltip>

              <Tooltip title="Segure Ctrl para abrir em nova aba">
                <Nav.Link href="/ejr/piloto" className="atalho">
                  <Icon icon={driversLicense} size={40} />
                  <strong>{'Pilotos'}</strong>
                </Nav.Link>
              </Tooltip>

              <Tooltip title="Segure Ctrl para abrir em nova aba">
                <Nav.Link href="/ejr/apua" className="atalho">
                  <Icon icon={ic_landscape} size={40} />
                  <strong>{'Apuas'}</strong>
                </Nav.Link>
              </Tooltip>

              <Tooltip title="Segure Ctrl para abrir em nova aba">
                <Nav.Link href="/ejr/mecanicos" className="atalho">
                  <Icon icon={ic_build} size={40} />
                  <strong>{'Mecânicos'}</strong>
                </Nav.Link>
              </Tooltip>

              <Tooltip title="Segure Ctrl para abrir em nova aba">
                <Nav.Link href="/ejr/aeroporto" className="atalho">
                  <Icon icon={ic_flight_takeoff} size={40} />
                  <strong>{'Aeródromos'}</strong>
                </Nav.Link>
              </Tooltip>

              <Tooltip title="Segure Ctrl para abrir em nova aba">
                <Nav.Link href="/ejr/orgao" className="atalho">
                  <Icon icon={ic_gavel} size={40} />
                  <strong>{'Órgãos'}</strong>
                </Nav.Link>
              </Tooltip>

              <Tooltip title="Segure Ctrl para abrir em nova aba">
                <Nav.Link href="/ejr/notificacoes" className="atalho">
                  <Icon icon={ic_notifications} size={40} />
                  <strong>{'Notificações'}</strong>
                </Nav.Link>
              </Tooltip>
            </>
          }

            <Tooltip title="Segure Ctrl para abrir em nova aba">
              <Nav.Link href="/ejr/palestra?buscar=true&fk_stp=2" className="atalho">
                <Icon icon={videoCamera} size={40} />
                <strong>{'Palestras'}</strong>
              </Nav.Link>
            </Tooltip>

            {auth.grupo == 1 && 
            <>
              <Tooltip title="Segure Ctrl para abrir em nova aba">
                <Nav.Link href="/ejr/processos?buscar=true&concluidos=false" className="atalho">
                  <Icon icon={ic_markunread_mailbox} size={40} />
                  <strong>{'Processos'}</strong>
                </Nav.Link>
              </Tooltip>

              <Tooltip title="Segure Ctrl para abrir em nova aba">
                <Nav.Link href="/ejr/visitas?buscar=true&fk_svi=6" className="atalho">
                  <Icon icon={ic_location_pin} size={40} />
                  <strong>{'Vistorias'}</strong>
                </Nav.Link>
              </Tooltip>

              <Tooltip title="Segure Ctrl para abrir em nova aba">
                <Nav.Link href="/ejr/engenheiro" className="atalho">
                  <Icon icon={driversLicense} size={40} />
                  <strong>{'Engenheiros'}</strong>
                </Nav.Link>
              </Tooltip>

              <Tooltip title="Segure Ctrl para abrir em nova aba">
                <Nav.Link href="/ejr/tecnico_agricola" className="atalho">
                  <Icon icon={driversLicense} size={40} />
                  <strong>{'Técnicos Agrícolas'}</strong>
                </Nav.Link>
              </Tooltip>

              <Tooltip title="Segure Ctrl para abrir em nova aba">
                <Nav.Link href="/ejr/anotacoes?buscar=true&fk_asi=1" className="atalho">
                  <Icon icon={fileText} size={40} />
                  <strong>{'Anotações'}</strong>
                </Nav.Link>
              </Tooltip>
            </>
            }
          </div>

        </div>
        {/* <div className="HomeOptions">
          <FrCollapsibleMenu/>
        </div> */}
        <FooterDelphus />
      </div>
    </div>
  );
}

export default Home;
