import React, { useState } from 'react';
import Swal from 'sweetalert2'
import Nav from 'react-bootstrap/Nav'
import withReactContent from 'sweetalert2-react-content'
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import FrTextInput from '../FrTextInput'
import FrImageEdit from '../FrImageEdit'
import FrDatePicker from '../FrDatePicker'
import FrSelect from '../FrSelect'
import FrLoading from '../FrLoading'
import ListRegisters from '../ListRegisters'
import ListRegistersDetailedFilter from '../ListRegistersDetailedFilter'
import { Icon } from 'react-icons-kit'
import { ic_save } from 'react-icons-kit/md/ic_save'
import { ic_mode_edit } from 'react-icons-kit/md/ic_mode_edit'
import { ic_delete } from 'react-icons-kit/md/ic_delete'
import { ic_undo } from 'react-icons-kit/md/ic_undo'
import {ic_launch} from 'react-icons-kit/md/ic_launch'
import {upload} from 'react-icons-kit/fa/upload'
import Tooltip from '@material-ui/core/Tooltip';
import $ from 'jquery'

import consultaCep from '../../services/consultaCep'
import { paramsToObject, validarcnpj, onlyNumbers, removeSpecialChar } from '../../utils/functions'
import { mcnpj, mTel, mCEP } from '../../utils/masks'

import api from '../../services/api'

import './index.css';

const swal = withReactContent(Swal)

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTabs-root': {
            backgroundColor: '#9c9595',
        },
        flexGrow: 1,
        width: '100%',
        border: '2px solid #9c9595',
        '& .MuiTabs-indicator': {
            backgroundColor: 'white',
            height: '5px'
        },
        '& .MuiTabs-scrollButtonsDesktop': {
            backgroundColor: '#9c9595',
        }
        //   backgroundColor: theme.palette.background.paper,
    },
}));

function TabsApua(props) {
    const classes = useStyles();

    //Tabs
    const [value, setValue] = React.useState(0);
    const handleChangeTabs = (event, newValue) => {
        setValue(newValue);
    };

    //Dados
    let data = props.data
    const setData = props.setData
    const handleChange = props.handleChange
    const [erroCep, setErroCep] = useState(false)
    const [erroCepOperacional, setErroCepOperacional] = useState(false)

    function handleCep(e) {
        e.preventDefault();
        if (e.target.value) {
            if (e.target.value) {
                consultaCep.get('/' + onlyNumbers(e.target.value) + '/json').then(r => {
                    console.log(r)
                    if (r.data.erro) {
                        setErroCep(true)
                        swal.fire('CEP inválido', 'Informe um CEP válido!', 'warning')
                    } else {
                        setErroCep(false)
                        const auxValues = { ...data };
                        auxValues['logradouro'] = r.data.logradouro;
                        auxValues['bairro'] = r.data.bairro;
                        setData(auxValues)
                    }
                }).catch(err => {
                    setErroCep(true)
                    swal.fire('CEP inválido', 'Informe um CEP válido!', 'warning')
                })
            }
        }
    }

        //RelacionadosApua
        const [currentDataRelacionadosApua, setCurrentDataRelacionadosApua] = React.useState(null)
        const [propsTabRelacionadosApua, setPropsTabRelacionadosApua] = React.useState({
            selected: null,
        })
        const [editRelacionadosApua, setEditRelacionadosApua] = React.useState(false)
        const [newRelacionadosApua, setNewRelacionadosApua] = React.useState(false)
        const filtersRelacionadosApua = [
            { type: 'text', field: 'descricao', name: 'Empresa' },
            { type: 'text', field: 'denominacao', name: 'Denominacao' },
            { type: 'text', field: 'analise', name: 'Análise'},
        ]
    
        const selectRelacionadosApua = (event, codigo) => {
            setEditRelacionadosApua(false)
            setNewRelacionadosApua(false)
            setPropsTabRelacionadosApua({... propsTabRelacionadosApua, selected: codigo })
        }
    
        React.useEffect(() => {
            setCurrentDataRelacionadosApua(props.dataRelacionadosApua.find((item) => item.codigo === propsTabRelacionadosApua.selected))
        }, [propsTabRelacionadosApua.selected])
    
        React.useEffect(() => {
    
        }, [props.dataRelacionadosApua])
    
        const handleChangeRelacionadosApua = (e) => {
            const auxValues = { ...currentDataRelacionadosApua };
            switch (e.target.name) {
                case 'CNPJ':
                default: auxValues[e.target.name] = e.target.value;
            }
            setCurrentDataRelacionadosApua(auxValues);
        }
    
        const saveRelacionadosApua = () => {
            const auxValues = { ...currentDataRelacionadosApua };
            auxValues.fk_apu = props.pk_apu
            console.log(auxValues)
            if (newRelacionadosApua) {
                api.post('/cliente/apua/add', auxValues).then(r => {
                    if (r.status === 200) {
                        swal.fire({
                            title: 'Registro Adicionado',
                            text: "O registro foi incluído com sucesso!",
                            icon: 'success',
                        }).then((result) => {
                            if (result.value) {
                                setPropsTabRelacionadosApua({... propsTabRelacionadosApua, selected: null })
                                setCurrentDataRelacionadosApua({})
                                setEditRelacionadosApua(false)
                                setNewRelacionadosApua(false)
                                props.reloadRelacionadosApua()
                            }
                        })
                    } else {
                        swal.fire({
                            title: 'Erro!',
                            text: "O registro não foi alterado!",
                            icon: 'warning',
                        })
                    }
                })
            } else {
                api.post('/cliente/apua/edit', auxValues).then(r => {
                    if (r.status === 200) {
                        swal.fire({
                            title: 'Registro Alteraddo',
                            text: "O registro foi alterado com sucesso!",
                            icon: 'success',
                        }).then((result) => {
                            if (result.value) {
                                setPropsTabRelacionadosApua({... propsTabRelacionadosApua, selected: null })
                                setCurrentDataRelacionadosApua({})
                                setEditRelacionadosApua(false)
                                setNewRelacionadosApua(false)
                                props.reloadRelacionadosApua()
                            }
                        })
                    } else {
                        swal.fire({
                            title: 'Erro!',
                            text: "O registro não foi alterado!",
                            icon: 'warning',
                        })
                    }
                })
            }
        }
    
        const deleteRelacionadosApua = () => {
            const auxValues = { ...currentDataRelacionadosApua };
            swal.fire({
                title: 'ATENÇÃO',
                text: 'Deseja excluir o registro?',
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não'
            }).then((result) => {
                if (result.value) {
                    api.post('/cliente/apua/delete/'+auxValues.pk_cap).then(r => {
                        if (r.status === 200) {
                            swal.fire({
                                title: 'Registro Excluído',
                                text: "O registro foi excluído com sucesso!",
                                icon: 'success',
                            }).then((result) => {
                                if (result.value) {
                                    setPropsTabRelacionadosApua({... propsTabRelacionadosApua, selected: null })
                                    setCurrentDataRelacionadosApua({})
                                    setEditRelacionadosApua(false)
                                    setNewRelacionadosApua(false)
                                    props.reloadRelacionadosApua()
                                }
                            })
                        } else {
                            swal.fire({
                                title: 'Erro!',
                                text: "O registro não foi alterado!",
                                icon: 'warning',
                            })
                        }
                    })
                }
            })
        }

         //Edit Tabs
    const handleEdit = (e, tab) => {
        e.preventDefault()
        switch (tab) {

            case 'RelacionadosApua':
                setEditRelacionadosApua(!editRelacionadosApua)
            break
        }
    }

    //Add Tabs
    const handleAdd = (tab) => {
        switch (tab) { 

            case 'RelacionadosApua':
                setEditRelacionadosApua(true)
                setCurrentDataRelacionadosApua({})
                setPropsTabRelacionadosApua({... propsTabRelacionadosApua, selected: null })
                setNewRelacionadosApua(true)
            break    
            
        }
    }

    //Save Tabs
    const handleSave = (e, tab) => {
        e.preventDefault()
        switch (tab) {
            case 'RelacionadosApua':
                saveRelacionadosApua()
            break
        }
    }

    //Delete Tabs
    const handleDelete = (e, tab) => {
        e.preventDefault()
        switch (tab) {
            case 'RelacionadosApua': 
                deleteRelacionadosApua()
            break

        }
    }


    function handleCepOperacional(e) {
        e.preventDefault();
        if (e.target.value) {
            if (e.target.value) {
                consultaCep.get('/' + onlyNumbers(e.target.value) + '/json').then(r => {
                    console.log(r)
                    if (r.data.erro) {
                        setErroCepOperacional(true)
                        swal.fire('CEP inválido', 'Informe um CEP válido!', 'warning')
                    } else {
                        setErroCepOperacional(false)
                        const auxValues = { ...data };
                        auxValues['logradouro_operacional'] = r.data.logradouro;
                        auxValues['bairro_operacional'] = r.data.bairro;
                        setData(auxValues)
                    }
                }).catch(err => {
                    setErroCepOperacional(true)
                    swal.fire('CEP inválido', 'Informe um CEP válido!', 'warning')
                })
            }
        }
    }


    const handleImageChange = (image, fieldName) => {
        const auxValues = { ...data };
        auxValues[fieldName] = image
        setData(auxValues)
    }

    const uploadFile = (file, index, auxValues) => {
        return new Promise((resolve, reject) => {
          let campoFoto = 'foto_'+(index+1)
          let formData = new FormData();
          formData.append('file', file, file.name)
          api.post('/apua/postImage/'+data.pk_apu,formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
          }).then(async r => {
              if (r.status === 200) {
                api.post('/apua/changeImage/'+campoFoto+'/'+data.pk_apu+'/'+file.name).then(r=>{
                  if (r.status === 200) {
                    auxValues[campoFoto] = file.name
                    resolve(auxValues)
                  }
                })
              }
          })
        })
    }

    const selectFile = (e) => {
		e.preventDefault();
        swal.fire({
        title: 'ATENÇÃO',
        text: "Ao fazer o envio múltiplo de imagens, as imagens selecionadas atualmente serão sobrepostas. Confirma?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                $('#html_btn_fotos').click();
            }
        })		
    }

    const handleSelectedFile = async (e) => {
		let files = e.target.files;
        if (files.length > 4) swal.fire('Máximo de imagens ultrapassado!', 'Selecione no máximo 4 imagens!', 'warning')
        else {
            swal.fire({
                html: <FrLoading text="Enviando imagens..."/>,
                showConfirmButton: false,
                allowOutsideClick: false,
                allowEscapeKey: false
            })
            console.log(files)
            let index = 0   
            let auxValues = {...data}
            for (let file of files) {
                if (file) {
                    auxValues = await uploadFile(file, index, auxValues)
                }
                index++
            }
            setData(auxValues)
            swal.close()
        }
    }


    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Tabs
                    value={value}
                    onChange={handleChangeTabs}
                    indicatorColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                >
                    <Tab label="Dados" {...a11yProps(0)} />
                    <Tab label="Fotos" disabled={!props.updateType} {...a11yProps(1)} />
                    <Tab label="Relacionados" disabled={!props.updateType} {...a11yProps(2)} />
                    
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <div className="flexImageFields">
                    <div>
                        <FrTextInput
                            maxLength='150'
                            value={data.identificacao || ''}
                            onChange={handleChange}
                            name="identificacao"
                            id="identificacao"
                            color="#49573B"
                            label="Identificação"
                            variant="outlined"
                            size="small"
                            required
                            style={{ width: 500 }}
                        />
                        <br/>
                        <br/>
                        <div className="grupoTitulo">
                            Cadastro
                        </div>
                        <div className="grupoEdits">
                            <FrTextInput
                                maxLength='100'
                                value={data.localidade || ''}
                                onChange={handleChange}
                                name="localidade"
                                id="localidade"
                                color="#9c9595"
                                label="Localidade"
                                variant="outlined"
                                size="small"
                                style={{ width: 170 }}
                                required
                            />
                            <FrSelect
                                value={data.fk_cid || []}
                                style={{ width: 300 }}
                                name="fk_cid"
                                id="fk_cid"
                                label='Cidade'
                                data={props.comboCidades}
                                onChange={handleChange}
                            />
                             <FrSelect 
                                value={data.fk_sit || []} 
                                style={{ width: 150 }} 
                                name="fk_sit" 
                                id="fk_sit" 
                                label='Situação' 
                                data={props.comboSituacao}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="grupoTitulo">
                            Dados Técnicos
                        </div>
                        <div className="grupoEdits">
                        
                            <FrTextInput
                                maxLength='50'
                                value={data.cabeceira || ''}
                                onChange={handleChange}
                                name="cabeceira"
                                id="cabeceira"
                                color="#9c9595"
                                label="Cabeceira"
                                variant="outlined"
                                size="small"
                                required
                                style={{ width: 400 }}
                            />
                            <FrTextInput
                                maxLength='50'
                                value={data.largura || ''}
                                onChange={handleChange}
                                name="largura"
                                id="largura"
                                color="#9c9595"
                                label="Largura"
                                variant="outlined"
                                size="small"
                                required
                                style={{ width: 100 }}
                            />
                            <FrTextInput
                                maxLength='50'
                                value={data.comprimento || ''}
                                onChange={handleChange}
                                name="comprimento"
                                id="comprimento"
                                color="#9c9595"
                                label="Comprimento"
                                variant="outlined"
                                size="small"
                                required
                                style={{ width: 250 }}
                            />
                            <FrSelect 
                                value={data.fk_tpi || []} 
                                style={{ width: 150 }} 
                                name="fk_tpi" 
                                id="fk_tpi" 
                                label='Piso' 
                                data={props.comboPiso}
                                onChange={handleChange}
                            />
                            <FrTextInput
                                maxLength='50'
                                value={data.latitude || ''}
                                onChange={handleChange}
                                name="latitude"
                                id="latitude"
                                color="#9c9595"
                                label="Latitude"
                                variant="outlined"
                                size="small"
                                required
                                style={{ width: 200 }}
                            />
                            <FrTextInput
                                maxLength='50'
                                value={data.longitude || ''}
                                onChange={handleChange}
                                name="longitude"
                                id="longitude"
                                color="#9c9595"
                                label="Longitude"
                                variant="outlined"
                                size="small"
                                required
                                style={{ width: 200 }}
                            />
                        </div>
                        <div className="grupoTitulo">
                            Documentação
                        </div>
                        <div className="grupoEdits">
                            <FrSelect 
                                value={data.ica || []} 
                                style={{ width: 150 }} 
                                name="ica" 
                                id="ica" 
                                label='ICA' 
                                data={[{display: 'Aplicável', value: 'S'}, {display:'Não aplicável', value: 'N'}]}
                                onChange={handleChange}
                            />
                            <FrDatePicker
                                maxLength='50'
                                value={(data.ica_validade || '').split('T')[0]}
                                onChange={handleChange}
                                name="ica_validade"
                                id="ica_validade"
                                color="#9c9595"
                                label="Validade"
                                variant="outlined"
                                size="small"
                                style={{ width: 200 }}
                            />
                             <FrTextInput
                                maxLength='45'
                                value={data.nr_oficio || ''}
                                onChange={handleChange}
                                name="nr_oficio"
                                id="nr_oficio"
                                color="#9c9595"
                                label="Nr Ofício"
                                variant="outlined"
                                size="small"
                                style={{ width: 250 }}
                            />
                        </div>
                        <br />
                        <FrTextInput
                            maxLength='2'
                            value={data.analise || ''}
                            onChange={handleChange}
                            name="analise"
                            id="analise"
                            color="#9c9595"
                            label="Análise"
                            variant="outlined"
                            size="small"
                            required
                            style={{ width: 100 }}
                        />
                        <FrTextInput
                            maxLength='1000'
                            value={data.obstaculos || ''}
                            onChange={handleChange}
                            name="obstaculos"
                            id="obstaculos"
                            color="#9c9595"
                            label="Obstáculos"
                            variant="outlined"
                            size="small"
                            required
                            style={{ width: '100%', flex: 1 }}
                            rows={5}
                            maxRows={5}
                            multiline
                        />

                        <FrTextInput
                            maxLength='1000'
                            value={data.recomendacoes || ''}
                            onChange={handleChange}
                            name="recomendacoes"
                            id="recomendacoes"
                            color="#9c9595"
                            label="Recomendações"
                            variant="outlined"
                            size="small"
                            required
                            style={{ width: '100%', flex: 1 }}
                            rows={5}
                            maxRows={5}
                            multiline
                        />

                        <FrTextInput
                            maxLength='1000'
                            value={data.observacoes || ''}
                            onChange={handleChange}
                            name="observacoes"
                            id="observacoes"
                            color="#9c9595"
                            label="Observações"
                            variant="outlined"
                            size="small"
                            required
                            style={{ width: '100%', flex: 1 }}
                            rows={5}
                            maxRows={5}
                            multiline
                        />

                        
                    </div>
                </div>

            </TabPanel>
            <TabPanel value={value} index={1}>
                <div>
                    <input 
                        type="file" 
                        name="arquivo"
                        id={"html_btn_fotos"}
                        accept=".png, .jpg, .jpeg"
                        multiple
                        onChange={handleSelectedFile}
                    />
                    <button className="multipleImagesButton" type="file" onClick={selectFile}>
                    <Icon icon={upload} size={20}/> Enviar Imagens
                    </button>
                    <div className="ScrollWrapper">
                    <div className="RegLotesImagesUpload">
                        <FrImageEdit visible={true} image={data.foto_1} title="Foto 1" fieldName="foto_1" onImageChange={handleImageChange} regName="apua" pk={data.pk_apu}/>
                        <FrImageEdit visible={true} image={data.foto_2} title="Foto 2" fieldName="foto_2" onImageChange={handleImageChange} regName="apua" pk={data.pk_apu}/>
                        <FrImageEdit visible={true} image={data.foto_3} title="Foto 3" fieldName="foto_3" onImageChange={handleImageChange} regName="apua" pk={data.pk_apu}/>
                        <FrImageEdit visible={true} image={data.foto_4} title="Foto 4" fieldName="foto_4" onImageChange={handleImageChange} regName="apua" pk={data.pk_apu}/>
                    </div>
                    </div>
                </div>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <div className="regRelacionados">
                        <Nav.Link href="/ejr/cliente" target="_blank" className="atalhoRelacionados">
                            <Icon icon={ic_launch} size={20}/>
                            <strong>{'Clientes'}</strong> 
                        </Nav.Link>

                        <div className="manutReg Relacionados">
                            <ListRegistersDetailedFilter reload={props.reloadRelacionadosApua} filters={filtersRelacionadosApua} width={800} height={300} tab="RelacionadosApua" handleClickItem={selectRelacionadosApua} selected={propsTabRelacionadosApua.selected} items={props.dataRelacionadosApua} textField={'descricao'} handleAdd={handleAdd} />
                            <div className="register">
                                <div className="buttons">
                                    <Tooltip title="Salvar">
                                        <button onClick={(e) => {handleSave(e, 'RelacionadosApua')}} disabled={!editRelacionadosApua}>
                                            <Icon icon={ic_save} size={20}/>
                                        </button>
                                    </Tooltip>
                                    <Tooltip title={editRelacionadosApua ? 'Desfazer' : 'Editar'}>
                                        <button onClick={(e) => {handleEdit(e, 'RelacionadosApua')}} disabled={newRelacionadosApua}>
                                            <Icon icon={editRelacionadosApua ? ic_undo : ic_mode_edit} size={20}/>
                                        </button>
                                    </Tooltip>
                                    <Tooltip title="Excluir">
                                        <button onClick={(e) => {handleDelete(e, 'RelacionadosApua')}} disabled={(!(Number(propsTabRelacionadosApua.selected) > 0)) || (newRelacionadosApua)}>
                                            <Icon icon={ic_delete} size={20}/>
                                        </button>
                                    </Tooltip>
                                </div>
                                <div className="fields">
                                    <FrSelect 
                                        value={currentDataRelacionadosApua?.fk_pes_cli || []} 
                                        name="fk_pes_cli" 
                                        id="fk_pes_cli" 
                                        label='Empresa' 
                                        style={{ width: '100%' }}
                                        data={props.comboClientes}
                                        onChange={handleChangeRelacionadosApua}
                                        disabled={!editRelacionadosApua}
                                    />
                                    <br/>
                                    <FrTextInput
                                        maxLength='150'
                                        value={currentDataRelacionadosApua?.denominacao || ''}
                                        onChange={handleChangeRelacionadosApua}
                                        name="denominacao"
                                        id="denominacao"
                                        color="#9c9595"
                                        label="Denominação"
                                        variant="outlined"
                                        size="small"
                                        style={{ width: 160 }}
                                        disabled={!editRelacionadosApua}
                                    />
                                    <br/>
                                    <FrTextInput
                                        maxLength='2'
                                        value={currentDataRelacionadosApua?.analise || ''}
                                        onChange={handleChangeRelacionadosApua}
                                        name="analise"
                                        id="analise"
                                        color="#9c9595"
                                        label="Análise"
                                        variant="outlined"
                                        size="small"
                                        style={{ width: 70 }}
                                        disabled={!editRelacionadosApua}
                                    />
                                    <br/>
                                    <FrTextInput
                                        maxLength='1000'
                                        value={currentDataRelacionadosApua?.obstaculos || ''}
                                        onChange={handleChangeRelacionadosApua}
                                        name="obstaculos"
                                        id="obstaculos"
                                        color="#9c9595"
                                        label="Obstáculos"
                                        variant="outlined"
                                        size="small"
                                        
                                        style={{ width: '100%' }}
                                        disabled={!editRelacionadosApua}
                                        rows={5}
                                        maxRows={5}
                                        multiline
                                    />
                                    <br/>
                                    <FrTextInput
                                        maxLength='1000'
                                        value={currentDataRelacionadosApua?.recomendacoes || ''}
                                        onChange={handleChangeRelacionadosApua}
                                        name="recomendacoes"
                                        id="recomendacoes"
                                        color="#9c9595"
                                        label="Recomendação"
                                        variant="outlined"
                                        size="small"
                                        
                                        style={{ width: '100%' }}
                                        disabled={!editRelacionadosApua}
                                        rows={5}
                                        maxRows={5}
                                        multiline
                                    />
                                    <br/>
                                    <FrTextInput
                                        maxLength='1000'
                                        value={currentDataRelacionadosApua?.observacoes || ''}
                                        onChange={handleChangeRelacionadosApua}
                                        name="observacoes"
                                        id="observacoes"
                                        color="#9c9595"
                                        label="Observações"
                                        variant="outlined"
                                        size="small"
                                        
                                        style={{ width: '100%' }}
                                        disabled={!editRelacionadosApua}
                                        rows={5}
                                        maxRows={5}
                                        multiline
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
            </TabPanel>
        </div>
    );
}

export default TabsApua;