import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import NavBar from '../components/NavBar'
import FrTextInput from '../components/FrTextInput'
import FrSelect from '../components/FrSelect'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import TabsCliente from '../components/TabsCliente'

import api from '../services/api'
import consultaCep from '../services/consultaCep'
import { paramsToObject, validarCNPJ, onlyNumbers, removeSpecialChar, validarCPF } from '../utils/functions'
import { mCNPJ, mTel, mCEP, mCPF } from '../utils/masks'

import './styles/DefaultReg.css';
import FooterDelphus from '../components/FooterDelphus';

const swal = withReactContent(Swal)

function RegCliente() {
  const [data, setData] = useState({ativo: 'S'})
  const [dataSocios, setDataSocios] = useState([])
  const [dataContatos, setDataContatos] = useState([])
  const [dataGestorResponsavel, setDataGestorResponsavel] = useState([])
  const [dataGestorOperacional, setDataGestorOperacional] = useState([])
  const [dataPiloto, setDataPiloto] = useState([])
  const [dataTecnico, setDataTecnico] = useState([])
  const [dataEngenheiro, setDataEngenheiro] = useState([])
  const [dataDocumentos, setDataDocumentos] = useState([])
  const [dataDocumentosLicenca, setDataDocumentosLicenca] = useState([])
  const [dataTanqueCombustivel, setDataTanqueCombustivel] = useState([])
  const [dataRelacionadosPiloto, setDataRelacionadosPiloto] = useState([])
  const [dataRelacionadosTecnico, setDataRelacionadosTecnico] = useState([])
  const [dataRelacionadosEngenheiro, setDataRelacionadosEngenheiro] = useState([])
  const [dataRelacionadosMecanico, setDataRelacionadosMecanico] = useState([])
  const [dataRelacionadosAeronave, setDataRelacionadosAeronave] = useState([])
  const [dataRelacionadosApua, setDataRelacionadosApua] = useState([])
  const [updateType, setUpdateType] = useState(false)
  const [enableEdit, setEnableEdit] = useState('N')
  const [erroCnpj, setErroCnpj] = useState(false)
  const [erroCpf, setErroCpf] = useState(false)
  const [erroCepGestorResponsavel, setErroCepGestorResponsavel] = useState(false)
  const [erroCepGestorOperacional, setErroCepGestorOperacional] = useState(false)
  const [erroCpfGestorResponsavel, setErroCpfGestorResponsavel] = useState(false)
  const [erroCpfGestorOperacional, setErroCpfGestorOperacional] = useState(false)
  const [comboCidades, setComboCidades] = useState([{ value: '', display: '' }])
  const [comboTipoDocumento, setComboTipoDocumento] = useState([{ value: '', display: '' }])
  const [comboTipoCombustivel, setComboTipoCombustivel] = useState([{ value: '', display: '' }])
  const [comboPilotos, setComboPilotos] = useState([{ value: '', display: '' }])
  const [comboTecnicos, setComboTecnicos] = useState([{ value: '', display: '' }])
  const [comboEngenheiros, setComboEngenheiros] = useState([{ value: '', display: '' }])
  const [comboClasses, setComboClasses] = useState([{ value: '', display: '' }])
  const [comboSituacao, setComboSituacao] = useState([{ value: '', display: '' }])
  const [comboSituacaoPiloto, setComboSituacaoPiloto] = useState([{ value: '', display: '' }])
  const [comboSituacaoTecnico, setComboSituacaoTecnico] = useState([{ value: '', display: '' }])
  const [comboSituacaoEngenheiro, setComboSituacaoEngenheiro] = useState([{ value: '', display: '' }])
  const [comboSituacaoAeronave, setComboSituacaoAeronave] = useState([{ value: '', display: '' }])
  const [comboSituacaoMecanico, setComboSituacaoMecanico] = useState([{ value: '', display: '' }])
  const [comboSituacaoFase, setComboSituacaoFase] = useState([{ value: '', display: '' }])
  const [comboMecanicos, setComboMecanicos] = useState([{ value: '', display: '' }])
  const [comboAeronave, setComboAeronave] = useState([{ value: '', display: '' }])
  const [comboModeloDocumento, setComboModeloDocumento] = useState([{ value: '', display: '' }])
  const [comboApua, setComboApua] = useState([{ value: '', display: '' }])
  const [comboSitCli, setComboSitCli] = useState([{ value: '', display: ''}])

  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })

      setComboCidades(await api.getComboData('cidades'))
      setComboTipoDocumento(await api.getComboData('tipo_documento'))
      setComboTipoCombustivel(await api.getComboData('tipo_combustivel'))
      setComboPilotos(await api.getComboData('piloto'))
      setComboTecnicos(await api.getComboData('tecnico_agricola'))
      setComboEngenheiros(await api.getComboData('engenheiro'))
      setComboClasses(await api.getComboData('piloto_classe'))
      setComboSituacao(await api.getComboData('situacao_padrao'))
      setComboMecanicos(await api.getComboData('mecanicos'))
      setComboAeronave(await api.getComboData('aeronave'))
      setComboApua(await api.getComboData('apua'))
      setComboModeloDocumento(await api.getComboData('modelo_documento'))
      setComboSituacaoPiloto(await api.getComboData('situacao_piloto'))
      setComboSituacaoTecnico(await api.getComboData('situacao_piloto'))
      setComboSituacaoEngenheiro(await api.getComboData('situacao_piloto'))
      setComboSituacaoAeronave(await api.getComboData('situacao_aeronave'))
      setComboSituacaoMecanico(await api.getComboData('situacao_mecanico'))
      setComboSituacaoFase(await api.getComboData('situacao_fase'))
      setComboSitCli(await api.getComboData('situacao_cliente'))

      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)

      if (query.codigo > 0) {
        setEnableEdit(await api.getAcessos('Cliente', 'Alteracao'))
      } else {
        setEnableEdit(await api.getAcessos('Cliente', 'Inclusao'))
        
      }

      if (query.codigo > 0) {
        setUpdateType(true)
        api.get('/cliente?pk_pes_cli=' + query.codigo).then(r => {
          console.log(r.data)
          if (r.data[0]) {
            //Administrativa
            if(r.data[0].cnpj){
             r.data[0].tipo_pessoa = 'J' 
            } else {
              r.data[0].tipo_pessoa = 'F'
            }
            r.data[0].cnpj = mCNPJ(r.data[0].cnpj || '')
            r.data[0].cpf = mCNPJ(r.data[0].cpf || '')
            r.data[0].fone1 = mTel(r.data[0].fone1 || '')
            r.data[0].fone2 = mTel(r.data[0].fone2 || '')
            r.data[0].celular = mTel(r.data[0].celular || '')
            r.data[0].cep = mCEP(r.data[0].cep || '')

            //Operacional
            r.data[0].fone1_operacional = mTel(r.data[0].fone1_operacional || '')
            r.data[0].fone2_operacional = mTel(r.data[0].fone2_operacional || '')
            r.data[0].cep_operacional = mCEP(r.data[0].cep_operacional || '')

            //Descontaminação
            r.data[0].fone1_desconta = mTel(r.data[0].fone1_desconta || '')
            r.data[0].fone2_desconta = mTel(r.data[0].fone2_desconta || '')
            r.data[0].celular_desconta = mTel(r.data[0].celular_desconta || '')
            r.data[0].cep_desconta = mCEP(r.data[0].cep_desconta || '')

            //Posto Abastecimento            
            r.data[0].fone1_abastec = mTel(r.data[0].fone1_abastec || '')
            r.data[0].fone2_abastec = mTel(r.data[0].fone2_abastec || '')
            r.data[0].celular_abastec = mTel(r.data[0].celular_abastec || '')
            r.data[0].cep_abastec = mCEP(r.data[0].cep_abastec || '')

            setData(r.data[0])
            // swal.close()
            api.get('/cliente/socios?pk_pes_cli=' + query.codigo).then(r => {
                if (r.data) {
                  setDataSocios(r.data)
                }
                api.get('/cliente/contato?pk_pes_cli=' + query.codigo).then(r => {
                  if (r.data) {
                    setDataContatos(r.data)
                  }
                  api.get('/cliente/documentos?pk_pes_cli=' + query.codigo).then(r => {
                    if (r.data) {
                      setDataDocumentos(r.data)
                      api.get('/cliente/documentos?pk_pes_cli=' + query.codigo+'&licenca=S').then(r => {
                        if (r.data) {
                          setDataDocumentosLicenca(r.data)
                          api.get('/cliente/tanque_combustivel?pk_pes_cli=' + query.codigo).then(r => {
                            if (r.data) {
                              console.log(r.data)
                              setDataTanqueCombustivel(r.data)
                            }
                            api.get('/cliente/piloto?pk_pes_cli=' + query.codigo).then(r => {
                              if (r.data) {
                                console.log(r.data)
                                setDataRelacionadosPiloto(r.data)
                              }
                              api.get('/cliente/mecanico?pk_pes_cli=' + query.codigo).then(r => {
                                if (r.data) {
                                  console.log(r.data)
                                  setDataRelacionadosMecanico(r.data)
                                }
                                api.get('/cliente/aeronave?pk_pes_cli=' + query.codigo).then(r => {
                                  if (r.data) {
                                    console.log(r.data)
                                    setDataRelacionadosAeronave(r.data)
                                  }
                                  api.get('/cliente/apua?pk_pes_cli=' + query.codigo).then(r => {
                                    if (r.data) {
                                      console.log(r.data)
                                      setDataRelacionadosApua(r.data)
                                    }
                                    api.get('/cliente/engenheiro?pk_pes_cli=' + query.codigo).then(r => {
                                      if (r.data) {
                                        console.log(r.data)
                                        setDataRelacionadosEngenheiro(r.data)
                                      }
                                      api.get('/cliente/tecnico_agricola?pk_pes_cli=' + query.codigo).then(r => {
                                        if (r.data) {
                                          console.log(r.data)
                                          setDataRelacionadosTecnico(r.data)
                                        }

                                        api.get('/piloto?fk_pes_cli=' + query.codigo).then(r => {
                                          if (r.data[0]) {
                                            r.data[0].cpf = mCPF(r.data[0].cpf || '')
                                            r.data[0].fone1 = mTel(r.data[0].fone1 || '')
                                            r.data[0].fone2 = mTel(r.data[0].fone2 || '')
                                            r.data[0].celular = mTel(r.data[0].celular || '')
                                            r.data[0].cep = mCEP(r.data[0].cep || '')
                                            setDataPiloto(r.data[0])
                                          }
                                          api.get('/cliente/gestor_responsavel?pk_pes_cli=' + query.codigo).then(r => {
                                            if (r.data[0]) {
                                              r.data[0].cpf = mCPF(r.data[0].cpf || '')
                                              r.data[0].fone1 = mTel(r.data[0].fone1 || '')
                                              r.data[0].fone2 = mTel(r.data[0].fone2 || '')
                                              r.data[0].celular = mTel(r.data[0].celular || '')
                                              r.data[0].cep = mCEP(r.data[0].cep || '')
                                              setDataGestorResponsavel(r.data[0])
                                            }
                                            api.get('/cliente/gestor_operacional?pk_pes_cli=' + query.codigo).then(r => {
                                              if (r.data[0]) {
                                                r.data[0].cpf = mCPF(r.data[0].cpf || '')
                                                r.data[0].fone1 = mTel(r.data[0].fone1 || '')
                                                r.data[0].fone2 = mTel(r.data[0].fone2 || '')
                                                r.data[0].celular = mTel(r.data[0].celular || '')
                                                r.data[0].cep = mCEP(r.data[0].cep || '')
                                                setDataGestorOperacional(r.data[0])
                                              }
                                              swal.close()
                                            })
                                          })
                                        })
                                      })
                                    })
                              })
                              })
                            })
                            })
                          })
                        }
                                                // swal.close()
                      })
                    }
                  })
                  // swal.close()
                })
            })
          } else {
            swal.fire('Código inválido', 'Código informado não encontrado!', 'warning')
              .then((result) => {
                if (result.value) {
                  window.location.href = '/ejr/cliente'
                }
              })
          }
        })
      } else {
        let auxValues = {...data}
        auxValues.tipo_pessoa = 'J'
        setData(auxValues)
      
        swal.close()
      }
    }

    fetchData()
  }, [])

  const reloadSocios = () => {
    swal.fire({
      html: <FrLoading text="Carregando..." />,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false
    })
    api.get('/cliente/socios?pk_pes_cli=' + data.pk_pes_cli).then(r => {
      if (r.data) {
        console.log(r.data)
        setDataSocios(r.data)
      }
      swal.close()
    })
  }

  const reloadContatos = () => {
    swal.fire({
      html: <FrLoading text="Carregando..." />,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false
    })
    api.get('/cliente/contato?pk_pes_cli=' + data.pk_pes_cli).then(r => {
      if (r.data) {
        console.log(r.data)
        setDataContatos(r.data)
      }
      swal.close()
    })
  }

  const reloadDocumentos = () => {
    swal.fire({
      html: <FrLoading text="Carregando..." />,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false
    })
    api.get('/cliente/documentos?pk_pes_cli=' + data.pk_pes_cli).then(r => {
      if (r.data) {
        console.log(r.data)
        setDataDocumentos(r.data)
      }
      swal.close()
    })
  }

  const reloadDocumentosLicenca = () => {
    swal.fire({
      html: <FrLoading text="Carregando..." />,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false
    })
    api.get('/cliente/documentos?pk_pes_cli=' + data.pk_pes_cli + '&licenca=S').then(r => {
      if (r.data) {
        console.log(r.data)
        setDataDocumentosLicenca(r.data)
      }
      swal.close()
    })
  }

  const reloadTanqueCombustivel = () => {
    swal.fire({
      html: <FrLoading text="Carregando..." />,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false
    })
    api.get('/cliente/tanque_combustivel?pk_pes_cli=' + data.pk_pes_cli).then(r => {
      swal.close()
      if (r.data) {
        console.log(r.data)
        setDataTanqueCombustivel(r.data)
      }
    })
  }

  const reloadRelacionadosPiloto = () => {
    swal.fire({
      html: <FrLoading text="Carregando..." />,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false
    })
    api.get('/cliente/piloto?pk_pes_cli=' + data.pk_pes_cli).then(r => {
      swal.close()
      if (r.data) {
        console.log(r.data)
        setDataRelacionadosPiloto(r.data)
      }
    })
  }

  const reloadRelacionadosTecnico = () => {
    swal.fire({
      html: <FrLoading text="Carregando..." />,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false
    })
    api.get('/cliente/tecnico_agricola?pk_pes_cli=' + data.pk_pes_cli).then(r => {
      swal.close()
      if (r.data) {
        console.log(r.data)
        setDataRelacionadosTecnico(r.data)
      }
    })
  }

  const reloadRelacionadosEngenheiro = () => {
    swal.fire({
      html: <FrLoading text="Carregando..." />,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false
    })
    api.get('/cliente/engenheiro?pk_pes_cli=' + data.pk_pes_cli).then(r => {
      swal.close()
      if (r.data) {
        console.log(r.data)
        setDataRelacionadosEngenheiro(r.data)
      }
    })
  }

  const reloadRelacionadosMecanico = () => {
    swal.fire({
      html: <FrLoading text="Carregando..." />,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false
    })
    api.get('/cliente/mecanico?pk_pes_cli=' + data.pk_pes_cli).then(r => {
      swal.close()
      if (r.data) {
        console.log(r.data)
        setDataRelacionadosMecanico(r.data)
      }
    })
  }

  const reloadRelacionadosAeronave = () => {
    swal.fire({
      html: <FrLoading text="Carregando..." />,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false
    })
    api.get('/cliente/aeronave?pk_pes_cli=' + data.pk_pes_cli).then(r => {
      swal.close()
      if (r.data) {
        console.log(r.data)
        setDataRelacionadosAeronave(r.data)
      }
    })
  }

  const reloadRelacionadosApua = () => {
    swal.fire({
      html: <FrLoading text="Carregando..." />,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false
    })
    api.get('/cliente/apua?pk_pes_cli=' + data.pk_pes_cli).then(r => {
      swal.close()
      if (r.data) {
        console.log(r.data)
        setDataRelacionadosApua(r.data)
      }
    })
  }

  function consistData(data) {
    let required = [
      'razao_social',
      'fantasia',
    ]

    let errors = []

    if(data.tipo_pessoa == 'J'){
      required.push('cnpj')
    } else {
      required.push('cpf')
    }

    required.forEach((curr, index) => {
      let fieldName = curr
      if (!data[curr]) return errors.push(fieldName)
    })

    return errors
  }

  function handleSubmitPiloto(pk_pes_pil) {
    return new Promise((resolve, reject) => {
      const auxValues = { ...dataPiloto };

      auxValues.cpf = onlyNumbers(auxValues.cpf)
      auxValues.cep = onlyNumbers(auxValues.cep)
      auxValues.fone1 = onlyNumbers(auxValues.fone1)
      auxValues.fone2 = onlyNumbers(auxValues.fone2)
      auxValues.celular = onlyNumbers(auxValues.celular)
      auxValues.data_nasc = auxValues.data_nasc ? auxValues.data_nasc.split('T')[0] : null
      auxValues.data_cma = auxValues.data_cma ? auxValues.data_cma.split('T')[0] : null
      auxValues.data_conclusao_cavag = auxValues.data_conclusao_cavag ? auxValues.data_conclusao_cavag.split('T')[0] : null
      auxValues.data_exp_certif = auxValues.data_exp_certif ? auxValues.data_exp_certif.split('T')[0] : null

      if (pk_pes_pil) {
        api.post('/piloto/edit', auxValues).then(r => {
          if (r.status === 200) {
            console.log('to mandando uma parada de teste ai')
            resolve(r.data)
          } else {
            reject()
            swal.fire({
              title: 'Erro!',
              text: "O registro não foi alterado!",
              icon: 'warning',
            })
          }
        })
      } else {
        api.post('/piloto/add', auxValues).then(r => {
          if (r.status === 200) {
            console.log(r.data)
            resolve(r.data)
          } else {
            reject()
            swal.fire({
              title: 'Erro!',
              text: "O registro não foi alterado!",
              icon: 'warning',
            })
          }
        })
      }
    })
  }

  function handleSubmitGestorResponsavel(pk_pes) {
    return new Promise((resolve, reject) => {
      const auxValues = { ...dataGestorResponsavel };

      auxValues.fk_pes_cli = data.pk_pes_cli
      auxValues.cpf = onlyNumbers(auxValues.cpf)
      auxValues.cep = onlyNumbers(auxValues.cep)
      auxValues.fone1 = onlyNumbers(auxValues.fone1)
      auxValues.fone2 = onlyNumbers(auxValues.fone2)
      auxValues.celular = onlyNumbers(auxValues.celular)
      auxValues.fk_trc = 3

      if (pk_pes) {
        api.post('/cliente/pessoa/edit', auxValues).then(r => {
          if (r.status === 200) {
            console.log(r.data)
            resolve(r.data)
          } else {
            reject()
            swal.fire({
              title: 'Erro!',
              text: "O registro não foi alterado!",
              icon: 'warning',
            })
          }
        })
      } else {
        api.post('/cliente/pessoa/add', auxValues).then(r => {
          if (r.status === 200) {
            console.log(r.data)
            resolve(r.data)
          } else {
            reject()
            swal.fire({
              title: 'Erro!',
              text: "O registro não foi alterado!",
              icon: 'warning',
            })
          }
        })
      }
    })
  }

  function handleSubmitGestorOperacional(pk_pes) {
    return new Promise((resolve, reject) => {
      const auxValues = { ...dataGestorOperacional };

      auxValues.fk_pes_cli = data.pk_pes_cli
      auxValues.cpf = onlyNumbers(auxValues.cpf)
      auxValues.cep = onlyNumbers(auxValues.cep)
      auxValues.fone1 = onlyNumbers(auxValues.fone1)
      auxValues.fone2 = onlyNumbers(auxValues.fone2)
      auxValues.celular = onlyNumbers(auxValues.celular)
      auxValues.fk_trc = 4

      if (pk_pes) {
        api.post('/cliente/pessoa/edit', auxValues).then(r => {
          if (r.status === 200) {
            console.log(r.data)
            resolve(r.data)
          } else {
            reject()
            swal.fire({
              title: 'Erro!',
              text: "O registro não foi alterado!",
              icon: 'warning',
            })
          }
        })
      } else {
        api.post('/cliente/pessoa/add', auxValues).then(r => {
          if (r.status === 200) {
            console.log(r.data)
            resolve(r.data)
          } else {
            reject()
            swal.fire({
              title: 'Erro!',
              text: "O registro não foi alterado!",
              icon: 'warning',
            })
          }
        })
      }
    })
  }

  function handleCepGestorResponsavel(e) {
    e.preventDefault();
    if (e.target.value) {
      if (e.target.value) {
        consultaCep.get('/' + onlyNumbers(e.target.value) + '/json').then(r => {
          console.log(r)
          if (r.data.erro) {
            setErroCepGestorResponsavel(true)
            swal.fire('CEP inválido', 'Informe um CEP válido!', 'warning')
          } else {
            setErroCepGestorResponsavel(false)
            const auxValues = { ...dataGestorResponsavel };
            auxValues['logradouro'] = r.data.logradouro;
            auxValues['bairro'] = r.data.bairro;
            let cidade = comboCidades.find(item => String(item.display).normalize('NFD').replace(/[\u0300-\u036f]/g, "") === String(r.data.localidade).toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ""))
            if (cidade) auxValues['fk_cid'] = cidade.value
            else alert('Cidade não cadastrada no sistema!')
            setDataGestorResponsavel(auxValues)
          }
        }).catch(err => {
          setErroCepGestorResponsavel(true)
          swal.fire('CEP inválido', 'Informe um CEP válido!', 'warning')
        })
      }
    }
  }

  function handleCepGestorOperacional(e) {
    e.preventDefault();
    if (e.target.value) {
      if (e.target.value) {
        consultaCep.get('/' + onlyNumbers(e.target.value) + '/json').then(r => {
          console.log(r)
          if (r.data.erro) {
            setErroCepGestorOperacional(true)
            swal.fire('CEP inválido', 'Informe um CEP válido!', 'warning')
          } else {
            setErroCepGestorOperacional(false)
            const auxValues = { ...dataGestorOperacional };
            auxValues['logradouro'] = r.data.logradouro;
            auxValues['bairro'] = r.data.bairro;
            let cidade = comboCidades.find(item => String(item.display).normalize('NFD').replace(/[\u0300-\u036f]/g, "") === String(r.data.localidade).toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ""))
            if (cidade) auxValues['fk_cid'] = cidade.value
            else alert('Cidade não cadastrada no sistema!')
            setDataGestorOperacional(auxValues)
          }
        }).catch(err => {
          setErroCepGestorOperacional(true)
          swal.fire('CEP inválido', 'Informe um CEP válido!', 'warning')
        })
      }
    }
  }

  function handleCpfGestorResponsavel(e) {
    e.preventDefault();
    let cpf = e.target.value
    if(cpf) {
      if(!validarCPF(cpf)) {
        setErroCpfGestorResponsavel(true)
        swal.fire('CPF inválido', 'Informe um CPF válido!', 'warning')
      } else {
        setErroCpfGestorResponsavel(false)
        api.get('/pessoa?cpf=' + onlyNumbers(cpf)).then(r => {
          if (r.data[0]) {
              r.data[0].cpf = mCPF(r.data[0].cpf)
              r.data[0].fone1 = mTel(r.data[0].fone1)
              r.data[0].fone2 = mTel(r.data[0].fone2)
              r.data[0].cep = mCEP(r.data[0].cep)
              setDataGestorResponsavel(r.data[0])
          }
        })
      }
    }
  }

  function handleCpfGestorOperacional(e) {
    e.preventDefault();
    let cpf = e.target.value
    if(cpf) {
      if(!validarCPF(cpf)) {
        setErroCpfGestorOperacional(true)
        swal.fire('CPF inválido', 'Informe um CPF válido!', 'warning')
      } else {
        setErroCpfGestorOperacional(false)
        api.get('/pessoa?cpf=' + onlyNumbers(cpf)).then(r => {
          if (r.data[0]) {
              r.data[0].cpf = mCPF(r.data[0].cpf)
              r.data[0].fone1 = mTel(r.data[0].fone1)
              r.data[0].fone2 = mTel(r.data[0].fone2)
              r.data[0].cep = mCEP(r.data[0].cep)
              setDataGestorOperacional(r.data[0])
          }
        })
      }
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Deseja salvar " + (updateType ? 'as alterações' : 'a inclusão') + ' do registro?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then(async (result) => {
      if (result.value) {
        const auxValues = { ...data };
        let errors = consistData(auxValues)
        console.log(errors.length)
        if (errors.length === 0) {
          if (auxValues.fk_pes_pil || dataPiloto.razao_social) auxValues.fk_pes_pil = await handleSubmitPiloto(auxValues.fk_pes_pil)
          if (auxValues.fk_pes_gre || dataGestorResponsavel.razao_social) auxValues.fk_pes_gre = await handleSubmitGestorResponsavel(auxValues.fk_pes_gre) 
          if (auxValues.fk_pes_gso || dataGestorOperacional.razao_social) auxValues.fk_pes_gso = await handleSubmitGestorOperacional(auxValues.fk_pes_gso) 

          console.log(auxValues.fk_pes_pil)
          //Administrativa
          auxValues.cnpj = onlyNumbers(auxValues.cnpj)
          auxValues.cpf = onlyNumbers(auxValues.cpf)
          auxValues.fone1 = onlyNumbers(auxValues.fone1)
          auxValues.fone2 = onlyNumbers(auxValues.fone2)
          auxValues.celular = onlyNumbers(auxValues.celular)
          auxValues.cep = onlyNumbers(auxValues.cep)

          //Operacional
          auxValues.fone1_operacional = onlyNumbers(auxValues.fone1_operacional)
          auxValues.fone2_operacional = onlyNumbers(auxValues.fone2_operacional)
          auxValues.celular_operacional = onlyNumbers(auxValues.celular_operacional)
          auxValues.cep_operacional = onlyNumbers(auxValues.cep_operacional)

          //Descontaminação
          auxValues.fone1_desconta = onlyNumbers(auxValues.fone1_desconta)
          auxValues.fone2_desconta = onlyNumbers(auxValues.fone2_desconta)
          auxValues.celular_desconta = onlyNumbers(auxValues.celular_desconta)
          auxValues.cep_desconta = onlyNumbers(auxValues.cep_desconta)

          //Posto Abastecimento            
          auxValues.fone1_abastec = onlyNumbers(auxValues.fone1_abastec)
          auxValues.fone2_abastec = onlyNumbers(auxValues.fone2_abastec)
          auxValues.celular_abastec = onlyNumbers(auxValues.celular_abastec)
          auxValues.cep_abastec = onlyNumbers(auxValues.cep_abastec)

          //Documentação
          auxValues.data_coa = auxValues.data_coa ? auxValues.data_coa.split('T')[0] : null
          auxValues.data_procuracao = auxValues.data_procuracao ? auxValues.data_procuracao.split('T')[0] : null
          auxValues.data_mapa = auxValues.data_mapa ? auxValues.data_mapa.split('T')[0] : null
          console.log(Number(auxValues.fk_pes_pil))
         
          auxValues.fk_pes_pil = !(auxValues.fk_pes_pil) || (auxValues.fk_pes_pil == '') ? null : Number(auxValues.fk_pes_pil)
          console.log(auxValues.fk_pes_pil)
          if (updateType) {
            api.post('/cliente/edit', auxValues).then(r => {
              if (r.status === 200) {
                swal.fire({
                  title: 'Registro Alteraddo',
                  text: "O registro foi alterado com sucesso!",
                  icon: 'success',
                }).then((result) => {
                  if (result.value) {
                    window.location.href = '/ejr/cliente'
                  }
                })
              } else {
                swal.fire({
                  title: 'Erro!',
                  text: "O registro não foi alterado!",
                  icon: 'warning',
                })
              }
            })
          } else {
            api.post('/cliente/add', auxValues).then(r => {
              if (r.status === 200) {
                let pk_pes_cli = r.data
                swal.fire({
                  title: 'Registro adicionado com sucesso!',
                  text: "Deseja editar agora as outras abas deste cadastro?",
                  icon: 'success',
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: 'Sim',
                  cancelButtonText: 'Não'
                }).then((result) => {
                  console.log(result)
                  if (result) {
                    window.location.href = '/ejr/cliente/registro?codigo='+pk_pes_cli
                  } else {
                    window.location.href = '/ejr/cliente'
                  }
                })
              } else {
                swal.fire({
                  title: 'Erro!',
                  text: "O registro não foi adicionado!",
                  icon: 'warning',
                })
              }
            }).catch((e) => {
              swal.fire({
                title: 'Erro!',
                text: "O registro não foi adicionado! CNPJ já utilizado!",
                icon: 'warning',
              })
            })
          }
        } else {
          swal.fire({
            title: 'Erro!',
            text: "Verifique o(s) campo(s) " + errors.join(','),
            icon: 'warning',
          })
        }
      }
    })
  }

  function handleCancel(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        window.location.href = '/ejr/cliente'
      }
    })
  }

  const handleChange = (e) => {
    const auxValues = { ...data };
    switch (e.target.name) {
      case 'fone1':
      case 'fone2':
      case 'celular':
      case 'fone1_operacional':
      case 'fone2_operacional':
      case 'celular_operacional':
      case 'fone1_abastec':
      case 'fone2_abastec':
      case 'celular_abastec':
      case 'fone2_desconta':
      case 'fone1_desconta':
      case 'celular_desconta':
        auxValues[e.target.name] = mTel(e.target.value)
      break

      case 'cnpj': 
        auxValues[e.target.name] = mCNPJ(e.target.value)
      break

      case 'cpf': 
        auxValues[e.target.name] = mCPF(e.target.value)
      break

      case 'tipo_pessoa': 
        if(auxValues[e.target.name] !== e.target.value){
          auxValues.cpf = ''
          auxValues.cnpj = ''
        }
        auxValues[e.target.name] = (e.target.value)
      break

      case 'cep':
      case 'cep_operacional': 
      case 'cep_desconta': 
      case 'cep_abastec': 
        auxValues[e.target.name] = mCEP(e.target.value)
      break

      case 'opera_terceiros':
      case 'piscina_coberta':
      case 'ozonizador':
        auxValues[e.target.name] = e.target.checked ? 'S' : 'N'
      break

      default: auxValues[e.target.name] = e.target.value;
    }
    setData(auxValues);
  };

  const handleChangePiloto = (e) => {
    const auxValues = { ...dataPiloto };
    switch (e.target.name) {
      case 'fone1':
      case 'fone2':
      case 'celular':
        auxValues[e.target.name] = mTel(e.target.value)
      break

      case 'cpf':
        auxValues[e.target.name] = mCPF(e.target.value)
      break

      case 'cep':
        auxValues[e.target.name] = mCEP(e.target.value)
      break

      default: auxValues[e.target.name] = e.target.value;
    }
    setDataPiloto(auxValues);
  };

  const handleChangeEngenheiro = (e) => {
    const auxValues = { ...dataEngenheiro };
    switch (e.target.name) {
      case 'fone1':
      case 'fone2':
      case 'celular':
        auxValues[e.target.name] = mTel(e.target.value)
      break

      case 'cpf':
        auxValues[e.target.name] = mCPF(e.target.value)
      break

      case 'cep':
        auxValues[e.target.name] = mCEP(e.target.value)
      break

      default: auxValues[e.target.name] = e.target.value;
    }
    setDataEngenheiro(auxValues);
  };

  const handleChangeTecnico = (e) => {
    const auxValues = { ...dataTecnico };
    switch (e.target.name) {
      case 'fone1':
      case 'fone2':
      case 'celular':
        auxValues[e.target.name] = mTel(e.target.value)
      break

      case 'cpf':
        auxValues[e.target.name] = mCPF(e.target.value)
      break

      case 'cep':
        auxValues[e.target.name] = mCEP(e.target.value)
      break

      default: auxValues[e.target.name] = e.target.value;
    }
    setDataTecnico(auxValues);
  };

  const handleChangeGestorResponsavel = (e) => {
    const auxValues = { ...dataGestorResponsavel };
    switch (e.target.name) {
      case 'fone1':
      case 'fone2':
      case 'celular':
        auxValues[e.target.name] = mTel(e.target.value)
      break

      case 'cpf':
        auxValues[e.target.name] = mCPF(e.target.value)
      break

      case 'cep':
        auxValues[e.target.name] = mCEP(e.target.value)
      break

      default: auxValues[e.target.name] = e.target.value;
    }
    setDataGestorResponsavel(auxValues);
  };

  const handleChangeGestorOperacional = (e) => {
    const auxValues = { ...dataGestorOperacional };
    switch (e.target.name) {
      case 'fone1':
      case 'fone2':
      case 'celular':
        auxValues[e.target.name] = mTel(e.target.value)
      break

      case 'cpf':
        auxValues[e.target.name] = mCPF(e.target.value)
      break

      case 'cep':
        auxValues[e.target.name] = mCEP(e.target.value)
      break

      default: auxValues[e.target.name] = e.target.value;
    }
    setDataGestorOperacional(auxValues);
  };

  function handleCnpj(e) {
    e.preventDefault();
    let cnpj = e.target.value
    if(cnpj) {
      if(!validarCNPJ(cnpj)) {
        setErroCnpj(true)
        swal.fire('CNPJ inválido', 'Informe um CNPJ válido!', 'warning')
      } else {
        setErroCnpj(false)
      }
    }
  }

  function handleCpf(e) {
    e.preventDefault();
    let cpf = e.target.value
    if(cpf) {
      if(!validarCPF(cpf)) {
        setErroCpf(true)
        swal.fire('CPF inválido', 'Informe um CPF válido!', 'warning')
      } else {
        setErroCpf(false)
      }
    }
  }

  return (
    <div className="defaultReg">
      <NavBar />
      <div className="defaultRegContent">
        <div className="defaultHeader">
          <h3>{updateType ? 'Edição' : 'Inclusão'} de Cliente</h3>
          <UserOptions />
        </div>
        <br />
        <form id="regForm" onSubmit={handleSubmit}>
          <h4>Dados Gerais</h4>
          <div className="dadosGeraisCliente">
            
            <FrSelect 
              value={data.tipo_pessoa || []} 
              style={{ width: 140 }} 
              name="tipo_pessoa" 
              id="tipo_pessoa" 
              label='Tipo Pessoa' 
              data={[{display: 'Física', value: 'F'}, {display: 'Jurídica', value: 'J'}]}
              onChange={handleChange}
              disabled={updateType}
            />
            {data.tipo_pessoa == 'J' ?

            <FrTextInput 
              error={erroCnpj} 
              maxLength='18'
              value={data.cnpj || ''} 
              onChange={handleChange} 
              onBlur={handleCnpj}  
              name="cnpj" 
              id="cnpj" 
              color="#49573B" 
              label="CNPJ" 
              variant="outlined" 
              size="small"
              disabled={updateType}
              required
              style={{width: 180}}
            />
            : 
            <FrTextInput 
              error={erroCpf} 
              maxLength='14'
              value={data.cpf || ''} 
              onChange={handleChange} 
              onBlur={handleCpf}  
              name="cpf" 
              id="cpf" 
              color="#49573B" 
              label="CPF" 
              variant="outlined" 
              size="small"
              disabled={updateType}
              required
              style={{width: 180}}
            />

            }

            <FrTextInput
              maxLength='150'
              value={data.razao_social || ''} 
              onChange={handleChange}  
              name="razao_social" 
              id="razao_social" 
              color="#49573B" 
              label="Razão Social" 
              variant="outlined" 
              size="small"
              required
              style={{width: 700}}
            />

            <FrSelect 
              value={data.fk_scl || []} 
              style={{ width: 140 }} 
              name="fk_scl" 
              id="fk_scl" 
              label='Situação' 
              data={comboSitCli}
              onChange={handleChange}
            />
            
            <FrTextInput 
              maxLength='50' 
              value={data.fantasia || ''} 
              onChange={handleChange} 
              name="fantasia" 
              id="fantasia" 
              color="#49573B" 
              label="Nome Fantasia" 
              variant="outlined" 
              size="small"
              required
              style={{width: 500}}
            />
            <br/>
            <FrTextInput 
              maxLength='50' 
              value={data.insc_estadual || ''} 
              onChange={handleChange} 
              name="insc_estadual" 
              id="insc_estadual" 
              color="#49573B" 
              label="Inscrição Estadual" 
              variant="outlined" 
              size="small"
              style={{width: 200}}
            />
            <FrTextInput 
              maxLength='50' 
              value={data.insc_municipal || ''} 
              onChange={handleChange} 
              name="insc_municipal" 
              id="insc_municipal" 
              color="#49573B" 
              label="Inscrição Municipal" 
              variant="outlined" 
              size="small"
              style={{width: 200}}
            />
          </div>
          <TabsCliente 
            data={data}
            reloadDocumentos={reloadDocumentos}
            reloadSocios={reloadSocios}
            reloadContatos={reloadContatos}
            reloadDocumentosLicenca={reloadDocumentosLicenca}
            dataDocumentosLicenca={dataDocumentosLicenca}
            dataDocumentos={dataDocumentos}
            dataTanqueCombustivel={dataTanqueCombustivel}
            reloadTanqueCombustivel={reloadTanqueCombustivel}
            dataRelacionadosPiloto={dataRelacionadosPiloto}
            reloadRelacionadosPiloto={reloadRelacionadosPiloto}
            dataRelacionadosTecnico={dataRelacionadosTecnico}
            reloadRelacionadosTecnico={reloadRelacionadosTecnico}
            dataRelacionadosMecanico={dataRelacionadosMecanico}
            reloadRelacionadosMecanico={reloadRelacionadosMecanico}
            dataRelacionadosAeronave={dataRelacionadosAeronave}
            reloadRelacionadosAeronave={reloadRelacionadosAeronave}
            dataRelacionadosEngenheiro={dataRelacionadosEngenheiro}
            reloadRelacionadosEngenheiro={reloadRelacionadosEngenheiro}
            dataRelacionadosApua={dataRelacionadosApua}
            reloadRelacionadosApua={reloadRelacionadosApua}
            handleChange={handleChange} 
            setData={setData} 
            handleChangePiloto={handleChangePiloto}
            handleChangeTecnico={handleChangeTecnico}
            handleChangeEngenheiro={handleChangeEngenheiro}
            setDataPiloto={setDataPiloto}
            setDataTecnico={setDataTecnico}
            setDataEngenheiro={setDataEngenheiro}
            comboCidades={comboCidades}
            comboPilotos={comboPilotos} 
            comboTecnicos={comboTecnicos} 
            comboEngenheiros={comboEngenheiros} 
            dataSocios={dataSocios} 
            dataContatos={dataContatos} 
            dataPiloto={dataPiloto}
            dataTecnico={dataTecnico}
            dataEngenheiro={dataEngenheiro}
            comboMecanicos={comboMecanicos}
            comboApua={comboApua}
            comboTipoDocumento={comboTipoDocumento}
            comboTipoCombustivel={comboTipoCombustivel}
            comboAeronave={comboAeronave}
            comboClasses={comboClasses}
            comboSituacao={comboSituacao}
            dataGestorResponsavel={dataGestorResponsavel}
            dataGestorOperacional={dataGestorOperacional}
            setDataGestorOperacional={setDataGestorOperacional}
            handleChangeGestorOperacional={handleChangeGestorOperacional}
            handleChangeGestorResponsavel={handleChangeGestorResponsavel}
            handleCepGestorResponsavel={handleCepGestorResponsavel}
            handleCepGestorOperacional={handleCepGestorOperacional}
            erroCepGestorResponsavel={erroCepGestorResponsavel}
            erroCepGestorOperacional={erroCepGestorOperacional}
            erroCpfGestorOperacional={erroCpfGestorOperacional}
            erroCpfGestorResponsavel={erroCpfGestorResponsavel}
            handleCpfGestorResponsavel={handleCpfGestorResponsavel}
            handleCpfGestorOperacional={handleCpfGestorOperacional}
            comboModeloDocumento={comboModeloDocumento}
            setComboTipoDocumento={setComboTipoDocumento}
            comboSituacaoAeronave={comboSituacaoAeronave}
            comboSituacaoPiloto={comboSituacaoPiloto}
            comboSituacaoTecnico={comboSituacaoTecnico}
            comboSituacaoEngenheiro={comboSituacaoEngenheiro}
            comboSituacaoMecanico={comboSituacaoMecanico}
            comboSituacaoFase={comboSituacaoFase}
            pk_pes_cli={data.pk_pes_cli}
            updateType={updateType}
          /> 
        </form>
        <div className="regButtonsDiv">
          {enableEdit === 'S' ?
            (<button onClick={handleSubmit}>Salvar</button>) : (<></>)}
          <button onClick={handleCancel}>{enableEdit === 'S' ? 'Cancelar' : 'Fechar'}</button>
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default RegCliente;
