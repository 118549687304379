import React, { useState } from 'react';
import { Icon } from 'react-icons-kit'
import { ic_chevron_right } from 'react-icons-kit/md/ic_chevron_right'
import { ic_chevron_left } from 'react-icons-kit/md/ic_chevron_left'
import { ic_airplanemode_active } from 'react-icons-kit/md/ic_airplanemode_active'
import { driversLicense } from 'react-icons-kit/fa/driversLicense'
import { ic_assignment } from 'react-icons-kit/md/ic_assignment'
import { ic_location_pin } from 'react-icons-kit/md/ic_location_pin'
import { ic_flight_takeoff } from 'react-icons-kit/md/ic_flight_takeoff'
import { ic_landscape } from 'react-icons-kit/md/ic_landscape'
import { videoCamera } from 'react-icons-kit/icomoon/videoCamera'
import { ic_description } from 'react-icons-kit/md/ic_description'
import { ic_desktop_mac } from 'react-icons-kit/md/ic_desktop_mac'
import { ic_library_books } from 'react-icons-kit/md/ic_library_books'
import { ic_work } from 'react-icons-kit/md/ic_work'
import { ic_build } from 'react-icons-kit/md/ic_build'

import Nav from 'react-bootstrap/Nav'
import Collapse from 'react-bootstrap/Collapse'
import Tooltip from '@material-ui/core/Tooltip';
import './index.css';
import icon from '../../imgs/logo.png';
import logo from '../../imgs/logo.png';
import { menu } from 'react-icons-kit/feather/menu';

function NavBar(props) {
    const [auth, setAuth] = useState(JSON.parse(localStorage['authejr'] || JSON.stringify({ authenticated: false, user: 0 })));   
    const [hide, setHide] = useState(true);
    const [animationStart, setAnimationStart] = useState(false)
    const [openCadastros, setOpenCadastros] = useState(false)
    const [openSistema, setOpenSistema] = useState(false)
    const [openAdmissao, setOpenAdmissao] = useState(false)
    const [openAuxiliares, setOpenAuxiliares] = useState(false)
    const [openLocalizacao, setOpenLocalizacao] = useState(false)
    const [openGerais, setOpenGerais] = useState(false)
    const [openInternacoes, setOpenInternacoes] = useState(false)
    const [openFinanceiros, setOpenFinanceiros] = useState(false)
    const [openFinanceiro2, setOpenFinanceiro2] = useState(false)
    const [openAeronaves, setOpenAeronaves] = useState(false)
    const [openApua, setOpenApua] = useState(false)
    const [openMecanico, setOpenMecanico] = useState(false)
    const [openAeroporto, setOpenAeroporto] = useState(false)
    const [openPiloto, setOpenPiloto] = useState(false)
    const [openCliente, setOpenCliente] = useState(false)
    const [openAnotacoes, setOpenAnotacoes] = useState(false)
    const [openDocumentos, setOpenDocumentos] = useState(false)
    const [openCursos, setOpenCursos] = useState(false)
    const [openVisitas, setOpenVisitas] = useState(false)


    function toggleNavBarHide(e, item) {
        e.preventDefault();

        if (!hide) {
            setOpenCadastros(false)
            setOpenAdmissao(false)
            setOpenSistema(false)
            setOpenAuxiliares(false)
        }
        setAnimationStart(true)
        setHide(!hide)
    }

    function abreCadastro(e, item) {
        e.preventDefault();
        setHide(false)
        setOpenCadastros(!openCadastros)

    }

    function abreAdmissao(e, item) {
        e.preventDefault();
        setHide(false)
        setOpenAdmissao(!openAdmissao)

    }
    function abreSistema(e, item) {
        e.preventDefault();
        setHide(false)
        setOpenSistema(!openSistema)
    }


    return (
        <div className={"Navbar" + (animationStart ? '-a': '') + (hide ? '-hide no-print' : ' no-print')}>
            <button onClick={toggleNavBarHide} className="toggle_navbar">
                {hide ? <Icon icon={ic_chevron_right} size={40} /> : <Icon icon={ic_chevron_left} size={40} />}
            </button>
            <Nav justify defaultActiveKey="/ejr/home" className="flex-column">
                <Tooltip title="Tela Principal">
                    <Nav.Link href="/ejr/home">
                        {hide ? <img alt='Expandir' src={icon} className={"fr_icon_navbar"+ (animationStart ? '-a': '')} /> : <img alt='Encolher' src={logo} className={"fr_logo_navbar"+ (animationStart ? '-a': '')} />}
                    </Nav.Link>
                </Tooltip>

                {auth.grupo !== 2 && 
                    <button onClick={abreCadastro} className={(hide ? 'hide-' : '') + "toggleMenuButton" + (openCadastros ? " Open" : "")}>
                        {console.log(auth.grupo)}
                        <Icon icon={ic_assignment} size={20} />
                        {hide ? '' : <strong style={{ paddingLeft: '10px' }}>{'Cadastros'}</strong>}
                    </button>
                }
                <Collapse in={openCadastros}>

                    <div className="toggleMenu">

                        
                        <Tooltip title="Clientes">
                            <Nav.Link href="/ejr/cliente" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                <Icon icon={ic_chevron_right} size={15} />
                                {hide ? '' : <strong style={{ paddingLeft: '10px' }}>{'Clientes'}</strong>}
                            </Nav.Link>
                        </Tooltip>

                        <Tooltip title="Aeronaves">
                            <Nav.Link href="/ejr/aeronave" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                <Icon icon={ic_chevron_right} size={15} />
                                {hide ? '' : <strong style={{ paddingLeft: '10px' }}>{'Aeronaves'}</strong>}
                            </Nav.Link>
                        </Tooltip>

                        <Tooltip title="Pilotos">
                            <Nav.Link href="/ejr/piloto" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                <Icon icon={ic_chevron_right} size={15} />
                                {hide ? '' : <strong style={{ paddingLeft: '10px' }}>{'Pilotos'}</strong>}
                            </Nav.Link>
                        </Tooltip>

                        <Tooltip title="Apuas">
                            <Nav.Link href="/ejr/apua" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                <Icon icon={ic_chevron_right} size={15} />
                                {hide ? '' : <strong style={{ paddingLeft: '10px' }}>{'Apuas'}</strong>}
                            </Nav.Link>
                        </Tooltip>

                        <Tooltip title="Mecânicos">
                            <Nav.Link href="/ejr/mecanicos" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                <Icon icon={ic_chevron_right} size={15} />
                                {hide ? '' : <strong style={{ paddingLeft: '10px' }}>{'Mecânicos'}</strong>}
                            </Nav.Link>
                        </Tooltip>

                        <Tooltip title="Aeródromos">
                            <Nav.Link href="/ejr/aeroporto" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                <Icon icon={ic_chevron_right} size={15} />
                                {hide ? '' : <strong style={{ paddingLeft: '10px' }}>{'Aeródromos'}</strong>}
                            </Nav.Link>
                        </Tooltip>

                        <Tooltip title="Palestras">
                            <Nav.Link href="/ejr/palestra?buscar=true&fk_stp=2" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                <Icon icon={ic_chevron_right} size={15} />
                                {hide ? '' : <strong style={{ paddingLeft: '10px' }}>{'Palestras'}</strong>}
                            </Nav.Link>
                        </Tooltip>

                        <Tooltip title="Órgãos">
                            <Nav.Link href="/ejr/orgao" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                <Icon icon={ic_chevron_right} size={15} />
                                {hide ? '' : <strong style={{ paddingLeft: '10px' }}>{'Órgãos'}</strong>}
                            </Nav.Link>
                        </Tooltip>


                        <button onClick={() => setOpenGerais(!openGerais)} className={(hide ? 'hide-' : '') + "toggleMenuButtonCollapse" + (openGerais ? " Open" : "")}>
                            <Icon icon={ic_library_books} size={20} />
                            {hide ? '' : <strong style={{ paddingLeft: '20px' }}>{'Gerais'}</strong>}
                        </button>

                        <Collapse in={openGerais}>

                            <div className="toggleMenuCollapse">

                                <Tooltip title="Estados">
                                    <Nav.Link href="/ejr/estados" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                        <Icon icon={ic_chevron_right} size={15} />
                                        {hide ? '' : <strong style={{ paddingLeft: '5px' }}>{'Estados'}</strong>}
                                    </Nav.Link>
                                </Tooltip>
                                <Tooltip title="Cidades">
                                    <Nav.Link href="/ejr/cidades" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                        <Icon icon={ic_chevron_right} size={15} />
                                        {hide ? '' : <strong style={{ paddingLeft: '5px' }}>{'Cidades'}</strong>}
                                    </Nav.Link>
                                </Tooltip>


                            </div>

                        </Collapse>

                        <button onClick={() => setOpenAuxiliares(!openAuxiliares)} className={(hide ? 'hide-' : '') + "toggleMenuButtonCollapse" + (openAuxiliares ? " Open" : "")}>
                            <Icon icon={ic_library_books} size={20} />
                            {hide ? '' : <strong style={{ paddingLeft: '20px' }}>{'Auxiliares'}</strong>}
                        </button>

                        <Collapse in={openAuxiliares}>

                            <div className="toggleMenuCollapse">

                                
                                <Tooltip title="Função">
                                    <Nav.Link href="/ejr/funcao" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                        <Icon icon={ic_chevron_right} size={15} />
                                        {hide ? '' : <strong style={{ paddingLeft: '5px' }}>{'Funções'}</strong>}
                                    </Nav.Link>
                                </Tooltip>

                                
                                

                                <Tooltip title="Situação Padrão">
                                    <Nav.Link href="/ejr/situacao_padrao" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                        <Icon icon={ic_chevron_right} size={15} />
                                        {hide ? '' : <strong style={{ paddingLeft: '5px' }}>{'Situação Padrão'}</strong>}
                                    </Nav.Link>
                                </Tooltip>

                                
                                

                                <Tooltip title="Tipo de Combustível">
                                    <Nav.Link href="/ejr/tipo_combustivel" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                        <Icon icon={ic_chevron_right} size={15} />
                                        {hide ? '' : <strong style={{ paddingLeft: '5px' }}>{'Tipo de Combustível'}</strong>}
                                    </Nav.Link>
                                </Tooltip>

                                


                                <button onClick={() => setOpenAeronaves(!openAeronaves)} className={(hide ? 'hide-' : '') + "toggleMenuButtonCollapse" + (openAeronaves ? " Open" : "")}>
                                    <Icon icon={ic_airplanemode_active} size={20} />
                                    {hide ? '' : <strong style={{ paddingLeft: '20px' }}>{'Aeronave'}</strong>}
                                </button>

                                <Collapse in={openAeronaves}> 

                                    <div className="toggleMenuCollapse">

                                        <Tooltip title="Categoria de Aeronave">
                                            <Nav.Link href="/ejr/aeronave_categoria" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                <Icon icon={ic_chevron_right} size={15} />
                                                {hide ? '' : <strong style={{ paddingLeft: '5px' }}>{'Categoria de Aeronave'}</strong>}
                                            </Nav.Link>
                                        </Tooltip>

                                        <Tooltip title="Cor de Aeronave">
                                            <Nav.Link href="/ejr/aeronave_cor" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                <Icon icon={ic_chevron_right} size={15} />
                                                {hide ? '' : <strong style={{ paddingLeft: '5px' }}>{'Cor de Aeronave'}</strong>}
                                            </Nav.Link>
                                        </Tooltip>
                                        
                                        <Tooltip title="Fabricante de Aeronave">
                                            <Nav.Link href="/ejr/aeronave_fabricante" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                <Icon icon={ic_chevron_right} size={15} />
                                                {hide ? '' : <strong style={{ paddingLeft: '5px' }}>{'Fabricante de Aeronave'}</strong>}
                                            </Nav.Link>
                                        </Tooltip>

                                        <Tooltip title="Modelo de Aeronave">
                                            <Nav.Link href="/ejr/aeronave_modelo" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                <Icon icon={ic_chevron_right} size={15} />
                                                {hide ? '' : <strong style={{ paddingLeft: '5px' }}>{'Modelo de Aeronave'}</strong>}
                                            </Nav.Link>
                                        </Tooltip>

                                        <Tooltip title="Situação Aeronave">
                                            <Nav.Link href="/ejr/situacao_aeronave" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                <Icon icon={ic_chevron_right} size={15} />
                                                {hide ? '' : <strong style={{ paddingLeft: '5px' }}>{'Situação Aeronave'}</strong>}
                                            </Nav.Link>
                                        </Tooltip>
                                    </div>

                                </Collapse>

                                <button onClick={() => setOpenPiloto(!openPiloto)} className={(hide ? 'hide-' : '') + "toggleMenuButtonCollapse" + (openPiloto ? " Open" : "")}>
                                    <Icon icon={driversLicense} size={20} />
                                    {hide ? '' : <strong style={{ paddingLeft: '20px' }}>{'Piloto'}</strong>}
                                </button>

                                <Collapse in={openPiloto}> 

                                    <div className="toggleMenuCollapse">

                                        <Tooltip title="Classe de Piloto">
                                            <Nav.Link href="/ejr/piloto_classe" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                <Icon icon={ic_chevron_right} size={15} />
                                                {hide ? '' : <strong style={{ paddingLeft: '5px' }}>{'Classe de Piloto'}</strong>}
                                            </Nav.Link>
                                        </Tooltip>

                                        <Tooltip title="Situação Piloto">
                                            <Nav.Link href="/ejr/situacao_piloto" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                <Icon icon={ic_chevron_right} size={15} />
                                                {hide ? '' : <strong style={{ paddingLeft: '5px' }}>{'Situação Piloto'}</strong>}
                                            </Nav.Link>
                                        </Tooltip>

                                        
                                    </div>

                                </Collapse>

                                <button onClick={() => setOpenAeroporto(!openAeroporto)} className={(hide ? 'hide-' : '') + "toggleMenuButtonCollapse" + (openAeroporto ? " Open" : "")}>
                                    <Icon icon={ic_flight_takeoff} size={20} />
                                    {hide ? '' : <strong style={{ paddingLeft: '20px' }}>{'Aeroporto'}</strong>}
                                </button>

                                <Collapse in={openAeroporto}> 

                                    <div className="toggleMenuCollapse">

                                        <Tooltip title="Situação Aeroporto">
                                            <Nav.Link href="/ejr/situacao_aeroporto" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                <Icon icon={ic_chevron_right} size={15} />
                                                {hide ? '' : <strong style={{ paddingLeft: '5px' }}>{'Situação Aeroporto'}</strong>}
                                            </Nav.Link>
                                        </Tooltip>

                                        <Tooltip title="Tipo de Aeroporto">
                                            <Nav.Link href="/ejr/tipo_aeroporto" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                <Icon icon={ic_chevron_right} size={15} />
                                                {hide ? '' : <strong style={{ paddingLeft: '5px' }}>{'Tipo de Aeroporto'}</strong>}
                                            </Nav.Link>
                                        </Tooltip>
                                        
                                    </div>

                                </Collapse>

                                <button onClick={() => setOpenApua(!openApua)} className={(hide ? 'hide-' : '') + "toggleMenuButtonCollapse" + (openApua ? " Open" : "")}>
                                    <Icon icon={ic_landscape} size={20} />
                                    {hide ? '' : <strong style={{ paddingLeft: '20px' }}>{'Apua'}</strong>}
                                </button>

                                <Collapse in={openApua}> 

                                    <div className="toggleMenuCollapse">

                                        <Tooltip title="Tipo de Piso">
                                            <Nav.Link href="/ejr/tipospiso" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                <Icon icon={ic_chevron_right} size={15} />
                                                {hide ? '' : <strong style={{ paddingLeft: '5px' }}>{'Tipo de Piso'}</strong>}
                                            </Nav.Link>
                                        </Tooltip>
                                        
                                    </div>

                                </Collapse>

                                <button onClick={() => setOpenMecanico(!openMecanico)} className={(hide ? 'hide-' : '') + "toggleMenuButtonCollapse" + (openMecanico ? " Open" : "")}>
                                    <Icon icon={ic_build} size={20} />
                                    {hide ? '' : <strong style={{ paddingLeft: '20px' }}>{'Mecânico'}</strong>}
                                </button>

                                <Collapse in={openMecanico}> 

                                    <div className="toggleMenuCollapse">

                                        <Tooltip title="Classe de Mecânico">
                                            <Nav.Link href="/ejr/mecanico_classe" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                <Icon icon={ic_chevron_right} size={15} />
                                                {hide ? '' : <strong style={{ paddingLeft: '5px' }}>{'Classe de Mecânico'}</strong>}
                                            </Nav.Link>
                                        </Tooltip>

                                        <Tooltip title="Situação Mecânico">
                                            <Nav.Link href="/ejr/situacao_mecanico" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                <Icon icon={ic_chevron_right} size={15} />
                                                {hide ? '' : <strong style={{ paddingLeft: '5px' }}>{'Situação Mecânico'}</strong>}
                                            </Nav.Link>
                                        </Tooltip>
                                        
                                    </div>

                                </Collapse>


                                <button onClick={() => setOpenCliente(!openCliente)} className={(hide ? 'hide-' : '') + "toggleMenuButtonCollapse" + (openCliente ? " Open" : "")}>
                                    <Icon icon={ic_work} size={20} />
                                    {hide ? '' : <strong style={{ paddingLeft: '20px' }}>{'Cliente'}</strong>}
                                </button>

                                <Collapse in={openCliente}> 

                                    <div className="toggleMenuCollapse">

                                        <Tooltip title="Situação Cliente">
                                            <Nav.Link href="/ejr/situacao_cliente" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                <Icon icon={ic_chevron_right} size={15} />
                                                {hide ? '' : <strong style={{ paddingLeft: '5px' }}>{'Situação Cliente'}</strong>}
                                            </Nav.Link>
                                        </Tooltip>

                                        <Tooltip title="Tipo de Relação de Cliente">
                                            <Nav.Link href="/ejr/tipo_relacao_cliente" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                <Icon icon={ic_chevron_right} size={15} />
                                                {hide ? '' : <strong style={{ paddingLeft: '5px' }}>{'Tipo de Relação de Cliente'}</strong>}
                                            </Nav.Link>
                                        </Tooltip>
                                        
                                    </div>

                                </Collapse>


                                <button onClick={() => setOpenAnotacoes(!openAnotacoes)} className={(hide ? 'hide-' : '') + "toggleMenuButtonCollapse" + (openAnotacoes ? " Open" : "")}>
                                    <Icon icon={ic_library_books} size={20} />
                                    {hide ? '' : <strong style={{ paddingLeft: '20px' }}>{'Anotações'}</strong>}
                                </button>

                                <Collapse in={openAnotacoes}> 

                                    <div className="toggleMenuCollapse">

                                        <Tooltip title="Categoria de Anotação">
                                            <Nav.Link href="/ejr/categoria_anotacao" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                <Icon icon={ic_chevron_right} size={15} />
                                                {hide ? '' : <strong style={{ paddingLeft: '5px' }}>{'Categoria de Anotação'}</strong>}
                                            </Nav.Link>
                                        </Tooltip>

                                        <Tooltip title="Situação Anotação">
                                            <Nav.Link href="/ejr/situacao_anotacao" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                <Icon icon={ic_chevron_right} size={15} />
                                                {hide ? '' : <strong style={{ paddingLeft: '5px' }}>{'Situação Anotação'}</strong>}
                                            </Nav.Link>
                                        </Tooltip>

                                        
                                        
                                    </div>

                                </Collapse>


                                <button onClick={() => setOpenDocumentos(!openDocumentos)} className={(hide ? 'hide-' : '') + "toggleMenuButtonCollapse" + (openDocumentos ? " Open" : "")}>
                                    <Icon icon={ic_library_books} size={20} />
                                    {hide ? '' : <strong style={{ paddingLeft: '20px' }}>{'Documentos'}</strong>}
                                </button>

                                <Collapse in={openDocumentos}> 

                                    <div className="toggleMenuCollapse">

                                        <Tooltip title="Modelo de Documento">
                                            <Nav.Link href="/ejr/modelo_documento" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                <Icon icon={ic_chevron_right} size={15} />
                                                {hide ? '' : <strong style={{ paddingLeft: '5px' }}>{'Modelo de Documento'}</strong>}
                                            </Nav.Link>
                                        </Tooltip>

                                        <Tooltip title="Tipo de Documento">
                                            <Nav.Link href="/ejr/tipo_documento" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                <Icon icon={ic_chevron_right} size={15} />
                                                {hide ? '' : <strong style={{ paddingLeft: '5px' }}>{'Tipo de Documento'}</strong>}
                                            </Nav.Link>
                                        </Tooltip>

                                        <Tooltip title="Unidades de Órgão">
                                            <Nav.Link href="/ejr/orgao_unidade" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                <Icon icon={ic_chevron_right} size={15} />
                                                {hide ? '' : <strong style={{ paddingLeft: '5px' }}>{'Unidades de Órgão'}</strong>}
                                            </Nav.Link>
                                        </Tooltip>

                                        <Tooltip title="Situação Palestra">
                                            <Nav.Link href="/ejr/situacao_palestra" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                <Icon icon={ic_chevron_right} size={15} />
                                                {hide ? '' : <strong style={{ paddingLeft: '5px' }}>{'Situação Palestra'}</strong>}
                                            </Nav.Link>
                                        </Tooltip>

                                        
                                    </div>

                                </Collapse>


                                <button onClick={() => setOpenCursos(!openCursos)} className={(hide ? 'hide-' : '') + "toggleMenuButtonCollapse" + (openCursos ? " Open" : "")}>
                                    <Icon icon={videoCamera} size={20} />
                                    {hide ? '' : <strong style={{ paddingLeft: '20px' }}>{'Cursos'}</strong>}
                                </button>

                                <Collapse in={openCursos}> 

                                    <div className="toggleMenuCollapse">

                                        
                                        <Tooltip title="Ministrantes">
                                            <Nav.Link href="/ejr/palestra_ministrante" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                <Icon icon={ic_chevron_right} size={15} />
                                                {hide ? '' : <strong style={{ paddingLeft: '5px' }}>{'Ministrantes'}</strong>}
                                            </Nav.Link>
                                        </Tooltip>

                                        <Tooltip title="Participantes Palestra">
                                            <Nav.Link href="/ejr/participantes" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                <Icon icon={ic_chevron_right} size={15} />
                                                {hide ? '' : <strong style={{ paddingLeft: '5px' }}>{'Participantes'}</strong>}
                                            </Nav.Link>
                                        </Tooltip>
                                        
                                        <Tooltip title="Tema Palestras">
                                            <Nav.Link href="/ejr/tema_palestra" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                <Icon icon={ic_chevron_right} size={15} />
                                                {hide ? '' : <strong style={{ paddingLeft: '5px' }}>{'Tema Palestras'}</strong>}
                                            </Nav.Link>
                                        </Tooltip>

                                        
                                    </div>

                                </Collapse>


                                <button onClick={() => setOpenVisitas(!openVisitas)} className={(hide ? 'hide-' : '') + "toggleMenuButtonCollapse" + (openVisitas ? " Open" : "")}>
                                    <Icon icon={ic_location_pin} size={20} />
                                    {hide ? '' : <strong style={{ paddingLeft: '20px' }}>{'Vistorias'}</strong>}
                                </button>

                                <Collapse in={openVisitas}> 

                                    <div className="toggleMenuCollapse">

                                      
                                        <Tooltip title="Tipo de Não Conformidade">
                                            <Nav.Link href="/ejr/tipo_nconformidade" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                <Icon icon={ic_chevron_right} size={15} />
                                                {hide ? '' : <strong style={{ paddingLeft: '5px' }}>{'Tipo de Não Conformidade'}</strong>}
                                            </Nav.Link>
                                        </Tooltip>

                                        <Tooltip title="Situação Visita">
                                            <Nav.Link href="/ejr/situacao_visita" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                                <Icon icon={ic_chevron_right} size={15} />
                                                {hide ? '' : <strong style={{ paddingLeft: '5px' }}>{'Situação Visita'}</strong>}
                                            </Nav.Link>
                                        </Tooltip>

                                        
                                    </div>

                                </Collapse>

                                



                                

                                {/* <Tooltip title="Tipo de Licença">
                                    <Nav.Link href="/ejr/tipo_licenca" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                        <Icon icon={ic_chevron_right} size={15} />
                                        {hide ? '' : <strong style={{ paddingLeft: '5px' }}>{'Tipo de Licença'}</strong>}
                                    </Nav.Link>
                                </Tooltip> */}

                                {/* <Tooltip title="Tipo de Portaria">
                                    <Nav.Link href="/ejr/tipo_portaria" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                        <Icon icon={ic_chevron_right} size={15} />
                                        {hide ? '' : <strong style={{ paddingLeft: '5px' }}>{'Tipo de Portaria'}</strong>}
                                    </Nav.Link>
                                </Tooltip> */}

                                

                                

                            </div>

                        </Collapse>

                    </div>
                </Collapse>

                {auth.grupo !== 2 && 
                    <button onClick={abreAdmissao} className={(hide ? 'hide-' : '') + "toggleMenuButton" + (openAdmissao ? " Open" : "")}>
                        <Icon icon={ic_build} size={20} />
                        {hide ? '' : <strong style={{ paddingLeft: '10px' }}>{'Operacional'}</strong>}
                    </button>
                }
                <Collapse in={openAdmissao}>
                    <div className="toggleMenu">
                        <Tooltip title="Anotações">
                            <Nav.Link href="/ejr/anotacoes?buscar=true&fk_asi=1" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                <Icon icon={ic_chevron_right} size={15} />
                                {hide ? '' : <strong style={{ paddingLeft: '10px' }}>{'Anotações'}</strong>}
                            </Nav.Link>
                        </Tooltip>
                        <Tooltip title="Autuações">
                            <Nav.Link href="/ejr/autuacoes" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                <Icon icon={ic_chevron_right} size={15} />
                                {hide ? '' : <strong style={{ paddingLeft: '10px' }}>{'Autuações'}</strong>}
                            </Nav.Link>
                        </Tooltip>
                        <Tooltip title="Consulta Relacionados">
                            <Nav.Link href="/ejr/relacionados" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                <Icon icon={ic_chevron_right} size={15} />
                                {hide ? '' : <strong style={{ paddingLeft: '10px' }}>{'Consulta Relacionados'}</strong>}
                            </Nav.Link>
                        </Tooltip>
                        <Tooltip title="Notificacoes">
                            <Nav.Link href="/ejr/notificacoes" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                <Icon icon={ic_chevron_right} size={15} />
                                {hide ? '' : <strong style={{ paddingLeft: '10px' }}>{'Notificações'}</strong>}
                            </Nav.Link>
                        </Tooltip>
                        <Tooltip title="Palestras">
                            <Nav.Link href="/ejr/palestra?buscar=true&fk_stp=2" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                <Icon icon={ic_chevron_right} size={15} />
                                {hide ? '' : <strong style={{ paddingLeft: '10px' }}>{'Palestras'}</strong>}
                            </Nav.Link>
                        </Tooltip>
                        <Tooltip title="Processos">
                            <Nav.Link href="/ejr/processos?buscar=true&concluidos=false" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                <Icon icon={ic_chevron_right} size={15} />
                                {hide ? '' : <strong style={{ paddingLeft: '10px' }}>{'Processos'}</strong>}
                            </Nav.Link>
                        </Tooltip>
                        <Tooltip title="Vencimentos">
                            <Nav.Link href="/ejr/vencimentos" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                <Icon icon={ic_chevron_right} size={15} />
                                {hide ? '' : <strong style={{ paddingLeft: '10px' }}>{'Vencimentos'}</strong>}
                            </Nav.Link>
                        </Tooltip>
                        <Tooltip title="Vistorias">
                            <Nav.Link href="/ejr/visitas?buscar=true&fk_svi=6" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                <Icon icon={ic_chevron_right} size={15} />
                                {hide ? '' : <strong style={{ paddingLeft: '10px' }}>{'Vistorias'}</strong>}
                            </Nav.Link>
                        </Tooltip>


                    </div>
                </Collapse>

                {auth.grupo !== 2 && 
                    <button onClick={abreSistema} className={(hide ? 'hide-' : '') + "toggleMenuButton" + (openSistema ? " Open" : "")}>
                        <Icon icon={ic_desktop_mac} size={20} />
                        {hide ? '' : <strong style={{ paddingLeft: '10px' }}>{'Sistema'}</strong>}
                    </button>
                }
                <Collapse in={openSistema}>
                    <div className="toggleMenu">
                        <Tooltip title="Grupo de Usuários">
                            <Nav.Link href="/ejr/grupo_usuarios" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                <Icon icon={ic_chevron_right} size={15} />
                                {hide ? '' : <strong style={{ paddingLeft: '10px' }}>{'Grupo de Usuários'}</strong>}
                            </Nav.Link>
                        </Tooltip>
                        <Tooltip title="Usuários">
                            <Nav.Link href="/ejr/usuarios" className={(hide ? 'hide-' : '') + "toggleMenuItem"}>
                                <Icon icon={ic_chevron_right} size={15} />
                                {hide ? '' : <strong style={{ paddingLeft: '10px' }}>{'Usuários'}</strong>}
                            </Nav.Link>
                        </Tooltip>
                    </div>
                </Collapse>



            </Nav>

        </div>
    );
}

export default NavBar;