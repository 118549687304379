import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import './index.css';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={4}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,

    },

    indicator: {
        backgroundColor: '#9c9595',

    },

    default_tabStyle: {
        color: 'white',
        fontSize: 11,
        backgroundColor: '#9c9595',

    },

    active_tabStyle: {
        fontSize: 11,
        color: 'YELLOW',
        backgroundColor: '#9c9595',

    }

}));

export default function ScrollableTabsButtonAuto(props) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    // const [abas, setAbas] = useState(props.abas)

    const handleChange = (e, newValue) => {
        // e.preventDefault();
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <AppBar position="static" color="default">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                    className={classes.indicator}
                >
                    {/* {abas.map((abas) => (

                        <Tab label={abas.caption} hidden={abas.hidden} wrapped
                            className=
                            {(value === abas.id) ? classes.active_tabStyle : classes.default_tabStyle}

                            {...a11yProps(value)} />

                    ))}

                </Tabs> */}



                    <Tab label={props.aba1_caption} hidden={props.aba1_hidden} wrapped
                        className=
                        {(value === 0) ? classes.active_tabStyle : classes.default_tabStyle}

                        {...a11yProps(0)} />

                    <Tab label={props.aba2_caption} hidden={props.aba2_hidden} wrapped
                        className=
                        {(value === 1) ? classes.active_tabStyle : classes.default_tabStyle}

                        {...a11yProps(1)} />

                    <Tab label={props.aba3_caption} hidden={props.aba3_hidden} wrapped
                        className=
                        {(value === 2) ? classes.active_tabStyle : classes.default_tabStyle}

                        {...a11yProps(2)} />

                    <Tab label={props.aba4_caption} hidden={props.aba4_hidden} wrapped
                        className=
                        {(value === 3) ? classes.active_tabStyle : classes.default_tabStyle}

                        {...a11yProps(3)} />

                    <Tab label={props.aba5_caption} hidden={props.aba5_hidden} wrapped
                        className=
                        {(value === 4) ? classes.active_tabStyle : classes.default_tabStyle}

                        {...a11yProps(4)} />

                    <Tab label={props.aba6_caption} hidden={props.aba6_hidden} wrapped
                        className=
                        {(value === 5) ? classes.active_tabStyle : classes.default_tabStyle}

                        {...a11yProps(5)} />

                    <Tab label={props.aba7_caption} hidden={props.aba7_hidden} wrapped
                        className=
                        {(value === 6) ? classes.active_tabStyle : classes.default_tabStyle}

                        {...a11yProps(6)} />

                </Tabs>
            </AppBar>
            {/* {abas.map((abas) => (

                <TabPanel value={value} index={0} hidden={abas.hidden}>
                    {abas.conteudo}

                    
                </TabPanel>

            ))} */}



            <TabPanel value={value} index={0} hidden={props.aba1_hidden}>
                {props.Aba1}

            </TabPanel>
            <TabPanel value={value} index={1} hidden={props.aba2_hidden}>
                {props.Aba2}
            </TabPanel>
            <TabPanel value={value} index={2} hidden={props.aba3_hidden}>
                {props.Aba3}
            </TabPanel>
            <TabPanel value={value} index={3} hidden={props.aba4_hidden}>
                {props.Aba4}
            </TabPanel>
            <TabPanel value={value} index={4} hidden={props.aba5_hidden}>
                {props.Aba5}
            </TabPanel>
            <TabPanel value={value} index={5} hidden={props.aba6_hidden}>
                {props.Aba6}
            </TabPanel>
            <TabPanel value={value} index={6} hidden={props.aba7_hidden}>
                {props.Aba7}
            </TabPanel>

        </div>
    );
}
