import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import NavBar from '../components/NavBar'
import FrTextInput from '../components/FrTextInput'
import FrSelect from '../components/FrSelect'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import TabsTecAgricola from '../components/TabsTecAgricola'

import api from '../services/api'
import consultaCNPJ from '../services/consultaCNPJ'
import { paramsToObject, validarCNPJ, onlyNumbers, removeSpecialChar, validarCPF } from '../utils/functions'
import { mCNPJ, mTel, mCEP, mCPF } from '../utils/masks'

import './styles/DefaultReg.css';
import FooterDelphus from '../components/FooterDelphus';

const swal = withReactContent(Swal)

function RegTecnico_Agricola() {
  const [data, setData] = useState({ativo: 'S'})
  const [dataDocumentos, setDataDocumentos] = useState([])
  const [updateType, setUpdateType] = useState(false)
  const [enableEdit, setEnableEdit] = useState('N')
  const [erroCpf, setErroCpf] = useState(false)
  const [dataRelacionadosTecnico_agricola, setDataRelacionadosTecnico_agricola] = useState([])
  const [comboCidades, setComboCidades] = useState([{ value: '', display: '' }])
  const [comboClasses, setComboClasses] = useState([{ value: '', display: '' }])
  const [comboTipoDocumento, setComboTipoDocumento] = useState([{ value: '', display: '' }])
  const [comboSituacaoTecnico_agricola, setComboSituacaoTecnico_agricola] = useState([{ value: '', display: '' }])
  const [comboSituacaoGeral, setComboSituacaoGeral] = useState([{ value: '', display: '' }])
  const [comboClientes, setComboClientes] = useState([{ value: '', display: '' }])
  const [comboModeloDocumento, setComboModeloDocumento] = useState([{ value: '', display: '' }])
  const [codigo, setCodigo] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })

      setComboCidades(await api.getComboData('cidades'))
      setComboTipoDocumento(await api.getComboData('tipo_documento'))
      setComboSituacaoTecnico_agricola(await api.getComboData('situacao_piloto'))
      setComboSituacaoGeral(await api.getComboData('situacao_padrao'))
      setComboClientes(await api.getComboData('cliente'))
      setComboModeloDocumento(await api.getComboData('modelo_documento'))

      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)

      // if (query.iframe) require('./styles/iframeReg.css');

      if (query.codigo > 0) {
        setEnableEdit(await api.getAcessos('Tecnico_agricola', 'Alteracao'))
      } else {
        setEnableEdit(await api.getAcessos('Tecnico_agricola', 'Inclusao'))
      }

      if (query.codigo > 0) {
        setUpdateType(true)
        setCodigo(query.codigo)
        api.get('/tecnico_agricola?pk_pes_tag=' + query.codigo).then(r => {          
          if (r.data[0]) {
            r.data[0].cpf = mCPF(r.data[0].cpf || '')
            r.data[0].fone1 = mTel(r.data[0].fone1 || '')
            r.data[0].fone2 = mTel(r.data[0].fone2 || '')
            r.data[0].celular = mTel(r.data[0].celular || '')
            r.data[0].cep = mCEP(r.data[0].cep || '')
            setData(r.data[0])
            console.log(data)
            api.get('/tecnico_agricola/documentos?pk_pes_tag=' + query.codigo).then(r => {
                if (r.data) {
                  setDataDocumentos(r.data)
                }
                api.get('/tecnico_agricola/cliente?pk_pes_tag=' + query.codigo).then(r => {
                  if (r.data) {
                    console.log(r.data)
                    setDataRelacionadosTecnico_agricola(r.data)
                  }
                  swal.close()
                })
            })
          } else {
            swal.fire('Código inválido', 'Código informado não encontrado!', 'warning')
              .then((result) => {
                if (result.value) {
                  window.location.href = '/ejr/tecnico_agricola'
                }
              })
          }
        })
      } else swal.close()
    }

    fetchData()
  }, [])

  const reloadDocumentos = () => {
    swal.fire({
      html: <FrLoading text="Carregando..." />,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false
    })
    api.get('/tecnico_agricola/documentos?pk_pes_tag=' + data.pk_pes_tag).then(r => {
      if (r.data) {
        console.log(r.data)
        setDataDocumentos(r.data)
      }
      swal.close()
    })
  }

  const reloadRelacionadosTecnico_agricola = () => {
    swal.fire({
      html: <FrLoading text="Carregando..." />,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false
    })
    api.get('/tecnico_agricola/cliente?pk_pes_tag=' + data.pk_pes_tag).then(r => {
      swal.close()
      if (r.data) {
        console.log(r.data)
        setDataRelacionadosTecnico_agricola(r.data)
      }
    })
  }

  function consistData(data) {
    let required = [
      'CNPJ'
    ]

    let errors = []

    required.forEach((curr, index) => {
      let fieldName = curr
      if (!data[curr]) return errors.push(fieldName)
    })

    return errors
  }

  const handleImageChange = (image, fieldName) => {
    const auxValues = { ...data };
    auxValues['foto'] = image
    setData(auxValues)
  }

  function handleSubmit(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Deseja salvar " + (updateType ? 'as alterações' : 'a inclusão') + ' do registro?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        const auxValues = { ...data };
        
        auxValues.cpf = onlyNumbers(auxValues.cpf)
        auxValues.cep = onlyNumbers(auxValues.cep)
        auxValues.fone1 = onlyNumbers(auxValues.fone1)
        auxValues.fone2 = onlyNumbers(auxValues.fone2)
        auxValues.celular = onlyNumbers(auxValues.celular)
        auxValues.data_nasc = auxValues.data_nasc ? auxValues.data_nasc.split('T')[0] : null
        auxValues.data_cma = auxValues.data_cma ? auxValues.data_cma.split('T')[0] : null
        auxValues.data_exp_certif = auxValues.data_exp_certif ? auxValues.data_exp_certif.split('T')[0] : null
        auxValues.data_cavag = auxValues.data_cavag ? auxValues.data_cavag.split('T')[0] : null
        // let errors = consistData(auxValues)
        let errors = []
        console.log(errors.length)
        if (errors.length === 0) {
          if (updateType) {
            api.post('/tecnico_agricola/edit', auxValues).then(r => {
              if (r.status === 200) {
                swal.fire({
                  title: 'Registro Alteraddo',
                  text: "O registro foi alterado com sucesso!",
                  icon: 'success',
                }).then((result) => {
                  if (result.value) {
                    window.location.href = '/ejr/tecnico_agricola'
                  }
                })
              } else {
                swal.fire({
                  title: 'Erro!',
                  text: "O registro não foi alterado!",
                  icon: 'warning',
                })
              }
            })
          } else {
            api.post('/tecnico_agricola/add', auxValues).then(r => {
              if (r.status === 200) {
                let pk_pes_tag = r.data
                swal.fire({
                  title: 'Registro adicionado com sucesso!',
                  text: "Deseja editar agora as outras abas deste cadastro?",
                  icon: 'success',
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: 'Sim',
                  cancelButtonText: 'Não'
                }).then((result) => {
                  console.log(result)
                  if (result) {
                    window.location.href = '/ejr/tecnico_agricola/registro?codigo='+pk_pes_tag
                  } else {
                    window.location.href = '/ejr/tecnico_agricola'
                  }
                })
              } else {
                swal.fire({
                  title: 'Erro!',
                  text: "O registro não foi adicionado!",
                  icon: 'warning',
                })
              }
            }).catch(e => {
              swal.fire({
                title: 'Erro!',
                text: "O registro não foi adicionado!",
                icon: 'warning',
              })
            })
          }
        } else {
          swal.fire({
            title: 'Erro!',
            text: "Verifique o(s) campo(s) " + errors.join(','),
            icon: 'warning',
          })
        }
      }
    })
  }

  function handleCancel(e) {
    e.preventDefault();
    swal.fire({
      title: 'ATENÇÃO',
      text: "Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        window.location.href = '/ejr/tecnico_agricola'
      }
    })
  }

  function handleCpf(e) {
    e.preventDefault();
    let cpf = e.target.value
    if(cpf) {
      if(!validarCPF(cpf)) {
        setErroCpf(true)
        swal.fire('CPF inválido', 'Informe um CPF válido!', 'warning')
      } else {
        setErroCpf(false)
        api.get('/pessoa?cpf=' + onlyNumbers(e.target.value)).then(r => {
          if (r.data[0]) {
              r.data[0].cpf = mCPF(r.data[0].cpf)
              r.data[0].fone1 = mTel(r.data[0].fone1)
              r.data[0].fone2 = mTel(r.data[0].fone2)
              r.data[0].cep = mCEP(r.data[0].cep)
              setData(r.data[0])
          }
        })
      }
    }
  }

  const handleChange = (e) => {
    const auxValues = { ...data };
    switch (e.target.name) {
      case 'fone1':
      case 'fone2':
      case 'celular':
        auxValues[e.target.name] = mTel(e.target.value)
      break

      case 'cpf':
        auxValues[e.target.name] = mCPF(e.target.value)
      break

      case 'cep':
        auxValues[e.target.name] = mCEP(e.target.value)
      break

      default: auxValues[e.target.name] = e.target.value;
    }
    setData(auxValues);
  };

  return (
    <div className="defaultReg">
      <NavBar />
      <div className="defaultRegContent">
        <div className="defaultHeader">
          <h3>{updateType ? 'Edição' : 'Inclusão'} de Técnico Agrícola</h3>
          <UserOptions />
        </div>
        <br />
        <form id="regForm" onSubmit={handleSubmit}>
          <TabsTecAgricola 
            data={data}
            handleChange={handleChange}
            setData={setData}
            comboTipoDocumento={comboTipoDocumento}
            comboCidades={comboCidades}
            comboSituacaoTecnico_agricola={comboSituacaoTecnico_agricola}
            reloadDocumentos={reloadDocumentos}
            comboSituacaoGeral={comboSituacaoGeral}
            comboClientes={comboClientes}
            dataDocumentos={dataDocumentos}
            handleImageChange={handleImageChange}
            handleCpf={handleCpf}
            erroCpf={erroCpf}
            updateType={updateType}
            dataRelacionadosTecnico_agricola={dataRelacionadosTecnico_agricola}
            reloadRelacionadosTecnico_agricola={reloadRelacionadosTecnico_agricola}
            comboModeloDocumento={comboModeloDocumento}
            setComboTipoDocumento={setComboTipoDocumento}
            pk_pes_tag={data.pk_pes_tag}/> 
        </form>
        <div className="regButtonsDiv">
          {enableEdit === 'S' ?
            (<button onClick={handleSubmit}>Salvar</button>) : (<></>)}
          <button onClick={handleCancel}>{enableEdit === 'S' ? 'Cancelar' : 'Fechar'}</button>
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default RegTecnico_Agricola;
