import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import Nav from 'react-bootstrap/Nav'
import withReactContent from 'sweetalert2-react-content'
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import FrTextInput from '../FrTextInput'
import FrImageEdit from '../FrImageEdit'
import FrDatePicker from '../FrDatePicker'
import FrSelect from '../FrSelect'
import FrLoading from '../FrLoading'
import ListRegisters from '../ListRegisters'
import ListRegistersDetailedFilter from '../ListRegistersDetailedFilter'
import { Icon } from 'react-icons-kit'
import {ic_save} from 'react-icons-kit/md/ic_save'
import {ic_mode_edit} from 'react-icons-kit/md/ic_mode_edit'
import {ic_delete} from 'react-icons-kit/md/ic_delete'
import {ic_undo} from 'react-icons-kit/md/ic_undo'
import {ic_launch} from 'react-icons-kit/md/ic_launch'
import {upload} from 'react-icons-kit/fa/upload'
import Tooltip from '@material-ui/core/Tooltip';
import $ from 'jquery'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { FiUpload } from "react-icons/fi";
import { FiDownload } from 'react-icons/fi';
import download from 'downloadjs'

import consultaCep from '../../services/consultaCep'
import { paramsToObject, validarcnpj, onlyNumbers, removeSpecialChar } from '../../utils/functions'
import { mcnpj, mTel, mCEP, mPRE } from '../../utils/masks'

import api from '../../services/api'

import './index.css';

const swal = withReactContent(Swal)

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
  
function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
}
  
const useStyles = makeStyles((theme) => ({
    root: {
      '& .MuiTabs-root': {
        backgroundColor: '#9c9595',
        '& .Mui-disabled': {
            color: '#9c9595', 
            }
      },
      flexGrow: 1,
      width: '100%',
      border: '2px solid #9c9595',
      borderRadius: 4,
      '& .MuiTabs-indicator': {
        backgroundColor: 'white',
        height: '5px'
      },
      '& .MuiTabs-scrollButtonsDesktop': {
        backgroundColor: '#9c9595',
      }
    //   backgroundColor: theme.palette.background.paper,
    },
}));

function TabsAeronave(props) {
    const classes = useStyles();

    //Tabs
    const [value, setValue] = React.useState(0);
    const handleChangeTabs = (event, newValue) => {
        setValue(newValue);
    };

    //Dados
    let data = props.data
    const setData = props.setData
    const handleChange = props.handleChange
    const [erroCep, setErroCep] = useState(false)
    const [erroCepOperacional, setErroCepOperacional] = useState(false)
    const [comboModelo, setComboModelo] = useState([{ value: '', display: '' }])
    const [blockModelo, setBlockModelo] = useState(true)
    const [prefixo, setPrefixo] = useState('')

    const handleImageChange = (image, fieldName) => {
        const auxValues = { ...data };
        auxValues[fieldName] = image
        setData(auxValues)
    }

    const uploadFile = (file, index, auxValues) => {
        return new Promise((resolve, reject) => {
          let campoFoto = 'foto_'+(index+1)
          let formData = new FormData();
          formData.append('file', file, file.name)
          api.post('/aeronave/postImage/'+data.pk_aer,formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
          }).then(async r => {
              if (r.status === 200) {
                api.post('/aeronave/changeImage/'+campoFoto+'/'+data.pk_aer+'/'+file.name).then(r=>{
                  if (r.status === 200) {
                    auxValues[campoFoto] = file.name
                    resolve(auxValues)
                  }
                })
              }
          })
        })
    }

    const selectFile = (e) => {
		e.preventDefault();
        swal.fire({
        title: 'ATENÇÃO',
        text: "Ao fazer o envio múltiplo de imagens, as imagens selecionadas atualmente serão sobrepostas. Confirma?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                $('#html_btn_fotos').click();
            }
        })		
    }

    const handleSelectedFile = async (e) => {
		let files = e.target.files;
        if (files.length > 10) swal.fire('Máximo de imagens ultrapassado!', 'Selecione no máximo 10 imagens!', 'warning')
        else {
            swal.fire({
                html: <FrLoading text="Enviando imagens..."/>,
                showConfirmButton: false,
                allowOutsideClick: false,
                allowEscapeKey: false
            })
            console.log(files)
            let index = 0   
            let auxValues = {...data}
            for (let file of files) {
                if (file) {
                    auxValues = await uploadFile(file, index, auxValues)
                }
                index++
            }
            setData(auxValues)
            swal.close()
        }
    }

    React.useEffect(() => {
        const fetchModelos = async () => {
            if (data.fk_afb > 0) {
                swal.fire({
                html: <FrLoading text="Buscando Modelos..."/>,
                showConfirmButton: false,
                allowOutsideClick: false,
                allowEscapeKey: false
                })
                setComboModelo(await api.getComboData('aeronave_modelo', data.fk_afb))
                swal.close()
                setBlockModelo(false)
            } else {
                const auxValues = { ...data };
                auxValues.fk_amo = null;
                setData(auxValues)
                setBlockModelo(true)
            }
        }
    
        fetchModelos()
    }, [props.data.fk_afb])



    //Documentos
    const [currentDataDocumentos, setCurrentDataDocumentos] = React.useState(null)
    const [propsTabDocumentos, setPropsTableDocumentos] = React.useState({
        selected: null,
    })
    const [editDocumentos, setEditDocumentos] = React.useState(false)
    const [newDocumento, setNewDocumento] = React.useState(false)
    const [blockTipoDocumento, setBlockTipoDocumento] = React.useState(true)
    const [selectedTdo, setSelectedTdo] = React.useState({})

    const selectDocumento = (event, codigo) => {
        setEditDocumentos(false)
        setNewDocumento(false)
        setPropsTableDocumentos({... propsTabDocumentos, selected: codigo })
    }

    React.useEffect(() => {
        setCurrentDataDocumentos(props.dataDocumentos.find((item) => item.codigo === propsTabDocumentos.selected))
    }, [propsTabDocumentos.selected])

    React.useEffect(() => {
        
    }, [props.dataDocumentos])

    React.useEffect(() => {
        const fetchModelos = async () => {
            if (currentDataDocumentos?.fk_mdl > 0) {
                swal.fire({
                    html: <FrLoading text="Buscando Tipos de Documento..."/>,
                    showConfirmButton: false,
                    allowOutsideClick: false,
                    allowEscapeKey: false
                })
                let comboTdo = await api.getTipoDocumento(currentDataDocumentos?.fk_mdl)
                props.setComboTipoDocumento(comboTdo)
                if (Number(currentDataDocumentos?.fk_tdo) > 0) {
                    let auxTdo = comboTdo.find((item) => Number(item.value) === Number(currentDataDocumentos?.fk_tdo))
                    setSelectedTdo(auxTdo)
                    console.log(currentDataDocumentos?.fk_tdo, auxTdo)
                }
                swal.close()
                setBlockTipoDocumento(false)
            } else {
                props.setComboTipoDocumento([{ value: '', display: '' }])
                setBlockTipoDocumento(true)
            }
        }
    
        fetchModelos()
        
    }, [currentDataDocumentos?.fk_mdl])

    React.useEffect(() => {
        let auxTdo = props.comboTipoDocumento.find((item) => Number(item.value) === Number(currentDataDocumentos?.fk_tdo))
        setSelectedTdo(auxTdo)
        console.log(currentDataDocumentos?.fk_tdo, auxTdo)
    }, [currentDataDocumentos?.fk_tdo])

    const handleChangeCheck = (e) =>{
        const auxValues = { ...data };
        switch (e.target.name) {
            case 'tem_contrato':
            auxValues[e.target.name] = (auxValues[e.target.name] === 'S' ? 'N' : 'S')
            break
        }
        setData(auxValues);
    }
    const handleChangeDocumentos = async (e) => {
        const auxValues = { ...currentDataDocumentos };
        
       
        switch (e.target.name) {     
            case 'arquivo':     
            let ext = e.target.files[0].type
            if( e.target.files[0].size > 2097152){
                swal.fire({
                    title: 'Erro!',
                    text: "Limite de upload - 2 Mb!",
                    icon: 'warning',
                });
                return false
                
            }else if(!(ext == 'application/pdf' || ext == 'image/jpeg')){
                    swal.fire({
                        title: 'Arquivo inválido!',
                        text: "Extensões permitidas: pdf, jpg ou jpeg",
                        icon: 'warning',
                    });
                    return false
                }
                
                else {
                    uploadArquivo(e.target.value, e);
                    auxValues[e.target.name] = e.target.value ;
                }
                break;
            default: auxValues[e.target.name] = e.target.value;
        }
        console.log(auxValues['arquivo'])
        console.log(e.target.value)
        
        setCurrentDataDocumentos(auxValues);
    }

    
    const uploadArquivo = (arquivo, e) => {
        console.log(arquivo)

        if( e.target.files[0].size > 2097152){
            swal.fire({
                title: 'Erro!',
                text: "O arquivo não ter mais de 2MB!",
                icon: 'warning',
            });
            return false
            
         };
        swal.fire({html: <FrLoading text="Atualizando Arquivo..."/>,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false})
        let file = e.target.files[0];
        let formData = new FormData();
        console.log(file)
        formData.append('file', file, file.name)
        api.post('aeronave/postArquivo/', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        }).then(r=>{
            console.log(r.data)
            if(r.data == 'File Changed!'){
                swal.fire({
                    title: 'Arquivo Importado',
                    text: "Arquivo alterado com sucesso!",
                    icon: 'success',
                })
                return true
            } else {
                swal.fire({
                    title: 'Erro!',
                    text: "O arquivo não foi atualizado!",
                    icon: 'warning',
                })
                return false
            }
        })
    }

    const handleArquivo = (e) => {
        if(editDocumentos){
            document.getElementById('arquivo').click()
        }
       
    }

    const handleDownload = (e,  arquivo) =>{
        e.preventDefault()
        api.post('aeronave/downloadArquivo?&arquivo='+currentDataDocumentos?.arquivo, {}, {responseType: 'blob'}).then((response) => {
            if(response.data.type == 'application/json'){
                swal.fire({
                    title: 'Arquivo Não Encontrado',
                    text: "Arquivo não foi encontrado no servidor!",
                    icon: 'error',
                })
              }  else {
                console.log(response, arquivo)
                let name = response.data.type == 'application/pdf' ? 'download.pdf' : 'download.jpg' 
                let type = ( response.data.type == 'application/pdf' ?'applicaton/pdf': 'application/jpeg')
                console.log(type)
                  var blob = new Blob([response.data], {
                    type: type
                  });
                  download(blob, name)
              }
        })
      }


    const saveDocumentos = () => {
        const auxValues = { ...currentDataDocumentos };
        auxValues.fk_aer = data.pk_aer
        auxValues.dt_emissao = auxValues.dt_emissao ? auxValues.dt_emissao.split('T')[0] : null
        auxValues.dt_validade = auxValues.dt_validade ? auxValues.dt_validade.split('T')[0] : null
        console.log(' - aqui ta ó le aqui')
        console.log(auxValues)
        if (newDocumento) {
            api.post('/aeronave/documentos/add', auxValues).then(r => {
                if (r.status === 200) {
                    swal.fire({
                        title: 'Registro Adicionado',
                        text: "O registro foi incluído com sucesso!",
                        icon: 'success',
                    }).then((result) => {
                        if (result.value) {
                            setPropsTableDocumentos({... propsTabDocumentos, selected: null })
                            setCurrentDataDocumentos({})
                            setEditDocumentos(false)
                            setNewDocumento(false)
                            props.reloadDocumentos()
                        }
                    })
                } else {
                    swal.fire({
                        title: 'Erro!',
                        text: "O registro não foi alterado!",
                        icon: 'warning',
                    })
                }
            })
        } else {
            api.post('/aeronave/documentos/edit', auxValues).then(r => {
                if (r.status === 200) {
                    swal.fire({
                        title: 'Registro Alteraddo',
                        text: "O registro foi alterado com sucesso!",
                        icon: 'success',
                    }).then((result) => {
                        if (result.value) {
                            setPropsTableDocumentos({... propsTabDocumentos, selected: null })
                            setCurrentDataDocumentos({})
                            setEditDocumentos(false)
                            setNewDocumento(false)
                            props.reloadDocumentos()
                        }
                    })
                } else {
                    swal.fire({
                        title: 'Erro!',
                        text: "O registro não foi alterado!",
                        icon: 'warning',
                    })
                }
            })
        }
    }

    const deleteDocumentos = () => {
        const auxValues = { ...currentDataDocumentos };
        swal.fire({
            title: 'ATENÇÃO',
            text: 'Deseja excluir o registro?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                api.post('/aeronave/documentos/delete/'+auxValues.pk_ado).then(r => {
                    if (r.status === 200) {
                        swal.fire({
                            title: 'Registro Excluído',
                            text: "O registro foi excluído com sucesso!",
                            icon: 'success',
                        }).then((result) => {
                            if (result.value) {
                                setPropsTableDocumentos({... propsTabDocumentos, selected: null })
                                setCurrentDataDocumentos({})
                                setEditDocumentos(false)
                                setNewDocumento(false)
                                props.reloadDocumentos()
                            }
                        })
                    } else {
                        swal.fire({
                            title: 'Erro!',
                            text: "O registro não foi alterado!",
                            icon: 'warning',
                        })
                    }
                })
            }
        })
    }

    //RelacionadosAeronave
    const [currentDataRelacionadosAeronave, setCurrentDataRelacionadosAeronave] = React.useState(null)
    const [propsTabRelacionadosAeronave, setPropsTabRelacionadosAeronave] = React.useState({
        selected: null,
    })
    const [editRelacionadosAeronave, setEditRelacionadosAeronave] = React.useState(false)
    const [newRelacionadosAeronave, setNewRelacionadosAeronave] = React.useState(false)
    const filtersRelacionadosAeronave = [
        { type: 'text', field: 'descricao', name: 'Empresa' },
        { type: 'period', field: 'data_aquisicao', name: 'Data Aquisição' },
        { type: 'period', field: 'data_baixa', name: 'Data Baixa'},
        { type: 'select', field: 'fk_sit', name: 'Situacao', data: props.comboSituacao}
    ]

    const selectRelacionadosAeronave = (event, codigo) => {
        setEditRelacionadosAeronave(false)
        setNewRelacionadosAeronave(false)
        setPropsTabRelacionadosAeronave({... propsTabRelacionadosAeronave, selected: codigo })
    }

    React.useEffect(() => {
        setCurrentDataRelacionadosAeronave(props.dataRelacionadosAeronave.find((item) => item.codigo === propsTabRelacionadosAeronave.selected))
    }, [propsTabRelacionadosAeronave.selected])

    React.useEffect(() => {

    }, [props.dataRelacionadosAeronave])

    const handleChangeRelacionadosAeronave = (e) => {
        const auxValues = { ...currentDataRelacionadosAeronave };
        switch (e.target.name) {
            case 'CNPJ':
            default: auxValues[e.target.name] = e.target.value;
        }
        setCurrentDataRelacionadosAeronave(auxValues);
    }

    const saveRelacionadosAeronave = () => {
        const auxValues = { ...currentDataRelacionadosAeronave };
        auxValues.fk_aer = props.pk_aer
        auxValues.data_aquisicao = auxValues.data_aquisicao ? auxValues.data_aquisicao.split('T')[0] : null
        auxValues.data_baixa = auxValues.data_baixa ? auxValues.data_baixa.split('T')[0] : null
        console.log(auxValues)
        if (newRelacionadosAeronave) {
            api.post('/cliente/aeronave/add', auxValues).then(r => {
                if (r.status === 200) {
                    swal.fire({
                        title: 'Registro Adicionado',
                        text: "O registro foi incluído com sucesso!",
                        icon: 'success',
                    }).then((result) => {
                        if (result.value) {
                            setPropsTabRelacionadosAeronave({... propsTabRelacionadosAeronave, selected: null })
                            setCurrentDataRelacionadosAeronave({})
                            setEditRelacionadosAeronave(false)
                            setNewRelacionadosAeronave(false)
                            props.reloadRelacionadosAeronave()
                        }
                    })
                } else {
                    swal.fire({
                        title: 'Erro!',
                        text: "O registro não foi alterado!",
                        icon: 'warning',
                    })
                }
            })
        } else {
            api.post('/cliente/aeronave/edit', auxValues).then(r => {
                if (r.status === 200) {
                    swal.fire({
                        title: 'Registro Alteraddo',
                        text: "O registro foi alterado com sucesso!",
                        icon: 'success',
                    }).then((result) => {
                        if (result.value) {
                            setPropsTabRelacionadosAeronave({... propsTabRelacionadosAeronave, selected: null })
                            setCurrentDataRelacionadosAeronave({})
                            setEditRelacionadosAeronave(false)
                            setNewRelacionadosAeronave(false)
                            props.reloadRelacionadosAeronave()
                        }
                    })
                } else {
                    swal.fire({
                        title: 'Erro!',
                        text: "O registro não foi alterado!",
                        icon: 'warning',
                    })
                }
            })
        }
    }

    const deleteRelacionadosAeronave = () => {
        const auxValues = { ...currentDataRelacionadosAeronave };
        swal.fire({
            title: 'ATENÇÃO',
            text: 'Deseja excluir o registro?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                api.post('/cliente/aeronave/delete/'+auxValues.pk_cae).then(r => {
                    if (r.status === 200) {
                        swal.fire({
                            title: 'Registro Excluído',
                            text: "O registro foi excluído com sucesso!",
                            icon: 'success',
                        }).then((result) => {
                            if (result.value) {
                                setPropsTabRelacionadosAeronave({... propsTabRelacionadosAeronave, selected: null })
                                setCurrentDataRelacionadosAeronave({})
                                setEditRelacionadosAeronave(false)
                                setNewRelacionadosAeronave(false)
                                props.reloadRelacionadosAeronave()
                            }
                        })
                    } else {
                        swal.fire({
                            title: 'Erro!',
                            text: "O registro não foi alterado!",
                            icon: 'warning',
                        })
                    }
                })
            }
        })
    }

    //Inspecoes

    // const [currentDataInspecoes, setCurrentDataInspecoes] = React.useState(null)
    // const [propsTabInspecoes, setPropsTableInspecoes] = React.useState({
    //     selected: null,
    // })
    // const [editInspecoes, setEditInspecoes] = React.useState(false)
    // const [newInspecao, setNewInspecao] = React.useState(false)

    // const selectInspecao = (event, codigo) => {
    //     setEditInspecoes(false)
    //     setNewInspecao(false)
    //     setPropsTableInspecoes({... propsTabInspecoes, selected: codigo })
    // }

    // React.useEffect(() => {
    //     setCurrentDataInspecoes(props.dataInspecoes.find((item) => item.codigo === propsTabInspecoes.selected))
    // }, [propsTabInspecoes.selected])

    // React.useEffect(() => {
        
    // }, [props.dataInspecoes])

    // const handleChangeInspecoes = (e) => {
    //     const auxValues = { ...currentDataInspecoes };
    //     switch (e.target.name) {
    //         case 'CNPJ':
    //         default: auxValues[e.target.name] = e.target.value;
    //     }
    //     setCurrentDataInspecoes(auxValues);
    // }

    // const saveInspecoes = () => {
    //     const auxValues = { ...currentDataInspecoes };
    //     auxValues.fk_aer = data.pk_aer
    //     auxValues.dt_emissao = auxValues.dt_emissao ? auxValues.dt_emissao.split('T')[0] : null
    //     auxValues.dt_validade = auxValues.dt_validade ? auxValues.dt_validade.split('T')[0] : null
    //     console.log(auxValues)
    //     if (newInspecao) {
    //         api.post('/aeronave/inspecoes/add', auxValues).then(r => {
    //             if (r.status === 200) {
    //                 swal.fire({
    //                     title: 'Registro Adicionado',
    //                     text: "O registro foi incluído com sucesso!",
    //                     icon: 'success',
    //                 }).then((result) => {
    //                     if (result.value) {
    //                         setPropsTableInspecoes({... propsTabInspecoes, selected: null })
    //                         setCurrentDataInspecoes({})
    //                         setEditInspecoes(false)
    //                         setNewInspecao(false)
    //                         props.reloadInspecoes()
    //                     }
    //                 })
    //             } else {
    //                 swal.fire({
    //                     title: 'Erro!',
    //                     text: "O registro não foi alterado!",
    //                     icon: 'warning',
    //                 })
    //             }
    //         })
    //     } else {
    //         api.post('/aeronave/inspecoes/edit', auxValues).then(r => {
    //             if (r.status === 200) {
    //                 swal.fire({
    //                     title: 'Registro Alteraddo',
    //                     text: "O registro foi alterado com sucesso!",
    //                     icon: 'success',
    //                 }).then((result) => {
    //                     if (result.value) {
    //                         setPropsTableInspecoes({... propsTabInspecoes, selected: null })
    //                         setCurrentDataInspecoes({})
    //                         setEditInspecoes(false)
    //                         setNewInspecao(false)
    //                         props.reloadInspecoes()

    //                     }
    //                 })
    //             } else {
    //                 swal.fire({
    //                     title: 'Erro!',
    //                     text: "O registro não foi alterado!",
    //                     icon: 'warning',
    //                 })
    //             }
    //         })
    //     }
    // }

    // const deleteInspecoes = () => {
    //     const auxValues = { ...currentDataInspecoes };
    //     swal.fire({
    //         title: 'ATENÇÃO',
    //         text: 'Deseja excluir o registro?',
    //         icon: 'question',
    //         showCancelButton: true,
    //         confirmButtonColor: '#3085d6',
    //         cancelButtonColor: '#d33',
    //         confirmButtonText: 'Sim',
    //         cancelButtonText: 'Não'
    //     }).then((result) => {
    //         if (result.value) {
    //             api.post('/aeronave/inspecoes/delete/'+auxValues.pk_ain).then(r => {
    //                 if (r.status === 200) {
    //                     swal.fire({
    //                         title: 'Registro Excluído',
    //                         text: "O registro foi excluído com sucesso!",
    //                         icon: 'success',
    //                     }).then((result) => {
    //                         if (result.value) {
    //                             setPropsTableInspecoes({... propsTabInspecoes, selected: null })
    //                             setCurrentDataInspecoes({})
    //                             setEditInspecoes(false)
    //                             setNewInspecao(false)
    //                             props.reloadInspecoes()
    //                         }
    //                     })
    //                 } else {
    //                     swal.fire({
    //                         title: 'Erro!',
    //                         text: "O registro não foi alterado!",
    //                         icon: 'warning',
    //                     })
    //                 }
    //             })
    //         }
    //     })
    // }
 
    //Edit Tabs
    const handleEdit = (e, tab) => {
        e.preventDefault()
        switch (tab) {
            case 'Documentos':
                setEditDocumentos(!editDocumentos)
            break

            // case 'Inspecoes':
            //     setEditInspecoes(!editInspecoes)
            // break

            case 'RelacionadosAeronave':
                setEditRelacionadosAeronave(!editRelacionadosAeronave)
            break
        }
    }

    //Add Tabs
    const handleAdd = (tab) => {
        switch (tab) {
            case 'Documentos':
                setEditDocumentos(true)
                setCurrentDataDocumentos({})
                setPropsTableDocumentos({... propsTabDocumentos, selected: null })
                setNewDocumento(true)
            break            

            // case 'Inspecoes':
            //     setEditInspecoes(true)
            //     setCurrentDataInspecoes({})
            //     setPropsTableInspecoes({... propsTabInspecoes, selected: null })
            //     setNewInspecao(true)
            // break            

            case 'RelacionadosAeronave':
                setEditRelacionadosAeronave(true)
                setCurrentDataRelacionadosAeronave({})
                setPropsTabRelacionadosAeronave({... propsTabRelacionadosAeronave, selected: null })
                setNewRelacionadosAeronave(true)
            break   
        }
    }

    //Save Tabs
    const handleSave = (e, tab) => {
        e.preventDefault()
        switch (tab) {
            case 'Documentos': 
                saveDocumentos()
            break
            // case 'Inspecoes': 
            //     saveInspecoes()
            // break
            case 'RelacionadosAeronave': 
                saveRelacionadosAeronave()
            break
        }
    }

    //Delete Tabs
    const handleDelete = (e, tab) => {
        e.preventDefault()
        switch (tab) {
            case 'Documentos': 
                deleteDocumentos()
            break
            // case 'Inspecoes': 
            //     deleteInspecoes()
            // break
            case 'RelacionadosAeronave': 
                deleteRelacionadosAeronave()
            break
        }
    }

     
    const handlePrefixo =(e) =>{
        let auxValues = {...data}
        
        auxValues[e.target.name] = mPRE(e.target.value, auxValues[e.target.name])
        setData(auxValues)          
    }
    
    const handleBlur = (e) => {
        e.preventDefault()
        console.log(data.prefixo)
        if(data.prefixo != ''){
            api.get('aeronave?prefixo='+data.prefixo).then(r => {
                console.log('tesste')
                if (r.data.length>0){
                    if (r.data[0].pk_aer != data.pk_aer){
                        swal.fire('Atenção!','Prefixo já registrado no sistema.', 'error').then(r => {
                            setTimeout(()=>{
                                document.getElementById('prefixo').focus()

                            }, 300)
                        })
                    }
                }
            })
        }

    }

    return (
        <div className={classes.root}>
        <AppBar position="static">
            <Tabs
                value={value}
                onChange={handleChangeTabs}
                indicatorColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
            >
                <Tab label="Dados" {...a11yProps(0)} />
                <Tab label="Documentos" disabled={!props.updateType} {...a11yProps(1)} />
                <Tab label="Fotos" disabled={!props.updateType} {...a11yProps(2)} />
                <Tab label="Relacionados" disabled={!props.updateType} {...a11yProps(3)} />
            </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
                {/* <div> */}
                    <FrTextInput
                        maxLength='150'
                        value={data.prefixo || ''} 
                        onChange={handlePrefixo}  
                        name="prefixo" 
                        id="prefixo" 
                        color="#49573B" 
                        label="Prefixo" 
                        variant="outlined" 
                        size="small"
                        onBlur={handleBlur}
                        // onBlur={handlePrefixo}
                        required
                        style={{width: 500}}
                />
                
                {/* <FrSelect 
                        value={data.ativo || []} 
                        style={{ width: 100 }} 
                        name="ativo" 
                        id="ativo" 
                        label='Ativo' 
                        data={[{ display: 'Sim', value: 'S' }, { display: 'Não', value: 'N' }]}
                        onChange={handleChange}
                    /> */}
                    <br/>
                    <FrSelect 
                        value={data.fk_afb || []} 
                        style={{ width: 300 }} 
                        name="fk_afb" 
                        id="fk_afb" 
                        label='Fabricante' 
                        data={props.comboFabricante}
                        onChange={handleChange}
                    />
                    <FrSelect 
                        value={data.fk_amo || []} 
                        style={{ width: 300 }} 
                        name="fk_amo" 
                        id="fk_amo" 
                        label='Modelo' 
                        data={comboModelo}
                        onChange={handleChange}
                        disabled={blockModelo}
                    />
                    <FrSelect 
                        value={data.fk_aco || []} 
                        style={{ width: 200 }} 
                        name="fk_aco" 
                        id="fk_aco" 
                        label='Cor' 
                        data={props.comboCor}
                        onChange={handleChange}
                    />
                    <br/>
                    <FrTextInput 
                        maxLength='10' 
                        value={data.nr_serie || ''} 
                        onChange={handleChange} 
                        name="nr_serie" 
                        id="nr_serie" 
                        color="#49573B" 
                        label="Nº Série" 
                        variant="outlined" 
                        size="small"
                        required
                        style={{width: 150}}
                    />
                    <FrTextInput 
                        maxLength='10' 
                        value={data.ano || ''} 
                        onChange={handleChange} 
                        name="ano" 
                        id="ano" 
                        color="#49573B" 
                        label="Ano" 
                        variant="outlined" 
                        size="small"
                        required
                        style={{width: 150}}
                    />
                    <FrSelect 
                        value={data.fk_aca || []} 
                        style={{ width: 400 }} 
                        name="fk_aca" 
                        id="fk_aca" 
                        label='Categoria' 
                        data={props.comboCategoria}
                        onChange={handleChange}
                    />
                    <br/>
                    <FrSelect 
                        value={data.fk_tcm || []} 
                        style={{ width: 200 }} 
                        name="fk_tcm" 
                        id="fk_tcm" 
                        label='Tipo Combustível' 
                        data={props.comboTipoCombustivel}
                        onChange={handleChange}
                    />   
                    <FrSelect 
                        value={data.acidentada || []} 
                        style={{ width: 200 }} 
                        name="acidentada" 
                        id="acidentada" 
                        label='Acidentada' 
                        data={[{value: 'S', display: 'Sim'}, {value: 'N', display: 'Não'}]}
                        onChange={handleChange}
                    />           
                    <FrSelect 
                        value={data.nao_aeronavega || []} 
                        style={{ width: 200 }} 
                        name="nao_aeronavega" 
                        id="nao_aeronavega" 
                        label='Aeronavegável' 
                        data={[{value: 'S', display: 'Não Aeronavegável'}, {value: 'N', display: 'Aeronavegável'}]}
                        onChange={handleChange}
                    />        
                    <br/>
                    <FrSelect 
                        value={data.fk_sae || []} 
                        style={{ width: 200 }} 
                        name="fk_sae" 
                        id="fk_sae" 
                        label='Situação' 
                        data={props.comboSituacao}
                        onChange={handleChange}
                    />
                    <br/>
                    {data.fk_sae != 1  ? 
                    <div>
                        <FormControlLabel
                        control={
                        <Checkbox
                            value={data.tem_contrato || ''}
                            checked={data.tem_contrato === 'S'}
                            name="tem_contrato"
                            id="tem_contrato"
                            size='small'
                            color="primary"
                            onChange={handleChangeCheck}
                        />
                        }
                        label="Possui Contrato"
                    />
                        <FrTextInput 
                        maxLength='150' 
                        value={data.proprietario || ''} 
                        onChange={handleChange} 
                        name="proprietario" 
                        id="proprietario" 
                        color="#49573B" 
                        label="Proprietario" 
                        variant="outlined" 
                        size="small"
                        required
                        style={{width: 500}}
                    />
                        <FrDatePicker
                        maxLength='50'
                        value={(data.dt_vcto_contrato || '').split('T')[0]}
                        onChange={handleChange}
                        name="dt_vcto_contrato"
                        id="dt_vcto_contrato"
                        color="#9c9595"
                        label="Dt. Vencimento Contrato"
                        variant="outlined"
                        size="small"
                        required
                        style={{ width: 200 }}
                    />
                    </div> : ''}
                    <br/>
                    <FrTextInput
                        maxLength='1000'
                        value={data.observacoes || ''}
                        onChange={handleChange}
                        name="observacoes"
                        id="observacoes"
                        color="#9c9595"
                        label="Observações"
                        variant="outlined"
                        size="small"
                        required
                        style={{ width: 500 }}
                        rows={5}
                        maxRows={5}
                        multiline
                    />
                    
                {/* </div> */}

        </TabPanel>
        <TabPanel value={value} index={1}>
            <div className="grupoTitulo">
                Documentos
            </div>
            <div className="grupoEdits">
                <div className="manutReg">
                    <ListRegisters width={800} height={200} tab="Documentos" handleClickItem={selectDocumento} selected={propsTabDocumentos.selected} items={props.dataDocumentos} textField={'nomedoc'} handleAdd={handleAdd} />
                    <div className="register">
                        <div className="buttons">
                            <Tooltip title="Salvar">
                                <button onClick={(e) => {handleSave(e, 'Documentos')}} disabled={!editDocumentos}>
                                    <Icon icon={ic_save} size={20}/>
                                </button>
                            </Tooltip>
                            <Tooltip title={editDocumentos ? 'Desfazer' : 'Editar'}>
                                <button onClick={(e) => {handleEdit(e, 'Documentos')}} disabled={newDocumento}>
                                    <Icon icon={editDocumentos ? ic_undo : ic_mode_edit} size={20}/>
                                </button>
                            </Tooltip>
                            <Tooltip title="Excluir">
                                <button onClick={(e) => {handleDelete(e, 'Documentos')}} disabled={(!(Number(propsTabDocumentos.selected) > 0)) || (newDocumento)}>
                                    <Icon icon={ic_delete} size={20}/>
                                </button>
                            </Tooltip>
                        </div>
                        <div className="fields">
                            <FrSelect 
                                value={currentDataDocumentos?.fk_mdl || []} 
                                style={{ flex: 1, minWidth: '200px' }} 
                                name="fk_mdl" 
                                id="fk_mdl" 
                                label='Modelo' 
                                data={props.comboModeloDocumento}
                                onChange={handleChangeDocumentos}
                                disabled={!editDocumentos}
                            />
                            <br/>
                            <FrSelect 
                                value={currentDataDocumentos?.fk_tdo || []} 
                                style={{ flex: 1, minWidth: '200px' }} 
                                name="fk_tdo" 
                                id="fk_tdo" 
                                label='Tipo de Documento' 
                                data={props.comboTipoDocumento}
                                onChange={handleChangeDocumentos}
                                disabled={!editDocumentos || blockTipoDocumento}
                            />
                            <br/>
                            <FrDatePicker 
                                maxLength='10' 
                                value={(currentDataDocumentos?.dt_emissao || '').split('T')[0]} 
                                onChange={handleChangeDocumentos} 
                                name="dt_emissao" 
                                id="dt_emissao" 
                                color="#49573B" 
                                label="Emissão" 
                                variant="outlined" 
                                size="small"
                                disabled={!editDocumentos}
                                
                                style={{width: 200}}
                            />
                            {/* <br/> */}
                            <FrTextInput
                                maxLength='150'
                                value={currentDataDocumentos?.nr_doc || ''} 
                                onChange={handleChangeDocumentos}  
                                name="nr_doc" 
                                id="nr_doc" 
                                color="#49573B" 
                                label="Número" 
                                variant="outlined" 
                                size="small"
                                disabled={!editDocumentos}
                                hidden={!(selectedTdo?.exige_numero  === 'S')}
                                style={{width: 200}}
                            />
                            {/* <br/> */}
                            <FrDatePicker
                                maxLength='50'
                                value={(currentDataDocumentos?.dt_validade || '').split('T')[0]}
                                onChange={handleChangeDocumentos}
                                name="dt_validade"
                                id="dt_validade"
                                color="#9c9595"
                                label="Validade"
                                variant="outlined"
                                size="small"
                                style={{ width: 200 }}
                                hidden={!(selectedTdo?.exige_validade  === 'S')}
                                disabled={!editDocumentos}
                            />
                            {/* <br/> */}
                            <FrSelect 
                                value={currentDataDocumentos?.revalidado || []} 
                                style={{ width: 200 }} 
                                name="revalidado" 
                                id="revalidado" 
                                label='Revalidado' 
                                data={[{value: 'S', display: 'Sim'}, {value: 'N', display: 'Não'}]}
                                onChange={handleChangeDocumentos}
                                hidden={!(selectedTdo?.exige_revalidacao  === 'S')}
                                disabled={!editDocumentos}
                            />
                            <div style={{display: 'flex', alignItems: 'center'}}>

                                <Tooltip title="Importar Arquivo">
                                    
                                    <div 
                                    style={{backgroundColor: '#9c9595', width: 140, heigth: 21, padding: 5, marginRight: 5}}
                                    onClick={handleArquivo}
                                    >
                                        
                                        <FiUpload size={20}/>
                                        Importar Arquivo
                                        <input 
                                            type='file'
                                            accept='.pdf, .jpg, .jpeg, .png'
                                            id='arquivo'
                                            name='arquivo'
                                            hidden
                                            onChange={handleChangeDocumentos}
                                            
                                        />
                                        <FrTextInput
                                            maxLength='150'
                                            value={currentDataDocumentos?.arquivo || ''} 
                                            onChange={handleChangeDocumentos}  
                                            name="arquivo" 
                                            id="arquivo" 
                                            color="#49573B" 
                                            label="arquivo" 
                                            variant="outlined" 
                                            size="small"
                                            hidden
                                        />

                                        

                                    </div>

                                </Tooltip> 
                                {currentDataDocumentos?.arquivo?.split('\\')[currentDataDocumentos?.arquivo.split('\\').length-1]}
                            </div>
                            {currentDataDocumentos?.arquivo? 
                            <>
                                <Tooltip title="Importar Arquivo">
                                    
                                    <div 
                                    style={{backgroundColor: '#9c9595', width: 140, heigth: 21, padding: 5, marginRight: 5 ,marginTop: 5}}
                                    onClick={handleDownload}
                                    
                                    >
                                        <FiDownload size={20}/>
                                        Baixar Arquivo
                                                                        

                                    </div>
                                
                                </Tooltip> 
                            </> 

                            : 

                            <></>

                            }
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="grupoTitulo">
                Inspeções
            </div>
            <div className="grupoEdits">
                <div className="manutReg">
                    <ListRegisters width={800} height={100} tab="Inspecoes" handleClickItem={selectInspecao} selected={propsTabInspecoes.selected} items={props.dataInspecoes} textField={'nomeins'} handleAdd={handleAdd} />
                    <div className="register">
                        <div className="buttons">
                            <Tooltip title="Salvar">
                                <button onClick={(e) => {handleSave(e, 'Inspecoes')}} disabled={!editInspecoes}>
                                    <Icon icon={ic_save} size={20}/>
                                </button>
                            </Tooltip>
                            <Tooltip title={editInspecoes ? 'Desfazer' : 'Editar'}>
                                <button onClick={(e) => {handleEdit(e, 'Inspecoes')}} disabled={newInspecao}>
                                    <Icon icon={editInspecoes ? ic_undo : ic_mode_edit} size={20}/>
                                </button>
                            </Tooltip>
                            <Tooltip title="Excluir">
                                <button onClick={(e) => {handleDelete(e, 'Inspecoes')}} disabled={(!(Number(propsTabInspecoes.selected) > 0)) || (newInspecao)}>
                                    <Icon icon={ic_delete} size={20}/>
                                </button>
                            </Tooltip>
                        </div>
                        <div className="fields">
                            <FrDatePicker 
                                maxLength='10' 
                                value={(currentDataInspecoes?.dt_emissao || '').split('T')[0]} 
                                onChange={handleChangeInspecoes} 
                                name="dt_emissao" 
                                id="dt_emissao" 
                                color="#49573B" 
                                label="Emissão" 
                                variant="outlined" 
                                size="small"
                                disabled={!editInspecoes}
                                style={{width: 200}}
                            />
                            <br/>
                            <FrDatePicker
                                maxLength='50'
                                value={(currentDataInspecoes?.dt_validade || '').split('T')[0]}
                                onChange={handleChangeInspecoes}
                                name="dt_validade"
                                id="dt_validade"
                                color="#9c9595"
                                label="Validade"
                                variant="outlined"
                                size="small"
                                style={{ width: 200 }}
                                disabled={!editInspecoes}
                            />
                            <br/>
                            <FrSelect 
                                value={currentDataInspecoes?.revalidado || []} 
                                style={{ width: 200 }} 
                                name="revalidado" 
                                id="revalidado" 
                                label='Revalidado' 
                                data={[{value: 'S', display: 'Sim'}, {value: 'N', display: 'Não'}]}
                                onChange={handleChangeInspecoes}
                                disabled={!editInspecoes}
                            />
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="grupoTitulo">
                Seguros
            </div>
            <div className="grupoEdits">
                    <FrDatePicker
                        maxLength='50'
                        value={(data.dt_inic_segreta || '').split('T')[0]}
                        onChange={handleChange}
                        name="dt_inic_segreta"
                        id="dt_inic_segreta"
                        color="#9c9595"
                        label="Período Seguro Reta - Início"
                        variant="outlined"
                        size="small"
                        style={{ width: 200 }}
                    />
                    <FrDatePicker
                        maxLength='50'
                        value={(data.dt_fim_segreta || '').split('T')[0]}
                        onChange={handleChange}
                        name="dt_fim_segreta"
                        id="dt_fim_segreta"
                        color="#9c9595"
                        label="Período Seguro Reta - Fim"
                        variant="outlined"
                        size="small"
                        style={{ width: 200 }}
                    />
                    <br/>
                    <FrDatePicker
                        maxLength='50'
                        value={(data.dt_inic_segcasco || '').split('T')[0]}
                        onChange={handleChange}
                        name="dt_inic_segcasco"
                        id="dt_inic_segcasco"
                        color="#9c9595"
                        label="Período Seguro Casco - Início"
                        variant="outlined"
                        size="small"
                        style={{ width: 200 }}
                    />
                    <FrDatePicker
                        maxLength='50'
                        value={(data.dt_fim_segcasco || '').split('T')[0]}
                        onChange={handleChange}
                        name="dt_fim_segcasco"
                        id="dt_fim_segcasco"
                        color="#9c9595"
                        label="Período Seguro Casco - Fim"
                        variant="outlined"
                        size="small"
                        style={{ width: 200 }}
                    />
                    <br/>
            </div>
            <div className="grupoTitulo">

            </div>
            <div className="grupoEdits">
                <FrSelect 
                    value={data.manual_operacao || []} 
                    style={{ width: 200 }} 
                    name="manual_operacao" 
                    id="manual_operacao" 
                    label='Manual de Operação' 
                    data={[{value: 'S', display: 'Possui'}, {value: 'N', display: 'Não possui'}]}
                    onChange={handleChange}
                />    
                <FrTextInput 
                    maxLength='10' 
                    value={data.revisao_manual || ''} 
                    onChange={handleChange} 
                    name="revisao_manual" 
                    id="revisao_manual" 
                    color="#49573B" 
                    label="Revisão" 
                    variant="outlined" 
                    size="small"
                    style={{width: 150}}
                />
                <FrDatePicker
                    maxLength='50'
                    value={(data.data_revisao || '').split('T')[0]}
                    onChange={handleChange}
                    name="data_revisao"
                    id="data_revisao"
                    color="#9c9595"
                    label="Data"
                    variant="outlined"
                    size="small"
                    style={{ width: 200 }}
                />
                <br/>
                <FrSelect 
                    value={data.check_list || []} 
                    style={{ width: 200 }} 
                    name="check_list" 
                    id="check_list" 
                    label='Check List' 
                    data={[{value: 'S', display: 'Possui'}, {value: 'N', display: 'Não possui'}]}
                    onChange={handleChange}
                />    
                <FrTextInput 
                    maxLength='10' 
                    value={data.revisao_check || ''} 
                    onChange={handleChange} 
                    name="revisao_check" 
                    id="revisao_check" 
                    color="#49573B" 
                    label="Revisão" 
                    variant="outlined" 
                    size="small"
                    style={{width: 150}}
                />
                <FrDatePicker
                    maxLength='50'
                    value={(data.data_revisao_check || '').split('T')[0]}
                    onChange={handleChange}
                    name="data_revisao_check"
                    id="data_revisao_check"
                    color="#9c9595"
                    label="Data"
                    variant="outlined"
                    size="small"
                    style={{ width: 200 }}
                />
            </div>
        </TabPanel>
        <TabPanel value={value} index={2}>
        <div>
            <input 
                type="file" 
                name="arquivo"
                id={"html_btn_fotos"}
                accept=".png, .jpg, .jpeg"
                multiple
                onChange={handleSelectedFile}
            />
            <button className="multipleImagesButton" type="file" onClick={selectFile}>
              <Icon icon={upload} size={20}/> Enviar Imagens
            </button>
            <div className="ScrollWrapper">
              <div className="RegLotesImagesUpload">
                <FrImageEdit visible={true} image={data.foto_1} title="Foto 1" fieldName="foto_1" onImageChange={handleImageChange} regName="aeronave" pk={data.pk_aer}/>
                <FrImageEdit visible={true} image={data.foto_2} title="Foto 2" fieldName="foto_2" onImageChange={handleImageChange} regName="aeronave" pk={data.pk_aer}/>
                <FrImageEdit visible={true} image={data.foto_3} title="Foto 3" fieldName="foto_3" onImageChange={handleImageChange} regName="aeronave" pk={data.pk_aer}/>
                <FrImageEdit visible={true} image={data.foto_4} title="Foto 4" fieldName="foto_4" onImageChange={handleImageChange} regName="aeronave" pk={data.pk_aer}/>
                <FrImageEdit visible={true} image={data.foto_5} title="Foto 5" fieldName="foto_5" onImageChange={handleImageChange} regName="aeronave" pk={data.pk_aer}/>
                <FrImageEdit visible={true} image={data.foto_6} title="Foto 6" fieldName="foto_6" onImageChange={handleImageChange} regName="aeronave" pk={data.pk_aer}/>
                <FrImageEdit visible={true} image={data.foto_7} title="Foto 7" fieldName="foto_7" onImageChange={handleImageChange} regName="aeronave" pk={data.pk_aer}/>
                <FrImageEdit visible={true} image={data.foto_8} title="Foto 8" fieldName="foto_8" onImageChange={handleImageChange} regName="aeronave" pk={data.pk_aer}/>
                <FrImageEdit visible={true} image={data.foto_9} title="Foto 9" fieldName="foto_9" onImageChange={handleImageChange} regName="aeronave" pk={data.pk_aer}/>
                <FrImageEdit visible={true} image={data.foto_10} title="Foto 10" fieldName="foto_10" onImageChange={handleImageChange} regName="aeronave" pk={data.pk_aer}/>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={value} index={3}>
            <div className="regRelacionados">
                <Nav.Link href="/ejr/cliente" target="_blank" className="atalhoRelacionados">
                    <Icon icon={ic_launch} size={20}/>
                    <strong>{'Clientes'}</strong> 
                </Nav.Link>

                <div className="manutReg Relacionados">
                    <ListRegistersDetailedFilter reload={props.reloadRelacionadosAeronave} filters={filtersRelacionadosAeronave} width={800} height={300} tab="RelacionadosAeronave" handleClickItem={selectRelacionadosAeronave} selected={propsTabRelacionadosAeronave.selected} items={props.dataRelacionadosAeronave} textField={'descricao'} handleAdd={handleAdd} />
                    <div className="register">
                        <div className="buttons">
                            <Tooltip title="Salvar">
                                <button onClick={(e) => {handleSave(e, 'RelacionadosAeronave')}} disabled={!editRelacionadosAeronave}>
                                    <Icon icon={ic_save} size={20}/>
                                </button>
                            </Tooltip>
                            <Tooltip title={editRelacionadosAeronave ? 'Desfazer' : 'Editar'}>
                                <button onClick={(e) => {handleEdit(e, 'RelacionadosAeronave')}} disabled={newRelacionadosAeronave}>
                                    <Icon icon={editRelacionadosAeronave ? ic_undo : ic_mode_edit} size={20}/>
                                </button>
                            </Tooltip>
                            <Tooltip title="Excluir">
                                <button onClick={(e) => {handleDelete(e, 'RelacionadosAeronave')}} disabled={(!(Number(propsTabRelacionadosAeronave.selected) > 0)) || (newRelacionadosAeronave)}>
                                    <Icon icon={ic_delete} size={20}/>
                                </button>
                            </Tooltip>
                        </div>
                        <div className="fields">
                            <FrSelect 
                                value={currentDataRelacionadosAeronave?.fk_pes_cli || []} 
                                name="fk_pes_cli" 
                                id="fk_pes_cli" 
                                label='Empresa' 
                                style={{ width: '100%' }}
                                data={props.comboClientes}
                                onChange={handleChangeRelacionadosAeronave}
                                disabled={!editRelacionadosAeronave}
                            />
                            <br/>
                            <FrDatePicker 
                                maxLength='10' 
                                value={(currentDataRelacionadosAeronave?.data_aquisicao || '').split('T')[0]} 
                                onChange={handleChangeRelacionadosAeronave} 
                                name="data_aquisicao" 
                                id="data_aquisicao" 
                                color="#49573B" 
                                label="Aquisição" 
                                variant="outlined" 
                                size="small"
                                disabled={!editRelacionadosAeronave}
                                style={{width: 200}}
                            />
                            <br/>
                            <FrDatePicker 
                                maxLength='10' 
                                value={(currentDataRelacionadosAeronave?.data_baixa || '').split('T')[0]} 
                                onChange={handleChangeRelacionadosAeronave} 
                                name="data_baixa" 
                                id="data_baixa" 
                                color="#49573B" 
                                label="Baixa" 
                                variant="outlined" 
                                size="small"
                                disabled={!editRelacionadosAeronave}
                                style={{width: 200}}
                            />
                            <br/>
                            <FrSelect 
                                value={currentDataRelacionadosAeronave?.fk_sit || []} 
                                style={{ width: 200 }} 
                                name="fk_sit" 
                                id="fk_sit" 
                                label='Situação' 
                                data={props.comboSituacao}
                                onChange={handleChangeRelacionadosAeronave}
                                disabled={!editRelacionadosAeronave}
                            />
                            <br/>
                            <FrTextInput
                                maxLength='1000'
                                value={currentDataRelacionadosAeronave?.observacoes || ''}
                                onChange={handleChangeRelacionadosAeronave}
                                name="observacoes"
                                id="observacoes"
                                color="#9c9595"
                                label="Observações"
                                variant="outlined"
                                size="small"
                                
                                style={{ width: '100%' }}
                                disabled={!editRelacionadosAeronave}
                                rows={5}
                                maxRows={5}
                                multiline
                            />
                        </div>
                    </div>
                </div>
            </div>
        </TabPanel>
        </div>
    );
}

export default TabsAeronave;